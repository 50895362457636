import React, { Component } from 'react';
import Button from '@components/Button';
import styles from './ExpandableCell.module.scss';
import {
    ExpandableCellProps,
    ExpandableCellState
} from './ExpandableCellModel';
import { defineMessages, injectIntl } from 'react-intl';

const expandableCellLabels = defineMessages({
    hideDescription: {
        id: 'ExpandableCell.hideDescr',
        defaultMessage: 'Hide description'
    },
    expandDescription: {
        id: 'ExpandableCell.expandDescr',
        defaultMessage: 'Expand description'
    }
});

class ExpandableCell extends Component<ExpandableCellProps, ExpandableCellState> {
    state = { isExpanded: false };

    onExpand = () => this.setState({ isExpanded: !this.state.isExpanded });
    createTags = tags => tags.map(tag => `#${tag}`).join(' ');
    render() {
        const { data: { name, description, tags }, intl: { formatMessage } } = this.props;
        const { isExpanded } = this.state;
        return (
            <div className={styles.ExpandableCellContainer}>
                <h3 id="expandCell_name" className={styles.ExpandableCellTitle}>{name}</h3>
                {description && (
                    <Button
                        onClick={this.onExpand}
                        ghost={true}
                        className={styles.ExpandableCellToggle}
                        style={{ width: 'auto' }}
                    >
                        <span>
                            {isExpanded ?
                                formatMessage(expandableCellLabels.hideDescription)
                                : formatMessage(expandableCellLabels.expandDescription)}
                        </span>
                    </Button>
                )}
                {isExpanded && <div id="expandCell_description">{description}</div>}
                {tags && <p id="expandCell_tags" className={styles.ExpandableCellTags}>{this.createTags(tags)}</p>}
            </div>
        );
    }
}

export default injectIntl(ExpandableCell);
