import React from 'react';
import RoundedCard from '../RoundedCard';
import { Loader } from '..';
import classes from './index.module.scss';
import classNames from 'classnames';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import Typography from '../Typography';
import { Link } from 'react-router-dom';
import { ROOT_ROUTE } from '../../constants/routing-constants';

type VerifyEmailCardProps = {
    isVerifying: boolean;
    isEmailVerified: boolean;
};

function VerifyEmailCard(props: VerifyEmailCardProps) {

    const iconClassNames = classNames(
        classes.IconHidden, {
        [classes.SuccessIcon]: props.isEmailVerified,
        [classes.ErrorIcon]: !props.isEmailVerified,
        [classes.IconVisible]: !props.isVerifying
    }
    );

    const labelClassNames = classNames(
        {
            [classes.LoaderDone]: !props.isVerifying
        }
    );

    return (
        <RoundedCard className={classes.VerifyEmailCard}>
            <div className={classes.StatusContainer}>
                {props.isVerifying && <Loader className={classes.LoaderOverrides} />}
                <Icon
                    type={props.isEmailVerified ? 'check-circle' : 'close-circle'}
                    className={iconClassNames}
                />
            </div>
            <div className={classes.LabelContainer}>
                {props.isVerifying && (<FormattedMessage
                    id="VerifyEmailCard.verify.emailLabel"
                    defaultMessage="Wait while we verify your email"
                />)}
                <p className={labelClassNames}>
                    {props.isEmailVerified ?
                        (<FormattedMessage
                            id="VerifyEmailCard.verify.success"
                            defaultMessage="Your email is now verified."
                        />) : (<FormattedMessage
                            id="VerifyEmailCard.verify.error"
                            defaultMessage="We couldn't verify your email. Please try again."
                        />)}
                </p>
            </div>
            {
                !props.isVerifying && props.isEmailVerified &&
                <Typography align="center" variant="paragraph">
                    <Link to={ROOT_ROUTE}>
                        <FormattedMessage
                            defaultMessage="Click here to get back to home"
                            id="components.VerifyEmailCard.getBackHome"
                        />
                    </Link>
                </Typography>
            }
            {
                !props.isVerifying && !props.isEmailVerified &&
                <Typography align="center" variant="paragraph">
                    <a href="javascript:;" onClick={() => location.reload()}>
                        <FormattedMessage
                            defaultMessage="Click here to retry"
                            id="components.VerifyEmailCard.clickHereToRetry"
                        />
                    </a>
                </Typography>
            }
        </RoundedCard>
    );
}

export default VerifyEmailCard;
