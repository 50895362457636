import { Form, Radio } from 'antd';
import React from 'react';
import Typography from '../../../Typography';
import { FormattedMessage } from 'react-intl';
import styles from './index.module.scss';
import Segment from '../../../Segment/Segment';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../../providers/UserPermissionsProvider';

const RadioGroup = Radio.Group;

export interface BooleanProps extends WithPermissionsToInteractReturnType {
    attributeName: React.ReactNode;
    titleTrue?: React.ReactNode;
    helperTrue: React.ReactNode;
    titleFalse?: React.ReactNode;
    helperFalse: React.ReactNode;
    value?: boolean;
    hasErrors: boolean;
    errors: string[];
    onChange(value: boolean): any;
}

const getRadioOption = (value: boolean, title: React.ReactNode, helper: React.ReactNode) => (
    <Radio value={value} className={styles.BooleanRadioWrapper}>
        <Typography variant="label" underline={true}>
            {title}
        </Typography>
        <Typography variant="caption" className={styles.HelperWrapper}>
            {helper}
        </Typography>
    </Radio>
);

function Boolean(props: BooleanProps) {
    return (
        <Segment
            divider={true}
            titleVariant="label"
            title={props.attributeName}
        >
            <Form.Item
                help={props.hasErrors ? props.errors.join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <RadioGroup
                    disabled={!props.hasPermissions}
                    onChange={(e) => props.onChange(e.target.value as boolean)}
                    value={props.value === undefined ? false : props.value}
                >
                    {getRadioOption(
                        true,
                        props.titleTrue
                            ? props.titleTrue
                            : <FormattedMessage
                                id={'components.ContentDefinitionSchemaEditor.BooleanAttribute.titleTrue.default'}
                                defaultMessage={'Yes'}
                            />,
                        props.helperTrue
                    )}
                    {getRadioOption(
                        false,
                        props.titleFalse
                            ? props.titleFalse
                            : <FormattedMessage
                                id={'components.ContentDefinitionSchemaEditor.BooleanAttribute.titleFalse.default'}
                                defaultMessage={'No'}
                            />,
                        props.helperFalse
                    )}
                </RadioGroup>
            </Form.Item>
        </Segment>
    );
}

export default withPermissionsToInteract(Boolean);
