import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { defineMessages, injectIntl } from 'react-intl';
import { SidebarItems, SideBarProps, SideBarState } from './SideBarModel';
import {
    baseRepositoriesPath,
    contentDefinitionBasePath,
    contentsBasePath,
    dashboardPath,
    mediaGalleryBasePath,
    publishingChannelBasePath,
    publishToLiveBasePath,
    createUsersPath,
    createWebhooksListPath,
    apiKeysPath,
    extensionsPath
} from '../../constants/routing-constants';

import styles from './SideBar.module.scss';
import { withRouter } from 'react-router';
import { userPermissionsTable } from '../../constants/user-permissions-table';
import { WebhookSvg } from '../../assets/custom_icons/components/webhook';
import SubMenu from 'antd/lib/menu/SubMenu';
import SidebarItem from '@scenes/Home/components/SidebarItem';

const sidebarTitles = defineMessages({
    home: {
        id: 'sideBar.home',
        defaultMessage: 'My Dashboard'
    },
    repositories: {
        id: 'sideBar.repositories',
        defaultMessage: 'Repositories'
    },
    contents: {
        id: 'sideBar.contents',
        defaultMessage: 'Contents'
    },
    mediaGalleries: {
        id: 'sideBar.mediaGalleries',
        defaultMessage: 'Media Galleries'
    },
    contentDefinitions: {
        id: 'sideBar.contentDefinitions',
        defaultMessage: 'Definitions'
    },
    publishingChannels: {
        id: 'sideBar.publishingChannels',
        defaultMessage: 'Channels'
    },
    publish: {
        id: 'sideBar.publishToLive',
        defaultMessage: 'Publish to live'
    },
    settings: {
        id: 'sideBar.settings',
        defaultMessage: 'Settings'
    },
    usersAndPermissions: {
        id: 'sideBar.usersAndPermissions',
        defaultMessage: 'Users'
    },
    webhooks: {
        id: 'sideBar.webhooks',
        defaultMessage: 'Webhooks'
    },
    spaceKeys: {
        id: 'sideBar.spaceKeys',
        defaultMessage: 'API keys'
    },
    developers: {
        id: 'sideBar.developers',
        defaultMessage: 'Developers'
    },
    extensions: {
        id: 'sideBar.extensions',
        defaultMessage: 'Extensions'
    }
});

const { Sider } = Layout;

class SideBar extends Component<SideBarProps, SideBarState> {

    constructor(props: SideBarProps) {
        super(props);
        this.state = {
            activeKey: this.findActiveMenuLink()
        };
    }

    findActiveMenuLink = () => {
        const paths = this.props.location.pathname.split('/');
        const mainSection = paths[3];

        if (mainSection === '') {
            return SidebarItems.DASHBOARD as SidebarItems;
        }

        const firstLevelKeys = [SidebarItems.CONTENTS, SidebarItems.CONTENT_DEFINITIONS, SidebarItems.MEDIA_GALLERIES,
        SidebarItems.PUBLISH_TO_LIVE, SidebarItems.REPOSITORIES,
        SidebarItems.PUBLISHING_CHANNELS,
        SidebarItems.USERS_AND_PERMISSIONS, SidebarItems.WEBHOOKS, SidebarItems.SpaceKeys, SidebarItems.EXTENSIONS];

        if (firstLevelKeys.indexOf(mainSection as SidebarItems) >= 0) {
            return mainSection as SidebarItems;
        }

        return this.state.activeKey;

    }

    onClick = (e) => {
        this.setState({
            activeKey: e.key
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;

        return (
            <Sider
                className={styles.SideBar}
                breakpoint="md"
                collapsedWidth={64}
            >
                <Menu
                    className={styles.MenuContainer}
                    mode="vertical"
                    onClick={this.onClick}
                    selectedKeys={[this.state.activeKey]}
                    theme="dark"
                >
                    <SidebarItem
                        key={SidebarItems.DASHBOARD}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="home"
                        title={formatMessage(sidebarTitles.home)}
                        to={dashboardPath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.CONTENTS}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="database"
                        title={formatMessage(sidebarTitles.contents)}
                        to={contentsBasePath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.REPOSITORIES}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="appstore"
                        title={formatMessage(sidebarTitles.repositories)}
                        to={baseRepositoriesPath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.CONTENT_DEFINITIONS}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="book"
                        title={formatMessage(sidebarTitles.contentDefinitions)}
                        to={contentDefinitionBasePath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.MEDIA_GALLERIES}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="picture"
                        title={formatMessage(sidebarTitles.mediaGalleries)}
                        to={mediaGalleryBasePath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.PUBLISH_TO_LIVE}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="cloud-upload"
                        title={formatMessage(sidebarTitles.publish)}
                        to={publishToLiveBasePath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.PUBLISHING_CHANNELS}
                        permissions={userPermissionsTable.SideBar.others}
                        className={styles.SideBarItemDefault}
                        icon="cluster"
                        title={formatMessage(sidebarTitles.publishingChannels)}
                        to={publishingChannelBasePath(spaceId)}
                    />
                    <SidebarItem
                        key={SidebarItems.USERS_AND_PERMISSIONS}
                        permissions={userPermissionsTable.SideBar.usersAndPermissions}
                        className={styles.SideBarItemDefault}
                        icon="team"
                        title={formatMessage(sidebarTitles.usersAndPermissions)}
                        to={createUsersPath(spaceId)}
                    />
                    <SubMenu
                        key="developers"
                        title={
                            <React.Fragment>
                                <Icon type="code" /> <span>{formatMessage(sidebarTitles.developers)}</span>
                            </React.Fragment>
                        }
                        popupOffset={[25, 0]}
                    >
                        <SidebarItem
                            key={SidebarItems.WEBHOOKS}
                            permissions={userPermissionsTable.SideBar.webhooks}
                            className={styles.SideBarItemDefault}
                            iconComponent={WebhookSvg}
                            title={formatMessage(sidebarTitles.webhooks)}
                            to={createWebhooksListPath(spaceId)}
                        />
                        <SidebarItem
                            key={SidebarItems.SpaceKeys}
                            permissions={userPermissionsTable.SideBar.others}
                            className={styles.SideBarItemDefault}
                            icon="key"
                            title={formatMessage(sidebarTitles.spaceKeys)}
                            to={apiKeysPath(spaceId)}
                        />
                        <SidebarItem
                            key={SidebarItems.EXTENSIONS}
                            permissions={userPermissionsTable.SideBar.others}
                            className={styles.SideBarItemDefault}
                            icon="layout"
                            title={formatMessage(sidebarTitles.extensions)}
                            to={extensionsPath(spaceId)}
                        />
                    </SubMenu>
                </Menu>
            </Sider>
        );
    }
}

export default withRouter(injectIntl(SideBar));
