import React, { Component } from 'react';
import { Form, Row, Popconfirm, Icon, Menu, Dropdown } from 'antd';
import Button from '@components/Button';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { ArrayFieldProps, ArrayFieldState } from './ArrayFieldModel';
import { DynamicContentField, LabelWithHint } from '../../';
const FormItem = Form.Item;
import styles from './ArrayField.module.scss';
import { observer } from 'mobx-react';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import { withArchivedStatus } from '@providers/ArchivedContentProvider';

const arrayFieldLabels = defineMessages({
    popconfirmRemoveMessage: {
        id: 'ArrayFieldLabels.PopConfirmRemoveMessage',
        defaultMessage: 'Remove item?'
    },
    removeButtonTitle: {
        id: 'ArrayFieldLabels.remove.title',
        defaultMessage: 'Remove item'
    },
    addNewAfterCurrent: {
        id: 'ArrayFieldLabels.addItemAfter.title',
        defaultMessage: 'Add new item after this'
    },
    addButtonMessage: {
        id: 'ArrayFieldLabels.addItem.title',
        defaultMessage: 'Append new item'
    },
    moveItemTitle: {
        id: 'ArrayFieldLabels.index.moveTo',
        defaultMessage: 'Move item to position {toPosition}'
    },
    currentItemIndex: {
        id: 'ArrayFieldLabels.itemPosition',
        defaultMessage: '{itemNumber} of {itemCount}'
    },
});

@observer
class DynamicArrayField extends Component<ArrayFieldProps, ArrayFieldState> {

    addField = () => {
        const { arrayFieldMeta } = this.props;
        arrayFieldMeta.addField();
    }

    addFieldAfter = (fieldIndex) => () => {
        const { arrayFieldMeta } = this.props;
        arrayFieldMeta.addField(undefined, undefined, fieldIndex + 1);
    }

    moveFieldTo = (fieldIndex, newIndex) => {
        const { arrayFieldMeta } = this.props;
        arrayFieldMeta.moveChildTo(fieldIndex, newIndex);
    }

    removeField = (fieldIndex) => e => {
        const { arrayFieldMeta } = this.props;
        arrayFieldMeta.removeField(fieldIndex);
    }

    isArrayChildRemovable = () => {
        const { arrayFieldMeta } = this.props;
        return !!arrayFieldMeta.constraints.minLength
            && arrayFieldMeta.value.length <= arrayFieldMeta.constraints.minLength;
    }

    displayRemoveButton = (index: number) => {
        const { archived, hasPermissions, intl: { formatMessage } } = this.props;
        return (archived || !hasPermissions || this.isArrayChildRemovable()
            ? (
                null
            )
            : (
                <Popconfirm
                    title={formatMessage(arrayFieldLabels.popconfirmRemoveMessage)}
                    overlayClassName={styles.ArrayFieldPopoverOverride}
                    placement="left"
                    onConfirm={this.removeField(index)}
                    onCancel={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <Button
                        icon="delete"
                        type="danger"
                        disabled={false}
                        className={styles.ArrayItemDeleteButton}
                        title={formatMessage(arrayFieldLabels.removeButtonTitle)}
                    />
                </Popconfirm>
            )
        );

    }

    displayAddToPositionButton = (index: number) => {
        const { archived, hasPermissions, intl: { formatMessage } } = this.props;
        return (archived || !hasPermissions || this.isArrayChildRemovable()
            ? null
            : (
                <Button
                    icon="plus"
                    type="default"
                    title={formatMessage(arrayFieldLabels.addNewAfterCurrent)}
                    className={styles.ArrayItemAddToPos}
                    onClick={this.addFieldAfter(index)}
                />
            )
        );

    }

    createItemActions = (currentIndex, items) => {
        const { intl: { formatMessage } } = this.props;

        const itemCount = items.length;

        const onClick = () => ({ key }) => {
            this.moveFieldTo(currentIndex, key);
        };

        const createPositionMenu = () => {
            return (
                <Menu onClick={onClick()}>
                    {items.map((_, index) => {
                        const title = formatMessage(arrayFieldLabels.moveItemTitle, { toPosition: index + 1 });

                        return (
                            <Menu.Item key={index} title={title}>{index + 1}</Menu.Item>
                        );
                    })}
                </Menu>
            );
        };

        const createPositionText = () => (
            formatMessage(
                arrayFieldLabels.currentItemIndex,
                {
                    itemNumber: currentIndex + 1,
                    itemCount: itemCount
                })
        );

        return (
            <div className={styles.ArrayItemButtonsWrapper}>
                <span className={styles.ArrayItemIndex}>
                    {items.length > 1 &&
                        <Dropdown overlay={createPositionMenu()} >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {createPositionText()}
                                <Icon type="down" />
                            </a>
                        </Dropdown>
                    }
                    {items.length === 1 && createPositionText()}
                </span>
                {this.displayAddToPositionButton(currentIndex)}
                {this.displayRemoveButton(currentIndex)}
            </div>
        );
    }

    createArrayFields = () => {
        const { arrayFieldMeta, parentFieldId, hasPermissions, archived, intl: { formatMessage } } = this.props;

        if (arrayFieldMeta.value.length === 0) {
            return (
                <Button
                    type="primary"
                    icon="plus"
                    disabled={archived || !hasPermissions}
                    onClick={this.addField}
                    className={styles.AddArrayFieldButton}
                    title={formatMessage(arrayFieldLabels.addButtonMessage)}
                >
                    <FormattedMessage
                        id="ArrayField.AddArrayElement"
                        defaultMessage="Add"
                    />
                </Button>
            );
        }

        return (
            arrayFieldMeta.value.map((field, index, items) => {
                let newParentFieldId: string;
                if (parentFieldId) {
                    newParentFieldId = parentFieldId + `.${arrayFieldMeta.id}` + `[${index}]`;
                } else {
                    newParentFieldId = arrayFieldMeta.id + `[${index}]`;
                }
                return (
                    <div className={styles.ArrayItemWrapper} key={field.id}>
                        {this.createItemActions(index, items)}
                        <DynamicContentField
                            genericFieldMeta={field}
                            parentFieldId={newParentFieldId}
                        />
                    </div>
                );
            })

        );
    }

    render() {
        const { arrayFieldMeta, hasPermissions, archived } = this.props;
        const { locale, formatMessage } = this.props.intl;
        return (
            <Row>
                <FormItem
                    htmlFor={arrayFieldMeta.id}
                    required={arrayFieldMeta.constraints.required}
                    label={<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        {!!arrayFieldMeta.hint
                            ? <LabelWithHint
                                label={arrayFieldMeta.labels[locale] || arrayFieldMeta.labels[arrayFieldMeta.locale]}
                                hint={arrayFieldMeta.hint[locale] || arrayFieldMeta.hint[arrayFieldMeta.locale]}
                            />
                            : arrayFieldMeta.labels[locale] || arrayFieldMeta.labels[arrayFieldMeta.locale]
                        }
                        {arrayFieldMeta.value.length > 0 &&
                            <Button
                                type="primary"
                                icon="plus"
                                disabled={archived || !hasPermissions}
                                onClick={this.addField}
                                className={styles.AddArrayFieldButton}
                                title={formatMessage(arrayFieldLabels.addButtonMessage)}
                            >
                                <FormattedMessage
                                    id="ArrayField.AddArrayElement"
                                    defaultMessage="Add"
                                />
                            </Button>
                        }
                    </div>}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    help={
                        arrayFieldMeta.errors.length > 0 ?
                            <div style={{ color: 'tomato' }}>{arrayFieldMeta.getFieldErrorMessages(
                                formatMessage,
                                locale
                            )}</div> :
                            undefined
                    }
                >

                    <div
                        className={`
                            ${styles.ArrayFieldWrapper}
                            ${arrayFieldMeta.errors.length > 0 ? styles.ArrayFieldErrorClass : ''}
                        `}
                    >
                        {this.createArrayFields()}
                    </div>
                </FormItem>
            </Row>
        );
    }
}

export default withArchivedStatus(withPermissionsToInteract(injectIntl(DynamicArrayField)));
