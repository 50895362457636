import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import {
    ContentListFiltersProps,
    ContentListFiltersState,
    InjectedProps
} from './ContentListFiltersModel';
import { List, Switch } from 'antd';
import {
    AppliedFilterListItem,
    Aside,
    AsideItem,
    ContentListByDefinitions,
    ContentListByRepositories,
    ContentListByTags,
    FilterBySearch
} from '../index';
import { DataAttributes, DataAttributesBuilder } from '../../services/DataAttributesBuilder';
import { ContentListUrlParams } from '../../stores/contentListStore/contentListStoreModel';
import { DefinitionOption, RepositoryOption } from '../../models/UI';

import styles from './ContentListFilters.module.scss';
import { ContentsStatus } from '@contentchef/contentchef-types';
import { ArchiveIcon } from '../CustomIcons/CustomIcons';

const ContentListFiltersLabel = defineMessages({
    filterByNamePlaceholder: {
        id: 'ContentListFiltersLabel.filterByNamePlaceholder',
        defaultMessage: 'Name, publicId or indexed value'
    },
    removeAllFilters: {
        id: 'ContentListFilters.RemoveAllFilters',
        defaultMessage: 'Reset filters'
    }
});

@inject('contentListFiltersStore', 'contentListStore')
@observer
class ContentListFilters extends Component<ContentListFiltersProps, ContentListFiltersState> {

    get injected() {
        return this.props as InjectedProps;
    }

    convertDataToStringArray = (ids: number[], listOfOption: (DefinitionOption | RepositoryOption)[]): string[] => {
        return ids.map(id => {
            const result = listOfOption.find(option => option.id === id);
            return [id, result!.mnemonicId].join('|');
        });
    }

    onRemoveAllFilters = () => {
        this.injected.contentListStore.searchParams.deleteAllUrlParams();
    }

    onRemoveSingleFilter = (key, value) => {
        this.injected.contentListStore.searchParams.deleteSingleUrlParamValue(key, value);
    }

    onChangeSearch = (value) => {
        this.injected.contentListStore.searchParams.setUrlParam(ContentListUrlParams.byNameAndPublicId, value);
    }

    onChangeSelectedContentDefinitions = (selectedIds: number[]) => {
        const { listOptions } = this.injected.contentListFiltersStore.contentDefinitionListOptions;
        this.injected.contentListStore.searchParams.appendUrlParam(
            ContentListUrlParams.definitionId, this.convertDataToStringArray(selectedIds, listOptions).join(',')
        );
        this.injected.contentListFiltersStore.setContentDefinitionOptions();
    }

    onChangeSelectedRepositories = (selectedIds: number[]) => {
        const { listOptions } = this.injected.contentListFiltersStore.pagedRepositoryListOptions;
        this.injected.contentListStore.searchParams.appendUrlParam(
            ContentListUrlParams.repositoryId, this.convertDataToStringArray(selectedIds, listOptions).join(',')
        );
    }

    onChangeSelectedTags = (value: string[]) => {
        this.injected.contentListStore.searchParams.appendUrlParam(ContentListUrlParams.tags, value.join(','));
        this.injected.contentListFiltersStore.setSuggestedTags();
    }

    onChangeContentsStatusFilter = (checked: boolean) => {
        if (checked) {
            this.injected.contentListStore.searchParams.deleteUrlParam(ContentListUrlParams.contentStatus);
        } else {
            this.injected.contentListStore.searchParams.setUrlParam(
                ContentListUrlParams.contentStatus, ContentsStatus.archived
            );
        }
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setSearchAttribute('search-content')
            .build();
    }

    createAsideRepositoryIdTags = () => {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const repositoryFilters = usableSearchParams[ContentListUrlParams.repositoryId].split(',');
        return (
            <AsideItem key="repository_applied_filters">
                <List
                    className={styles.ContentListFiltersListAppliedFilters}
                    dataSource={repositoryFilters}
                    renderItem={item => (
                        <AppliedFilterListItem
                            itemId={item.split('|')[0]}
                            itemName={item.split('|')[1]}
                            filterType={ContentListUrlParams.repositoryId}
                            onRemoveFilterCallback={this.onRemoveSingleFilter}
                        />
                    )}
                />
            </AsideItem>
        );
    }

    crateAsideTagTags = () => {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const tagFilters = usableSearchParams[ContentListUrlParams.tags].split(',');
        return (
            <AsideItem key="tags_applied_filters">
                <List
                    className={styles.ContentListFiltersListAppliedFilters}
                    dataSource={tagFilters}
                    renderItem={item => (
                        <AppliedFilterListItem
                            itemId={item}
                            filterType={ContentListUrlParams.tags}
                            onRemoveFilterCallback={this.onRemoveSingleFilter}
                        />
                    )}
                />
            </AsideItem>
        );
    }

    createAsideDefinitionIdTags = () => {
        const { contentListStore: { searchParams: { usableSearchParams } } } = this.injected;
        const definitionFilters = usableSearchParams[ContentListUrlParams.definitionId].split(',');
        return (
            <AsideItem key="contentdefinition_applied_filters">
                <List
                    className={styles.ContentListFiltersListAppliedFilters}
                    dataSource={definitionFilters}
                    renderItem={item => (
                        <AppliedFilterListItem
                            itemId={item.split('|')[0]}
                            itemName={item.split('|')[1]}
                            filterType={ContentListUrlParams.definitionId}
                            onRemoveFilterCallback={this.onRemoveSingleFilter}
                        />
                    )}
                />
            </AsideItem>
        );
    }

    renderAsideItems = () => {
        const { intl: { formatMessage } } = this.props;
        const {
            contentListStore: { searchParams: { usableSearchParams, urlSearchParams }, contentListData }
        } = this.injected;
        const asideItems = [(
            <AsideItem key="FilterByNameAndPublicId">
                <FilterBySearch
                    dataAttributes={this.createSearchDataAttributes}
                    placeholder={formatMessage(ContentListFiltersLabel.filterByNamePlaceholder)}
                    defaultValue={(
                        usableSearchParams[ContentListUrlParams.byNameAndPublicId]
                        && usableSearchParams[ContentListUrlParams.byNameAndPublicId]
                    ) || undefined}
                    onSearch={this.onChangeSearch}
                />
            </AsideItem>
        ), (
            <AsideItem key="ContentsStatus">
                <Switch
                    className={styles.ArchivedUnarchivedSwitch}
                    onChange={this.onChangeContentsStatusFilter}
                    checked={!urlSearchParams.has(ContentListUrlParams.contentStatus)}
                />
                {!!urlSearchParams.get(ContentListUrlParams.contentStatus)
                    ? <React.Fragment>
                        <FormattedMessage
                            id="ContentListFilters.OnlyArchived"
                            defaultMessage="Archived content"
                        />
                        <ArchiveIcon className={styles.ArchiveIconStyle} />
                    </React.Fragment>
                    : <FormattedMessage
                        id="ContentListFilters.OnlyUnarchived"
                        defaultMessage="Unarchived content"
                    />
                }
            </AsideItem>
        ), (
            <AsideItem key="FilterByContentDefinitions">
                <ContentListByDefinitions onApplyFilters={this.onChangeSelectedContentDefinitions} />
            </AsideItem>
        ), (
            <AsideItem key="FilterByRepositories">
                <ContentListByRepositories onApplyFilters={this.onChangeSelectedRepositories} />
            </AsideItem>
        ), (
            <AsideItem key="FilterByTags">
                <ContentListByTags onApplyFilters={this.onChangeSelectedTags} />
            </AsideItem>
        ), (
            <AsideItem key="list_total_items">
                <h4 style={{ textAlign: 'center' }}>{contentListData.total} contents</h4>
            </AsideItem>
        )];
        if (Object.keys(usableSearchParams).length > 0) {
            asideItems.push((
                <AsideItem key="RemoveAllAppliedFilters">
                    <a onClick={this.onRemoveAllFilters}>{formatMessage(ContentListFiltersLabel.removeAllFilters)}</a>
                </AsideItem>
            ));
            if (urlSearchParams.has(ContentListUrlParams.definitionId)) {
                asideItems.push(this.createAsideDefinitionIdTags());
            }
            if (urlSearchParams.has(ContentListUrlParams.repositoryId)) {
                asideItems.push(this.createAsideRepositoryIdTags());
            }
            if (urlSearchParams.has(ContentListUrlParams.tags)) {
                asideItems.push(this.crateAsideTagTags());
            }
        }
        return asideItems;
    }

    render() {
        return (
            <Aside>
                {this.renderAsideItems()}
            </Aside>
        );
    }
}

export default injectIntl(ContentListFilters);
