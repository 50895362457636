import {
    Field,
    LinkedContentSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function contentReference(defaultLanguage: string, name: string): Field<LinkedContentSchemaType> {
    return {
        name,
        type: SchemaTypeIds.LINKED_CONTENT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            lazy: true,
            definitionMnemonicId: undefined
        }
    };
}

function contentReferenceInArray(): LinkedContentSchemaType {
    return {
        type: SchemaTypeIds.LINKED_CONTENT,
        enableFacet: undefined,
        constraints: {
            lazy: true,
            definitionMnemonicId: undefined
        }
    };
}

export const linkedContentSnippets = {
    asField: {
        contentReference,
    },
    asArray: {
        contentReferenceInArray,
    }
};
