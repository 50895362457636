import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RepositoryForm, Aside, AsideItem, FormActions, ContainerWithAside } from '../../../components';
import { CreateRepositoryProps, InjectedProps } from './CreateRepositoryModel';
import { DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import { withRepositoryFormStores, withFormInitialization } from '../../../hoc';
import {
    createInfoNotification,
    createErrorNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../../providers/UserPermissionsProvider';

@inject('notificationStore', 'repositoryStore')
@observer
class CreateRepository extends Component<CreateRepositoryProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_CREATE)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_CREATE);
        }
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('repository')
            .setIdAttribute('addRepository')
            .setAddFormAttribute('repositoryCreate')
            .build();
    }

    onSubmitForm = async (values) => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.REPOSITORY_CREATE,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            await this.injected.repositoryStore.save(values);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_CREATE);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        }
    }

    render() {
        const { notifications } = this.injected.notificationStore;
        return (
            <ContainerWithAside
                dataAttributes={this.createDataAttributes().Attributes}
                disableContent={notifications.includes(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_CREATE)}
                renderAside={() => (
                    <Aside>
                        <AsideItem>
                            <FormActions
                                onSubmit={this.onSubmitForm}
                                permissions={userPermissionsTable.FormActions.repository}
                            />
                        </AsideItem>
                    </Aside>
                )}
            >
                <ManageFieldProvider permissions={userPermissionsTable.Forms.CreateRepositoryForm}>
                    <RepositoryForm />
                </ManageFieldProvider>
            </ContainerWithAside>
        );
    }
}

export default withRepositoryFormStores(withFormInitialization(injectIntl(CreateRepository)));
