import {
    DateSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface DateConstraints {
    required: boolean;
    from?: Date;
    to?: Date;
}

export type SerializedCloudinaryRawSchema = SerializedField<DateConstraints, {}>;

export function retrieveDateEditingType() {
    return ListItemIds.SimpleDate;
}

export function deserialize(serialized: SerializedCloudinaryRawSchema): Field<DateSchemaType> {
    return {
        constraints: {
            after: serialized.constraints.from ? serialized.constraints.from.toJSON() : undefined,
            before: serialized.constraints.to ? serialized.constraints.to.toJSON() : undefined,
            required: serialized.constraints.required,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.DATE,
        extension: serialized.extension
    };
}

export function serialize(deserialized: Field<DateSchemaType>, initialIndex: number): SerializedCloudinaryRawSchema {
    return {
        settings: {},
        constraints: {
            required: deserialized.constraints.required,
            from: deserialized.constraints.after ? new Date(deserialized.constraints.after) : undefined,
            to: deserialized.constraints.before ? new Date(deserialized.constraints.before) : undefined,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        editingType: retrieveDateEditingType(),
        initialIndex,
        extension: deserialized.extension
    };
}
