import { defaultFormatDate } from './FormHelpers';

export const formNormalizers = {
    'date': (newValue) => {
        newValue = defaultFormatDate(newValue);
        return newValue;
    },
    'onlineRange': (onlineRange) => {
        const formatOnlineRange: any = {};
        if (onlineRange) {

            if (onlineRange.onlineDate) {
                const formattedDate = defaultFormatDate(onlineRange.onlineDate);
                formatOnlineRange.onlineDate = formattedDate;
            }

            if (onlineRange.offlineDate) {
                const formattedDate = defaultFormatDate(onlineRange.offlineDate);
                formatOnlineRange.offlineDate = formattedDate;
            }
        }
        return onlineRange ? formatOnlineRange : onlineRange;
    },
    'json': (value) => {
        if (typeof value === 'object') {
            return value;
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return {};
        }
    },
    emptyStringToUndefined: (value: string) => {
        if (!value) {
            return undefined;
        }
        return value;
    }
};

export const formFieldNormalize = (fieldType) => (formNormalizers[fieldType]);
