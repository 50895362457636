import React from 'react';
import { Row, Col, Icon } from 'antd';
import { ModalTitleProps } from './ModalTitleModel';

import styles from './ModalTitle.module.scss';

function ModalTitle(props: ModalTitleProps) {
    return (
        <Row>
            <Col xs={24} className={styles.ModalTitleLabel}>
                {props.children}
            </Col>
            <div className={styles.ModalTitleCancel} onClick={props.onCancel}>
                <Icon type="close-circle" theme="filled" />
            </div>
        </Row>
    );
}

export default ModalTitle;
