import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RepositoryForm, Aside, AsideItem, FormActions, ContainerWithAside } from '@components/index';
import { UpdateRepositoryProps, InjectedProps } from './UpdateRepositoryModel';
import { DataAttributesBuilder } from '@services/DataAttributesBuilder';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '@constants/notifications-constants';
import {
    createInfoNotification,
    createErrorNotification,
    createSuccessNotification
} from '@services/Notification';
import { WithLoadingData, withRepositoryFormStores, withFormInitialization } from '@hoc/index';
import { userPermissionsTable } from '@constants/user-permissions-table';
import { ManageFieldProvider } from '@providers/UserPermissionsProvider';
import { repositoryNotFound } from '@constants/routing-constants';
import { Redirect } from 'react-router';
import { Modal } from 'antd';

type UpdateRepositoryState = {
    showModal: boolean;
    modalText: string;
    modalTitle: string;
    okText: string;
    onModalOk?(): void;
    onCancel?(): void;
};

const labels = defineMessages({
    onArchiveModalTitle: {
        id: 'UpdateRepository.onArchiveModalTitle',
        defaultMessage: 'Archive repository',
    },
    onArchiveModalText: {
        id: 'UpdateRepository.onArchiveModalText',
        defaultMessage: 'Archiving the selected repository will archive all contents related to this repository. ' +
            'Are you sure?',
    },
    onArchiveOkText: {
        id: 'UpdateRepository.onArchiveOkText',
        defaultMessage: 'Confirm archive'
    },
    onUnarchiveModalTitle: {
        id: 'UpdateRepository.onUnarchiveModalTitle',
        defaultMessage: 'Unarchive repository',
    },
    onUnarchiveModalText: {
        id: 'UpdateRepository.onUnarchiveModalText',
        defaultMessage: 'Unarchiving the selected repository will unarchive all contents that were archived ' +
            'with the repository. Are you sure?'
    },
    onUnarchiveOkText: {
        id: 'UpdateRepository.onUnarchiveOkText',
        defaultMessage: 'Confirm unarchive'
    }
});

function getInitialState(): UpdateRepositoryState {
    return {
        modalTitle: '',
        modalText: '',
        okText: '',
        onCancel: undefined,
        onModalOk: undefined,
        showModal: false,
    };
}

@inject('notificationStore', 'repositoryStore')
@observer
class UpdateRepository extends Component<UpdateRepositoryProps, UpdateRepositoryState> {
    state: UpdateRepositoryState = getInitialState();

    get injected() {
        return this.props as InjectedProps;
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE);
        }
    }

    getRepository = async () => {
        const { repositoryStore } = this.injected;
        const { repositoryId } = this.props.match.params;
        return repositoryStore.setRepository(+repositoryId);
    }

    createDataAttributes = () => {
        return new DataAttributesBuilder('repository')
            .setIdAttribute('updateRepository')
            .setAddFormAttribute('repositoryUpdate')
            .build();
    }

    onSubmitForm = async (values) => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.createAction
        );
        try {
            await this.injected.repositoryStore.update(values);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        } catch (e) {
            console.log(e);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction
            );
        }
    }

    onConfirmArchive = async () => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE,
            this.props.intl.formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.archiveAction
        );
        try {
            await this.injected.repositoryStore.toggleArchive(true);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.archiveAction
            );
            this.setState(getInitialState());
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.archiveAction
            );
        }
    }

    onArchive = () => {
        const { formatMessage } = this.props.intl;
        this.setState({
            showModal: true,
            modalTitle: formatMessage(labels.onArchiveModalTitle),
            modalText: formatMessage(labels.onArchiveModalText),
            okText: formatMessage(labels.onArchiveOkText),
            onCancel: () => { this.setState(getInitialState()); },
            onModalOk: this.onConfirmArchive
        });
    }

    onConfirmUnarchive = async () => {
        const { formatMessage } = this.props.intl;
        const { notificationStore } = this.injected;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE,
            this.props.intl.formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
        );
        try {
            await this.injected.repositoryStore.toggleArchive(false);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
            this.setState(getInitialState());
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_TOGGLEARCHIVE);
            createErrorNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
        }
    }

    onUnarchive = () => {
        const { formatMessage } = this.props.intl;
        this.setState({
            showModal: true,
            modalTitle: formatMessage(labels.onUnarchiveModalTitle),
            modalText: formatMessage(labels.onUnarchiveModalText),
            okText: formatMessage(labels.onUnarchiveOkText),
            onCancel: () => { this.setState(getInitialState()); },
            onModalOk: this.onConfirmUnarchive
        });
    }

    render() {
        const { notificationStore: { notifications }, repositoryStore: { repository } } = this.injected;
        return (
            <WithLoadingData promise={this.getRepository}>
                {!!repository
                    ? <ContainerWithAside
                        dataAttributes={this.createDataAttributes().Attributes}
                        disableContent={notifications.includes(NOTIFICATION_KEY_CONSTANTS.REPOSITORY_UPDATE)}
                        renderAside={() => (
                            <Aside>
                                <AsideItem>
                                    <FormActions
                                        onSubmit={this.onSubmitForm}
                                        permissions={userPermissionsTable.FormActions.repository}
                                        onArchive={repository.archived
                                            ? undefined
                                            : this.onArchive
                                        }
                                        onUnarchive={repository.archived
                                            ? this.onUnarchive
                                            : undefined
                                        }
                                    />
                                </AsideItem>
                            </Aside>
                        )}
                    >
                        <>
                            <ManageFieldProvider permissions={userPermissionsTable.Forms.UpdateRepositoryForm}>
                                <RepositoryForm repository={repository} />
                            </ManageFieldProvider>
                            <Modal
                                visible={this.state.showModal}
                                onOk={this.state.onModalOk}
                                onCancel={this.state.onCancel}
                                okText={this.state.okText}
                                title={<strong>{this.state.modalTitle}</strong>}
                                centered={true}
                                closable={false}
                                destroyOnClose={true}
                                confirmLoading={this.injected.repositoryStore.loaders.toggleArchive}
                            >
                                <div>{this.state.modalText}</div>
                            </Modal>
                        </>
                    </ContainerWithAside>
                    : <Redirect to={repositoryNotFound(this.props.match.params.spaceId)} />
                }
            </WithLoadingData>
        );
    }
}

export default withRepositoryFormStores(withFormInitialization(injectIntl(UpdateRepository)));
