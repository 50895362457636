import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { List } from 'antd';
import { ListContentsItem } from '@contentchef/contentchef-types';
import { contentSelectDefinitionPath, editContentPath } from '../../../constants/routing-constants';
import { WithLoadingData, withContentsStores } from '../../../hoc';
import { ContentListProps, ContentListState, InjectedProps } from './ContentListModel';
import {
    FloatingActions,
    ContainerWithAside,
    ContentListFilters,
    ContentsFooter,
    ContentItem
} from '../../../components';
import styles from './ContentList.module.scss';
import { ContentListUrlParams } from '../../../stores/contentListStore/contentListStoreModel';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/tables';
import { HasPermissions } from '../../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import AddButton from '../../../components/atoms/AddButton';

const labels = defineMessages({
    buttonTitle: {
        id: 'Content.Add',
        defaultMessage: 'Add new content'
    }
});

@inject('contentListStore')
@observer
class ContentList extends Component<ContentListProps, ContentListState> {
    get injected() {
        return this.props as InjectedProps;
    }

    getContentList = () => {
        return this.injected.contentListStore.initContentList();
    }

    printCreateButton = () => {
        const { match: { params: { spaceId } } } = this.props;
        return (
            <Link to={contentSelectDefinitionPath(spaceId)}>
                <AddButton
                    dataId="btn-create-content"
                    title={labels.buttonTitle}
                />
            </Link>
        );
    }

    goToContent = (content) => () => {
        const { spaceId } = this.props.match.params;
        this.props.history.push(editContentPath(spaceId, content.id));
    }

    render() {
        const { dataAttributes } = this.props;
        const { usableSearchParams = {}, urlSearchParams } = this.injected.contentListStore.searchParams;
        return (
            <React.Fragment>
                <ContainerWithAside
                    drawerIcon={'filter'}
                    dataAttributes={dataAttributes}
                    footer={<ContentsFooter
                        currentSize={urlSearchParams.has(ContentListUrlParams.bySize)
                            ? parseInt(usableSearchParams[ContentListUrlParams.bySize], undefined)
                            : DEFAULT_PAGE_SIZE
                        }
                        currentPage={urlSearchParams.has(ContentListUrlParams.byPage)
                            ? parseInt(usableSearchParams[ContentListUrlParams.byPage], undefined)
                            : DEFAULT_PAGE
                        }
                    />}
                    renderAside={() => (<ContentListFilters />)}
                >
                    <WithLoadingData
                        promise={this.getContentList}
                        rerenderWhenRouteChange={true}
                    >
                        <List
                            className={styles.ContentListContainer}
                            itemLayout="horizontal"
                            dataSource={this.injected.contentListStore.contentListData.contentsByRepo}
                            renderItem={(item: ListContentsItem) => (
                                <ContentItem
                                    content={item}
                                    onCardClick={this.goToContent(item)}
                                    showActions={true}
                                />
                            )}
                        />
                    </WithLoadingData>
                </ContainerWithAside>
                <HasPermissions permissions={userPermissionsTable.FloatingActions.Content}>
                    <FloatingActions>
                        {this.printCreateButton()}
                    </FloatingActions>
                </HasPermissions>
            </React.Fragment>
        );
    }
}

export default withContentsStores(injectIntl(ContentList));
