import React, { Component } from 'react';
import {
    Modal,
} from 'antd';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { autorun } from 'mobx';
import { withContentEditStores, WithLoadingData, withFormInitialization } from '../../../hoc';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import {
    EditContentProps, EditContentState, InjectedProps
} from './EditContentModel';
import {
    EditContentForm,
    Aside,
    AsideItem,
    AsidePanelItem,
    FormActions,
    PublishToStage,
    PublishingRequestsSummary,
    ContainerWithAside
} from '../../../components';
import { NOTIFICATION_KEY_CONSTANTS, NOTIFICATION_DEFAULT_MESSAGES } from '../../../constants/notifications-constants';
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { contentNotFoundPath, contentsBasePath } from '../../../constants/routing-constants';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import { HasPermissions, ManageFieldProvider } from '../../../providers/UserPermissionsProvider';
import { ArchivedContentProvider } from '../../../providers/ArchivedContentProvider';

const EditContentLabels = defineMessages({
    confirmArchiveModalTitle: {
        id: 'EditContent.confirmArchiveModalTitle',
        defaultMessage: 'Archive content'
    },
    confirmUnarchiveModalTitle: {
        id: 'EditContent.confirmUnarchiveModalTitle',
        defaultMessage: 'Unarchive content'
    },
    confirmArchiveModalContent: {
        id: 'EditContent.confirmArchiveModalContent',
        defaultMessage: 'Are you sure you want to archive this content?'
    },
    confirmUnarchiveModalContent: {
        id: 'EditContent.confirmUnarchiveModalContent',
        defaultMessage: 'Are you sure you want to unarchive this content?'
    },
    confirmToggleArchiveModalOk: {
        id: 'EditContent.confirmToggleArchiveModalOk',
        defaultMessage: 'Yes'
    },
    confirmToggleArchiveModalCancel: {
        id: 'EditContent.confirmToggleArchiveModalCancel',
        defaultMessage: 'No'
    },
    definitionNotFoundModalTitle: {
        id: 'EditContent.ModalTitle',
        defaultMessage: 'Content Definition Not Found',
    },
    definitionNotFoundModalOkButton: {
        id: 'EditContent.ModalOkButton',
        defaultMessage: 'Select Content Definition',
    }
});

@inject('editContentStore', 'formMetaStore', 'notificationStore', 'contentStatusStore', 'extensionManager')
@observer
class EditContent extends Component<EditContentProps, EditContentState> {
    errorModalInstance: { destroy(): void } | undefined = undefined;
    confirmArchiveModalInstance: { destroy(): void } | undefined = undefined;
    confirmUnarchiveModalInstance: { destroy(): void } | undefined = undefined;

    openErrorModal = autorun(
        () => {
            const { genericErrors } = this.injected.formMetaStore;
            if (genericErrors.contentDefinitionNotFound) {
                const { spaceId } = this.props.match.params;
                const { formatMessage } = this.props.intl;
                this.errorModalInstance = Modal.error({
                    title: formatMessage(EditContentLabels.definitionNotFoundModalTitle),
                    okText: formatMessage(EditContentLabels.definitionNotFoundModalOkButton),
                    keyboard: false,
                    onOk: () => this.props.history.push(contentsBasePath(spaceId)),
                    content: this.injected.intl.formatMessage(genericErrors.contentDefinitionNotFound)
                });
            }
        }
    );

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: EditContentProps) {
        super(props);

        this.state = {
            openDrawer: false
        };
    }

    componentWillUnmount() {
        const { notificationStore } = this.injected;
        const { notifications } = notificationStore;
        this.injected.formMetaStore.unsetGenericErrors();
        this.openErrorModal();
        if (this.errorModalInstance) {
            this.errorModalInstance.destroy();
        }
        if (this.confirmArchiveModalInstance) {
            this.confirmArchiveModalInstance.destroy();
        }
        if (this.confirmUnarchiveModalInstance) {
            this.confirmUnarchiveModalInstance.destroy();
        }
        if (notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
        }

        this.injected.extensionManager.destroy();
    }

    initializeEditContent = () => {
        const { contentId } = this.props.match.params;
        return Promise.all([
            this.injected.editContentStore.initializeEditContent(contentId),
            this.injected.contentStatusStore.setContentStatus(+contentId)
        ]);
    }

    onSubmitForm = async () => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        const { contentId } = this.props.match.params;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            NOTIFICATION_DEFAULT_MESSAGES.updateAction
        );
        try {
            await this.injected.editContentStore.updateContent();
            this.injected.contentStatusStore.setContentStatus(+contentId);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.updateAction
            );
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            e.handleError(formatMessage, NOTIFICATION_DEFAULT_MESSAGES.updateAction);
        }
    }

    onSubmitFormErrors = (errors: any) => {
        this.injected.formMetaStore.setClientSideErrors(errors);
    }

    confirmToggleArchive = async (archive: boolean) => {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        // const { match: { params: { spaceId } } } = this.props;
        createInfoNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST,
            formatMessage,
            archive
                ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
        );
        try {
            await this.injected.editContentStore.toggleArchiveContent(archive);
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createSuccessNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
            // this.props.history.push(contentsBasePath(spaceId));
        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST);
            createErrorNotification(
                formatMessage,
                archive
                    ? NOTIFICATION_DEFAULT_MESSAGES.archiveAction
                    : NOTIFICATION_DEFAULT_MESSAGES.unarchiveAction
            );
        }
    }

    onArchive = () => {
        const { formatMessage } = this.props.intl;
        this.confirmArchiveModalInstance = Modal.confirm({
            title: formatMessage(EditContentLabels.confirmArchiveModalTitle),
            content: formatMessage(EditContentLabels.confirmArchiveModalContent),
            onOk: () => this.confirmToggleArchive(true),
            okText: formatMessage(EditContentLabels.confirmToggleArchiveModalOk),
            cancelText: formatMessage(EditContentLabels.confirmToggleArchiveModalCancel)
        });
    }

    onUnarchive = () => {
        const { formatMessage } = this.props.intl;
        this.confirmUnarchiveModalInstance = Modal.confirm({
            title: formatMessage(EditContentLabels.confirmUnarchiveModalTitle),
            content: formatMessage(EditContentLabels.confirmUnarchiveModalContent),
            onOk: () => this.confirmToggleArchive(false),
            okText: formatMessage(EditContentLabels.confirmToggleArchiveModalOk),
            cancelText: formatMessage(EditContentLabels.confirmToggleArchiveModalCancel)
        });
    }

    requestPublish = (contentId: number, version: number) => {
        this.injected.editContentStore.createPublishingRequest(contentId, version);
        this.injected.contentStatusStore.pollUntilStatusChanges(contentId);
    }

    createDataAttributes = (contentDefinitionName: string): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setEditFormAttribute(contentDefinitionName)
            .build();
    }

    showContentDetail = () => {
        this.setState({
            openDrawer: true
        });
    }

    hideContentDetail = () => {
        this.setState({
            openDrawer: false
        });
    }

    render() {
        const {
            name, repository, publicId, onlineRange, tags, channels, dynamicFields
        } = this.injected.formMetaStore.formMeta;
        const { editContentData: { initialContentData }, isContentModified } = this.injected.editContentStore;
        const { notifications } = this.injected.notificationStore;
        return (
            <WithLoadingData
                promise={this.initializeEditContent}
            >
                {!!initialContentData ?
                    (
                        <ArchivedContentProvider archived={initialContentData.archived}>
                            <ContainerWithAside
                                dataAttributes={this.createDataAttributes(initialContentData.name).Attributes}
                                disableContent={
                                    notifications.includes(NOTIFICATION_KEY_CONSTANTS.CONTENT_API_KEY_REQUEST)
                                }
                                renderAside={() => (
                                    <Aside defaultActiveKeys={['publishing_queue']}>
                                        <AsideItem>
                                            <FormActions
                                                disableSave={isContentModified}
                                                onSubmit={this.onSubmitForm}
                                                onSubmitFormErrors={this.onSubmitFormErrors}
                                                onArchive={this.onArchive}
                                                onUnarchive={initialContentData.archived ? this.onUnarchive : undefined}
                                                permissions={userPermissionsTable.FormActions.content}
                                            />
                                        </AsideItem>
                                        {!initialContentData.archived &&
                                            <HasPermissions permissions={userPermissionsTable.FormActions.content}>
                                                <AsideItem>
                                                    <PublishToStage />
                                                </AsideItem>
                                            </HasPermissions>
                                        }
                                        <AsidePanelItem
                                            key="publishing_queue"
                                            header={
                                                <FormattedMessage
                                                    id="EditContent.sidebarPublishingQueue"
                                                    defaultMessage="Publishing Queue"
                                                />
                                            }
                                        >
                                            <PublishingRequestsSummary />
                                        </AsidePanelItem>
                                    </Aside>
                                )}
                            >
                                <ManageFieldProvider
                                    permissions={userPermissionsTable.Forms.AddContentForm}
                                >
                                    <EditContentForm
                                        fieldName={name}
                                        fieldRepository={repository}
                                        fieldPublicId={publicId}
                                        fieldOnlineRange={onlineRange}
                                        fieldTags={tags}
                                        fieldChannels={channels}
                                        dynamicFields={dynamicFields}
                                        onSubmitForm={this.onSubmitForm}
                                        onDelete={this.onArchive}
                                    />
                                </ManageFieldProvider>
                            </ContainerWithAside>
                        </ArchivedContentProvider>
                    ) : (
                        <Redirect to={contentNotFoundPath(this.props.match.params.spaceId)} />
                    )
                }
            </WithLoadingData>
        );
    }
}

export default withContentEditStores(withFormInitialization(injectIntl(EditContent)));
