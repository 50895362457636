import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import initAuthHub from './services/AuthHub';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID as string);

// refer to https://aws-amplify.github.io/docs/js/authentication#manual-setup
Amplify.configure({
    Auth: {
        authenticationFlowType: 'USER_SRP_AUTH',
        identityPoolId: process.env.IdentityPoolId,
        oauth: {
            domain: process.env.AppWebDomain,
            redirectSignIn: process.env.RedirectUriSignIn,
            redirectSignOut: process.env.RedirectUriSignOut,
            scope: [
                'openid',
                'profile'
            ],
            responseType: 'code',
        },
        region: process.env.Region,
        userPoolId: process.env.UserPoolId,
        userPoolWebClientId: process.env.UserPoolClientId,
    },
});

initAuthHub();

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
);

// Change unregister to register if you want to enable serviceWorker
serviceWorker.unregister();
