import { action, computed, IObservableArray, observable, toJS } from 'mobx';
import { diff } from 'deep-diff';
import {
    ContentDefinitionSchema,
    Field,
    FragmentDefinition,
    FragmentsTable,
    NumberSchemaType,
    TextSchemaType,
    SchemaTypeIds, I18NDictionary, ListItem
} from '@contentchef/contentchef-types';
import {
    ArrayAttributeViolation,
    AttributeViolation,
    ContentDefinitionSchemaErrors,
    SchemaFieldViolationsErrors,
    SchemaFragmentErrors
} from '@contentchef/contentchef-types';
import arrayMove from 'array-move';
import { SerializedField } from '../../components/ContentDefinitionSchemaEditor/FieldSerializer/types';
import FieldSerializer from '../../components/ContentDefinitionSchemaEditor/FieldSerializer';
import { TextConstraints } from '../../components/ContentDefinitionSchemaEditor/FieldSerializer/fields/text';
import { NumberConstraints } from '../../components/ContentDefinitionSchemaEditor/FieldSerializer/fields/number';
import { ArrayConstraints } from '../../components/ContentDefinitionSchemaEditor/FieldSerializer/fields/array';

export interface ContentDefinitionSchemaStoreModel {
    schema: SerializedDefinitionSchema;
    jsonEditorSchema: ContentDefinitionSchema | undefined;
    schemaViolations: ContentDefinitionSchemaErrors;
    customFieldsHasErrors: boolean;
    isDefinitionSchemaModified(): boolean;
    cleanJsonEditorSchema(): void;
    initStoreSchemas(schema: ContentDefinitionSchema): void;
    setJsonEditorSchema(schema: ContentDefinitionSchema): void;
    setCustomEditorSchema(): void;
    retrieveDeserializedDefinitionSchema(): ContentDefinitionSchema;
    saveDefaultLanguage(lang: string): void;
    saveNewSchemaField(field: SerializedField): void;
    editSchemaField(field: SerializedField, fieldIndexToEdit: number): void;
    deleteSchemaField(fieldIndexToEdit: number, hasErrors: boolean): void;
    cloneSchemaField(field: SerializedField, hasErrors: boolean): void;
    cloneSchemaFieldErrors(fieldIndex: number): void;
    changeSchemaFieldsPositions(oldIndex: number, newIndex: number): void;
    changeSchemaI18Language(newLang: string, oldLang: string): void;
    resetFieldsInitialIndex(): void;
    saveFragment(key: string, fragment: FragmentDefinition): void;
    editFragment(oldKey: string, key: string, fragment: FragmentDefinition): void;
    deleteFragment(key: string): void;
    cloneFragment(key: string, fragment: FragmentDefinition, newFragmentKey: string): void;
    setSchemaErrors(violations: ContentDefinitionSchemaErrors): void;
    resetSchemaErrors(): void;
    fieldHasErrors(fieldIndex: number): boolean;
    customFieldHasErrors(customFieldId: string): boolean;
    retrieveFieldErrors(fieldIndex: number): (AttributeViolation | ArrayAttributeViolation)[];
    retrieveCustomFieldErrors(customFieldId: string): (SchemaFieldViolationsErrors | AttributeViolation)[] | undefined;
    removeFieldErrors(fieldIndex: number): void;
    isJsonSchemaValidForCustomEditorSchema(): boolean;
}

type SerializedDefinitionSchema = {
    fields: IObservableArray<SerializedField>;
    fragments: FragmentsTable;
    lang: string;
};

class ContentDefinitionSchemaStore implements ContentDefinitionSchemaStoreModel {
    @observable schemaViolations: ContentDefinitionSchemaErrors = {};
    @observable schema: SerializedDefinitionSchema = {
        fields: observable.array([]),
        fragments: {},
        lang: ''
    };
    @observable jsonEditorSchema: ContentDefinitionSchema | undefined = undefined;
    private initialSchema: ContentDefinitionSchema;

    // tslint:disable-next-line:max-line-length
    isSchemaFieldViolationsErrorsArray(arg: ContentDefinitionSchemaErrors['fields']): arg is SchemaFieldViolationsErrors[] {
        return typeof arg !== 'undefined' && typeof arg !== 'string';
    }

    isSchemaFragmentsErrors(arg?: string | SchemaFragmentErrors): arg is SchemaFragmentErrors {
        return typeof arg !== 'undefined' && typeof arg !== 'string';
    }

    retrieveDeserializedDefinitionSchema = (): ContentDefinitionSchema => {
        return {
            lang: this.schema.lang,
            fragments: this.schema.fragments,
            fields: this.schema.fields.map(field => this.deserializeSchemaField(field))
        };
    }

    @action
    initStoreSchemas = (schema: ContentDefinitionSchema) => {
        this.initialSchema = toJS(schema);
        this.jsonEditorSchema = undefined;
        this.schema = {
            lang: schema.lang,
            fragments: schema.fragments,
            fields: observable.array(
                schema.fields.map((field, index) => this.serializeSchemaField(field, index))
            )
        };
    }

    @action
    cleanJsonEditorSchema = () => {
        this.jsonEditorSchema = undefined;
    }

    @action
    setJsonEditorSchema = (schema: ContentDefinitionSchema) => {
        this.jsonEditorSchema = schema;
    }

    @action
    setCustomEditorSchema = () => {
        if (this.jsonEditorSchema) {
            this.schema = {
                lang: this.jsonEditorSchema.lang,
                fragments: this.jsonEditorSchema.fragments,
                fields: observable.array(
                    this.jsonEditorSchema.fields.map((field, index) => this.serializeSchemaField(field, index))
                )
            };
        }
    }

    serializeSchemaField = (field: Field, index: number): SerializedField => {
        return FieldSerializer.serialize(field, index);
    }

    @action
    saveDefaultLanguage = (lang: string) => {
        this.schema.lang = lang;
    }

    @action
    editSchemaField = (field: SerializedField, fieldIndexToEdit: number) => {
        this.removeFieldErrors(fieldIndexToEdit);
        this.schema.fields.splice(fieldIndexToEdit, 1, field);
    }

    @action
    saveNewSchemaField = (field: SerializedField) => {
        this.schema.fields.push(field);
    }

    @action
    deleteSchemaField = (fieldIndexToDelete: number, hasErrors: boolean) => {
        if (hasErrors) {
            this.removeFieldErrors(fieldIndexToDelete);
        }
        this.schema.fields.splice(fieldIndexToDelete, 1);
    }

    @action
    cloneSchemaField = (field: SerializedField, hasErrors: boolean) => {
        const clonedField = { ...toJS(field, { recurseEverything: true }), initialIndex: this.schema.fields.length };
        this.schema.fields.push(clonedField);
        if (hasErrors) {
            this.cloneSchemaFieldErrors(field.initialIndex);
        }
    }

    @action
    cloneSchemaFieldErrors = (fieldToCloneIndex: number) => {
        const clonedFieldViolations = this.retrieveFieldErrors(fieldToCloneIndex);
        const clonedError: SchemaFieldViolationsErrors = {
            fieldIndex: this.schema.fields.length,
            violations: clonedFieldViolations
        };
        if (!!this.schemaViolations.fields && typeof this.schemaViolations.fields !== 'string') {
            this.schemaViolations.fields.push(clonedError);
        }
    }

    @action
    changeSchemaFieldsPositions = (oldIndex: number, newIndex: number) => {
        if (oldIndex !== newIndex) {
            this.schema.fields.replace(arrayMove(this.schema.fields, oldIndex, newIndex));
        }
    }

    @action
    changeSchemaI18Language = (newLang: string, oldLang: string) => {
        this.schema.fields.forEach(field => this.changeI18nFieldLabels(field, oldLang, newLang));
        for (const fragmentKey in this.schema.fragments) {
            if (this.schema.fragments.hasOwnProperty(fragmentKey)) {
                const fragmentVal = this.schema.fragments[fragmentKey];
                if (fragmentVal.labels && Object.keys(fragmentVal.labels).length) {
                    fragmentVal.labels[newLang] = fragmentVal.labels[oldLang];
                    delete fragmentVal.labels[oldLang];
                }
                fragmentVal.fields.forEach((field, index) => {
                    const serializedField = FieldSerializer.serialize(field, index);
                    this.changeI18nFieldLabels(serializedField, oldLang, newLang);
                    this.schema.fragments[fragmentKey].fields[index] = FieldSerializer.deserialize(serializedField);
                });
            }
        }
    }

    @action
    resetFieldsInitialIndex = () => {
        this.schema.fields.replace(this.schema.fields.map(
            (field, index) =>
                ({ ...field, initialIndex: index })
        ));
    }

    @action
    saveFragment = (key: string, fragment: FragmentDefinition): void => {
        this.schema.fragments[key] = fragment;
    }

    @action
    editFragment = (oldKey: string, key: string, fragment: FragmentDefinition): void => {
        delete this.schema.fragments[oldKey];
        this.schema.fragments[key] = fragment;
        if (this.customFieldHasErrors(oldKey)) {
            this.deleteFragmentErrors(oldKey);
        }
    }

    @action
    cloneFragment(key: string, fragment: FragmentDefinition, newFragmentKey: string): void {
        this.schema.fragments[newFragmentKey] = fragment;
        if (this.customFieldHasErrors(key)) {
            this.cloneFragmentErrors(key, newFragmentKey);
        }
    }

    @action
    cloneFragmentErrors = (oldKeyToCloneErrors: string, newKey: string) => {
        if (!!this.schemaViolations.fragments && this.isSchemaFragmentsErrors(this.schemaViolations.fragments)) {
            this.schemaViolations.fragments[newKey] = this.schemaViolations.fragments[oldKeyToCloneErrors];
        }
    }

    @action
    deleteFragment(key: string): void {
        delete this.schema.fragments[key];
        if (this.customFieldHasErrors(key)) {
            this.deleteFragmentErrors(key);
        }
    }

    @action
    deleteFragmentErrors = (key) => {
        if (!!this.schemaViolations.fragments && this.isSchemaFragmentsErrors(this.schemaViolations.fragments)) {
            delete this.schemaViolations.fragments[key];
        }
    }

    @action
    setSchemaErrors(violations: ContentDefinitionSchemaErrors): void {
        this.schemaViolations = violations;
    }

    @action
    resetSchemaErrors(): void {
        this.schemaViolations = {};
    }

    @computed
    get customFieldsHasErrors(): boolean {
        return !!this.schemaViolations.fragments && Object.keys(this.schemaViolations.fragments).length > 0;
    }

    retrieveFieldErrors = (fieldIndex: number): (AttributeViolation | ArrayAttributeViolation)[] => {
        if (this.isSchemaFieldViolationsErrorsArray(this.schemaViolations.fields)) {
            const errors = this.schemaViolations.fields.find(fieldViolations =>
                fieldViolations.fieldIndex === fieldIndex
            );
            if (!!errors && typeof errors.violations !== 'string') {
                return errors.violations;
            }
        }
        return [];
    }

    @action
    removeFieldErrors = (fieldIndex: number) => {
        if (!!this.schemaViolations.fields && this.isSchemaFieldViolationsErrorsArray(this.schemaViolations.fields)) {
            const fieldIndexToRemove = this.schemaViolations.fields
                .findIndex(fieldErrors => fieldErrors.fieldIndex === fieldIndex);
            this.schemaViolations.fields.splice(fieldIndexToRemove, 1);
        }
    }

    fieldHasErrors = (fieldIndex: number) => {
        if (!!this.schemaViolations.fields && this.isSchemaFieldViolationsErrorsArray(this.schemaViolations.fields)) {
            return !!this.schemaViolations.fields.find(fieldErrors => fieldErrors.fieldIndex === fieldIndex);
        } else {
            return false;
        }
    }

    customFieldHasErrors = (customFieldId: string): boolean => {
        if (this.isSchemaFragmentsErrors(this.schemaViolations.fragments)) {
            const fragmentsWithErrors = Object.keys(this.schemaViolations.fragments);
            return fragmentsWithErrors.includes(customFieldId);
        }
        return false;
    }

    retrieveCustomFieldErrors = (customFieldId: string) => {
        if (this.isSchemaFragmentsErrors(this.schemaViolations.fragments)) {
            return this.schemaViolations.fragments[customFieldId];
        }
        return undefined;
    }

    isDefinitionSchemaModified = () => {
        if (!!this.jsonEditorSchema) {
            const result = diff(
                toJS(this.initialSchema),
                toJS(this.jsonEditorSchema));
            return !!result;
        } else {
            const result = diff(
                JSON.parse(JSON.stringify(toJS(this.initialSchema))),
                JSON.parse(JSON.stringify(toJS(this.retrieveDeserializedDefinitionSchema())))
            );
            return !!result;
        }
    }

    isJsonSchemaValidForCustomEditorSchema = () => {
        if (
            !this.jsonEditorSchema || !this.jsonEditorSchema.fields ||
            !this.jsonEditorSchema.fragments || !this.jsonEditorSchema.lang
        ) {
            return false;
        }

        try {
            this.jsonEditorSchema.fields.forEach((field, index) => {
                this.serializeSchemaField(field, index);
            });
            for (const key in this.jsonEditorSchema.fragments) {
                if (this.jsonEditorSchema.fragments.hasOwnProperty(key)) {
                    this.jsonEditorSchema.fragments[key].fields.forEach((field, index) => {
                        this.serializeSchemaField(field, index);
                    });
                }
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    private deserializeSchemaField = (field: SerializedField) => {
        return FieldSerializer.deserialize(field);
    }

    private changeI18nFieldLabels = (field: SerializedField, oldLang: string, newLang: string) => {
        const changeLabels = (i18NDictionary: I18NDictionary) => {
            i18NDictionary[newLang] = i18NDictionary[oldLang];
            delete i18NDictionary[oldLang];
        };
        const changeListOfValuesLabels = (listOfValues?: ListItem<string | number>[]) => {
            if (listOfValues !== undefined) {
                listOfValues.forEach(value => changeLabels(value.labels));
            }
        };
        const changeRootFieldLabels = () => {
            if (Object.keys(field.labels).length) {
                changeLabels(field.labels);
            }
            if (Object.keys(field.hint).length) {
                changeLabels(field.hint);
            }
            if (Object.keys(field.placeholder).length) {
                changeLabels(field.placeholder);
            }
        };
        const checkFieldAndChangeValueLabels = () => {
            if (field.type === SchemaTypeIds.TEXT) {
                const typedField: SerializedField<TextConstraints> = field;
                changeListOfValuesLabels(typedField.constraints.listOfValues);
            } else if (field.type === SchemaTypeIds.NUMBER) {
                const typedField: SerializedField<NumberConstraints> = field;
                changeListOfValuesLabels(typedField.constraints.listOfValues);
            } else if (field.type === SchemaTypeIds.ARRAY) {
                const typedField: SerializedField<ArrayConstraints<any>> = field;
                if (typedField.constraints.items.type === SchemaTypeIds.TEXT) {
                    const typedArrayOfStringField: SerializedField<ArrayConstraints<TextSchemaType>> = typedField;
                    changeListOfValuesLabels(typedArrayOfStringField.constraints.items.constraints.listOfValues);
                } else if (typedField.constraints.items.type === SchemaTypeIds.NUMBER) {
                    const typedArrayOfNumberField: SerializedField<ArrayConstraints<NumberSchemaType>> = typedField;
                    changeListOfValuesLabels(typedArrayOfNumberField.constraints.items.constraints.listOfValues);
                }
            }
        };

        changeRootFieldLabels();
        checkFieldAndChangeValueLabels();
    }
}

export default ContentDefinitionSchemaStore;
