import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Icon } from 'antd';
import { ListItem, Media, RawFile } from '..';
import { MediaListItemProps, InjectedProps } from './MediaListItemModel';
import LazyImage from '../LazyImage';

import styles from './MediaListItem.module.scss';
import { CLOUDINARY_IMAGE, CLOUDINARY_VIDEO } from '../../services/cloudinaryUtils';

@inject('mediaGalleryFlowStore')
@observer
class MediaListItem extends Component<MediaListItemProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    renderMediaImage = () => {
        const { mediaItem } = this.props;
        if (mediaItem.data.resourceType === CLOUDINARY_IMAGE || mediaItem.data.resourceType === CLOUDINARY_VIDEO) {
            return (
                <LazyImage
                    src={mediaItem.data.thumbnailUrl}
                    className={styles.MediaListItemImage}
                />
            );
        } else {
            return (
                <Icon
                    className={styles.MediaListItemRawFileIcon}
                    type="file"
                    twoToneColor="#00AEEF"
                    theme="twoTone"
                />
            );
        }
    }

    renderMediaDescription = () => {
        const { mediaItem } = this.props;
        return (
            <p className={styles.MediaListImageDescription}>
                {mediaItem.retrieveImageName()}
            </p>
        );
    }

    renderMediaThumbnail = () => {
        const { onClick, mediaItem } = this.props;
        if (mediaItem.data.resourceType === CLOUDINARY_IMAGE || mediaItem.data.resourceType === CLOUDINARY_VIDEO) {
            return (
                <Media
                    media={mediaItem}
                    onClick={onClick}
                />
            );
        } else {
            return (
                <RawFile
                    media={mediaItem}
                />
            );
        }
    }

    onClickMedia = () => {
        const { onClick, mediaItem } = this.props;
        if (onClick) {
            onClick(mediaItem);
        }
    }

    render() {
        const { mediaItem, columnClassName } = this.props;
        const { media: activeMedia } = this.injected.mediaGalleryFlowStore;
        return (
            <Col
                xs={24}
                sm={12}
                lg={6}
                xl={4}
                key={mediaItem.data.publicId}
                className={columnClassName}
                data-media-name={mediaItem.retrieveImageName()}
            >
                <ListItem
                    renderPreview={this.renderMediaImage}
                    renderDescription={this.renderMediaDescription}
                    active={activeMedia ? activeMedia.data.publicId === mediaItem.data.publicId : false}
                    renderOnHover={this.renderMediaThumbnail}
                    onClick={this.onClickMedia}
                />
            </Col>
        );
    }
}

export default MediaListItem;
