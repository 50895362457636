import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Empty } from 'antd';
import { FolderListItem, MediaListItem } from '..';
import { MediaListProps, InjectedProps } from './MediaListModel';
import styles from './MediaList.module.scss';
import { FormattedMessage } from 'react-intl';

@inject('mediaGalleryFlowStore')
@observer
class MediaList extends Component<MediaListProps> {
    container: HTMLDivElement;

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        this.container.addEventListener('scroll', (event: Event) => {
            const { nextCursor } = this.injected.mediaGalleryFlowStore;
            if (event && event.target && nextCursor) {
                const element = event.target as Element;
                const scrollToTriggerApi = element.scrollHeight - element.clientHeight;
                const currentScroll = element.scrollTop;
                if (currentScroll === scrollToTriggerApi) {
                    this.injected.mediaGalleryFlowStore.setMediaList(nextCursor);
                }
            }
        });
    }

    render() {
        const { mediaList, folders, includeFolders } = this.injected.mediaGalleryFlowStore;
        const { onClick } = this.props;
        return (
            <React.Fragment>
                <div
                    className={styles.MediaListContainer}
                    ref={(container) => { this.container = container as HTMLDivElement; }}
                >
                    {
                        includeFolders &&
                        folders.map(folder => {
                            return (
                                <FolderListItem
                                    key={folder.path}
                                    columnClassName={styles.MediaListColumns}
                                    folder={folder}
                                />
                            );
                        })
                    }
                    {
                        mediaList.map(mediaItem => {
                            return (
                                <MediaListItem
                                    columnClassName={styles.MediaListColumns}
                                    key={`${mediaItem.data.resourceType}_${mediaItem.data.publicId}`}
                                    mediaItem={mediaItem}
                                    onClick={onClick}
                                />
                            );
                        })
                    }
                    {
                        mediaList.length === 0 && folders.length === 0 && (
                            <Empty
                                description={
                                    <FormattedMessage
                                        id="MediaList.emptyMedias"
                                        defaultMessage="Media not found in this folder."
                                    />
                                }
                            />
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default MediaList;
