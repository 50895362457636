import {
    Field,
    FragmentSchemaType, SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface FragmentConstraints {
    required: boolean;
    name: string;
}

export type SerializedFragmentSchema = SerializedField<FragmentConstraints, {}>;

export function retrieveFragmentEditingType(): ListItemIds {
    return ListItemIds.CustomField;
}

export function deserialize(serialized: SerializedFragmentSchema): Field<FragmentSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required,
            name: serialized.constraints.name!
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.FRAGMENT,
        extension: serialized.extension
    };
}

export function serialize(deserialized: Field<FragmentSchemaType>, initialIndex: number): SerializedFragmentSchema {
    return {
        settings: {},
        constraints: {
            required: deserialized.constraints.required,
            name: deserialized.constraints.name
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        editingType: retrieveFragmentEditingType(),
        initialIndex,
        extension: deserialized.extension
    };
}
