import React from 'react';
import { Input, Select } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldText } from '../FormFields';
import { ConstraintsToMatch, fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

const Option = Select.Option;

export class TextTypeMatcher implements FieldMatcher {

    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.TEXT)) { return null; }
        const fieldText = field as FieldText;
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);

        function elementToRender(disabled: boolean) {
            return (
                <Input
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={fieldText.value}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);

    }
}

export class TextWithListOfValuesMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeAndConstraintMatcher(
            field, SchemaTypeIds.TEXT, ConstraintsToMatch.listOfValues
        )) {
            return null;
        }
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);
        const fieldText = field as FieldText;

        function customOnChange(value: string | undefined) {
            onChange(value === undefined ? '' : value);
        }

        function elementToRender(disabled: boolean) {
            return (
                <Select
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={fieldText.value}
                    onChange={customOnChange}
                    allowClear={true}
                >
                    {fieldText.constraints.listOfValues!.map((value) => {
                        return (
                            <Option key={value.code} value={value.code}>
                                {value.labels[userLocale] || value.labels[field.locale]}
                            </Option>
                        );
                    })}
                </Select>
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
