import React, { Component, ReactElement } from 'react';
import { WithLoadingDataProps, WithLoadingDataState } from './WithLoadingDataModel';
import { LoaderManagerStore } from '../../stores';
import Loader from '../../components/Loader/Loader';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { LoaderManagerStoreModel } from '../../stores/loaderManagerStore/loaderManagerStoreModel';

import styles from './WithLoadingData.module.scss';

@observer
class WithLoadingData extends Component<WithLoadingDataProps, WithLoadingDataState> {
    loaderManagerStore: LoaderManagerStoreModel;

    constructor(props: WithLoadingDataProps) {
        super(props);

        this.loaderManagerStore = new LoaderManagerStore();
    }

    UNSAFE_componentWillReceiveProps(nextProps: WithLoadingDataProps) {
        if (nextProps.location.search !== this.props.location.search && this.props.rerenderWhenRouteChange) {
            this.executeApiCall();
        }
    }

    componentDidMount() {
        this.executeApiCall();
    }

    componentWillUnmount() {
        delete this.loaderManagerStore;
    }

    async executeApiCall() {
        this.loaderManagerStore.setLoader();
        await this.props.promise();
        if (this.loaderManagerStore) { this.loaderManagerStore.unsetLoader(); }
    }

    render() {
        if (this.props.broadcastLoading && Array.isArray(this.props.children)) {
            throw new Error('With broadcastLoading childrenProps must be a single JSX.ELEMENT');
        }
        if (this.props.broadcastLoading) {
            return (
                React.cloneElement(
                    this.props.children as ReactElement<any>,
                    { isLoading: this.loaderManagerStore.showLoader }
                ));
        }
        if (this.loaderManagerStore.mustShowLoader) {
            return (<Loader className={styles.CenteredLoader} />);
        }
        return this.props.children;
    }
}

export default withRouter(WithLoadingData);
