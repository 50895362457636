import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
    Checkbox,
    List
} from 'antd';
import { FilterByRepositoriesProps, FilterByRepositoriesState } from './FilterByRepositoriesModel';
const ListItem = List.Item;

import styles from './FilterByRepositories.module.scss';
import { RepositoryOption } from '../../models/UI';

@observer
class FilterByRepositories extends Component<FilterByRepositoriesProps, FilterByRepositoriesState> {

    render() {
        const { listRepository, defaultValue, onChange, isLoading, showMore, onLoadMore } = this.props;
        const filteredRepositories = defaultValue
            ? listRepository.filter(repository => !defaultValue.find(value => value === repository.id))
            : listRepository;
        return (
            <React.Fragment>
                <List
                    className={styles.FilterByRepositoriesListWrapper}
                    loading={isLoading}
                    loadMore={showMore
                        ? <a onClick={onLoadMore}>Load More</a>
                        : null
                    }
                    dataSource={filteredRepositories}
                    renderItem={(repository: RepositoryOption) => (
                        <ListItem key={repository.id}>
                            <Checkbox
                                id={repository.id.toString()}
                                className={styles.FilterByRepositoriesCheckboxWrapper}
                                onChange={onChange}
                            >
                                <span className={styles.FilterByRepositoriesOptionWrapper}>
                                    <span className={styles.FilterByRepositoriesOptionColorWrapper}>
                                        <span
                                            className={styles.FilterByRepositoriesOptionColor}
                                            style={{ backgroundColor: repository.color }}
                                        />
                                    </span>
                                    <span className={styles.FilterByRepositoriesOptionNameWrapper}>
                                        <p className={styles.FilterByRepositoriesOptionName}>
                                            {repository.name} ({repository.mnemonicId})
                                        </p>
                                    </span>
                                </span>
                            </Checkbox>
                        </ListItem>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default FilterByRepositories;
