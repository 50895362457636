import {
    NumberSchemaType,
    Field,
    SchemaTypeIds, ListItem, NumberFieldConstraints
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface NumberConstraints {
    listOfValues?: ListItem<number>[];
    min?: number;
    max?: number;
    required: boolean;
}

export type SerializedNumberSchema = SerializedField<NumberConstraints, {}>;

export function retrieveNumberEditingType(constraints: NumberFieldConstraints): ListItemIds {
    if (constraints.hasOwnProperty('listOfValues')) {
        return ListItemIds.ListOfNumberValues;
    }
    return ListItemIds.SimpleNumber;
}

export function deserialize(serialized: SerializedNumberSchema): Field<NumberSchemaType> {
    return {
        constraints: {
            max: serialized.constraints.max ? serialized.constraints.max : undefined,
            min: serialized.constraints.min ? serialized.constraints.min : undefined,
            required: serialized.constraints.required,
            ...{
                ...serialized.constraints.hasOwnProperty('listOfValues')
                    ? { listOfValues: serialized.constraints.listOfValues }
                    : {}
            }
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        enableFacet: serialized.enableFacet,
        type: SchemaTypeIds.NUMBER,
        extension: serialized.extension
    };
}

export function serialize(deserialized: Field<NumberSchemaType>, initialIndex: number): SerializedNumberSchema {
    return {
        settings: {},
        constraints: { ...deserialized.constraints },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        enableFacet: deserialized.enableFacet,
        editingType: retrieveNumberEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension
    };
}
