import { action, computed, observable, runInAction } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { RepositoryListStoreModel, RepositoryListUrlParams } from './repositoryListStoreModel';
import {
    ListContentRepositoriesRequest, ListContentRepositoriesResponse, ListContentRepositoryFilters
} from '@contentchef/contentchef-types';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';
import SearchParamsStore from '../searchParamsStore/searchParamsStore';
import { CONTENTREPOSITORY_CONSTANTS } from '@constants/content-repository';

class RepositoryListStore implements RepositoryListStoreModel {
    api: ContentChefClient;
    searchParams: SearchParamsStoreModel;
    @observable error = {
        repositories: ''
    };
    @observable isLoadingList: boolean = true;
    @observable listFilters: ListContentRepositoryFilters = {
        name: undefined,
        archived: undefined
    };

    @observable pagedRepositoryList: ListContentRepositoriesResponse = {
        skip: CONTENTREPOSITORY_CONSTANTS.DEFAULT_SKIP,
        take: CONTENTREPOSITORY_CONSTANTS.DEFAULT_TAKE,
        items: [],
        total: 0
    };

    constructor(api: ContentChefClient) {
        this.api = api;
        this.searchParams = new SearchParamsStore();
        this.listFilters = {
            name: this.searchParams.usableSearchParams[RepositoryListUrlParams.byName],
            archived: !!this.searchParams.usableSearchParams[RepositoryListUrlParams.byArchived]
        };
    }

    @action.bound
    setListFilterByName = (value?: string) => {
        this.listFilters.name = value;
    }

    @action.bound
    setListFilterByArchived = (value?: boolean) => {
        this.listFilters.archived = value;
    }

    @action.bound
    resetAllFilters() {
        this.listFilters = {
            name: undefined,
            archived: undefined,
        };
    }

    @computed
    get canResetAllFilters() {
        return this.listFilters.name !== undefined ||
            !!this.listFilters.archived ||
            this.pagedRepositoryList.skip !== CONTENTREPOSITORY_CONSTANTS.DEFAULT_SKIP ||
            this.pagedRepositoryList.take !== CONTENTREPOSITORY_CONSTANTS.DEFAULT_TAKE;
    }

    @action.bound
    async setRepositories() {
        this.isLoadingList = true;
        const reqParam: ListContentRepositoriesRequest = {
            skip: this.getSkipValue(this.getTakeValue()),
            take: this.getTakeValue(),
            filters: {
                name: this.listFilters.name,
                archived: this.listFilters.archived
            }
        };
        try {
            const payload = await this.api.contentRepositories.list(reqParam);
            runInAction(() => {
                this.pagedRepositoryList = payload;
                this.isLoadingList = false;
            });
        } catch (e) {
            runInAction(() => {
                this.error.repositories = e.errorMessage;
                this.isLoadingList = false;
                console.log(e, 'Impossible to call contentRepositories.list');
            });
        }
    }

    private getSkipValue = (takeValue: number) => {
        const { urlSearchParams, usableSearchParams } = this.searchParams;
        return urlSearchParams.has(RepositoryListUrlParams.byPage)
            ? (+usableSearchParams[RepositoryListUrlParams.byPage] - 1) * takeValue
            : CONTENTREPOSITORY_CONSTANTS.DEFAULT_SKIP;
    }

    private getTakeValue = () => {
        const { urlSearchParams, usableSearchParams } = this.searchParams;
        return urlSearchParams.has(RepositoryListUrlParams.bySize)
            ? (+usableSearchParams[RepositoryListUrlParams.bySize])
            : CONTENTREPOSITORY_CONSTANTS.DEFAULT_TAKE;
    }
}

export default RepositoryListStore;
