import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Select, Col, Row, AutoComplete } from 'antd';
import { ContentPublicIdProps, ContentPublicIdState } from './ContentPublicIdModel';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from './ContentPublicIdModel';
import { debounce } from '../../services/utils';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import { withArchivedStatus } from '../../providers/ArchivedContentProvider';
import { SelectValue } from 'antd/lib/select';
const Option = Select.Option;

const AliasSelectLabels = defineMessages({
    placeholder: {
        id: 'ContentPublicId.placeholder',
        defaultMessage: 'Select a public id or create a new one'
    }
});

@inject('listPublicIdStore')
@observer
class ContentPublicId extends Component<ContentPublicIdProps, ContentPublicIdState> {
    debounceSearch: Function;

    constructor(props: ContentPublicIdProps) {
        super(props);

        this.debounceSearch = debounce(this.debouncedSetContentAliases, 500);
    }

    get injected() {
        return this.props as InjectedProps;
    }

    debouncedSetContentAliases = (value) => {
        this.injected.listPublicIdStore.setListPublicId(value);
    }

    onSearchSelect = (value) => {
        this.debounceSearch(value);
    }

    onChangeSelect = (value) => {
        this.props.publicIdField.setFieldValue(value);
        this.injected.onChange(value);
    }

    onBlur = (value: SelectValue) => {
        const trimmedValue = this.props.publicIdField.trimValue(value as string);
        return this.injected.onChange(trimmedValue);
    }

    render() {
        const { publicIdField, intl, hasPermissions, archived, ...others } = this.props;
        const { formatMessage } = intl;
        const { listPublicId } = this.injected.listPublicIdStore;
        return (
            <Row type="flex" align="middle" gutter={10}>
                <Col xs={24}>
                    <AutoComplete
                        {...others}
                        disabled={archived ? archived : !hasPermissions}
                        showSearch={true}
                        allowClear={true}
                        onSearch={this.onSearchSelect}
                        onChange={this.onChangeSelect}
                        onBlur={this.onBlur}
                        value={publicIdField.value}
                        placeholder={formatMessage(AliasSelectLabels.placeholder)}
                    >
                        {listPublicId.map((publicId) => {
                            return (
                                <Option
                                    key={publicId}
                                    value={publicId}
                                >{publicId}
                                </Option>
                            );
                        })}
                    </AutoComplete>
                </Col>
            </Row>
        );
    }
}

export default withArchivedStatus(withPermissionsToInteract(injectIntl(ContentPublicId)));
