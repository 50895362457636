import { defineMessages } from 'react-intl';

export const COMMON_LABELS = defineMessages({
    tagsSelectPlaceholder: {
        id: 'CommonLabels.TagsSelectPlaceholder',
        defaultMessage: 'Search for tags by separating them with a comma or by pressing Enter.'
    },
    tagSelectNotFoundPlaceholder: {
        id: 'CommonLabels.notFoundPlaceholder',
        defaultMessage: 'Preferred tags not available'
    },
    saveFormButtonLabel: {
        id: 'GenericFormButtons.saveFormLabel',
        defaultMessage: 'Save'
    },
    goBackFormButtonLabel: {
        id: 'GenericFormButtons.goBackFormLabel',
        defaultMessage: 'Go Back'
    },
    deleteFormButtonLabel: {
        id: 'GenericFormButtons.deleteFormLabel',
        defaultMessage: 'Delete'
    },
});
