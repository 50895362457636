import { defineMessages } from 'react-intl';

const statusCodeLabel =
    defineMessages({
        failed: {
            id: 'PublishingRequests.statusFailed',
            defaultMessage: 'Failed',
        },
        queued: {
            id: 'PublishingRequests.statusQueued',
            defaultMessage: 'Queued'
        },
        published: {
            id: 'PublishingRequests.statusPublished',
            defaultMessage: 'Published'
        },
        processing: {
            id: 'PublishingRequests.statusProcessing',
            defaultMessage: 'Processing'
        },
        already_published: {
            id: 'PublishingRequests.statusAlreadyPublished',
            defaultMessage: 'Already Published'
        }
    });

export function getPublishingRequestsStatusCode(statusCode: string) {
    return statusCodeLabel[statusCode];
}
