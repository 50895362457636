import {
    CloudinaryImageSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface CloudinaryImageConstraints {
    required: boolean;
}

export type SerializedCloudinaryImageSchema = SerializedField<CloudinaryImageConstraints>;

export function retrieveCloudinaryImageEditingType() {
    return ListItemIds.TransformableImage;
}

export function deserialize(serialized: SerializedCloudinaryImageSchema): Field<CloudinaryImageSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.CLOUDINARY_IMAGE,
        extension: serialized.extension
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<CloudinaryImageSchemaType>, initialIndex: number): SerializedCloudinaryImageSchema {
    return {
        settings: {},
        constraints: {
            required: deserialized.constraints.required,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        type: deserialized.type,
        editingType: ListItemIds.TransformableImage,
        initialIndex,
        extension: deserialized.extension
    };
}
