import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { List, Icon, Modal, Form, Input, notification } from 'antd';
import Button from '@components/Button';
import AccessTokenStore from '@stores/accessTokenStore';
import { AccessTokenResponse } from '@contentchef/contentchef-types';
import { InjectedIntlProps, injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import AccessTokenCardExtraButton from '@components/AccessTokenCardExtraButton';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@constants/date-constants';
import SettingsContentHeader from '@components/SettingsContentHeader';
import Paper from '@components/Paper';
import { withFormInitialization, withForm } from '../../../hoc';
import { FormComponentProps } from 'antd/es/form';
import Typography from '@components/Typography';
import TextEllipsis from '@components/TextEllipsis';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

const accessTokenListLabels = defineMessages({
    newTokenDialogTitle: {
        id: 'AccessTokenList.newTokenDialogTitle',
        defaultMessage: 'Add a new access token'
    },
    newTokenDialogOkText: {
        id: 'AccessTokenList.newTokenDialogOkText',
        defaultMessage: 'OK'
    },
    notificationConfirm: {
        id: 'AccessTokenList.confirm',
        defaultMessage: 'Create'
    },
    maxLengthValidation: {
        id: 'AccessTokenList.MaxLengthValidation',
        defaultMessage: 'Value must be max 15 characters.'
    },
    minLengthValidation: {
        id: 'AccessTokenList.MinLengthValidation',
        defaultMessage: 'Value must be at least 2 characters.'
    },
    requiredValidation: {
        id: 'scenes.AccessTokens.AccessTokenList.requiredValidation',
        defaultMessage: 'You must give your access token a name.'
    },
    createTokenUnexpectedError: {
        id: 'AccessTokenList.UnexpectedCreationError',
        defaultMessage: 'Unexpected error creating your token. If the error persist please contact the support team'
    }
});

interface AccessTokenListProps extends RouteComponentProps<{}>, InjectedIntlProps, FormComponentProps { }

interface AccessTokenListState {
    accessToken: string;
    showCreateToken: boolean;
    showFloatingAction: boolean;
}

interface AccessTokenListInjectedProps extends AccessTokenListProps {
    accessTokenStore: AccessTokenStore;
}

function getInitialState(): AccessTokenListState {
    return {
        accessToken: '',
        showCreateToken: false,
        showFloatingAction: true,
    };
}

@inject('accessTokenStore')
@observer
class AccessTokenList extends Component<AccessTokenListProps, AccessTokenListState> {
    public state = getInitialState();

    get injected() {
        return this.props as AccessTokenListInjectedProps;
    }

    async componentDidMount() {
        await this.injected.accessTokenStore.setAccessTokenList();
    }

    downloadAccessToken = (name: string, token: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(token));
        element.setAttribute('download', `${name}.contentchef.token.txt`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    showCreateTokenModal = () => {
        this.setState({
            showCreateToken: true,
            showFloatingAction: false,
        });
    }

    closeCreateTokenModal = () => {
        this.setState({
            showCreateToken: false,
            showFloatingAction: true,
        });
    }

    public handleCreateAccessToken = () => {
        this.props.form.validateFields(async error => {
            if (error) {
                return;
            }

            const value = this.state.accessToken;

            try {
                const createdToken = await this.injected.accessTokenStore.createAccessToken(value);
                this.setState(getInitialState());
                this.downloadAccessToken(createdToken.referenceName, createdToken.tokenString);

                notification.success({
                    message: (
                        <FormattedMessage
                            defaultMessage="Access token created"
                            id="scenes.AccessTokens.AccessTokenList.accessTokenCreated"
                        />
                    )
                });
            } catch (error) {
                notification.error({
                    message: (
                        <FormattedMessage
                            defaultMessage="{error}"
                            id="scenes.AccessTokens.AccessTokenList.accessTokenError"
                            values={{
                                error: error.message
                            }}
                        />
                    )
                });
            }
        });
    }

    render() {
        const {
            accessTokenList,
            loaders: {
                accessTokenListLoading,
                createAccessTokenLoading,
            },
        } = this.injected.accessTokenStore;
        const {
            form: {
                getFieldDecorator,
            },
            intl: {
                formatMessage
            }
        } = this.props;
        return (
            <React.Fragment>
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Programmatic Access"
                            id="scenes.AccessTokens.AccessTokenList.title"
                        />
                    }
                >
                    <Button
                        data-id="btn-create-access-token"
                        onClick={this.showCreateTokenModal}
                        type="primary"
                    >
                        <Icon type="plus" />
                        <FormattedMessage
                            defaultMessage="Add access token"
                            id="scenes.AccessTokens.AccessTokenList.addAccessToken"
                        />
                    </Button>
                </SettingsContentHeader>
                <Paper gutter={true}>
                    <List
                        loading={accessTokenListLoading}
                        dataSource={accessTokenList}
                        renderItem={(accessToken: AccessTokenResponse) => (
                            <ListItem
                                actions={
                                    [
                                        <AccessTokenCardExtraButton
                                            key="revoke"
                                            tokenHash={accessToken.tokenHash}
                                        />
                                    ]
                                }
                            >
                                <ListItemMeta
                                    title={
                                        <Typography
                                            gutter={false}
                                            variant="h6"
                                        >
                                            <TextEllipsis
                                                title={accessToken.referenceName}
                                            >
                                                {accessToken.referenceName}
                                            </TextEllipsis>
                                        </Typography>
                                    }
                                    description={
                                        <Typography
                                            gutter={false}
                                            variant="caption"
                                        >
                                            <TextEllipsis
                                                title={
                                                    moment(accessToken.createdDate).format(DATE_TIME_FORMAT)
                                                }
                                            >
                                                {moment(accessToken.createdDate).format(DATE_TIME_FORMAT)}
                                            </TextEllipsis>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )}
                    />
                </Paper>
                <Modal
                    okButtonProps={{
                        disabled: createAccessTokenLoading,
                        loading: createAccessTokenLoading,
                    }}
                    onCancel={this.closeCreateTokenModal}
                    onOk={this.handleCreateAccessToken}
                    title={formatMessage(accessTokenListLabels.newTokenDialogTitle)}
                    visible={this.state.showCreateToken}
                    okText={
                        formatMessage(accessTokenListLabels.newTokenDialogOkText)
                    }
                >
                    <Form>
                        <Form.Item>
                            <Typography variant="label">
                                <FormattedMessage
                                    defaultMessage="Token name"
                                    id="scenes.AccessTokens.AccessTokenList.tokenName"
                                />
                            </Typography>
                            {
                                getFieldDecorator('Access token', {
                                    initialValue: this.state.accessToken,
                                    rules: [
                                        {
                                            message: formatMessage(accessTokenListLabels.requiredValidation),
                                            required: true,
                                        },
                                        {
                                            message: formatMessage(accessTokenListLabels.minLengthValidation),
                                            min: 2,
                                        },
                                        {
                                            max: 15,
                                            message: formatMessage(accessTokenListLabels.maxLengthValidation),
                                        }
                                    ]
                                })(
                                    <Input onChange={event => this.setState({ accessToken: event.target.value })} />
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withFormInitialization(injectIntl(withForm(AccessTokenList)));
