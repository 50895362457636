import { UserBySpace } from '@contentchef/contentchef-types';
import { Avatar, Icon, List, Modal, notification, Tooltip, Tabs, Badge } from 'antd';
import Button from 'antd/lib/button';
import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserInvite } from '@contentchef/contentchef-types';
import { getAvatarBackgroundColor } from '../../../services/utils';
import { InviteStoreModel } from '../../../stores/inviteStore';
import { UsersStore, UserPermissionStore } from '../../../stores';
import { ApiStoreModel } from '../../../stores/apiStore/apiStore';
import Typography from '../../../components/Typography';
import InviteUserModal from '../../../components/InviteUserModal';
import getAvailableProfileLabel from '../../../constants/user-profiles-internationalized-labels';
import TextEllipsis from '../../../components/TextEllipsis';
import UserProfileTreeSelect from '../../../components/UserProfileTreeSelect';
import { UserProfileIds } from '@contentchef/contentchef-types';
import { withUserInfo } from '../../../hoc/withUserInfo/withUserInfo';
import { UserInfo } from '../../../services/Users';
import { NavStoreModel, NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { USERS_ROUTE } from '../../../constants/routing-constants';
import { FloatingActions } from '../../../components';
import AddUserButton from './AddUserButton';

const labels = defineMessages({
    modalOkText: {
        id: 'scenes.SettingsUsers.UsersList.user.edit.modal.okText',
        defaultMessage: 'Update'
    }
});

export interface UsersListProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps { }

interface DecoratedUsersListProps extends UsersListProps {
    apiStore: ApiStoreModel;
    inviteStore: InviteStoreModel;
    usersStore: UsersStore;
    userInfo: UserInfo;
    userPermissionsStore: UserPermissionStore;
    navStore: NavStoreModel;
}

interface UsersListState {
    currentEditingUser: UserBySpace | null;
    isInviting: boolean;
    isRevoking: boolean;
    isUpdatingUser: boolean;
    revokingInvite: UserInvite | null;
}

function getAvatarStyle(value: unknown): React.CSSProperties {
    return {
        backgroundColor: getAvatarBackgroundColor(
            getAvatarText(value),
            75,
            75,
        ),
    };
}

function getAvatarText(value: unknown): string {
    return typeof value === 'string' ? value.substr(0, 2) : '';
}

function getInitialState(): UsersListState {
    return {
        currentEditingUser: null,
        isInviting: false,
        isRevoking: false,
        isUpdatingUser: false,
        revokingInvite: null,
    };

}

const PendingInvitesTabLabel: React.FunctionComponent<{ badgeCount: number | undefined }> = ({ badgeCount }) => {
    return (
        <>
            <FormattedMessage
                defaultMessage="Pending invites"
                id="scenes.SettingsUsers.UsersList.pendingInvites.title"
            />
            {badgeCount !== undefined && badgeCount > 0 &&
                <Badge
                    style={{ marginLeft: '0.3rem' }}
                    count={badgeCount}
                    overflowCount={10}
                />
            }
        </>
    );
};

@inject(
    'apiStore',
    'inviteStore',
    'usersStore',
    'userPermissionsStore',
    'navStore'
)
@observer
class UsersList extends React.Component<UsersListProps, UsersListState> {
    public get decoratedProps(): DecoratedUsersListProps {
        return this.props as DecoratedUsersListProps;
    }

    public state = getInitialState();

    public async componentDidMount() {
        const {
            inviteStore,
            usersStore,
            navStore, match
        } = this.decoratedProps;

        const { spaceId } = match.params;

        navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.USERS,
                USERS_ROUTE,
                this.props.intl.formatMessage(NAVIGATION_LABELS.usersAndPermissions),
                NAVIGATION_PRIORITY.FIRST
            )
        );

        await usersStore.getUsersListBySpaceId(spaceId);
        await inviteStore.listForSpace(spaceId);
    }

    public createEditUserHandler(currentEditingUser: UserBySpace) {
        return async () => this.setState({ currentEditingUser });
    }

    public createRevokeInviteHandler(revokingInvite: UserInvite) {
        return async () => this.setState({ revokingInvite });
    }

    public handleCloseInviteDialog = () => this.setState({ isInviting: false });

    public handleCloseUserDialog = () => this.setState({ currentEditingUser: null });

    public handleCloseRevokeInviteDialog = () => this.setState({ revokingInvite: null });

    public handleConfirmRevokeInviteDialog = async () => {
        this.setState({ isRevoking: true });

        const { spaceId } = this.props.match.params;
        const inviteStore = this.decoratedProps.inviteStore;
        const result = await inviteStore.revoke(this.state.revokingInvite!);

        this.setState({ isRevoking: false });

        await inviteStore.listForSpace(spaceId);

        if (!result) {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="Cannot revoke this invite"
                        id="scenes.SettingsUsers.UsersList.revoke.error"
                    />
                )
            });
        }

        this.setState({ revokingInvite: null });
    }

    public handleInvite = () => this.setState({ isInviting: true });

    public handleInviteSent = async () => {
        const { spaceId } = this.props.match.params;
        await this.decoratedProps.inviteStore.pollListForSpace(spaceId);
        this.handleCloseInviteDialog();
    }

    public handleUpdateUser = async () => {
        const user = this.state.currentEditingUser!;
        const { spaceId } = this.props.match.params;
        const fullName = user.fullName;

        this.setState({ isUpdatingUser: true });

        try {
            const {
                usersStore,
                userPermissionsStore,
            } = this.decoratedProps;

            await userPermissionsStore.updateUserProfile(user, spaceId);

            this.setState({ currentEditingUser: null });

            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="{fullName} updated"
                        id="scenes.SettingsUsers.UsersList.user.edit.modal.update.success"
                        values={{ fullName }}
                    />
                )
            });

            await usersStore.getUsersListBySpaceId(spaceId);

        } catch (error) {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="{error}"
                        id="scenes.SettingsUsers.UsersList.user.edit.modal.update.error"
                        values={{ error: error.message }}
                    />
                )
            });
        }

        this.setState({ isUpdatingUser: false });
    }

    public retrieveReadableProfiles(user: UserBySpace) {
        const { formatMessage } = this.props.intl;
        const profiles = user.profileIds;
        const profilesLabelArray: string[] = [];

        for (let i = 0; i < profiles.length; i++) {
            const maybeLabel = getAvailableProfileLabel(profiles[i], formatMessage);

            if (maybeLabel) {
                profilesLabelArray.push(maybeLabel);
            }
        }

        return profilesLabelArray.sort().join(', ');
    }

    public render() {
        const {
            inviteStore: {
                invites: unfilteredInvites,
            },
            usersStore: {
                usersListBySpaceId,
                loading: isUserLoading,
            },
            match,
            intl: {
                formatMessage
            }
        } = this.decoratedProps;

        const { spaceId } = match.params;
        const {
            currentEditingUser,
            isUpdatingUser,
        } = this.state;

        const invites = unfilteredInvites.filter(a => !a.accepted);

        return (
            <div
                style={{ padding: '1rem', overflowY: 'auto' }}
            >
                <Tabs
                    defaultActiveKey="users"
                    tabBarGutter={10}
                >
                    <Tabs.TabPane
                        key="users"
                        tab={
                            <FormattedMessage
                                defaultMessage="Users"
                                id="scenes.SettingsUsers.UsersList.title"
                            />
                        }
                    >
                        <List
                            dataSource={usersListBySpaceId}
                            loading={isUserLoading.userList}
                            renderItem={this.renderUser}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        key="invites"
                        tab={<PendingInvitesTabLabel badgeCount={invites.length} />}
                    >
                        <List dataSource={invites} renderItem={this.renderInvite} />
                    </Tabs.TabPane>
                </Tabs>

                {/* invites.length */}
                <Modal
                    onCancel={this.handleCloseRevokeInviteDialog}
                    onOk={this.handleConfirmRevokeInviteDialog}
                    okButtonProps={{
                        disabled: this.state.isRevoking,
                        loading: this.state.isRevoking,
                    }}
                    title={
                        <FormattedMessage
                            defaultMessage="Revoking invite"
                            id="scenes.SettingsUsers.UsersList.invite.revoke.modal.title"
                        />
                    }
                    children={
                        <Typography variant="paragraph">
                            <FormattedMessage
                                defaultMessage="You are going to revoke this invite, this action is permanent."
                                id="scenes.SettingsUsers.UsersList.invite.revoke.modal.message"
                            />
                        </Typography>
                    }
                    visible={this.state.revokingInvite !== null}
                />
                <FloatingActions>
                    <AddUserButton handleInvite={this.handleInvite} />
                </FloatingActions>

                <Modal
                    okButtonProps={{
                        disabled: isUpdatingUser,
                        loading: isUpdatingUser,
                    }}
                    onCancel={this.handleCloseUserDialog}
                    onOk={this.handleUpdateUser}
                    title={
                        <FormattedMessage
                            defaultMessage="Update {fullName}"
                            id="scenes.SettingsUsers.UsersList.user.edit.modal.title"
                            values={{ fullName: currentEditingUser ? currentEditingUser.fullName : '' }}
                        />
                    }
                    okText={formatMessage(labels.modalOkText)}
                    visible={currentEditingUser !== null}

                >
                    {
                        currentEditingUser &&
                        <Fragment>
                            <Typography variant="label">
                                <FormattedMessage
                                    defaultMessage="Assigned user roles"
                                    id="scenes.SettingsUsers.UsersList.user.edit.modal.roles"
                                />
                            </Typography>
                            <UserProfileTreeSelect
                                onChange={
                                    profileIds => this.setState(state => (
                                        {
                                            ...state,
                                            currentEditingUser: {
                                                ...state.currentEditingUser!,
                                                profileIds
                                            }
                                        }
                                    ))
                                }
                                value={currentEditingUser.profileIds as UserProfileIds[]}
                            />
                        </Fragment>
                    }
                </Modal>

                <InviteUserModal
                    onClose={this.handleCloseInviteDialog}
                    onInvite={this.handleInviteSent}
                    open={this.state.isInviting}
                    spaceId={spaceId}
                />
            </div>
        );
    }

    public renderInvite = (invite: UserInvite) => {
        return (
            <List.Item
                actions={[
                    <Button
                        onClick={this.createRevokeInviteHandler(invite)}
                        key="revoke-invite"
                        type="danger"
                    >
                        <FormattedMessage
                            defaultMessage="Revoke invite"
                            id="scenes.SettingsUsers.UsersList.invite.revoke"
                        />
                    </Button>
                ]}
            >
                <List.Item.Meta
                    avatar={
                        <Avatar
                            children={getAvatarText(invite.userEmail)}
                            style={getAvatarStyle(invite.userEmail)}
                        />
                    }
                    title={
                        <>
                            {invite.userEmail}
                        </>
                    }
                    description={invite.guid}
                />
            </List.Item>
        );
    }

    public renderUser = (user: UserBySpace) => {
        const emailVerified = String(user.emailVerified).toLowerCase() === 'true';
        const canUpdatedHimself = this.decoratedProps.userInfo.email === user.email;

        return (
            <List.Item
                actions={
                    [
                        canUpdatedHimself
                            ? null
                            : (
                                <Button
                                    onClick={this.createEditUserHandler(user)}
                                    key="update-user"
                                    type="dashed"
                                >
                                    <Icon type="edit" />
                                    <FormattedMessage
                                        defaultMessage="Update user"
                                        id="scenes.SettingsUsers.UsersList.user.updateUser"
                                    />
                                </Button>
                            )
                    ]
                }
            >
                <List.Item.Meta
                    avatar={
                        <Avatar
                            children={user.avatar ? null : getAvatarText(user.fullName)}
                            style={getAvatarStyle(user.userId)}
                            src={user.avatar}
                        />
                    }
                    title={
                        <Fragment>
                            <Typography gutter={false} variant="h6" component={'span'}>
                                {user.fullName}
                            </Typography>
                            <Typography gutter={false} variant="caption">
                                {
                                    <Tooltip
                                        title={
                                            emailVerified
                                                ?
                                                <FormattedMessage
                                                    defaultMessage="Email verified"
                                                    id="scenes.SettingsUsers.user.emailVerified"
                                                />
                                                :
                                                <FormattedMessage
                                                    defaultMessage="{fullName}'s email is not verified"
                                                    id="scenes.SettingsUsers.user.emailNotVerified"
                                                    values={{ fullName: user.fullName }}
                                                />
                                        }
                                    >
                                        <Icon
                                            style={{
                                                color: emailVerified
                                                    ? 'green'
                                                    : 'orangered',
                                                cursor: 'help',
                                                marginRight: '.25rem',
                                            }}
                                            type={
                                                emailVerified
                                                    ? 'check-circle'
                                                    : 'warning'
                                            }
                                        />
                                    </Tooltip>
                                }
                                {user.email}
                            </Typography>
                        </Fragment>
                    }
                    description={
                        <Typography variant="small">
                            <TextEllipsis>
                                {this.retrieveReadableProfiles(user)}
                            </TextEllipsis>
                        </Typography>
                    }
                />
            </List.Item>
        );
    }
}

export default
    withUserInfo(
        withRouter(
            injectIntl(
                UsersList
            )
        )
    );
