import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { TagsSelectorProps, TagsSelectorState } from './TagsSelectorModel';
import styles from './TagsSelector.module.scss';
import { COMMON_LABELS } from '../../constants/common-internationalized-labels';
import { debounce } from '../../services/utils';
import { observer } from 'mobx-react';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
const Option = Select.Option;

@observer
class TagsSelector extends Component<TagsSelectorProps, TagsSelectorState> {
    debouncedOnSearchSuggested: (value?: string) => void;

    constructor(props: TagsSelectorProps) {
        super(props);
        if (this.props.onSearch) {
            this.debouncedOnSearchSuggested = debounce(this.props.onSearch);
        }
    }

    componentDidMount() {
        this.props.onSearch();
    }

    onSelect = () => {
        this.props.onSearch();
    }

    onChange = (tags: string[]) => {
        const trimmedTags = tags.map(tag => tag.trim()).filter(tag => !!tag);
        this.props.onChange(trimmedTags);
    }

    render() {
        const {
            intl: { formatMessage },
            hasPermissions,
            value,
            suggestedTags,
            loading,
            size = 'default'
        } = this.props;
        return (
            <Select
                className={styles.TagsSelectorSelect}
                disabled={!hasPermissions}
                mode="tags"
                tokenSeparators={[',']}
                value={value}
                onChange={this.onChange}
                onSelect={this.onSelect}
                onDeselect={this.onSelect}
                notFoundContent={suggestedTags && suggestedTags.length === 0 ? <Spin size="small" /> : null}
                filterOption={false}
                loading={loading}
                onSearch={this.debouncedOnSearchSuggested}
                placeholder={formatMessage(COMMON_LABELS.tagsSelectPlaceholder)}
                size={size}
            >
                {!loading && suggestedTags && suggestedTags.map((tag) => {
                    return (
                        <Option key={tag} value={tag}>
                            {tag}
                        </Option>
                    );
                })}
                {loading &&
                    <Option
                        key="loading-tags"
                        value="loading-tags"
                        disabled={true}
                    >
                        <Spin size="small" />
                    </Option>}
            </Select>
        );
    }
}

export const TagsSelectorWithoutPermission = injectIntl(TagsSelector);

export default withPermissionsToInteract(injectIntl(TagsSelector));
