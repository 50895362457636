import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ContentStatus, ContentId } from '@contentchef/contentchef-types';
import { observable, action, runInAction } from 'mobx';

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

class ContentStatusStore {
    @observable status: ContentStatus;
    constructor(readonly api: ContentChefClient) { }

    @action.bound
    async getContentStatus(id: ContentId) {
        try {
            const result = await this.api.contents.getContentStatus({ id });
            return result;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action.bound
    async setContentStatus(id: ContentId) {
        try {
            const status = await this.getContentStatus(id);
            runInAction(() => {
                this.status = status;
            });
        } catch (error) {
            console.error(error);
        }
    }

    @action.bound
    async pollUntilStatusChanges(id: ContentId) {
        const currentStatus = Object.assign({}, this.status);
        let status = await this.getContentStatus(id);
        while (currentStatus.stageVersion === status.stageVersion) {
            await delay(1500);
            status = await this.getContentStatus(id);
        }
        runInAction(() => {
            this.status = status;
        });
    }
}

export default ContentStatusStore;
