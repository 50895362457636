import React, { Component } from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import WebhookForm, { WebhookFormIds, WebhookManageableAttrs } from '@components/WebhookForm/WebhookForm';
import { WebhooksApiErrorTypes, WebhooksStoreModel } from '@stores/webhooksStore/WebhooksStore';
import { NotificationStoreModel } from '@stores/notificationStore/notificationStoreModel';
import { createInfoNotification } from '@services/Notification';
import {
    NOTIFICATION_DEFAULT_MESSAGES,
    NOTIFICATION_KEY_CONSTANTS
} from '@constants/notifications-constants';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import withFormInitialization from '@hoc/withFormInitialization/withFormInitialization';
import withForm from '@hoc/withForm/withForm';
import { FormComponentProps } from 'antd/lib/form';
import Button from '@components/Button';
import { SaveWebhookRequest } from '@contentchef/contentchef-types';
import { viewWebhookPath, createWebhooksListPath } from '@constants/routing-constants';
import { NavStoreModel, NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import { NavigationItem } from '@stores/navStore/navStore';
import { Link } from 'react-router-dom';

interface CreateWebhookProps extends FormComponentProps, InjectedIntlProps, RouteComponentProps<{ spaceId: string }> { }

interface CreateWebhookState { }

interface DecoratedProps extends CreateWebhookProps {
    webhooksStore: WebhooksStoreModel;
    notificationStore: NotificationStoreModel;
    navStore: NavStoreModel;
}

@inject('webhooksStore', 'notificationStore', 'navStore')
@observer
class CreateWebhook extends Component<CreateWebhookProps, CreateWebhookState> {
    get decoratedProps() { return this.props as DecoratedProps; }

    componentDidMount() {
        const { navStore, match: { params: { spaceId } } } = this.decoratedProps;

        navStore.replaceNavigationPaths([
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.WEBHOOKS,
                createWebhooksListPath(spaceId),
                this.props.intl.formatMessage(NAVIGATION_LABELS.webhooks),
                NAVIGATION_PRIORITY.FIRST
            ),
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.WEBHOOKS,
                undefined,
                this.props.intl.formatMessage(NAVIGATION_LABELS.new),
                NAVIGATION_PRIORITY.SECOND
            )
        ]);
    }

    handleOnSaveWebhook = async () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            const webhookManageableAttrs: WebhookManageableAttrs = {
                name: values[WebhookFormIds.name],
                url: values[WebhookFormIds.url],
                webhookType: values[WebhookFormIds.eventType]
            };
            createInfoNotification(
                NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST,
                this.props.intl.formatMessage,
                NOTIFICATION_DEFAULT_MESSAGES.createAction,
            );
            const { webhooksStore, match: { params: { spaceId } } } = this.decoratedProps;
            try {
                const createParams: SaveWebhookRequest = webhookManageableAttrs;
                const response = await webhooksStore.create(spaceId, createParams);
                this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
                this.props.history.push(viewWebhookPath(spaceId, response.id));
            } catch (e) {
                this.decoratedProps.notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.WEBHOOK_API_REQUEST);
                if (webhooksStore.error.create === WebhooksApiErrorTypes.validationError) {
                    this.decoratedProps.notificationStore.openNotificationWithIcon('error', {
                        message: (
                            <FormattedMessage
                                id="SettingsWebhook.WebhookList.CreateWebhook.ValidationErrorMessage"
                                /* tslint:disable-next-line:max-line-length */
                                defaultMessage="Validation error while saving your webhook, please check your data before to proceed"
                            />
                        ),
                        duration: 5,
                        placement: 'topRight'
                    });
                } else {
                    this.decoratedProps.notificationStore.openNotificationWithIcon('error', {
                        message: (
                            <FormattedMessage
                                id="SettingsWebhook.WebhookList.CreateWebhook.UnexpectedErrorMessage"
                                /* tslint:disable-next-line:max-line-length */
                                defaultMessage="Unexpected error, if the problem persist please contact our support team."
                            />
                        ),
                        duration: 5,
                        placement: 'topRight'
                    });
                }
            }
        });
    }

    render() {
        const { match: { params: { spaceId } } } = this.decoratedProps;

        return (
            <div style={{ padding: '1em' }}>
                <WebhookForm />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to={createWebhooksListPath(spaceId)} style={{ marginRight: '1em' }}>
                        <Button>
                            <FormattedMessage
                                id="SettingsWebhook.CreateWebhook.Button.GoBack"
                                defaultMessage="Back to list"
                            />
                        </Button>
                    </Link>
                    <Button
                        type="primary"
                        onClick={this.handleOnSaveWebhook}
                    >
                        <FormattedMessage
                            id="SettingsWebhook.CreateWebhook.Button.Save"
                            defaultMessage="Save"
                        />
                    </Button>
                </div>
            </div>
        );
    }
}

export default withFormInitialization(withForm(injectIntl(CreateWebhook)));
