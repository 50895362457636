import { FieldEditTabProps } from '../../types';
import React from 'react';
import { observer } from 'mobx-react';
import LazyField from '../Constraints/LazyField';
import { LinkedContentConstraints } from '../../FieldSerializer/fields/linked-content';
import DefinitionMnemonicIds from '../Constraints/DefinitionMnemonicIds';
import { FormattedMessage } from 'react-intl';
import {
    definitionMnemonicIdMappedErrors,
    lazyMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function LegacyLinkedContentsDetails(props: FieldEditTabProps<LinkedContentConstraints>) {
    return (
        <>
            <LazyField
                relatedTabKey={'detailsTab'}
                mappedErrors={lazyMappedErrors}
                onChange={lazy => props.onChangeConstraint('lazy', lazy)}
                value={props.constraints.lazy}
            />
            <DefinitionMnemonicIds
                relatedTabKey={'detailsTab'}
                mappedErrors={definitionMnemonicIdMappedErrors}
                selectionType="single"
                value={props.constraints.definitionMnemonicId === undefined
                    ? []
                    : [props.constraints.definitionMnemonicId]
                }
                label={<FormattedMessage
                    id="LinkedContentsDetails.DefinitionMnemonicId.Label"
                    defaultMessage="Referable content definitions"
                />}
            />
        </>
    );
}

export default observer(LegacyLinkedContentsDetails);
