import { NAVIGATION_LABELS } from '@constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '@constants/navigation-item-identifier';
import {
    extensionsPath,
    EXTENSIONS_ROUTE,
    CREATE_EXTENSION_ROUTE,
    EDIT_EXTENSION_ROUTE
} from '@constants/routing-constants';
import { useNavStore } from '@hooks/mobx';
import { NavigationItem } from '@stores/navStore/navStore';
import { NAVIGATION_PRIORITY } from '@stores/navStore/navStoreModel';
import React, { useEffect } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Route, RouteComponentProps, Switch } from 'react-router';
import CreateExtension from './CreateExtension';
import ExtensionList from './ExtensionList';
import UpdateExtension from './UpdateExtension';

interface ExtensionsRouterProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps { }

function ExtensionsRouter(props: ExtensionsRouterProps) {

    const navStore = useNavStore();

    useEffect(
        () => {
            navStore.setNavigationPath(new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.EXTENSIONS,
                extensionsPath(props.match.params.spaceId),
                props.intl.formatMessage(NAVIGATION_LABELS.extensions),
                NAVIGATION_PRIORITY.FIRST
            ));
        }
        ,
        []
    );

    return (
        <Switch>
            <Route
                exact={true}
                component={ExtensionList}
                path={EXTENSIONS_ROUTE}
            />
            <Route
                exact={true}
                component={CreateExtension}
                path={CREATE_EXTENSION_ROUTE}
            />
            <Route
                exact={true}
                component={UpdateExtension}
                path={EDIT_EXTENSION_ROUTE}
            />
        </Switch>
    );
}

export default injectIntl(ExtensionsRouter);
