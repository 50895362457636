import { ContentDefinitionFormLoadersModel, ContentDefinitionFormStoreModel } from './contentDefinitionFormStoreModel';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action, observable, runInAction } from 'mobx';
import {
    CreateContentDefinitionRequest, ContentDefinitionResponse, GetContentDefinitionRequest
} from '@contentchef/contentchef-types';
import { routerStoreHelper } from '..';
import {
    UpdateContentDefinitionRequest
} from '@contentchef/contentchef-types';
import {
    Field, I18NDictionary
} from '@contentchef/contentchef-types';
import { ContentDefinitionErrorFactory } from '../../services/Error/ContentDefinitionErrorManager';

class ContentDefinitionFormStore implements ContentDefinitionFormStoreModel {
    api: ContentChefClient;
    @observable isContentDefinitionSavedSuccessfully: boolean = false;
    @observable loaders: ContentDefinitionFormLoadersModel = {
        setContentDefinition: true,
        setDefinitionSchema: true,
        validateField: false,
        validateFragment: false,
    };
    @observable contentDefinition: ContentDefinitionResponse;
    @observable definitionSchema: object = {};

    static handlerApiErrors(error: any, apiName: string) {
        console.log(`impossible to call ${apiName}`);
        return error.details
            ? ContentDefinitionErrorFactory.createContentDefinitionError(error.details)
            : ContentDefinitionErrorFactory.createContentDefinitionError(error);
    }

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async validateContentDefinitionMnemonicId(mnemonicId: string): Promise<boolean> {
        if (!mnemonicId) {
            return true;
        }
        return (await this.api.contentDefinitions.list({ skip: 0, take: 10, filters: { mnemonicId } })).total === 0;
    }

    async save(values: CreateContentDefinitionRequest) {
        this.isContentDefinitionSavedSuccessfully = false;
        try {
            const response = await this.api.contentDefinitions.save(values);
            await runInAction(async () => {
                this.isContentDefinitionSavedSuccessfully = true;
                routerStoreHelper.onSuccessfulContentDefinitionCreate(await this.api.spaceId, response.id);
            });
            return;
        } catch (e) {
            return Promise.reject(
                ContentDefinitionFormStore.handlerApiErrors(e, `Failed Schema Validation`)
            );
        }
    }

    async update(values: UpdateContentDefinitionRequest) {
        this.isContentDefinitionSavedSuccessfully = false;
        const request: UpdateContentDefinitionRequest = {
            ...values,
            id: this.contentDefinition.id
        };
        try {
            const result = await this.api.contentDefinitions.update(request);
            await runInAction(() => {
                this.isContentDefinitionSavedSuccessfully = true;
            });
            return Promise.resolve(result);
        } catch (e) {
            return Promise.reject(
                ContentDefinitionFormStore.handlerApiErrors(e, `Failed Schema Validation`)
            );
        }
    }

    @action.bound
    updateLoaderStatus(loaderKey: keyof ContentDefinitionFormLoadersModel, value: boolean) {
        this.loaders[loaderKey] = value;
    }

    @action.bound
    async setContentDefinition(contentDefinitionId: string) {
        this.loaders.setContentDefinition = true;
        const reqParams: GetContentDefinitionRequest = {
            id: +contentDefinitionId
        };
        try {
            const response = await this.api.contentDefinitions.get(reqParams);
            runInAction(() => {
                this.contentDefinition = <ContentDefinitionResponse> response;
            });
        } catch (e) {
            runInAction(() => {
                this.loaders.setContentDefinition = false;
            });
            console.log(e, 'Impossible to call singleContentDefinition');
        }
    }

    @action
    async setDefinitionSchema() {
        this.loaders.setDefinitionSchema = true;
        try {
            const response = await this.api.contentDefinitions.getSchema();
            runInAction(() => {
                this.definitionSchema = response;
                this.loaders.setDefinitionSchema = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log('Error when retrieving content definition schema');
                this.loaders.setDefinitionSchema = false;
            });
        }
    }

    @action
    async validateSchemaField(field: Field, defaultLanguage: string, fragmentNames: string[]) {
        this.loaders.validateField = true;
        try {
            await this.api.contentDefinitions.validateField({ field, defaultLanguage, fragmentNames });
            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(
                ContentDefinitionFormStore.handlerApiErrors(e, `ValidateSchemaField field: ${field}`)
            );
        } finally {
            runInAction(() => {
                this.loaders.validateField = false;
            });
        }
    }

    @action
    async validateSchemaFragment(
        name: string, fields: Field[], labels: I18NDictionary, defaultLanguage: string, fragmentNames: string[],
    ) {
        this.loaders.validateFragment = true;
        try {
            await this.api.contentDefinitions.validateFragment(
                { name, fields, labels, defaultLanguage, fragmentNames }
            );
            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(
                ContentDefinitionFormStore.handlerApiErrors(e, `ValidateSchemaFragment: ${name}`)
            );
        } finally {
            runInAction(() => {
                this.loaders.validateFragment = false;
            });
        }
    }
}

export default ContentDefinitionFormStore;
