import {
    Field,
    LongTextSchemaType,
    LongTextSubTypes,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function simplePlainText(defaultLanguage: string, name: string): Field<LongTextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
        }
    };
}

function simplePlainTextInArray(): LongTextSchemaType {
    return {
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        constraints: {
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
        }
    };
}

function markdownPlainText(defaultLanguage: string, name: string): Field<LongTextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.MARKDOWN
        }
    };
}

function markdownPlainTextInArray(): LongTextSchemaType {
    return {
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        constraints: {
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.MARKDOWN
        }
    };
}

function jsonPlainText(defaultLanguage: string, name: string): Field<LongTextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.JSON
        }
    };
}

function jsonPlainTextInArray(): LongTextSchemaType {
    return {
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        constraints: {
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.JSON
        }
    };
}

function htmlPlainText(defaultLanguage: string, name: string): Field<LongTextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.HTML
        }
    };
}

function htmlPlainTextInArray(): LongTextSchemaType {
    return {
        type: SchemaTypeIds.LONG_TEXT,
        enableFacet: undefined,
        constraints: {
            allowEmpty: undefined,
            maxLength: undefined,
            minLength: undefined,
            subType: LongTextSubTypes.HTML
        }
    };
}

export const longTextSnippets = {
    asField: {
        simplePlainText,
        markdownPlainText,
        jsonPlainText,
        htmlPlainText,
    },
    asArray: {
        simplePlainTextInArray,
        markdownPlainTextInArray,
        jsonPlainTextInArray,
        htmlPlainTextInArray
    }
};
