import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { observer, inject } from 'mobx-react';
import { RepositoriesProps, InjectedProps } from './RepositoriesModel';
import { RepositoryList, FloatingActions } from '@components/index';
import withRepositoriesStores from '@hoc/withRepositoriesStores/withRepositoriesStores';
import { DataAttributes, DataAttributesBuilder } from '@services/DataAttributesBuilder';
import { createRepositoryPath } from '@constants/routing-constants';
import { RepositoryListUrlParams } from '@stores/repositoryListStore/repositoryListStoreModel';
import { HasPermissions } from '@providers/UserPermissionsProvider';
import { userPermissionsTable } from '@constants/user-permissions-table';
import AddButton from '@components/atoms/AddButton';
import ContainerWithAside from '@components/ContainerWithAside/ContainerWithAside';
import { Pagination } from 'antd';
import { MediaGalleryListUrlParams } from '@stores/mediaGalleryListStore';
import { CONTENTREPOSITORY_CONSTANTS } from '@constants/content-repository';
import RepositoryListFilters from '@components/RepositoryListFilters';

const repositoriesLabels = defineMessages({
    addButtonTitle: {
        id: 'Repository.Add',
        defaultMessage: 'Add new repository'
    }
});

@inject('repositoryListStore')
@observer
class Repositories extends Component<RepositoriesProps> {

    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount(): void {
        this.injected.repositoryListStore.setRepositories();
    }

    onSearchRepositories = (value) => {
        // Not yet implemented
        console.log(value);
    }

    onChangePage = (page: number) => {
        if (page !== CONTENTREPOSITORY_CONSTANTS.DEFAULT_PAGE) {
            this.injected.repositoryListStore.searchParams.setUrlParam(
                RepositoryListUrlParams.byPage, page.toString()
            );
        } else {
            this.injected.repositoryListStore.searchParams.deleteUrlParam(
                RepositoryListUrlParams.byPage
            );
        }
        this.injected.repositoryListStore.setRepositories();
    }

    onChangePageSize = (currentPage: number, newPageSize: number) => {
        if (newPageSize !== CONTENTREPOSITORY_CONSTANTS.DEFAULT_TAKE) {
            this.injected.repositoryListStore.searchParams.setUrlParam(
                MediaGalleryListUrlParams.bySize, newPageSize.toString()
            );
        } else {
            this.injected.repositoryListStore.searchParams.deleteUrlParam(
                MediaGalleryListUrlParams.bySize
            );
        }
        this.injected.repositoryListStore.setRepositories();
    }

    onResetFilters = () => {
        // Not yet implemented
        console.log('Reset');
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setSearchAttribute('search-repository')
            .build();
    }

    createSceneContainerAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('repository')
            .setIdAttribute('repositories_list')
            .build();
    }

    printCreateButton = () => {
        const { match: { params: { spaceId } } } = this.props;
        return (
            <Link to={createRepositoryPath(spaceId)}>
                <AddButton
                    dataId="btn-create-new-repository"
                    title={repositoriesLabels.addButtonTitle}
                />
            </Link>
        );
    }

    render() {
        const {
            pagedRepositoryList: { take, total, skip, items },
            isLoadingList,
        } = this.injected.repositoryListStore;
        return (
            <>
                <ContainerWithAside
                    drawerIcon={'filter'}
                    dataAttributes={this.createSceneContainerAttributes().Attributes}
                    footer={<Pagination
                        showSizeChanger={true}
                        pageSize={take}
                        current={(skip / take) + 1}
                        total={total}
                        onChange={this.onChangePage}
                        onShowSizeChange={this.onChangePageSize}
                    />}
                    renderAside={() => (<RepositoryListFilters />)}
                >
                    <RepositoryList
                        isLoading={isLoadingList}
                        repositoryList={items}
                    />
                </ContainerWithAside>
                <HasPermissions permissions={userPermissionsTable.FloatingActions.Repository}>
                    <FloatingActions>
                        {this.printCreateButton()}
                    </FloatingActions>
                </HasPermissions>
            </>
        );
    }
}

export default withRepositoriesStores(injectIntl(Repositories));
