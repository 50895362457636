import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { FormContext } from '@contexts/FormContext';
import { RcBaseFormProps, WrappedFormInternalProps } from 'antd/lib/form/Form';

type WithFormInitializationProps<T> = T & FormComponentProps;

type FormInitializationReturnComponent<T> =
    React.ComponentClass<RcBaseFormProps & Pick<T, Exclude<keyof T, keyof WrappedFormInternalProps>>>;

const withFormInitialization = <T extends any>(WrappedComponent: React.ComponentType<T>)
    : FormInitializationReturnComponent<T> => {
    class WithFormInitialization extends React.Component<WithFormInitializationProps<T>> {
        render() {
            const { form, ...others } = this.props;
            return (
                <FormContext.Provider value={form}>
                    <WrappedComponent {...others as T} />
                </FormContext.Provider>
            );
        }
    }

    return Form.create<WithFormInitializationProps<T>>()(WithFormInitialization);
};

export default withFormInitialization;
