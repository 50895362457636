import React from 'react';
import { InviteStoreModel } from '../../stores/inviteStore';
import { withRouter, RouteComponentProps } from 'react-router';
import withInvites from '../../hoc/withInvites';
import { inject, observer } from 'mobx-react';
import { ROOT_ROUTE, ACCEPT_INVITE_ROUTE } from '../../constants/routing-constants';
import AcceptInvite from '../../scenes/AcceptInvite';
import Loader from '../Loader/Loader';

interface DecoratedProps extends RouteComponentProps {
    inviteStore: InviteStoreModel;
}

interface HandleInviteState {
    shouldRedirect: boolean;
}

function getInitialState(): HandleInviteState {
    return {
        shouldRedirect: true,
    };
}

@inject('inviteStore')
@observer
class HandleInvite extends React.Component<RouteComponentProps, HandleInviteState> {
    public get decoratedProps(): DecoratedProps {
        return this.props as DecoratedProps;
    }

    public state = getInitialState();

    public async componentDidMount() {
        const {
            inviteStore,
            history,
        } = this.decoratedProps;

        await inviteStore.listForUser();

        if (inviteStore.invites.length === 0) {
            history.push(ROOT_ROUTE);
            return;
        }

        const invite = inviteStore.invites[0]!;
        const search = new URLSearchParams();

        search.append('invite', invite.guid);
        search.append('spaceId', invite.spaceId);
        search.append('email', invite.userEmail);

        const href = [ACCEPT_INVITE_ROUTE, String(search)].join('?');

        if (location.pathname !== ACCEPT_INVITE_ROUTE) {
            location.href = href;
        } else {
            this.setState({ shouldRedirect: false });
        }
    }

    public render() {
        if (this.state.shouldRedirect) {
            return (
                <Loader />
            );
        }

        return (
            <AcceptInvite {... this.props} />
        );
    }
}

export default withRouter(
    withInvites(
        HandleInvite
    )
);
