import React from 'react';
import { ContentStatus as ContentStatusType } from '@contentchef/contentchef-types';
import { Badge, Icon, Popover, Table, Tag } from 'antd';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './index.module.scss';
import { envRelatedLabels } from '../../constants/envinroment-related-labels';

interface ContentStatusProps extends InjectedIntlProps {
    currentVersion: number;
    currentChannels: string[];
    status?: ContentStatusType;
    className?: string;
}

function ContentStatus(props: ContentStatusProps) {

    const { formatMessage } = props.intl;

    function renderChannels(channels: string[], moreThanOne: boolean) {

        const channelsToRender = channels.map(channel =>
            <Tag title={channel} color={'#00AEEF'} key={channel}>{channel}</Tag>);

        function renderWithBadge(channelToRender: JSX.Element, numberOfChannels: number) {
            if (numberOfChannels <= 1) {
                return channelToRender;
            }
            return (
                <Badge count={`+${numberOfChannels - 1}`}>
                    {channelToRender}
                </Badge>
            );
        }

        return (
            <div
                className={styles.StatusChannelsContainer}
            >
                <Popover
                    trigger="hover"
                    content={channelsToRender}
                    title={formatMessage(envRelatedLabels.channels)}
                    {...moreThanOne ? {} : { visible: false }}
                >
                    {renderWithBadge(channelsToRender[0], channelsToRender.length)}
                </Popover>
            </div>
        );
    }

    function renderStageLive(stage: boolean, live: boolean) {

        function renderCheckOrClose() {
            return stage || live ?
                <Icon type="check-circle" className={styles.SameVersion} /> :
                <Icon type="close-circle" className={styles.DifferentVersion} />;
        }

        function renderLabel() {
            const label: string[] = [];
            if (!stage && !live) {
                return (
                    <Popover
                        content={
                            formatMessage(envRelatedLabels.neverPublishedVersion)
                        }
                    >
                        {formatMessage(envRelatedLabels.shortNeverPublishedVersion)}
                    </Popover>
                );
            }
            if (stage) {
                label.push('Stage');
            }

            if (live) {
                label.push('Live');
            }

            return label.join(', ');
        }

        return (
            <div
                className={styles.EnvironmentContainer}
            >
                <p>{renderCheckOrClose()} {renderLabel()}</p>
            </div>
        );
    }

    function buildTableDataSource() {
        const { currentVersion, currentChannels, status } = props;

        const data: any[] = [];

        if (!status || Object.keys(status).length === 0) {
            data.push({
                version: `v${currentVersion}`,
                channels:
                    renderChannels(currentChannels, currentChannels.length > 1),
                environment: (
                    renderStageLive(false, false)
                )
            });
            return data;
        }

        const stageVersion = status!.stageVersion;
        const liveVersion = status!.liveVersion;
        const liveChannels = status!.liveChannels;
        const stageChannels = status!.stageChannels;

        if (stageVersion && liveVersion && stageVersion > 0 && liveVersion > 0 && stageVersion === liveVersion) {
            data.unshift(...[{
                version: `v${stageVersion}`,
                channels: renderChannels(stageChannels!, stageChannels!.length > 1),
                environment: (
                    renderStageLive(true, true)
                )
            }]);
        }

        if (stageVersion && liveVersion && stageVersion > 0 && liveVersion > 0 && stageVersion !== liveVersion) {
            data.unshift(...[{
                version: `v${stageVersion}`,
                channels: renderChannels(stageChannels!, stageChannels!.length > 1),
                environment: renderStageLive(true, false)
            }, {
                version: `v${liveVersion}`,
                channels: renderChannels(liveChannels!, liveChannels!.length > 1),
                environment: renderStageLive(false, true)
            }]);
        }

        if (stageVersion && stageVersion > 0 && !liveVersion) {
            data.unshift(...[{
                version: `v${stageVersion}`,
                channels: renderChannels(stageChannels!, stageChannels!.length > 1),
                environment: renderStageLive(true, false)
            }]);
        }

        if (currentVersion !== stageVersion) {
            data.unshift({
                version: `v${currentVersion}`,
                channels:
                    renderChannels(currentChannels, currentChannels.length > 1),
                environment: (
                    renderStageLive(false, false)
                )
            });
        }

        return data;

    }

    const tableDataSource = buildTableDataSource();

    const tableClassName = classNames(styles.StatusTable, props.className);
    return (
        <Table
            scroll={{ y: true }}
            showHeader={false}
            className={tableClassName}
            pagination={false}
            size="small"
            columns={[{
                title: formatMessage(envRelatedLabels.version),
                dataIndex: 'version',
                key: 'version'
            }, {
                title: formatMessage(envRelatedLabels.channels),
                dataIndex: 'channels',
                key: 'channels'
            }, {
                title: formatMessage(envRelatedLabels.environment),
                dataIndex: 'environment',
                key: 'environment'
            }]}
            dataSource={tableDataSource}
        />
    );
}

export default injectIntl(ContentStatus);
