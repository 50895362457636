import React, { Component } from 'react';
import {
    Home,
    MediaGalleriesRouter,
    RepositoriesRouter,
    ContentDefinitionsRouter,
    PublishingChannelsRouter,
    PublishRouter,
    ContentsRouter,
} from '../../scenes';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import {
    MEDIA_GALLERIES_ROUTE,
    PUBLISHING_CHANNELS_ROUTE,
    CONTENT_DEFINITIONS_ROUTE,
    REPOSITORY_ROUTE,
    SPACE_ROUTE,
    PUBLISH_ROUTE,
    CONTENTS_ROUTE,
    CHOOSE_SPACE_ROUTE,
    USERS_ROUTE,
    WEBHOOKS_ROUTE,
    NOT_FOUND_ROUTE,
    API_KEYS_ROUTE,
    EXTENSIONS_ROUTE
} from '../../constants/routing-constants';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './SpaceBasedRoutes.module.scss';
import { inject } from 'mobx-react';
import { ApiStoreModel } from '../../stores/apiStore/apiStore';
import UserPermissionsStore from '../../stores/userPermissionsStore';
import { Loader } from '../index';
import SettingsUsers from '../../scenes/SpaceSettings/SettingsUsers';
import WebhooksRouter from '../../scenes/SpaceSettings/Webhooks/WebhooksRouter';
import ApiKeysRouter from '../../scenes/SpaceSettings/ApiKeys/ApiKeysRouter';
import ExtensionsRouter from '@scenes/Extensions/ExtensionsRouter';

export interface SpaceBasedRoutesProps extends RouteComponentProps<{ spaceId: string }> { }

export interface SpaceBasedRoutesInjectedProps extends SpaceBasedRoutesProps {
    apiStore: ApiStoreModel;
    userPermissionsStore: UserPermissionsStore;
}

export interface SpaceBasedRoutesState {
    loading: boolean;
    isSpaceInUrlAvailable: boolean;
}

@inject('apiStore', 'userPermissionsStore')
class SpaceBasedRoutes extends Component<SpaceBasedRoutesProps, SpaceBasedRoutesState> {
    state: SpaceBasedRoutesState = {
        loading: true,
        isSpaceInUrlAvailable: true
    };

    get injected() {
        return this.props as SpaceBasedRoutesInjectedProps;
    }

    componentDidMount(): void {
        let activeSpace: string = this.props.match.params.spaceId;
        const activeUserSpaces = this.injected.userPermissionsStore.activeUserSpaces;

        if (activeUserSpaces.find(space => space.spaceId === activeSpace)) {
            this.injected.apiStore.changeCurrentSpace(activeSpace);
            this.setState({
                loading: false
            });
        } else {
            this.setState({
                loading: false,
                isSpaceInUrlAvailable: false
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />;
        }

        if (!this.state.isSpaceInUrlAvailable) {
            return <Redirect to={CHOOSE_SPACE_ROUTE} />;
        }

        return (
            <Switch>
                <Route
                    exact={true}
                    path={SPACE_ROUTE}
                    component={Home}
                />
                <Route
                    path={REPOSITORY_ROUTE}
                    component={RepositoriesRouter}
                />
                <Route
                    path={CONTENTS_ROUTE}
                    component={ContentsRouter}
                />
                <Route
                    path={MEDIA_GALLERIES_ROUTE}
                    component={MediaGalleriesRouter}
                />
                <Route
                    path={CONTENT_DEFINITIONS_ROUTE}
                    component={ContentDefinitionsRouter}
                />
                <Route
                    path={PUBLISHING_CHANNELS_ROUTE}
                    component={PublishingChannelsRouter}
                />
                <Route
                    path={PUBLISH_ROUTE}
                    component={PublishRouter}
                />
                <Route path={USERS_ROUTE} component={SettingsUsers} />
                <Route path={WEBHOOKS_ROUTE} component={WebhooksRouter} />
                <Route path={API_KEYS_ROUTE} component={ApiKeysRouter} />
                <Route path={EXTENSIONS_ROUTE} component={ExtensionsRouter} />
                <Route
                    path={NOT_FOUND_ROUTE}
                    render={(routeProps) => {
                        const { match: { params: { type } } } = routeProps;
                        return (
                            <div className={styles.PageNotFoundContainer}>
                                <Empty
                                    description={
                                        <FormattedMessage
                                            id="SpaceBasedRoutes.notFound"
                                            defaultMessage="{type} not found"
                                            values={{ type: type }}
                                        />
                                    }
                                />
                            </div>
                        );
                    }}
                />
            </Switch>
        );
    }
}

export default withRouter(SpaceBasedRoutes);
