import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Col, Collapse, Row, Popconfirm, Form } from 'antd';
import Button from '@components/Button';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { FragmentFieldProps, FragmentFieldState } from './FragmentFieldModel';
import { InjectedProps } from './FragmentFieldModel';
import styles from './FragmentField.module.scss';
import { DynamicContentField, LabelWithHint } from '../..';
import { withPermissionsToInteract } from '@providers/UserPermissionsProvider';
import { withArchivedStatus } from '@providers/ArchivedContentProvider';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { FragmentValueModel } from '@services/FormUtils/FormFields';
import { FragmentFieldTypeIcon } from '@assets/custom_icons/components/fragment';

const Panel = Collapse.Panel;
const FormItem = Form.Item;

const fragmentFieldLabels = defineMessages({
    popconfirmChangeSelection: {
        id: 'FragmentField.popconfirmChangeSelection',
        defaultMessage: 'Changing type will remove your field data, confirm?'
    },
    popconfirmRemoveMessage: {
        id: 'FragmentField.PopconfirmRemoveMessage',
        defaultMessage: 'Remove {fieldName} data?'
    }
});

@observer
class FragmentField extends Component<FragmentFieldProps, FragmentFieldState> {
    get injected() {
        return this.props as InjectedProps;
    }

    state = {
        isOpen: !this.props.fragmentMetaData.loaded ||
            this.props.fragmentMetaData.hasError
    };

    handleAddFragment = () => {
        const { fragmentMetaData } = this.props;
        fragmentMetaData.addFragmentChildren();
        this.setState({ isOpen: true });
    }

    handleRemoveFragment = (e) => {
        if (!this.state.isOpen) {
            e.stopPropagation();
        }
        this.setState({ isOpen: false });
        const { fragmentMetaData } = this.props;
        fragmentMetaData.removeFragmentChildren();
    }

    stopPropagation = (e) => {
        e.stopPropagation();
    }

    handleFragmentHeading = () => {
        const leafSchemaTypes: SchemaTypeIds[] = [SchemaTypeIds.BOOLEAN, SchemaTypeIds.DATE,
        SchemaTypeIds.LINKED_CONTENT, SchemaTypeIds.ONE_LINKED_CONTENT_OF,
        SchemaTypeIds.NUMBER, SchemaTypeIds.TEXT];

        const traverseFragment = (fields: FragmentValueModel) => {
            const traverseField = (fieldData) => {
                const isLeaf = leafSchemaTypes.indexOf(fieldData.type) >= 0;
                if (isLeaf) {
                    return fieldData.value;
                }

                switch (fieldData.type) {
                    case SchemaTypeIds.FRAGMENT:
                        return traverseFragment(fieldData.value);
                    case SchemaTypeIds.ONE_FRAGMENT_OF:
                        const value = fieldData.value.get();
                        return Object.keys(value).length > 0 ? traverseFragment(value.fieldMeta.value) : undefined;
                    case SchemaTypeIds.ARRAY:
                        const arrayValue = fieldData.value;
                        return arrayValue.length > 0 ? traverseField(arrayValue[0]) : undefined;
                    default:
                        return undefined;
                }
            };

            const fragmentKeys = Object.keys(fields);
            if (fragmentKeys.length > 0) {
                for (const key of fragmentKeys) {
                    if (fields.hasOwnProperty(key)) {
                        const result = traverseField(fields[key]);
                        return result;
                    }
                }
            }

            return undefined;
        };

        const selectFirstFieldWithValue = () => {
            const { fragmentMetaData } = this.props;

            return { value: traverseFragment(fragmentMetaData.value) };
        };

        const retrieveFragmentInfo = () => {
            const { fragmentMetaData, intl: { locale } } = this.props;
            const fragmentLabelValue = fragmentMetaData.fragmentNameLabels[locale] ||
                fragmentMetaData.fragmentNameLabels[fragmentMetaData.locale];

            const fragmentName = fragmentMetaData.constraints.name;

            const hasErrors = fragmentMetaData.hasError;

            return { name: fragmentName, label: fragmentLabelValue ? fragmentLabelValue : fragmentName, hasErrors };
        };

        const fragmentInfo = retrieveFragmentInfo();
        const firstValue = selectFirstFieldWithValue();

        return (
            <Col xs={24} >
                <Row type="flex" align="middle">
                    <span className={styles.FragmentName}>
                        {fragmentInfo.label}
                    </span>
                    <FragmentFieldTypeIcon title={fragmentInfo.name} />
                </Row>
                {firstValue !== undefined && <Row>
                    <span> {firstValue.value}</span>
                </Row>}
            </Col>

        );
    }

    handleFragmentFields = () => {
        const { fragmentMetaData, parentFieldId } = this.props;
        const fields = fragmentMetaData.value;
        const fieldsToRender: JSX.Element[] = [];
        if (Object.keys(fields).length > 0) {
            for (const key in fields) {
                if (fields.hasOwnProperty(key)) {
                    const fieldData = fields[key];
                    if (fieldData) {
                        let newParentFieldId: string;
                        if (parentFieldId) {
                            newParentFieldId = parentFieldId + `.${fragmentMetaData.id}`;
                        } else {
                            newParentFieldId = fragmentMetaData.id;
                        }
                        fieldsToRender.push(
                            <DynamicContentField
                                key={`${fragmentMetaData.id}-__` + fieldData.id}
                                genericFieldMeta={fieldData}
                                parentFieldId={newParentFieldId}
                            />
                        );
                    }
                }
            }
        }
        return fieldsToRender;
    }

    createUndoFragmentSelectionButton = () => {
        const { undoFragmentSelection, intl: { formatMessage }, hasPermissions, archived } = this.props;

        if (!undoFragmentSelection) {
            return null;
        }

        const readonly = archived || !hasPermissions;

        const swapButton = (
            <Button
                icon="swap"
                type="danger"
                disabled={readonly}
                className={styles.FragmentFieldChangeSelection}
            />
        );

        return (
            readonly
                ? swapButton
                : (
                    <Popconfirm
                        overlayClassName={styles.FragmentFieldPopoverOverride}
                        title={formatMessage(fragmentFieldLabels.popconfirmChangeSelection)}
                        placement="topLeft"
                        onConfirm={undoFragmentSelection}
                        onCancel={this.stopPropagation}
                    >
                        {swapButton}
                    </Popconfirm>
                )
        );
    }

    createToggleStateButton = () => {
        const { isOpen } = this.state;
        const { fragmentMetaData } = this.props;

        if (fragmentMetaData.hasError) {
            return null;
        }

        return (
            <Button

                icon={isOpen ? 'shrink' : 'arrows-alt'}
                type="default"
                className={styles.FragmentFieldToggleState}
            />
        );
    }

    createRemoveFragmentButton = () => {
        const { intl: { formatMessage }, fragmentMetaData, hasPermissions, archived } = this.props;
        const { locale } = fragmentMetaData;

        if (fragmentMetaData.constraints.required) {
            return null;
        }

        const readonly = archived || !hasPermissions;

        const deleteButton = (
            <Button
                icon="delete"
                type="danger"
                disabled={readonly}
                className={styles.FragmentFieldRemoveFragment}
            />
        );

        return (
            readonly
                ? deleteButton : (
                    <Popconfirm
                        overlayClassName={styles.FragmentFieldPopoverOverride}
                        title={formatMessage(fragmentFieldLabels.popconfirmRemoveMessage, {
                            fieldName:
                                fragmentMetaData.labels[locale] || fragmentMetaData.labels[fragmentMetaData.locale]
                        })}
                        placement="topLeft"
                        onConfirm={this.handleRemoveFragment}
                        onCancel={this.stopPropagation}
                    >
                        {deleteButton}
                    </Popconfirm>
                )
        );
    }

    createAddFragmentButton = () => {
        const { hasPermissions, archived } = this.props;
        return (
            <div className={styles.FragmentFieldAddFragmentWrapper}>
                <Button
                    type="primary"
                    icon="plus"
                    disabled={archived || !hasPermissions}
                    className={styles.FragmentFieldAddFragment}
                    onClick={this.handleAddFragment}
                >
                    <FormattedMessage
                        id="FragmentField.CreateFragment"
                        defaultMessage="New"
                    />
                </Button>
            </div>
        );
    }

    createPanel = () => {
        const { fragmentMetaData } = this.props;
        const { isOpen } = this.state;
        return (
            <Collapse
                onChange={this.onChangePanelState}
                className={fragmentMetaData.hasError ? styles.FragmentFieldErrorBorder : undefined}
                defaultActiveKey={
                    isOpen ? [fragmentMetaData.id] : []
                }
                {...fragmentMetaData.hasError ? { activeKey: [fragmentMetaData.id] } : ''}
            >
                <Panel
                    header={this.createPanelHeader()}
                    disabled={Object.keys(fragmentMetaData.value).length === 0}
                    showArrow={false}
                    key={fragmentMetaData.id}
                >
                    {this.handleFragmentFields()}
                </Panel>
            </Collapse>
        );
    }

    createPanelHeader = () => {
        return (
            <Row gutter={10} className={styles.FragmentFieldPanelHeaderWrapper} >
                <Col xs={16} sm={18} lg={20}>
                    {this.handleFragmentHeading()}
                </Col>
                <Col xs={8} sm={6} lg={4} >
                    <Row type="flex" justify="end">
                        {this.createToggleStateButton()}
                        {this.createUndoFragmentSelectionButton()}
                        {this.createRemoveFragmentButton()}
                    </Row>
                </Col>
            </Row>
        );
    }

    onChangePanelState = (value: string[] | string) => {
        const { fragmentMetaData: { id } } = this.props;
        this.setState({ isOpen: value.includes(id) });
    }

    createLabel = () => {

        const { fragmentMetaData, undoFragmentSelection, intl: { locale } } = this.props;
        const fragmentLabel = fragmentMetaData.labels[locale] || fragmentMetaData.labels[fragmentMetaData.locale];

        const emptyLabels = Object.keys(fragmentMetaData.labels).length === 0;

        const isFragmentOf = !!undoFragmentSelection;

        if (isFragmentOf || emptyLabels) {
            return null;
        }

        return (
            <div className={styles.FragmentFieldLabelWrapper}>
                {fragmentMetaData.hint
                    ? <LabelWithHint
                        label={
                            fragmentLabel
                        }
                        labelClassName={styles.OneFragmentOfSelectFragment}
                        hint={
                            fragmentMetaData.hint[locale] || fragmentMetaData.hint[fragmentMetaData.locale]
                        }
                    />
                    : fragmentLabel}
            </div>
        );

    }

    render() {
        const { fragmentMetaData, intl: { locale, formatMessage } } = this.props;
        return (
            <FormItem
                htmlFor={fragmentMetaData.id}
                required={fragmentMetaData.constraints.required}
                label={this.createLabel()}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                help={
                    fragmentMetaData.errors.length > 0
                        ? <div className={styles.FragmentFieldErrorLabels}>
                            {fragmentMetaData.getFieldErrorMessages(formatMessage, locale)}
                        </div>
                        : undefined
                }
            >
                <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    className={styles.FragmentFieldWrapper}
                >
                    <Col xs={24} id={fragmentMetaData.id}>
                        {Object.keys(fragmentMetaData.value).length > 0
                            ? this.createPanel()
                            : this.createAddFragmentButton()
                        }
                    </Col>
                </Row>
            </FormItem>
        );
    }
}

export default withArchivedStatus(withPermissionsToInteract(injectIntl(FragmentField)));
