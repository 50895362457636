import {
    SubscriptionResponse
} from '@contentchef/contentchef-types';
import { Form, Input, Modal, Table, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { inject } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import SubscriptionTypeName from '@components/SubscriptionTypeName';
import TextEllipsis from '@components/TextEllipsis';
import { Link } from 'react-router-dom';
import { getSubscriptionDetailRoute } from '@constants/routing-constants';
import { withForm, withFormInitialization } from '../../../hoc';
import { SubscriptionStoreModel } from '@stores/subscriptionStore';
import { ScenarioSelect } from '@components/SubscriptionScenarioSelect';
import UserPermissionStore from '@stores/userPermissionsStore';
import SettingsContentHeader from '@components/SettingsContentHeader';
import Typography from '@components/Typography';

export interface SubscriptionListSceneProps extends FormComponentProps, InjectedIntlProps {
    subscriptionStore: SubscriptionStoreModel;
    userPermissionsStore: UserPermissionStore;
}

export interface SubscriptionListSceneState {
    isCreating: boolean;
    isDialogVisible: boolean;
    loading: boolean;
    subscriptionScenario: number;
    subscriptionSpaceName: string;
}

const labels = defineMessages({
    headerTitle: {
        defaultMessage: 'Subscriptions',
        id: 'settings.subscriptions.list.header.title',
    },
    invalidSpaceName: {
        defaultMessage:
            'Space must start with a letter, accept letters, numbers, spaces, _ and must end with a letter or a number',
        id: 'settings.subscriptions.list.validation.invalidSpaceName',
    },
    newSubscription: {
        defaultMessage: 'Add a new subscription',
        id: 'settings.subscriptions.list.actions.create',
    },
    subscriptionID: {
        defaultMessage: 'ID',
        id: 'settings.subscriptions.list.item.id',
    },
    subscriptionCreationDate: {
        defaultMessage: 'Creation date',
        id: 'settings.subscriptions.list.item.creationDate',
    },
    subscriptionUpdateDate: {
        defaultMessage: 'Renewal date',
        id: 'settings.subscriptions.list.item.updateDate',
    },
    subscriptionListItemActions: {
        defaultMessage: 'Actions',
        id: 'settings.subscriptions.list.item.actions',
    },
    subscriptionListItemActionEdit: {
        defaultMessage: 'Edit',
        id: 'settings.subscriptions.list.action.edit',
    },
    subscriptionCreateConfirm: {
        defaultMessage: 'Create',
        id: 'settings.subscriptions.list.new.actions.create',
    },
    subscriptionCreateMessage: {
        defaultMessage: 'We will create a space for your new subscription.',
        id: 'settings.subscriptions.create.subscriptionCreateMessage',
    },
    subscriptionCreateUndo: {
        defaultMessage: 'Cancel',
        id: 'settings.subscriptions.list.new.actions.cancel',
    },
    subscriptionScenario: {
        defaultMessage: 'Scenario',
        id: 'settings.subscriptions.list.new.scenario',
    },
    subscriptionSpaceName: {
        defaultMessage: 'Space name',
        id: 'settings.subscriptions.list.new.spaceName',
    },
    subscriptionType: {
        defaultMessage: 'Plan',
        id: 'settings.subscriptions.list.new.subscriptionType',
    },
});

interface DecoratedSubscriptionListSceneProps extends SubscriptionListSceneProps {

}

function getIndexableSubscriptions(subscriptions: SubscriptionResponse[]): any[] {
    return subscriptions.map((item, key) => (
        {
            ...item,
            key,
        }
    ));
}

function getInitialState(): SubscriptionListSceneState {
    return {
        isCreating: false,
        isDialogVisible: false,
        loading: false,
        subscriptionScenario: 0,
        subscriptionSpaceName: '',
    };
}

@inject('subscriptionStore', 'userPermissionsStore')
class SubscriptionListScene extends React.Component<SubscriptionListSceneProps, SubscriptionListSceneState> {
    public get injectedProps(): DecoratedSubscriptionListSceneProps {
        return this.props as DecoratedSubscriptionListSceneProps;
    }
    public state = getInitialState();

    public buildShowNewSubscriptionHandler(isDialogVisible: boolean): () => any {
        return () => this.setState({ isDialogVisible });
    }

    public createSubscription = () => {
        const {
            form,
            subscriptionStore,
            userPermissionsStore,
        } = this.injectedProps;
        const selectedScenario = 0;
        const spaceName = this.state.subscriptionSpaceName;

        form.validateFields(async (error, _) => {
            if (error) {
                return;
            }

            this.setState({ isCreating: true });

            if (await subscriptionStore.create({ selectedScenario, spaceName })) {
                await userPermissionsStore.pollUntilChange();
                this.setState(getInitialState());
            }
        });
    }

    public componentDidMount() {
        this.setState({ loading: true });
        this.injectedProps.subscriptionStore.list().then(() => {
            this.setState({ loading: false });
        });
    }

    public getParsedDate = (date: Date): string => {
        return this.injectedProps.intl.formatDate(date);
    }

    public render() {
        const {
            form: {
                getFieldDecorator,
            },
            intl,
            subscriptionStore,
        } = this.injectedProps;
        const subscriptions = subscriptionStore.subscriptions;
        const subscriptionScenarioLabel = intl.formatMessage(labels.subscriptionScenario);
        const subscriptionSpaceNameLabel = intl.formatMessage(labels.subscriptionSpaceName);

        return (
            <div>
                <SettingsContentHeader title={intl.formatMessage(labels.headerTitle)} />
                <Table
                    columns={[
                        {
                            align: 'left',
                            dataIndex: 'id',
                            key: 'id',
                            title: intl.formatMessage(labels.subscriptionID),
                            render: value =>
                                <Tooltip title={value}>
                                    <TextEllipsis style={{ maxWidth: 120 }}>
                                        {value}
                                    </TextEllipsis>
                                </Tooltip>
                        },
                        {
                            align: 'left',
                            dataIndex: 'type',
                            key: 'type',
                            title: intl.formatMessage(labels.subscriptionType),
                            render: value => <SubscriptionTypeName type={value} />
                        },
                        {
                            align: 'left',
                            dataIndex: 'subscriptionEndDate',
                            key: 'subscriptionEndDate',
                            title: intl.formatMessage(labels.subscriptionUpdateDate),
                            render: value => value === null ? '--' : intl.formatDate(value),
                        },
                        {
                            key: 'actions',
                            title: intl.formatMessage(labels.subscriptionListItemActions),
                            render: (_, record: SubscriptionResponse) => (
                                <div>
                                    <Link to={getSubscriptionDetailRoute(record.id)}>
                                        {intl.formatMessage(labels.subscriptionListItemActionEdit)}
                                    </Link>
                                </div>
                            ),
                        },
                    ]}
                    dataSource={getIndexableSubscriptions(subscriptions)}
                    loading={this.state.loading}
                    pagination={{
                        pageSize: 5,
                    }}
                />

                <Modal
                    cancelText={intl.formatMessage(labels.subscriptionCreateUndo)}
                    destroyOnClose={true}
                    okText={intl.formatMessage(labels.subscriptionCreateConfirm)}
                    onCancel={this.buildShowNewSubscriptionHandler(false)}
                    onOk={this.createSubscription}
                    okButtonProps={{
                        disabled: this.state.isCreating,
                        loading: this.state.isCreating,
                    }}
                    title={intl.formatMessage(labels.newSubscription)}
                    visible={this.state.isDialogVisible}
                >
                    <Form>
                        <Typography >
                            {intl.formatMessage(labels.subscriptionCreateMessage)}
                        </Typography>
                        <Form.Item label={subscriptionScenarioLabel} style={{ display: 'none' }}>
                            {
                                getFieldDecorator(subscriptionScenarioLabel, {
                                    initialValue: this.state.subscriptionScenario,
                                    rules: [{
                                        required: true,
                                    }]
                                })(
                                    <ScenarioSelect
                                        onChange={
                                            value => this.setState({
                                                subscriptionScenario: Number(value)
                                            })
                                        }
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item label={subscriptionSpaceNameLabel}>
                            {
                                getFieldDecorator(subscriptionSpaceNameLabel, {
                                    initialValue: this.state.subscriptionSpaceName,
                                    rules: [{
                                        message: intl.formatMessage(labels.invalidSpaceName),
                                        pattern: this.injectedProps.subscriptionStore.spaceNameRegExp,
                                        required: true,
                                    }]
                                })(
                                    <Input
                                        onChange={event =>
                                            this.setState({
                                                subscriptionSpaceName: event.target.value
                                            })
                                        }
                                    />
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default withFormInitialization(
    withForm(
        injectIntl(
            SubscriptionListScene
        )
    )
);
