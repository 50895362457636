import { Field } from '@contentchef/contentchef-types';
import FieldEditorStore from './FieldEditorStore';
import { createSnippetParams } from './ContentDefinitionEditorDrawer/snippets';

export enum ListItemFamily {
    Custom = 'contentDefinitions.snippet.tags.tag.custom',
    Text = 'contentDefinitions.snippet.tags.tag.text',
    Media = 'contentDefinitions.snippet.tags.tag.media',
    Special = 'contentDefinitions.snippet.tags.tag.special',
}

export enum ListItemIds {
    SimpleText = 'Text',
    ListOfTextValues = 'Predefined Text Values',
    SimpleNumber = 'Number',
    ListOfNumberValues = 'Predefined Number Values',
    SimplePlainText = 'Plain Text',
    MarkdownPlainText = 'MARKDOWN Plain Text',
    JsonPlainText = 'JSON Plain Text',
    HtmlPlainText = 'HTML Plain Text',
    SimpleDate = 'Date',
    SimpleBoolean = 'Boolean',
    LegacyContentReference = 'Legacy Content Reference',
    ContentReference = 'Content Reference',
    TransformableImage = 'Transformable Image',
    TransformableVideo = 'Transformable Video',
    ImageReference = 'Image Reference',
    VideoReference = 'Video Reference',
    RawReference = 'Raw Reference',
    CustomField = 'Custom Field',
    MultiCustomField = 'Multi Custom Field',
    ListOfItems = 'Multiple Values'
}

export interface FieldDescriptor {
    name: string;
    id: ListItemIds;
    description?: string;
    families: ListItemFamily[];
    usable: boolean;
    snippet: (defaultLanguage: string, name: string, params?: createSnippetParams) => Field;
    icon?: string;
    iconComponent?: JSX.Element;
}

export interface FieldEditTabProps<Constraints = any, Settings = any> {
    defaultLang: string;
    fieldEditorStore: FieldEditorStore<Constraints, Settings>;
    isArrayChildren: boolean;
    constraints: Constraints;
    onChangeConstraint(key: keyof Constraints, value: unknown): void;
}
