import { Media, MediaType } from '@contentchef/contentchef-types';
import { default as prettyBytes } from 'pretty-bytes';
import { defineMessages, Messages } from 'react-intl';
import { parseDate, parseHour } from '../utils';

export interface MediaAdapterDataModel {
    publicId: string;
    publicUrl: string;
    thumbnailUrl: string;
    createdDate: string;
    tags: string[];
    name: string;
    dimension: string;
    size: string;
    format: string;
    resourceType: MediaType;
    aspectRatio: number;
}

const MediaAdapterLabels: Messages = defineMessages({
    publicId: {
        id: 'MediaDetails.PublicId',
        defaultMessage: 'Public Id'
    },
    publicUrl: {
        id: 'MediaDetails.PublicUrl',
        defaultMessage: 'Public Url'
    },
    name: {
        id: 'MediaDetails.name',
        defaultMessage: 'Name'
    },
    createdDate: {
        id: 'MediaDetails.CreatedDate',
        defaultMessage: 'Creation Date'
    },
    tags: {
        id: 'MediaDetails.Tags',
        defaultMessage: 'Tags'
    },
    dimension: {
        id: 'MediaDetails.Dimensions',
        defaultMessage: 'Dimensions'
    },
    size: {
        id: 'MediaDetails.Size',
        defaultMessage: 'Size'
    },
    format: {
        id: 'MediaDetails.Format',
        defaultMessage: 'Format'
    },
    resourceType: {
        id: 'MediaDetails.ResourceType',
        defaultMessage: 'Resource Type'
    },
    aspectRatio: {
        id: 'MediaDetails.AspectRatio',
        defaultMessage: 'Aspect Ratio'
    }
});

export class MediaAdapter {
    data: MediaAdapterDataModel;
    labels: Messages;
    copyableUrl: string;

    constructor(mediaData: Media) {
        this.labels = MediaAdapterLabels;
        this.data = {
            publicId: mediaData.mediaPublicId,
            publicUrl: mediaData.originalUrl,
            thumbnailUrl: mediaData.thumbnailUrl,
            name: mediaData.mediaPublicId.split('/').pop() as string,
            tags: mediaData.tags,
            createdDate: this.buildDateString(mediaData.createdAt),
            dimension: this.buildMediaDimension(mediaData.height, mediaData.width),
            size: prettyBytes(mediaData.bytes),
            format: mediaData.format,
            resourceType: mediaData.resourceType,
            aspectRatio: mediaData.aspectRatio ? +mediaData.aspectRatio.toFixed(2) : 0
        };
        this.copyableUrl = mediaData.copyableUrl;
    }

    getDisplayableData() {
        const displayableData = Object.assign({}, this.data);
        delete displayableData.publicId;
        delete displayableData.publicUrl;
        delete displayableData.thumbnailUrl;
        delete displayableData.tags;
        delete displayableData.size;
        return displayableData;
    }

    replaceTags(values: string[]) {
        this.data.tags = values;
    }

    retrieveImageName = () => {
        return this.data.publicId.split('/').pop();
    }

    private buildMediaDimension = (height: number, width: number): string => {
        if (!height || !width) {
            return '';
        }
        return `${height} H X ${width} W`;
    }

    private buildDateString = (date: Date): string => {
        return `${parseDate(date)} - ${parseHour(date)}`;
    }
}

export function fromMultiMediaToAdaptedMediaArray(mediaData: Media[]): MediaAdapter[] {
    const convertedMedia: MediaAdapter[] = [];
    mediaData.map((media) => {
        convertedMedia.push(new MediaAdapter(media));
    });
    return convertedMedia;
}

export function fromSingleMediaToAdaptedMedia(media: Media): MediaAdapter {
    return new MediaAdapter(media);
}
