import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { defineMessages, injectIntl } from 'react-intl';
import { PublishingChannelList, FloatingActions, ContainerWithAside } from '../../../components';
import { withChannelsStore } from '../../../hoc';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { PublishingChannelsProps, InjectedProps } from './PublishingChannelsModel';
import { createPublishingChannelPath } from '../../../constants/routing-constants';
import { Link } from 'react-router-dom';
import { HasPermissions } from '../../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../../constants/user-permissions-table';
import AddButton from '../../../components/atoms/AddButton';
import PublishingChannelListFilters from '@components/PublishingChannelListFilters';

const publishingChannelsLabels = defineMessages({
    title: {
        id: 'PublishingChannels.title',
        defaultMessage: 'Publishing channels'
    },
    addButtonTitle: {
        id: 'PublishingChannel.Add',
        defaultMessage: 'Add new channel'
    }
});

@inject('channelsStore')
@observer
class PublishingChannels extends Component<PublishingChannelsProps> {

    get injected() {
        return this.props as InjectedProps;
    }

    createDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('publishing-channels')
            .setIdAttribute('publishing-channels-list')
            .build();
    }

    componentDidMount(): void {
        return this.injected.channelsStore.setChannels();
    }

    printCreateButton = () => {
        const { spaceId } = this.props.match.params;
        return (
            <Link to={createPublishingChannelPath(spaceId)}>
                <AddButton
                    dataId="publishing-channels-new"
                    title={publishingChannelsLabels.title}
                />
            </Link>
        );
    }

    render() {
        const { channelsStore: { channels, isLoading } } = this.injected;
        return (
            <ContainerWithAside
                drawerIcon="filter"
                dataAttributes={this.createDataAttributes().dataAttributes}
                renderAside={() => <PublishingChannelListFilters />}
            >
                <HasPermissions permissions={userPermissionsTable.FloatingActions.Channel}>
                    <FloatingActions>
                        {this.printCreateButton()}
                    </FloatingActions>
                </HasPermissions>
                <PublishingChannelList
                    isLoading={isLoading}
                    channels={channels}
                />
            </ContainerWithAside>
        );
    }
}

export default withChannelsStore(injectIntl(PublishingChannels));
