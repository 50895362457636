import React, { Component } from 'react';
import { Row, Form, Input } from 'antd';
import { injectIntl, defineMessages } from 'react-intl';
import { RepositoryFormProps } from './RepositoryFormModel';

import styles from './RepositoryForm.module.scss';
import { withForm } from '../../../hoc';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import { MissingPermissions } from '../..';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const repositoryFormLabel = defineMessages({
    name: {
        id: 'RepositoryForm.name',
        defaultMessage: 'Name'
    },
    mnemonicId: {
        id: 'RepositoryForm.mnemonicId',
        defaultMessage: 'MnemonicId'
    },
    description: {
        id: 'RepositoryForm.description',
        defaultMessage: 'Description'
    },
    color: {
        id: 'RepositoryForm.color',
        defaultMessage: 'Color'
    }
});

class RepositoryForm extends Component<RepositoryFormProps> {
    randomHexColor: string;

    constructor(props: RepositoryFormProps) {
        super(props);
        this.randomHexColor = '#000000'.replace(/0/g, function() {
            // tslint:disable-next-line
            return (~~(Math.random() * 16)).toString(16);
        });
    }

    trimValue = ({ target }) => {
        const { id, value } = target;
        this.props.form.setFieldsValue({ [id]: value.trim() });
    }

    shouldDisableFormItem = () => {
        return !this.props.hasPermissions || (this.props.repository && this.props.repository.archived);
    }

    render() {
        const {
            hasPermissions,
            repository,
            form: { getFieldDecorator }, intl: { formatMessage }
        } = this.props;
        if (!repository && !hasPermissions) {
            return <MissingPermissions />;
        }
        return (
            <Form>
                <Row type="flex">
                    <FormItem
                        label={formatMessage(repositoryFormLabel.name)}
                        className={styles.RepositoryFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('name', {
                            initialValue: repository ? repository.name : undefined,
                            rules: [{ required: true }]
                        })(
                            <Input disabled={this.shouldDisableFormItem()} onBlur={this.trimValue} />
                        )}
                    </FormItem>

                    <FormItem
                        label={formatMessage(repositoryFormLabel.mnemonicId)}
                        className={styles.RepositoryFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('mnemonicId', {
                            initialValue: repository ? repository.mnemonicId : undefined,
                            rules: [{ required: true }]
                        })(
                            <Input disabled={!hasPermissions || !!repository} onBlur={this.trimValue} />
                        )}
                    </FormItem>

                    <FormItem
                        label={formatMessage(repositoryFormLabel.description)}
                        className={styles.RepositoryFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('description', {
                            initialValue: repository ? repository.description : undefined,
                            rules: [{ required: true }]
                        })(
                            <TextArea disabled={this.shouldDisableFormItem()} onBlur={this.trimValue} />
                        )}
                    </FormItem>

                    <FormItem
                        label={formatMessage(repositoryFormLabel.color)}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('color', {
                            initialValue: repository ? repository.color : this.randomHexColor
                        })(
                            <Input disabled={this.shouldDisableFormItem()} type="color" />
                        )}
                    </FormItem>

                </Row>
            </Form>
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(RepositoryForm)));
