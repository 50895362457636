import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { inject, observer } from 'mobx-react';
import { InjectedProps, MediaGallerySceneProps, MediaGallerySceneState } from './MediaGallerySceneModel';
import { WithLoadingData, withMediaGalleryStores } from '../../../hoc';
import MediaListScene from '../MediaListScene/MediaListScene';
import { DataAttributes, DataAttributesBuilder } from '../../../services/DataAttributesBuilder';
import { CloudinaryImageUpload } from '../../../components';
import UpdateMediaGallery from '../UpdateMediaGallery/UpdateMediaGallery';
import {
    EDIT_MEDIA_GALLERY,
    MEDIA_GALLERY_BROWSE,
    MEDIA_GALLERY_NEW_MEDIA,
    mediaGalleryNotFound,
    MEDIA_GALLERY_NEW_MEDIA_WITH_FOLDER_PATH,
    MEDIA_GALLERY_BROWSE_WITH_FOLDER_PATH,
    browseMediaGalleryPathWithFolderPath
} from '../../../constants/routing-constants';
import { isValidType } from '../../../services/cloudinaryUtils';
import { MediaNavigationProvider } from '../../../providers/MediaNavigationProvider';

@inject('mediaGalleryFlowStore')
@observer
class MediaGalleryScene extends Component<MediaGallerySceneProps, MediaGallerySceneState> {

    get injected() {
        return this.props as InjectedProps;
    }

    setMediaGalleryData = () => {
        const mediaGalleryId = this.props.match.params.mediaGalleryId;
        return this.injected.mediaGalleryFlowStore.initializeMediaGalleryFromApi(mediaGalleryId);
    }

    createDataAttributes = (): DataAttributes => {
        const { name } = this.injected.mediaGalleryFlowStore.mediaGallery;
        return new DataAttributesBuilder('mediagallery')
            .setNameAttribute(name)
            .build();
    }

    validateFileType = (file: File) => {
        return isValidType(file);
    }

    render() {
        const mediaGallery = this.injected.mediaGalleryFlowStore.getMediaGallery;
        return (
            <WithLoadingData promise={this.setMediaGalleryData}>
                <MediaNavigationProvider>
                    {Object.keys(mediaGallery).length
                        ? <Switch>
                            <Route
                                exact={true}
                                path={[
                                    MEDIA_GALLERY_NEW_MEDIA,
                                    MEDIA_GALLERY_NEW_MEDIA_WITH_FOLDER_PATH
                                ]}
                                render={(routeProps: RouteComponentProps<{
                                    mediaGalleryId: string, spaceId: string, folderPath?: string
                                }>) => (
                                        <CloudinaryImageUpload
                                            {...routeProps}
                                            onUpload={() => {
                                                const {
                                                    match: {
                                                        params: {
                                                            mediaGalleryId, spaceId, folderPath } } } = routeProps;
                                                this.props.history.push(
                                                    browseMediaGalleryPathWithFolderPath(
                                                        spaceId, +mediaGalleryId, folderPath
                                                    )
                                                );
                                            }}
                                            previousStep={() => {
                                                const {
                                                    match: {
                                                        params: {
                                                            mediaGalleryId, spaceId, folderPath } } } = routeProps;
                                                this.props.history.push(
                                                    browseMediaGalleryPathWithFolderPath(
                                                        spaceId, +mediaGalleryId, folderPath
                                                    )
                                                );
                                            }}
                                            validateFileType={this.validateFileType}
                                        />
                                    )}
                            />
                            <Route
                                exact={true}
                                path={[
                                    MEDIA_GALLERY_BROWSE,
                                    MEDIA_GALLERY_BROWSE_WITH_FOLDER_PATH
                                ]}
                                render={(routeProps: RouteComponentProps<
                                    { mediaGalleryId: string, spaceId: string, folderPath: string }>) => (
                                        <MediaListScene
                                            {...routeProps}
                                            dataAttributes={this.createDataAttributes}
                                        />
                                    )}
                            />
                            <Route
                                exact={true}
                                path={EDIT_MEDIA_GALLERY}
                                render={(routeProps: RouteComponentProps<{
                                    mediaGalleryId: string, spaceId: string
                                }>) => (
                                        <UpdateMediaGallery
                                            {...routeProps}
                                            dataAttributes={this.createDataAttributes}
                                            mediaGalleryData={mediaGallery}
                                        />
                                    )}
                            />
                            <Route>
                                <Redirect to="browse" />
                            </Route>
                        </Switch>
                        : <Redirect to={mediaGalleryNotFound(this.props.match.params.spaceId)} />
                    }
                </MediaNavigationProvider>
            </WithLoadingData>
        );
    }
}

export default withMediaGalleryStores<MediaGallerySceneProps>(MediaGalleryScene);
