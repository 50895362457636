import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Col, Icon, Row, Tag } from 'antd';
import { copyToClipboard } from '@services/utils';
import Button from '@components/Button';
import TagsSelector from '@components/TagsSelector/TagsSelector';
import { MediaDetailsProps, MediaDetailsState, InjectedProps } from './MediaDetailsModel';
import { inject, observer } from 'mobx-react';
import { HasPermissions, ManageFieldProvider } from '@providers/UserPermissionsProvider';
import { userPermissionsTable } from '@constants/user-permissions-table';

import styles from './MediaDetails.module.scss';

const mediaDetailsLabels = defineMessages({
    copyUrlToClip: {
        id: 'MediaDetails.CopyUrlToClip',
        defaultMessage: 'Copy Url'
    },
    urlCopiedToClip: {
        id: 'MediaDetails.UrlCopiedToClip',
        defaultMessage: 'Url copied'
    },
    copyPublicIdToClip: {
        id: 'MediaDetails.copyPublicIdToClip',
        defaultMessage: 'Copy Public Id'
    },
    publicIdCopiedToClip: {
        id: 'MediaDetails.publicIdCopiedToClip',
        defaultMessage: 'Public Id copied'
    },
    viewOriginalImage: {
        id: 'MediaDetails.viewOriginalImage',
        defaultMessage: 'View Original ({size})'
    },
    emptyTags: {
        id: 'MediaDetails.emptyTags',
        defaultMessage: 'There are no tags available for this media asset.'
    },
    selectTagsPlaceholder: {
        id: 'MediaDetails.selectTagsPlaceholder',
        defaultMessage: 'Insert a tag by pressing comma or enter'
    },
    saveTags: {
        id: 'MediaDetails.updateTags',
        defaultMessage: 'Save tags'
    },
    editTags: {
        id: 'MediaDetails.editTags',
        defaultMessage: 'Edit tags'
    }
});

@inject('mediaTagsStore')
@observer
class MediaDetails extends Component<MediaDetailsProps, MediaDetailsState> {

    get injected() {
        return this.props as InjectedProps;
    }

    state = {
        isEditingTags: false,
        isUrlCopiedToClipboard: false,
        isPublicIdCopiedToClipboard: false
    };

    onViewOriginalClick = () => {
        const { media } = this.props;
        window.open(media.data.publicUrl, '_blank');
    }

    onCopyUrlToClipboard = () => {
        const { media } = this.props;
        copyToClipboard('copyUrlToClip_input', media.copyableUrl);
        this.setState({ isUrlCopiedToClipboard: true });
        setTimeout(() => { this.setState({ isUrlCopiedToClipboard: false }); }, 500);
    }

    onCopyPublicIdToClipboard = () => {
        const { media } = this.props;
        copyToClipboard('copyPublicIdToClip_input', media.data.publicId);
        this.setState({ isPublicIdCopiedToClipboard: true });
        setTimeout(() => { this.setState({ isPublicIdCopiedToClipboard: false }); }, 500);
    }

    displayMediaInformation = () => {
        const { media, intl: { formatMessage } } = this.props;
        const displayableData = media.getDisplayableData();
        const displayableElement: JSX.Element[] = [];
        for (const key in displayableData) {
            if (displayableData.hasOwnProperty(key)) {
                if (displayableData[key]) {
                    displayableElement.push(
                        <React.Fragment key={key}>
                            <Col
                                xs={24}
                                sm={8}
                                xl={6}
                                className={styles.MediaDetailsInformationLabel}
                            >
                                {formatMessage(media.labels[key])}:
                            </Col>
                            <Col
                                xs={24}
                                sm={16}
                                xl={18}
                                className={styles.MediaDetailsInformationValue}
                            >
                                {displayableData[key]}
                            </Col>
                        </React.Fragment>
                    );
                }
            }
        }
        return displayableElement;
    }

    onSearchSuggestedTags = async (value: string) => {
        await this.injected.mediaTagsStore.setSuggestedTags(value);
    }

    displayEditableTags = () => {
        const { media, media: { data: { tags } } } = this.props;
        const { loader: { suggestedTags: loaderSuggestedTags }, suggestedTags } = this.injected.mediaTagsStore;
        return (
            <ManageFieldProvider permissions={userPermissionsTable.MediaDetails.modifyTagsPermissions}>
                <TagsSelector
                    value={tags}
                    onChange={(values: string[]) => media.replaceTags(values)}
                    loading={loaderSuggestedTags}
                    suggestedTags={suggestedTags}
                    onSearch={this.onSearchSuggestedTags}
                />
            </ManageFieldProvider>
        );
    }

    displayTagList = () => {
        const { media: { data }, intl: { formatMessage } } = this.props;
        const tagList: JSX.Element[] = [];
        if (data.tags.length > 0) {
            data.tags.map((tag) => {
                tagList.push(<Tag key={tag} className={styles.MediaDetailsTag}>{tag}</Tag>);
            });
        } else {
            tagList.push(
                <p
                    className={styles.MediaDetailsEmptyTags}
                    key={'empty_tags'}
                >
                    {formatMessage(mediaDetailsLabels.emptyTags)}
                </p>);
        }
        return tagList;
    }

    updateTags = async () => {
        const { media } = this.props;
        await this.props.onUpdateMedia(media.data);
        this.setState({ isEditingTags: !this.state.isEditingTags });
    }

    render() {
        const { media, intl: { formatMessage } } = this.props;
        return (
            <React.Fragment>
                <Row type="flex" align="middle" className={styles.MediaDetailsTagsWrapper}>
                    <Col xs={21} sm={22} md={23} className={styles.MediaDetailsTagsColumn}>
                        {this.state.isEditingTags ?
                            this.displayEditableTags() :
                            this.displayTagList()
                        }
                    </Col>
                    <Col xs={3} sm={2} md={1} className={styles.MediaDetailsEditTagsColumn}>
                        {this.state.isEditingTags ?
                            <Icon
                                type="check"
                                theme="outlined"
                                className={styles.MediaDetailsEditTagsIcon}
                                onClick={this.updateTags}
                                title={formatMessage(mediaDetailsLabels.saveTags)}
                            />
                            :
                            <HasPermissions permissions={userPermissionsTable.MediaDetails.modifyTagsPermissions}>
                                <Icon
                                    data-id="modify-tags"
                                    type="edit"
                                    className={styles.MediaDetailsEditTagsIcon}
                                    onClick={() => { this.setState({ isEditingTags: !this.state.isEditingTags }); }}
                                    title={formatMessage(mediaDetailsLabels.editTags)}
                                />
                            </HasPermissions>
                        }
                    </Col>
                </Row>
                <Row type="flex" justify="center" className={styles.MediaDetailsDataWrapper}>
                    <Col xs={24} md={16} xl={18} className={styles.MediaDetailsInformationColumn}>
                        <Row type="flex" justify="center">
                            {this.displayMediaInformation()}
                        </Row>
                    </Col>
                    <Col xs={22} sm={14} md={8} xl={6} className={styles.MediaDetailsActionColumn}>
                        <Button type="primary" onClick={this.onViewOriginalClick} id="view-original-image">
                            <p>{formatMessage(mediaDetailsLabels.viewOriginalImage, { size: media.data.size })}</p>
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.onCopyUrlToClipboard}
                            id="copy-url-to-clipboard"
                        >
                            <p>
                                {this.state.isUrlCopiedToClipboard ?
                                    formatMessage(mediaDetailsLabels.urlCopiedToClip) :
                                    formatMessage(mediaDetailsLabels.copyUrlToClip)
                                }
                            </p>
                        </Button>
                        <Button
                            type="primary"
                            onClick={this.onCopyPublicIdToClipboard}
                            id="copy-publicId-to-clipboard"
                        >
                            <p>
                                {this.state.isPublicIdCopiedToClipboard ?
                                    formatMessage(mediaDetailsLabels.publicIdCopiedToClip) :
                                    formatMessage(mediaDetailsLabels.copyPublicIdToClip)
                                }
                            </p>
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default injectIntl(MediaDetails);
