import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import ContentList from '../ContentList/ContentList';
import SelectDefinition from '../SelectDefinition/SelectDefinition';
import CreateContentFormInitializer from '../CreateContent/CreateContent';
import EditContentFormInitializer from '../EditContent/EditContent';
import { ContentsRouterProps, ContentsRouterState, InjectedProps } from './ContentsRouterModel';
import {
    CONTENTS_ROUTE,
    CREATE_CONTENT_ROUTE,
    EDIT_CONTENT_ROUTE,
    CREATE_CONTENT_SELECT_DEFINITION_ROUTE, contentsBasePath
} from '../../../constants/routing-constants';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';

@inject('navStore')
class ContentsRouter extends Component<ContentsRouterProps, ContentsRouterState> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        const { intl: { formatMessage }, match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.CONTENTS,
                contentsBasePath(spaceId),
                formatMessage(NAVIGATION_LABELS.contents),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.CONTENTS);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact={true}
                    path={CONTENTS_ROUTE}
                    component={ContentList}
                />
                <Route
                    exact={true}
                    path={CREATE_CONTENT_SELECT_DEFINITION_ROUTE}
                    component={SelectDefinition}
                />
                <Route
                    exact={true}
                    path={CREATE_CONTENT_ROUTE}
                    component={CreateContentFormInitializer}
                />
                <Route
                    exact={true}
                    path={EDIT_CONTENT_ROUTE}
                    component={EditContentFormInitializer}
                />
            </Switch>
        );
    }
}

export default injectIntl(ContentsRouter);
