import {
    FieldArray,
    FieldNumber,
    FieldText,
    FieldLongText,
    FieldBoolean,
    FieldDate,
    FieldLinkedContent,
    FieldCloudinaryImage,
    FieldFragment,
    SimpleSchemaTypes,
    SimpleField,
    FieldOneFragmentOf,
    FieldOneLinkedContentOf, FieldCloudinaryPublicId, FieldCloudinaryVideo, FieldCloudinaryRaw, createFragmentLabels
} from './FormFields';
import {
    ArraySchemaType,
    BooleanSchemaType,
    CloudinaryImageSchemaType, CloudinaryPublicIdSchemaType,
    DateSchemaType,
    Field,
    FragmentSchemaType,
    LinkedContentSchemaType,
    LongTextSchemaType,
    NumberSchemaType,
    OneFragmentOfSchemaType,
    OneLinkedContentOfSchemaType,
    SchemaTypeIds,
    TextSchemaType,
    CloudinaryVideoSchemaType,
    CloudinaryRawSchemaType,
    FragmentsTable
} from '@contentchef/contentchef-types';

export function FormFieldFactory(
    fragments: FragmentsTable,
    field: Field, definitionLocale: string, fromInit: boolean, value?: any, parentUniqueId?: string): (
        SimpleField<Field<SimpleSchemaTypes>, any> |
        FieldArray<Field<ArraySchemaType>> |
        FieldFragment | FieldOneFragmentOf |
        null
    ) {
    switch (field.type) {
        case SchemaTypeIds.TEXT:
            return new FieldText(
                <Field<TextSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.LONG_TEXT:
            return new FieldLongText(
                <Field<LongTextSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.NUMBER:
            return new FieldNumber(
                <Field<NumberSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.BOOLEAN:
            return new FieldBoolean(
                <Field<BooleanSchemaType>> field, definitionLocale, value, parentUniqueId)
                ;
        case SchemaTypeIds.DATE:
            return new FieldDate(
                <Field<DateSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.LINKED_CONTENT:
            return new FieldLinkedContent(
                <Field<LinkedContentSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return new FieldCloudinaryPublicId(
                <Field<CloudinaryPublicIdSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return new FieldCloudinaryImage(
                <Field<CloudinaryImageSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return new FieldCloudinaryVideo(
                <Field<CloudinaryVideoSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.CLOUDINARY_RAW:
            return new FieldCloudinaryRaw(
                <Field<CloudinaryRawSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return new FieldOneLinkedContentOf(
                <Field<OneLinkedContentOfSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.ARRAY:
            return new FieldArray(
                <Field<ArraySchemaType>> field, definitionLocale, value, parentUniqueId
            );
        case SchemaTypeIds.FRAGMENT:
            const fragmentNameLabels = createFragmentLabels(fragments, field.constraints.name, definitionLocale);
            return new FieldFragment(
                <Field<FragmentSchemaType>> field, definitionLocale, fromInit,
                fragmentNameLabels, value, parentUniqueId
            );
        case SchemaTypeIds.ONE_FRAGMENT_OF:
            return new FieldOneFragmentOf(
                <Field<OneFragmentOfSchemaType>> field, definitionLocale, value, parentUniqueId
            );
        default:
            return null;
    }
}
