import React from 'react';
import { ValidatedTranslateElement } from '../TranslateElement';
import { FormattedMessage } from 'react-intl';
import FieldEditorStore from '../../FieldEditorStore';
import {
    extensionMappedErrors,
    hintMappedErrors,
    placeholderMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';
import Extension from '../Extension';
import { observer } from 'mobx-react';

interface TranslationTabProps {
    id: string;
    fieldEditorStore: FieldEditorStore;
    defaultLang: string;
}

function TranslationsTab(props: TranslationTabProps) {
    return (
        <div id={props.id}>
            <ValidatedTranslateElement
                relatedTabKey={'uiCustomizationTab'}
                mappedErrors={placeholderMappedErrors}
                segment={true}
                defaultCulture={props.defaultLang}
                items={props.fieldEditorStore.serializedField.placeholder}
                label={<FormattedMessage
                    defaultMessage="Placeholder"
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.TranslationsTab.placeholder"
                />}
                onAdd={(defaultCulture) => props.fieldEditorStore.createI18n('placeholder', defaultCulture)}
                onChange={(culture, label) => {
                    if (label.trim().length === 0) {
                        props.fieldEditorStore.removeI18n('placeholder', culture);
                    } else {
                        props.fieldEditorStore.updateI18n('placeholder', culture, label);
                    }
                }}
            />
            <ValidatedTranslateElement
                relatedTabKey={'uiCustomizationTab'}
                mappedErrors={hintMappedErrors}
                segment={true}
                defaultCulture={props.defaultLang}
                items={props.fieldEditorStore.serializedField.hint}
                label={<FormattedMessage
                    defaultMessage="Hint"
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.TranslationsTab.hint"
                />}
                onAdd={(defaultCulture) => props.fieldEditorStore.createI18n('hint', defaultCulture)}
                onChange={(culture, label) => {
                    if (label.trim().length === 0) {
                        props.fieldEditorStore.removeI18n('hint', culture);
                    } else {
                        props.fieldEditorStore.updateI18n('hint', culture, label);
                    }
                }}
            />
            <Extension
                relatedTabKey="uiCustomizationTab"
                onChange={(value) => {
                    if (value) {
                        props.fieldEditorStore.updateExtension(value);
                    } else {
                        props.fieldEditorStore.removeExtension();
                    }
                }}
                value={props.fieldEditorStore.serializedField.extension}
                mappedErrors={extensionMappedErrors}
                type={props.fieldEditorStore.serializedField.type}
            />
        </div>
    );
}

export default observer(TranslationsTab);
