// tslint:disable: max-line-length
import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import {
    ContentDefinitionSchemaStoreModel
} from '../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { SerializedField } from './FieldSerializer/types';
import ContentDefinitionElement, { SortableContentDefinitionElement } from './ContentDefinitionElement';
import { IObservableArray } from 'mobx';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../providers/UserPermissionsProvider';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { createFieldItemsMap } from './ContentDefinitionEditorDrawer/snippets';
import { ListItemIds } from './types';
import { FragmentFieldTypeIcon } from '../../assets/custom_icons/components/fragment';
import FieldTypeIcon from '../../components/atoms/FieldTypeIcon';

interface ContentDefinitionElementListProps extends InjectedIntlProps, WithPermissionsToInteractReturnType {
    sortable?: boolean;
    fields: IObservableArray<SerializedField>;
    onCheckElementHasErrors(elementIndex: number): boolean;
    onCloneClick(field: SerializedField, hasErrors: boolean): void;
    onEditClick(field: SerializedField, index: number, hasErrors: boolean): void;
    onRemoveClick(index: number, hasErrors: boolean): void;
    onCheckNameAlreadyExist(fieldName: string): boolean;
}

interface DecoratedProps extends ContentDefinitionElementListProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}

@inject('contentDefinitionSchemaStore')
@observer
class ContentDefinitionElementList extends Component<ContentDefinitionElementListProps, {}> {
    get decoratedProps() { return this.props as DecoratedProps; }

    render() {
        const fieldDescriptors = createFieldItemsMap(this.props.intl.formatMessage);

        return (
            <div style={{ flex: 1, overflow: 'auto', padding: '10px 10px' }}>
                {this.props.fields.map((field, index) => {
                    const fieldHasErrors = this.props.onCheckElementHasErrors(field.initialIndex);

                    const isArray = field.editingType === ListItemIds.ListOfItems;

                    const typeToUse = isArray ? field.editingSubType : field.editingType;
                    const isFragment = ListItemIds.CustomField === typeToUse;
                    const fieldDescriptor = fieldDescriptors.get(typeToUse as ListItemIds);

                    const typeName = !isFragment ? fieldDescriptor!.name :
                        !isArray ? field.constraints.name : field.constraints.items.constraints.name;

                    const iconComponent = isFragment ? <FragmentFieldTypeIcon title={'Custom Field'} /> :
                        fieldDescriptor ? <FieldTypeIcon title={typeName} icon={fieldDescriptor!.icon!} /> : null;

                    const typeDescription = isFragment ? undefined : fieldDescriptor!.description;

                    return (this.props.sortable && this.props.hasPermissions
                        ? <SortableContentDefinitionElement
                            index={index}
                            key={index}
                            hasErrors={fieldHasErrors}
                            fieldName={field.name}
                            typeName={typeName}
                            typeDescription={typeDescription}
                            draggable={true}
                            typeIconComponent={iconComponent}
                            multiValue={isArray}
                            onClone={(newFieldName: string) => {
                                const newField = Object.assign({}, field, { name: newFieldName });
                                return this.props.onCloneClick(newField, fieldHasErrors);
                            }
                            }
                            onEdit={() => this.props.onEditClick(field, index, fieldHasErrors)}
                            onRemove={() => this.props.onRemoveClick(index, fieldHasErrors)}
                            onCheckNameAlreadyExist={this.props.onCheckNameAlreadyExist}
                        />
                        : <ContentDefinitionElement
                            draggable={false}
                            key={index}
                            hasErrors={fieldHasErrors}
                            fieldName={field.name}
                            typeName={typeName}
                            typeDescription={typeDescription}
                            typeIconComponent={iconComponent}
                            multiValue={isArray}
                            onClone={(newFieldName: string) => {
                                const newField = Object.assign({}, field, { name: newFieldName });
                                return this.props.onCloneClick(newField, fieldHasErrors);
                            }
                            }
                            onEdit={() => this.props.onEditClick(field, index, fieldHasErrors)}
                            onRemove={() => this.props.onRemoveClick(index, fieldHasErrors)}
                            onCheckNameAlreadyExist={this.props.onCheckNameAlreadyExist}
                        />
                    );
                })}
            </div>
        );
    }
}

export default withPermissionsToInteract(injectIntl(ContentDefinitionElementList));
export const SortableDefinitionElementList = withPermissionsToInteract(
    injectIntl(SortableContainer<ContentDefinitionElementListProps>(
        props => <ContentDefinitionElementList {...props} sortable={true} />
    )));
