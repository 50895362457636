import React from 'react';
import { FormattedMessage } from 'react-intl';
import BooleanAttribute from '../Fields/Boolean';
import
withDefinitionErrors,
{ WithDefinitionErrorsReturnProps }
    from '../../../../hoc/withDefinitionErrors/withDefinitionErrors';
import { InjectedIntlProps, injectIntl } from 'react-intl';

export interface LazyFieldProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    value?: boolean;
    onChange(required: boolean): any;
}

function LazyField(props: LazyFieldProps) {
    return (
        <BooleanAttribute
            hasErrors={props.hasErrors}
            errors={props.errors.map(e => props.intl.formatMessage(e))}
            value={props.value}
            onChange={props.onChange}
            attributeName={<FormattedMessage
                id={'components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.lazyAttribute.label'}
                defaultMessage={'Reference type'}
            />}
            titleTrue={<FormattedMessage
                id={'components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.contentReference.title'}
                defaultMessage={'Content Reference'}
            />}
            titleFalse={<FormattedMessage
                id={'components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.InlinedContent.switch'}
                defaultMessage={'Inline content'}
            />}
            helperTrue={<FormattedMessage
                id={'components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.contentReference.help'}
                // tslint:disable-next-line:max-line-length
                defaultMessage={`Select this option if you don't want to automatically load the referenced content when retrieving content.`}
            />}
            helperFalse={<FormattedMessage
                id={'components.ContentDefinitionSchemaEditor.DetailsTab.LinkedContent.includedContent.hint'}
                // tslint:disable-next-line:max-line-length
                defaultMessage={`Select this option if you want to automatically load the referenced content when retrieving content.`}
            />}
        />
    );
}

export default withDefinitionErrors(injectIntl(LazyField));
