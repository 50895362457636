import React from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { UserPermissionIds } from '@contentchef/contentchef-types';
import UserPermissionsStore from '../stores/userPermissionsStore';
import { WithStoresComponentProps } from '../constants/common-types';
import apiStore, { ApiStoreModel } from '../stores/apiStore/apiStore';
import Loader from '../components/Loader/Loader';

/**
 * @export
 * @template T
 * @type InjectedProps
 */
type InjectedProps<T> = WithStoresComponentProps<T> & {
    apiStore: ApiStoreModel;
};

type UserPermissionsProviderState = {
    loading: boolean;
};

/**
 * @export
 * @type withPermissionsReturnType
 */
export type withPermissionsReturnType =
    <T extends any>(Component: React.ComponentType<T & WithPermissionsReturnTypeProps>) => (props: T) => any;

/**
 * @export
 * @type withoutDisableFieldContextProps
 */
export type withoutDisableFieldContextProps<T> = Pick<T, Exclude<keyof T, keyof DisableFieldProviderData>>;

/**
 * @export
 * @interface UserPermissionsProviderData
 */
export interface UserPermissionsProviderData {
    userPermissions: UserPermissionIds[];
}

/**
 * @export
 * @interface DisableFieldProviderData
 */
export interface DisableFieldProviderData {
    hasPermissions: boolean;
}

/**
 * @export
 * @interface WithPermissionsToInteractReturnType
 */
export interface WithPermissionsToInteractReturnType {
    hasPermissions: boolean;
}

/**
 * @export
 * @interface WithPermissionsReturnTypeProps
 */
export interface WithPermissionsReturnTypeProps {
    isAllowed: boolean;
}

export const UserPermissionContext = React.createContext<UserPermissionsProviderData>({
    userPermissions: [],
});

export const DisableFieldContext = React.createContext<DisableFieldProviderData>({
    hasPermissions: false,
});

/**
 * @export
 * @param {UserPermissionIds[]} userPermissions
 * @param {UserPermissionIds[]} permissions
 * @returns {boolean}
 */
function isAllowed(userPermissions: UserPermissionIds[] = [], permissions: UserPermissionIds[] = []): boolean {
    for (let i = 0; i < permissions.length; i++) {
        if (!userPermissions.find(permission => permission === permissions[i])) {
            return false;
        }
    }

    return true;
}

/**
 * @param {UserPermissionIds[]} permissions
 * @returns {withPermissionsReturnType}
 */
export const withPermissions = (permissions: UserPermissionIds[]): withPermissionsReturnType => {
    return Component => {
        return props => {
            return (
                <UserPermissionContext.Consumer>
                    {
                        context => (
                            <Component
                                isAllowed={isAllowed(context.userPermissions, permissions)}
                                {
                                ...props
                                }
                            />
                        )
                    }
                </UserPermissionContext.Consumer>
            );
        };
    };
};

/**
 * @param {UserPermissionIds[]} permissions
 * @returns {withPermissionsReturnType}
 */

/**
 * @template T
 * @param {*} Component
 * @returns
 */
function withUserPermissionStoresProvider<T>(Component: any) {
    return class WithUserPermissionProvider extends React.Component<InjectedProps<T>> {
        public userPermissionsStore = new UserPermissionsStore(apiStore.api, apiStore.getCurrentSpaceId);

        public render() {
            const {
                ...props
            } = this.props;

            return (
                <Provider userPermissionsStore={this.userPermissionsStore}>
                    <Component {...props} />
                </Provider>
            );
        }
    };
}

export interface InjectedUserPermissionsProps extends UserPermissionsProviderData {
    userPermissionsStore: UserPermissionsStore;
}

/**
 * @export
 * @class UserPermissionsProvider
 * @extends {React.Component<UserPermissionsProviderData>}
 */
@inject('userPermissionsStore')
@observer
class UserPermissions extends React.Component<UserPermissionsProviderData, UserPermissionsProviderState> {
    state: UserPermissionsProviderState = {
        loading: true
    };

    get injectedProps() {
        return this.props as InjectedUserPermissionsProps;
    }

    public async componentDidMount(): Promise<void> {
        await this.injectedProps.userPermissionsStore.list();
        this.setState({ loading: false });
    }

    /**
     * @returns
     * @memberof UserPermissionsProvider
     */
    public render() {
        if (this.state.loading) {
            return <Loader />;
        }
        const { children } = this.props;
        const userPermissions = this.injectedProps.userPermissionsStore.currentPermissions;
        return (
            <UserPermissionContext.Provider children={children} value={{ userPermissions }} />
        );
    }
}

export const HasPermissions = (props: { children?: any; permissions: UserPermissionIds[]; }) => {
    const Component = withPermissions(props.permissions)(childProps => (
        <React.Fragment>
            {
                childProps.isAllowed ? childProps.children : null
            }
        </React.Fragment>
    ));

    return <Component {...props as any} />;
};

export const withPermissionsToInteract =
    <T extends WithPermissionsToInteractReturnType>
        (WrappedComponent: React.ComponentType<T>): React.ComponentType<withoutDisableFieldContextProps<T>> => {

        return class WithPermissionsToInteract extends React.Component<withoutDisableFieldContextProps<T>> {
            static contextType = DisableFieldContext;
            render() {
                return (
                    <WrappedComponent
                        {...{
                            ...this.props,
                            hasPermissions: this.context.hasPermissions
                        } as T}
                    />
                );
            }
        };
    };

export interface ManageFieldProviderProps {
    permissions: UserPermissionIds[];
}

export class ManageFieldProvider extends React.Component<ManageFieldProviderProps, {}> {
    render() {
        const { children, ...others } = this.props;
        return (
            <UserPermissionContext.Consumer>
                {
                    context => (
                        <DisableFieldContext.Provider
                            value={{ hasPermissions: isAllowed(context.userPermissions, this.props.permissions) }}
                        >
                            {React.Children.map(children, child => React.cloneElement(child as any, { ...others }))}
                        </DisableFieldContext.Provider>
                    )
                }
            </UserPermissionContext.Consumer>
        );
    }
}

export const UserPermissionsProvider = withUserPermissionStoresProvider(UserPermissions);
