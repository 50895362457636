import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { MediaProps, InjectedProps } from './MediaModel';
import styles from './Media.module.scss';
import { Icon } from 'antd';

@inject('mediaGalleryFlowStore')
@observer
class Media extends Component<MediaProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    onClick = () => {
        const { media, onClick } = this.props;
        if (onClick) {
            onClick(media);
        }
    }

    isMediaActive = () => {
        const { data } = this.props.media;
        const { media: selectedMedia } = this.injected.mediaGalleryFlowStore;
        if (selectedMedia) {
            return data.publicId === selectedMedia.data.publicId;
        }
        return false;
    }

    render() {
        const { data, retrieveImageName } = this.props.media;
        return (
            <div
                onClick={this.onClick}
                className={styles.MediaContainer}
                data-media-name={retrieveImageName()}
            >
                <img
                    src={data.thumbnailUrl}
                    className={styles.MediaImg}
                />
                <div className={styles.MediaInfoContainer}>
                    <p className={styles.MediaInfoTitle}>{retrieveImageName()}</p>
                    <div className={styles.MediaTagsContainer}>
                        <p className={styles.MediaInfoTitle}>
                            {data.tags.map(
                                (tag, index) => (<span key={index} className={styles.MediaTag}>#{tag}</span>)
                            )}
                        </p>
                    </div>
                    {this.isMediaActive() && <Icon className={styles.MediaSelected} type="check-circle" />}
                </div>
            </div>
        );
    }
}

export default Media;
