import React from 'react';
import MinMax from '../Constraints/MinMax';
import RequiredField from '../Constraints/RequiredField';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { ArrayConstraints } from '../../FieldSerializer/fields/array';
import { FormattedMessage } from 'react-intl';
import ArrayAdditionalConstraints from './index';
import {
    minMaxLengthMappedErrors, requiredMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfItemsConstraints(props: FieldEditTabProps<ArrayConstraints>) {
    return (
        <>
            <MinMax
                relatedTabKey={'validationTab'}
                mappedErrors={minMaxLengthMappedErrors}
                valueMax={props.constraints.maxLength}
                valueMin={props.constraints.minLength}
                onChange={(key, value) => props.onChangeConstraint(
                    key === 'max' ? 'maxLength' : 'minLength', value
                )}
                attributeLabel={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.ListOfItems.MinMaxLength.label"
                    defaultMessage="Elements' number"
                />}
                minTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.ListOfItems.MinMaxLength.min.title"
                    defaultMessage="Minimum number"
                />}
                maxTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.ListOfItems.MinMaxLength.max.title"
                    defaultMessage="Maximum number"
                />}
                minHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.ListOfItems.MinMaxLength.min.helper"
                    defaultMessage="This will define the minimum elements' number"
                />}
                maxHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.ListOfItems.MinMaxLength.max.max.helper"
                    defaultMessage="This will define the maximum elements' number"
                />}
            />
            <RequiredField
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                onChange={required => props.onChangeConstraint('required', required)}
                value={props.constraints.required}
            />
            <ArrayAdditionalConstraints
                id={'ArrayAdditionalConstraints'}
                isArrayChildren={true}
                fieldEditorStore={props.fieldEditorStore}
                fieldToRenderType={props.fieldEditorStore.serializedField.editingSubType!}
                defaultLang={props.defaultLang}
                constraints={props.constraints.items.constraints}
                onChangeConstraint={props.fieldEditorStore.updateArrayItemsConstraint}
            />
        </>
    );
}

export default observer(ListOfItemsConstraints);
