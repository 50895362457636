import React from 'react';
import { Auth } from 'aws-amplify';
import { Icon } from 'antd';
import Button from '@components/Button';
import classes from './index.module.scss';
import { FormattedMessage } from 'react-intl';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

export interface SignInWithGoogleProps {
    inSignUpPage?: boolean;
    disabled?: boolean;
}

export default function SignInWithGoogle(props: SignInWithGoogleProps) {
    const signIn = () => Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
    });

    return (
        <Button
            className={classes.SignInWithGoogle}
            disabled={props.disabled}
            onClick={signIn}
        >
            <Icon type="google" />
            {props.inSignUpPage
                ? <FormattedMessage
                    defaultMessage="Sign up with google"
                    id="components.onboarding.SignInWithGoogle.label1"
                />
                : <FormattedMessage
                    defaultMessage="Sign in with google"
                    id="components.onboarding.SignInWithGoogle.label2"
                />
            }
        </Button>
    );
}
