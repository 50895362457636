import { action, IObservableArray, observable, runInAction } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { ListPublicIdStoreModel } from './listPublicIdStoreModel';
import {
    ListPublicIdsRequest
} from '@contentchef/contentchef-types';
import { ContentDefinitionId } from '@contentchef/contentchef-types';

class ListPublicIdStore implements ListPublicIdStoreModel {
    api: ContentChefClient;
    @observable.shallow listPublicId: IObservableArray<string> = observable.array([]);

    @observable error: string = '';

    definitionId: ContentDefinitionId;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async initialize(definitionId: ContentDefinitionId, publicId?: string) {
        this.definitionId = definitionId;
        await this.setListPublicId(publicId);
    }

    @action
    async setListPublicId(publicId?: string) {
        this.listPublicId.clear();
        const reqParams: ListPublicIdsRequest = {
            filters: {
                publicId: publicId,
                definitionId: this.definitionId
            }
        };
        try {
            const response = await this.api.contents.listPublicIds(reqParams);
            runInAction(() => {
                this.listPublicId.replace(response);
            });
        } catch (e) {
            runInAction(() => {
                this.error = e;
                console.log('Impossible to call contents.listPublicIds.list()', e);
            });
        }
    }
}

export default ListPublicIdStore;
