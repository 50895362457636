import React from 'react';
import OnboardingContainer from './OnboardingContainer';
import { injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';
import { Form, Input, Divider } from 'antd';
import Button from '@components/Button';
import classes from './index.module.scss';
import { Auth } from 'aws-amplify';
import { withRouter, RouteComponentProps } from 'react-router';
import { LOGIN_ROUTE } from '@constants/routing-constants';
import ForgotPasswordImage from '../../assets/images/forgotPasswordImage.svg';
import { IAuthPieceProps } from 'aws-amplify-react/lib/Auth/AuthPiece';

interface ForgotPasswordProps extends IAuthPieceProps, RouteComponentProps, InjectedIntlProps { }

type ForgotPasswordState = {
    email: string;
    code: string;
    newPassword: string;
    delivery: any;
    loadingSubmit: boolean;
    loadingSendCode: boolean;
};

const { Item } = Form;

const forgotPasswordLabels = defineMessages({
    code: {
        defaultMessage: 'Code',
        id: 'components.onboarding.forgotPassword.code'
    },
    newPassword: {
        defaultMessage: 'New Password',
        id: 'components.onboarding.forgotPassword.newPassword'
    },
    email: {
        defaultMessage: 'Email',
        id: 'components.onboarding.forgotPassword.email'
    },
    title: {
        defaultMessage: `Recover access to your account.`,
        id: 'components.onboarding.forgotPassword.title'
    },
    or: {
        defaultMessage: 'or',
        id: 'components.onboarding.forgotPassword.or'
    },
    secondaryAction: {
        defaultMessage: 'Back to Sign In',
        id: 'components.onboarding.forgotPassword.backToSignIn'
    },
    sendCode: {
        defaultMessage: 'Send Code',
        id: 'components.onboarding.forgotPassword.sendCode'
    },
    resendCode: {
        defaultMessage: 'Resend Code',
        id: 'components.onboarding.forgotPassword.resendCode',
    },
    primaryAction: {
        defaultMessage: 'Submit',
        id: 'components.onboarding.forgotPassword.submit'
    }
});

function getInitialState(): ForgotPasswordState {
    return {
        email: '',
        code: '',
        newPassword: '',
        delivery: null,
        loadingSubmit: false,
        loadingSendCode: false
    };
}

class CustomForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
    constructor(props: ForgotPasswordProps & RouteComponentProps) {
        super(props);

        this.state = getInitialState();
    }

    public onError(error: unknown): any {
        if (error === null) {
            return;
        }

        if (typeof error === 'string') {
            return this.props.onAuthEvent!('forgotPassword', {
                data: error,
                type: 'error',
            });
        }

        if (typeof error === 'object' && error !== null) {
            const message = (error as any).message;

            return this.props.onAuthEvent!('forgotPassword', {
                type: 'error',
                data: message ? message : JSON.stringify(error)
            });
        }
    }

    send = async () => {
        const { email } = this.state;
        if (!Auth || typeof Auth.forgotPassword !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        try {
            const data = await Auth.forgotPassword(email);
            this.setState({ delivery: data.CodeDeliveryDetails });
        } catch (error) {
            this.onError(error);
        }
    }

    onClickSendCode = async () => {
        this.setState({
            loadingSendCode: true
        });
        try {
            await this.send();
        } finally {
            this.setState({
                loadingSendCode: false
            });
        }
    }

    submit = async () => {
        const { email, code, newPassword } = this.state;

        if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
            throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
        }
        try {
            await Auth.forgotPasswordSubmit(email, code, newPassword);
            this.props.onStateChange!('signIn', {});
            this.props.history.push(LOGIN_ROUTE);
            this.setState(getInitialState());
        } catch (error) {
            this.onError(error);
        }
    }

    onClickSubmit = async () => {
        this.setState({
            loadingSubmit: true
        });
        try {
            await this.submit();
        } finally {
            this.setState({
                loadingSubmit: false
            });
        }
    }

    public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [event.target.name]: event.target.value,
        } as any);
    }

    submitView() {

        const {
            newPassword,
            code
        } = this.state;

        return (
            <React.Fragment>
                <Item>
                    <Input
                        placeholder={this.props.intl.formatMessage(forgotPasswordLabels.code)}
                        onChange={this.handleInputChange}
                        name="code"
                        id="code"
                        value={code}
                    />
                </Item>
                <Item>
                    <Input
                        placeholder={this.props.intl.formatMessage(forgotPasswordLabels.newPassword)}
                        onChange={this.handleInputChange}
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        value={newPassword}
                    />
                </Item>
            </React.Fragment>
        );
    }

    sendView() {
        const {
            email
        } = this.state;
        return (
            <Item>
                <Input
                    placeholder={this.props.intl.formatMessage(forgotPasswordLabels.email)}
                    onChange={this.handleInputChange}
                    name="email"
                    id="email"
                    value={email}
                />
            </Item>
        );
    }

    render() {
        if (this.props.authState !== 'forgotPassword') {
            return null;
        }

        return (
            <OnboardingContainer
                topContent={this.props.intl.formatMessage(forgotPasswordLabels.title)}
                leftContent={<img style={{ width: '100%' }} src={ForgotPasswordImage} alt={'Sign up image'} />}
            >

                <Form className={classes.OnboardingForm}>
                    {
                        this.state.delivery ?
                            this.submitView()
                            :
                            this.sendView()
                    }
                    {this.state.delivery ?
                        (
                            <React.Fragment>
                                <Button
                                    className={classes.OnboardingButton}
                                    onClick={this.onClickSubmit}
                                    type="primary"
                                    loading={this.state.loadingSubmit}
                                >
                                    {this.props.intl.formatMessage(forgotPasswordLabels.primaryAction)}
                                </Button>
                                <Divider className={classes.OnboardingOr}>
                                    {this.props.intl.formatMessage(forgotPasswordLabels.or)}
                                </Divider>
                                <Button
                                    className={classes.OnboardingButton}
                                    onClick={this.onClickSendCode}
                                    type="dashed"
                                    loading={this.state.loadingSendCode}
                                >
                                    {this.props.intl.formatMessage(forgotPasswordLabels.resendCode)}
                                </Button>
                                <Divider className={classes.OnboardingOr}>
                                    {this.props.intl.formatMessage(forgotPasswordLabels.or)}
                                </Divider>
                                <Button
                                    className={classes.OnboardingButton}
                                    onClick={() => this.props.onStateChange!('signIn', {})}
                                    type="dashed"
                                >
                                    {this.props.intl.formatMessage(forgotPasswordLabels.secondaryAction)}
                                </Button>
                            </React.Fragment>
                        )
                        :
                        (
                            <React.Fragment>
                                <Button
                                    className={classes.OnboardingButton}
                                    onClick={this.onClickSendCode}
                                    type="primary"
                                    loading={this.state.loadingSendCode}
                                >
                                    {this.props.intl.formatMessage(forgotPasswordLabels.sendCode)}
                                </Button>
                                <Divider className={classes.OnboardingOr}>
                                    {this.props.intl.formatMessage(forgotPasswordLabels.or)}
                                </Divider>
                                <Button
                                    className={classes.OnboardingButton}
                                    onClick={() => this.props.onStateChange!('signIn', {})}
                                    type="dashed"
                                >
                                    {this.props.intl.formatMessage(forgotPasswordLabels.secondaryAction)}
                                </Button>
                            </React.Fragment>
                        )
                    }
                </Form>

            </OnboardingContainer>
        );
    }
}

export default withRouter(injectIntl(CustomForgotPassword));
