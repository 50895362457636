import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ExpandedListItemProps, ExpandedListItemState } from './ExpandedListItemModel';
import { withExpandedListItem } from '../../../hoc';

import styles from './ExpandedListItem.module.scss';

class ExpandedListItem extends Component<ExpandedListItemProps, ExpandedListItemState> {
    transitionTimeout: number;

    state = {
        startTransition: false
    };

    componentDidMount() {
        this.transitionTimeout = window.setTimeout(
            () => {
                this.setState({ startTransition: true });
            },
            100);
    }

    componentWillUnmount() {
        window.clearTimeout(this.transitionTimeout);
    }

    render() {
        const { renderExpandedItem, style, parent, } = this.props;
        const { startTransition } = this.state;
        const listContainerNode = parent;

        return (
            ReactDOM.createPortal(
                <div
                    style={style}
                    className={`${styles.ExpandedListItem} ${startTransition ? styles.ExpandedListItemTransition : ''}`}
                >
                    {renderExpandedItem()}
                </div>,
                listContainerNode
            )
        );
    }
}

export default withExpandedListItem(ExpandedListItem);
