import { Badge, Icon, Tooltip, Modal, Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { isFunction } from '../../services/utils';
import Typography from '../Typography';
import classes from './index.module.scss';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';
import { FormattedMessage, defineMessages } from 'react-intl';
import SimpleForm from '../SimpleForm';

const labels = defineMessages({
    elementView: {
        id: 'components.DefinitionSchemaEditor.ContentDefinitionElement.view',
        defaultMessage: 'View'
    },
    elementEdit: {
        id: 'components.DefinitionSchemaEditor.ContentDefinitionElement.edit',
        defaultMessage: 'Edit'
    },
    elementClone: {
        id: 'components.DefinitionSchemaEditor.ContentDefinitionElement.clone',
        defaultMessage: 'Clone'
    },
    elementDelete: {
        id: 'components.DefinitionSchemaEditor.ContentDefinitionElement.delete',
        defaultMessage: 'Delete'
    }
});

export interface ContentDefinitionElementProps extends WithPermissionsToInteractReturnType {
    draggable: boolean;
    active?: boolean;
    hasErrors?: boolean;
    className?: string;
    disabled?: boolean;
    fieldName?: string;
    typeDescription: string | undefined;
    typeName: string;
    typeIconComponent: JSX.Element | null;
    multiValue: boolean;
    onClone?(newFieldName: string): any;
    onEdit?(): any;
    onInspect?(): any;
    onRemove?(): any;
    onCheckNameAlreadyExist(fieldName: string): boolean;
}

interface ContentDefinitionElementState {
    editing: boolean;
    isCloning: boolean;
}

function getInitialState(): ContentDefinitionElementState {
    return {
        editing: false,
        isCloning: false
    };
}

const CreateDraggableElement = SortableHandle(({ children }) => (
    children
));

class ContentDefinitionElement extends React.Component<ContentDefinitionElementProps, ContentDefinitionElementState> {
    public state = getInitialState();

    public createContextualMenuToggleHandler(editing: boolean) {
        return this.props.disabled ? () => void 0 : () => this.setState({ editing });
    }

    public handleClone = (newFieldName: string) => {
        if (isFunction(this.props.onClone) && this.props.hasPermissions) {
            this.createContextualMenuToggleHandler(false)();
            this.props.onClone(newFieldName);
            this.setState({
                isCloning: false
            });
        }
    }

    public handleEdit = () => {
        if (isFunction(this.props.onEdit)) {
            this.createContextualMenuToggleHandler(false)();
            return this.props.onEdit();
        }
    }

    public handleInspect = () => {
        if (isFunction(this.props.onInspect) && this.props.hasPermissions) {
            this.createContextualMenuToggleHandler(false)();
            return this.props.onInspect();
        }
    }

    public handleRemove = () => {
        if (isFunction(this.props.onRemove) && this.props.hasPermissions) {
            this.createContextualMenuToggleHandler(false)();
            return this.props.onRemove();
        }
    }

    openCloneModal = () => {
        this.setState({
            isCloning: true
        });
    }

    closeCloneModal = () => {
        this.setState({
            isCloning: false
        });
    }

    createElementHandle = () => (
        <span
            className={
                classNames(classes.ContentDefinitionElementHandle, {
                    [classes.ContentDefinitionElementDraggable]: this.props.draggable,
                })
            }
        >
            {this.props.draggable && <Icon type="drag" />}
        </span>
    )

    createActions = () => {
        const actions = [] as ActionDef[];

        const { hasPermissions } = this.props;

        const viewAction = {
            clickHandler: this.props.onInspect,
            title: labels.elementView,
            icon: 'edit',
            type: 'primary',
        } as ActionDef;

        const editAction = {
            clickHandler: this.props.onEdit,
            title: labels.elementEdit,
            icon: 'edit',
            type: 'primary',
        } as ActionDef;

        actions.push(hasPermissions ? editAction : viewAction);

        if (hasPermissions) {
            actions.push({
                clickHandler: this.openCloneModal,
                title: labels.elementClone,
                icon: 'copy',
                type: 'default',
            });
            actions.push({
                clickHandler: this.props.onRemove,
                title: labels.elementDelete,
                icon: 'delete',
                type: 'danger',
            });
        }

        return (
            <ActionList actions={actions} />
        );
    }

    public render() {
        const {
            active,
            hasErrors,
            disabled,
            className,
            children,
            fieldName,
            draggable,
            typeDescription,
            typeName,
            typeIconComponent,
            multiValue
        } = this.props;

        return (
            <div className={classes.ContentDefinitionElementContainer}>
                <div
                    className={
                        classNames(
                            classes.ContentDefinitionElement,
                            {
                                [classes.ContentDefinitionElementActive]: active,
                                [classes.ContentDefinitionElementDisabled]: disabled,
                            },
                            className,
                        )
                    }
                >
                    <div className={classes.ContentDefinitionElementSection}>
                        {draggable
                            ? <CreateDraggableElement>{this.createElementHandle()}</CreateDraggableElement>
                            : this.createElementHandle()
                        }
                        <div className={classes.ContentDefinitionElementAvatar}>
                            {typeIconComponent}
                        </div>
                        <div className={classes.ContentDefinitionElementContent}>
                            <div className={classes.ContentDefinitionElementFieldType}>
                                {typeName &&
                                    <Typography variant="caption" style={{ flexGrow: 5 }}>
                                        <Tooltip
                                            title={typeDescription}
                                        >
                                            <span>{multiValue ? 'Multiple values ' : ''}{typeName}</span>
                                        </Tooltip>
                                    </Typography>}
                            </div>
                            <Typography variant="h6" gutter={false}>
                                {fieldName}
                            </Typography>
                        </div>
                        {this.createActions()}
                    </div>
                    {
                        children &&
                        <div
                            className={
                                classNames(
                                    classes.ContentDefinitionElementSection,
                                    classes.ContentDefinitionElementSectionChildren,
                                )
                            }
                        >
                            {
                                children
                            }
                        </div>
                    }
                </div>
                <Badge dot={hasErrors} />
                <Modal
                    visible={this.state.isCloning}
                    footer={null}
                    onCancel={this.closeCloneModal}
                    title={
                        <FormattedMessage
                            id="ContentDefinitionElement.cloneModal.labels.title"
                            defaultMessage="Insert field name"
                        />
                    }
                    centered={true}
                    destroyOnClose={true}
                >
                    <SimpleForm
                        layout="horizontal"
                        onSubmit={(fields: { name: string }) => this.handleClone(fields.name)}
                        fields={[
                            {
                                id: 'name',
                                component: <Input />,
                                initialValue: `${fieldName}-(copy)`,
                                rules: [{
                                    required: true,
                                    type: 'string',
                                }, {
                                    validator: (rule, value, callback) => {
                                        const errors: string[] = [];
                                        if (this.props.onCheckNameAlreadyExist(value)) {
                                            errors.push(rule.message);
                                        }
                                        callback(errors);
                                    },
                                    message: (
                                        <FormattedMessage
                                            id="ContentDefinitionElement.cloneModal.labels.nameAlreadyExists"
                                            defaultMessage="Name is already in use"
                                        />
                                    )
                                }, {
                                    pattern: /^[a-zA-Z0-9_]+$/
                                }
                                ]
                            }
                        ]}
                    />
                </Modal>
            </div>
        );
    }
}

export default withPermissionsToInteract(ContentDefinitionElement);
export const SortableContentDefinitionElement = withPermissionsToInteract(SortableElement(ContentDefinitionElement));
