import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Col, List } from 'antd';
import { LinkableContent } from '../../hoc';
import { MediaGalleryListItemActions } from '..';
import { MediaGalleryProps } from './MediaGalleryListItemModel';
import { browseMediaGalleryPath } from '../../constants/routing-constants';

const ListItem = List.Item;

import styles from './MediaGalleryListItem.module.scss';
import { ManageFieldProvider } from '../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../constants/user-permissions-table';

class MediaGalleryListItem extends Component<MediaGalleryProps> {

    onClickWhenOverride = () => {
        this.props.overrideClick!(this.props.mediaGallery);
    }

    createMediaGalleryListItem = () => {
        const { overrideClick, mediaGallery } = this.props;
        return (
            <div
                className={styles.MediaGalleryListItemContainer}
                style={{ borderLeftColor: mediaGallery.color }}
            >
                <Col xs={19} sm={20} md={21} className={styles.MediaGalleryListItemDataContainer}>
                    <div className={styles.MediaGalleryListItemNameContainer}>
                        {mediaGallery.name}
                    </div>
                    <div className={styles.MediaGalleryListItemDescriptionContainer}>
                        {mediaGallery.description}
                    </div>
                </Col>
                <Col xs={5} sm={4} md={3} className={styles.MediaGalleryListItemActionsContainer}>
                    <ManageFieldProvider permissions={userPermissionsTable.ListItemActions.MediaGalleryListItemActions}>
                        <MediaGalleryListItemActions mediaGallery={mediaGallery} overrideBrowse={overrideClick} />
                    </ManageFieldProvider>
                </Col>
            </div>
        );
    }

    render() {
        const { overrideClick, mediaGallery, match: { params: { spaceId } } } = this.props;
        return (
            <ListItem id={mediaGallery.id.toString()}>
                {!!overrideClick
                    ? <div className={styles.MediaGalleryListItemOverrideContainer} onClick={this.onClickWhenOverride}>
                        {this.createMediaGalleryListItem()}
                    </div>
                    : <LinkableContent to={browseMediaGalleryPath(spaceId, mediaGallery.id)}>
                        {this.createMediaGalleryListItem()}
                    </LinkableContent>
                }
            </ListItem>
        );
    }
}

export default withRouter(MediaGalleryListItem);
