import React from 'react';
import { SignInWithRoute } from '../../components/Onboarding/SignIn';
import {
    SignUp,
    SignIn,
    Greetings,
    ForgotPassword as AWSForgotPassword,
    Authenticator,
    Loading,
} from 'aws-amplify-react';
import { SignUpWithRoute } from '../../components/Onboarding/SignUp';
import ForgotPassword from '../../components/Onboarding/ForgotPassword';
import { Auth } from 'aws-amplify';
import { RouteComponentProps, withRouter } from 'react-router';
import { LOGIN_ROUTE, REGISTRATION_ROUTE } from '../../constants/routing-constants';
import { UsernameAttributes } from 'aws-amplify-react/lib/Auth/common/types';
import { IAuthPieceProps } from 'aws-amplify-react/lib/Auth/AuthPiece';

export default function withAuthentication<Props>(Component: React.ComponentType<Props>): React.ComponentType<Props> {
    class WithAuthentication extends React.Component<Props & IAuthPieceProps & RouteComponentProps> {
        async componentDidMount() {
            try {
                await Auth.currentAuthenticatedUser({
                    bypassCache: false
                });
                if (this.props.onStateChange) {
                    this.props.onStateChange('signedIn', {});
                }
            } catch (error) {

                if (location.pathname !== REGISTRATION_ROUTE) {
                    this.props.history.push(LOGIN_ROUTE);
                }
            }
        }
        render() {
            return (
                this.props.authState === 'signedIn'
                    ? <Component {...this.props} />
                    : null
            );
        }
    }

    const WithAuthenticationDecorated = withRouter(WithAuthentication);

    return (props: any) => {
        return (
            <Authenticator
                authState={location.pathname === '/registration' ? 'signUp' : 'signIn'}
                hide={[
                    Greetings,
                    SignIn,
                    SignUp,
                    AWSForgotPassword,
                    Loading
                ]}
                usernameAttributes={UsernameAttributes.EMAIL}
            >
                <SignInWithRoute override="SignIn" />
                <SignUpWithRoute override="SignUp" />
                <ForgotPassword />
                <WithAuthenticationDecorated {...props} />
            </Authenticator>
        );
    };
}
