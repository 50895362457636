import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ContentInfo, Extension, Message, MessagePayload } from '@contentchef/contentchef-types';
import { DynamicFieldTypes } from '@services/FormUtils/FormFields';
import { FormMetaStoreModel } from '@stores/formMetaStore/formMetaStoreModel';
import { observable, runInAction } from 'mobx';

export default class ExtensionStore {
    @observable height: number = 0;
    public extensionURL: string;
    private frame: HTMLIFrameElement;
    private uniqueId: string;
    private supportedTypes: string[];
    constructor(
        private api: ContentChefClient,
        private field: DynamicFieldTypes,
        extension: Extension,
        private contentInfo: ContentInfo,
        private formMetaStore: FormMetaStoreModel
    ) {
        this.uniqueId = field.uniqueId;
        this.extensionURL = extension.extensionURL;
        this.supportedTypes = extension.supportedTypes;
        window.addEventListener('message', this.listener);
    }

    listener = async (event: MessageEvent) => {
        this.handleMessage(event.data as MessagePayload);
    }

    init(frame: HTMLIFrameElement) {
        this.frame = frame;
        this.frame.contentWindow!.postMessage(
            {
                source: this.field.uniqueId,
                type: Message.CONNECT,
                field: this.field.getSubmittableValue(),
                system: {
                    supportedTypes: this.supportedTypes,
                    fieldType: this.field.type,
                    ...this.contentInfo
                },
            } as MessagePayload,
            '*'
        );
    }

    send(messageId: number, type: Message, result: any, error: any) {
        this.frame.contentWindow!.postMessage(
            {
                messageId,
                type,
                source: this.field.uniqueId,
                result,
                error,
            } as MessagePayload,
            '*'
        );
    }

    handleMessage = async (message: MessagePayload) => {
        if (typeof message.messageId === undefined || message.source !== this.uniqueId) {
            return;
        }
        let apiCall: Function | null = null;
        switch (message.type) {
            case Message.CREATE_CONTENT:
                apiCall = (value: any) => this.api.contents.save(value);
                break;
            case Message.LIST_CONTENT:
                apiCall = (value: any) => this.api.contents.list(value);
                break;
            case Message.UPDATE_CONTENT:
                apiCall = (value: any) => this.api.contents.update(value);
                break;
            case Message.GET_CONTENT:
                apiCall = (value: any) => this.api.contents.get(value);
                break;
            case Message.CREATE_DEFINITION:
                apiCall = (value: any) => this.api.contentDefinitions.save(value);
                break;
            case Message.LIST_DEFINITION:
                apiCall = (value: any) => this.api.contentDefinitions.list(value);
                break;
            case Message.UPDATE_DEFINITION:
                apiCall = (value: any) => this.api.contentDefinitions.update(value);
                break;
            case Message.GET_DEFINITION:
                apiCall = (value: any) => this.api.contentDefinitions.get(value);
                break;
            case Message.CREATE_PUBLISHING_CHANNEL:
                apiCall = (value: any) => this.api.channels.save(value);
                break;
            case Message.LIST_PUBLISHING_CHANNEL:
                apiCall = (value: any) => this.api.channels.list(value);
                break;
            case Message.UPDATE_PUBLISHING_CHANNEL:
                apiCall = (value: any) => this.api.channels.update(value);
                break;
            case Message.GET_PUBLISHING_CHANNEL:
                apiCall = (value: any) => this.api.channels.get(value);
                break;
            case Message.CREATE_REPOSITORY:
                apiCall = (value: any) => this.api.contentRepositories.save(value);
                break;
            case Message.LIST_REPOSITORY:
                apiCall = (value: any) => this.api.contentRepositories.list(value);
                break;
            case Message.UPDATE_REPOSITORY:
                apiCall = (value: any) => this.api.contentRepositories.update(value);
                break;
            case Message.GET_REPOSITORY:
                apiCall = (value: any) => this.api.contentRepositories.get(value);
                break;
            case Message.PUBLISH_CONTENT:
                apiCall = (value: any) => this.api.publishingRequests.create(value);
                break;
            case Message.RESIZE:
                runInAction(() => {
                    this.height = message.value;
                });
                break;
            case Message.SET_FIELD_VALUE:
                try {
                    if (!message.value.fieldPath || !message.value.fieldValue) {
                        throw new Error(`fieldPath and fieldValue both have to be defined`);
                    }
                    this.formMetaStore.setFieldPathValue(message.value.fieldPath, message.value.fieldValue);
                    this.send(message.messageId, Message.RESULT, true, null);
                } catch (error) {
                    this.send(message.messageId, Message.ERROR, null, error);
                }
                break;
            case Message.GET_FORM_VALUE:
                try {
                    const result = this.formMetaStore.getFormValue();
                    this.send(message.messageId, Message.RESULT, result, null);
                } catch (error) {
                    this.send(message.messageId, Message.ERROR, null, error);
                }
                break;
            case Message.SET_VALUE:
                this.field.setFieldValue(message.value);
                break;
            default:
                throw new Error('Message type not handled');
        }

        if (apiCall) {
            try {
                const result = await apiCall(message.value);
                this.send(message.messageId, Message.RESULT, result, null);
            } catch (error) {
                this.send(message.messageId, Message.ERROR, null, error);
            }
        }
    }

    deregister = () => {
        window.removeEventListener('message', this.listener);
    }

}
