import { Divider, Col, Row, notification, Icon, Modal, Form, Input, Checkbox } from 'antd';
import Button from '@components/Button';
import { inject } from 'mobx-react';
import React, { Fragment } from 'react';
import { InjectedIntlProps, injectIntl, FormattedMessage, InjectedIntl, defineMessages } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Breadcrumb, Loader } from '../../../components';
import { SubscriptionStoreModel } from '@stores/subscriptionStore';
import { Link } from 'react-router-dom';
import { SUBSCRIPTION_MANAGEMENT_ROUTE } from '@constants/routing-constants';
import {
    SubscriptionType,
    GetSubscriptionSummaryResponse
} from '@contentchef/contentchef-types';
import SubscriptionTypeName from '@components/SubscriptionTypeName';
import classNames from './index.module.scss';
import SubscriptionDetailPlanTab from './SubscriptionDetailTabs/SubscriptionDetailPlanTab';
import YouTrialHasExpiredMessage from '@components/YouTrialHasExpiredMessage';
import SubscriptionPlanSummary from '@components/SubscriptionPlanSummary';
import SubscriptionSpaceCountIndicator from '@components/SubscriptionSpaceCountIndicator';
import SpaceList from '@components/SpaceList/SpaceList';
import FullPageModal from '@components/FullPageModal';
import { withFormInitialization, withForm } from '../../../hoc';
import { FormComponentProps } from 'antd/lib/form';
import Typography from '@components/Typography';
import { UserPermissionStore } from '../../../stores';
import Paper from '@components/Paper';
import SettingsContentHeader from '@components/SettingsContentHeader';
import BillingInfo from '@components/BillingInfo';
import { ScenarioSelect } from '@components/SubscriptionScenarioSelect';

export interface SubscriptionDetailSceneProps extends FormComponentProps, InjectedIntlProps, RouteComponentProps<{
    subscriptionId: string
}> { }

interface DecoratedSubscriptionDetailSceneProps extends SubscriptionDetailSceneProps {
    subscriptionStore: SubscriptionStoreModel;
    userPermissionsStore: UserPermissionStore;
}

interface SubscriptionDetailSceneState {
    isCreatingSpace: boolean;
    isLoading: boolean;
    isViewingUpgrades: boolean;
    newSpaceName: string;
    newSpaceScenario: number;
    shouldShowCreateSpaceModal: boolean;
    isUserConfirmingCancelSubscription: boolean;
    cancellingSubscription: boolean;
    isSubscriptionDeleteConfirmChecked: boolean;
}

export interface SubscriptionListSceneState {
    loading: boolean;
}

function getInitialState(): SubscriptionDetailSceneState {
    return {
        newSpaceName: '',
        newSpaceScenario: 1,
        isCreatingSpace: false,
        isLoading: true,
        isViewingUpgrades: false,
        shouldShowCreateSpaceModal: false,
        isUserConfirmingCancelSubscription: false,
        cancellingSubscription: false,
        isSubscriptionDeleteConfirmChecked: false
    };
}

function isEnterprise(subscription: GetSubscriptionSummaryResponse): boolean {
    return subscription.plan.subscriptionType === SubscriptionType.Enterprise;
}

function isTrial(subscription: GetSubscriptionSummaryResponse): boolean {
    return subscription.plan.subscriptionType === SubscriptionType.Trial;
}

function isTrialExpired(subscription: GetSubscriptionSummaryResponse): boolean {
    if (!subscription.subscription.subscriptionEndDate) {
        return false;
    }

    return new Date() >= new Date(subscription.subscription.subscriptionEndDate);
}

const labels = defineMessages({
    deleteSubscription: {
        id: 'scenes.Subscription.SubscriptionDetailScene.cancelSubscription.confirmButton',
        defaultMessage: 'Cancel subscription!'
    }
});

@inject('subscriptionStore', 'userPermissionsStore')
class SubscriptionDetailScene extends React.Component<SubscriptionDetailSceneProps, SubscriptionDetailSceneState> {
    public get injectedProps(): DecoratedSubscriptionDetailSceneProps {
        return this.props as DecoratedSubscriptionDetailSceneProps;
    }

    public state = getInitialState();

    public canCreateSpace(): boolean {
        const subscriptionSummary = this.injectedProps.subscriptionStore.subscriptionSummary!;

        if (isEnterprise(subscriptionSummary)) {
            return true;
        }

        return subscriptionSummary.spaces.length < subscriptionSummary.plan.spacesCap;
    }

    public async componentDidMount() {
        const {
            match,
            subscriptionStore
        } = this.injectedProps;

        const subscriptionId = match.params.subscriptionId;

        await Promise.all([
            subscriptionStore.getSummary({ subscriptionId }),
            subscriptionStore.setSubscriptionBillingAddress({ subscriptionId })
        ]);

        this.setState({
            isLoading: false
        });
    }

    public createToggleModalHandler(isViewingUpgrades: boolean) {
        return () => this.setState({ isViewingUpgrades });
    }

    public getRenewalDate(subscriptionSummary: GetSubscriptionSummaryResponse, intl: InjectedIntl): string {
        const date = subscriptionSummary.subscription.subscriptionEndDate;

        if (date) {
            return intl.formatDate(new Date(date));
        }

        return '--/--/----';
    }

    public getTruncatedSpaceId(subscriptionSummary: GetSubscriptionSummaryResponse): string {
        return String(subscriptionSummary.subscription.id).split('-')[0];
    }

    public handleCreateSpace = async () => {
        const subscriptionStore = this.injectedProps.subscriptionStore;

        this.setState({ isCreatingSpace: true });

        try {
            const subscriptionId = subscriptionStore.subscriptionSummary!.subscription.id;
            const spacesCount = subscriptionStore.subscriptionSummary!.spaces.length;
            const pollSummary = async (pollSubscriptionId: string, pollSpacesCount: number, pollCount: number = 0) => {
                const pollResult = await subscriptionStore.getSummary({ subscriptionId: pollSubscriptionId });

                if (pollResult!.spaces.length === pollSpacesCount) {
                    return setTimeout(pollSummary, 2000, pollSubscriptionId, pollSpacesCount, pollCount + 1);
                }

                return this.setState({
                    isCreatingSpace: false,
                    newSpaceName: '',
                    newSpaceScenario: 0,
                    shouldShowCreateSpaceModal: false,
                });
            };
            const result = await subscriptionStore.createSpace({
                scenario: this.state.newSpaceScenario,
                spaceName: this.state.newSpaceName,
                subscriptionId,
            });

            if (!result) {
                throw 'Cannot create new space';
            }

            await pollSummary(subscriptionId, spacesCount);

            await this.injectedProps.userPermissionsStore.pollUntilChange();

            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="Space {name} created"
                        id="scenes.Subscriptions.SubscriptionDetail.space.create.success"
                        values={{ name: this.state.newSpaceName }}
                    />
                )
            });
        } catch (error) {
            this.setState({
                isCreatingSpace: false,
            });

            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="{error}"
                        id="scenes.Subscriptions.SubscriptionDetail.space.create.error"
                        values={{ error: error.message }}
                    />
                )
            });
        }
    }

    public handleSubscriptionUpgradeSuccess = () => {
        this.setState({
            isViewingUpgrades: false,
        });
    }

    public handleCancelSubscription = async () => {

        const { params: { subscriptionId } } = this.props.match;

        this.setState({
            cancellingSubscription: true
        });

        const success = await this.injectedProps.subscriptionStore.cancelSubscription({ subscriptionId });

        this.setState({
            cancellingSubscription: false
        });
        if (success) {
            notification.success({
                message: (
                    <FormattedMessage
                        defaultMessage="Subscription successfully cancelled"
                        id="scenes.Subscriptions.SubscriptionDetail.subscription.cancel.success"
                    />
                )
            });
        } else {
            notification.error({
                message: (
                    <FormattedMessage
                        defaultMessage="Error cancelling you subscription. Try again later."
                        id="scenes.Subscriptions.SubscriptionDetail.subscription.cancel.failure"
                    />
                )
            });
        }

    }

    public render() {
        const {
            form: {
                getFieldDecorator,
            },
            intl,
            subscriptionStore: {
                subscriptionSummary
            },
        } = this.injectedProps;

        if (this.state.isLoading) {
            return <Loader />;
        }

        if (!subscriptionSummary) {
            return null;
        }

        return (
            <div>
                <Breadcrumb>
                    <Link to={SUBSCRIPTION_MANAGEMENT_ROUTE}>
                        <FormattedMessage id="Breadcrumb.Subscriptions" defaultMessage="Subscriptions" />
                    </Link>
                    <div>
                        <strong>
                            <SubscriptionTypeName
                                type={subscriptionSummary.subscription.type}
                            />
                        </strong>
                        <Divider type="vertical" />
                        <small>
                            (<FormattedMessage
                                id="subscription.detail.detail.updateDate"
                                defaultMessage="Update Date"
                            />
                            {' '}
                            {this.getRenewalDate(subscriptionSummary, intl)}
                            )
                        </small>
                        <Divider type="vertical" />
                        <small>
                            {this.getTruncatedSpaceId(subscriptionSummary)}
                        </small>
                    </div>
                </Breadcrumb>
                <Paper className={classNames.PartialsContainer}>
                    {
                        isTrial(subscriptionSummary) &&
                        isTrialExpired(subscriptionSummary) &&
                        <YouTrialHasExpiredMessage />
                    }

                    <Row gutter={16} className={classNames.SubscriptionDetail}>
                        <Col xs={12} md={14}>
                            <SubscriptionPlanSummary summary={subscriptionSummary} />
                        </Col>
                        <Col xs={12} md={10}>
                            {
                                !isEnterprise(subscriptionSummary) &&
                                <SubscriptionSpaceCountIndicator
                                    onClickUpgrade={this.createToggleModalHandler(true)}
                                    spaceCap={subscriptionSummary.plan.spacesCap}
                                    spaceCount={subscriptionSummary.spaces.length}
                                />
                            }
                        </Col>
                    </Row>
                    <Divider />
                    <div className={classNames.SubscriptionTabTitle}>
                        <FormattedMessage
                            defaultMessage="Spaces belonging to this subscription"
                            id="subscription.detail.space.list.title"
                        />
                    </div>
                    {
                        this.canCreateSpace() &&
                        (
                            <Fragment>
                                <Divider />
                                <Button
                                    onClick={
                                        () =>
                                            this.setState({ shouldShowCreateSpaceModal: true })
                                    }
                                    type="primary"
                                >
                                    <Icon type="plus" />
                                    <FormattedMessage
                                        defaultMessage="Create new space"
                                        id="scenes.Subscriptions.SubscriptionDetail.space.create.button"
                                    />
                                </Button>
                                <Divider />
                            </Fragment>
                        )
                    }
                    <SpaceList spaceList={subscriptionSummary.spaces as any} />
                    <Modal
                        onCancel={() => this.setState({ shouldShowCreateSpaceModal: false })}
                        onOk={this.handleCreateSpace}
                        cancelButtonProps={{
                            disabled: this.state.isCreatingSpace,
                        }}
                        okButtonProps={{
                            disabled: this.state.isCreatingSpace,
                            loading: this.state.isCreatingSpace,
                        }}
                        title={
                            <FormattedMessage
                                defaultMessage="Creating a new space"
                                id="scenes.Subscriptions.SubscriptionDetail.space.modal.title"
                            />
                        }
                        visible={this.state.shouldShowCreateSpaceModal}
                    >
                        <Form>
                            <Form.Item>
                                <Typography variant="label">
                                    <FormattedMessage
                                        defaultMessage="Space name"
                                        id="scenes.Subscriptions.SubscriptionDetail.space.modal.spaceName"
                                    />
                                </Typography>
                                {
                                    getFieldDecorator(
                                        'Space name',
                                        {
                                            initialValue: this.state.newSpaceName,
                                            rules: [
                                                {
                                                    pattern: this.injectedProps.subscriptionStore.spaceNameRegExp,
                                                    required: true,
                                                }
                                            ]
                                        }
                                    )(
                                        <Input
                                            onChange={
                                                event =>
                                                    this.setState({
                                                        newSpaceName: event.target.value,
                                                    })
                                            }
                                        />
                                    )
                                }
                            </Form.Item>
                            <Form.Item>
                                <Typography variant="label">
                                    <FormattedMessage
                                        defaultMessage="Scenario"
                                        id="scenes.Subscriptions.SubscriptionDetail.space.modal.scenario"
                                    />
                                </Typography>
                                {
                                    getFieldDecorator(
                                        'Scenario',
                                        {
                                            initialValue: this.state.newSpaceScenario,
                                            rules: [
                                                {
                                                    required: true,
                                                }
                                            ]
                                        }
                                    )(
                                        <ScenarioSelect
                                            onChange={value => this.setState({ newSpaceScenario: Number(value) })}
                                        />
                                    )
                                }
                            </Form.Item>
                        </Form>
                    </Modal>
                    <FullPageModal
                        open={this.state.isViewingUpgrades}
                        onClose={this.createToggleModalHandler(false)}
                        title={
                            <FormattedMessage
                                defaultMessage="Choose plan"
                                id="subscription.plan.tabTitle"
                            />
                        }
                    >
                        <SubscriptionDetailPlanTab
                            intl={intl}
                            onUpgradeSuccess={this.handleSubscriptionUpgradeSuccess}
                            subscriptionSummary={subscriptionSummary}
                        />
                    </FullPageModal>
                </Paper>
                <SettingsContentHeader
                    gutter={true}
                    title={
                        <FormattedMessage
                            defaultMessage="Billing"
                            id="scenes.Subscription.SubscriptionDetailScene.billing"
                        />
                    }
                />
                <BillingInfo />
                <SettingsContentHeader
                    gutter={true}
                    title={
                        <FormattedMessage
                            defaultMessage="Danger Zone"
                            id="scenes.Subscription.SubscriptionDetailScene.dangerZone"
                        />
                    }
                />
                <Paper className={classNames.PartialsContainer}>
                    <Button
                        type="danger"
                        onClick={() => this.setState({ isUserConfirmingCancelSubscription: true })}
                    >
                        <FormattedMessage
                            id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription"
                            defaultMessage="Cancel subscription"
                        />
                    </Button>
                    <Modal
                        onCancel={() => this.setState({ isUserConfirmingCancelSubscription: false })}
                        onOk={this.handleCancelSubscription}
                        okButtonProps={{
                            loading: this.state.cancellingSubscription,
                            type: 'danger',
                            disabled: !this.state.isSubscriptionDeleteConfirmChecked
                        }}
                        title={
                            <FormattedMessage
                                defaultMessage="You are about to cancel your subscription!"
                                id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription.confirmTitle"
                            />
                        }
                        visible={this.state.isUserConfirmingCancelSubscription}
                        okText={intl.formatMessage(labels.deleteSubscription)}
                        destroyOnClose={true}
                    >
                        <Typography variant="paragraph">
                            <FormattedMessage
                                defaultMessage="Warning: This action will cancel your subscription!"
                                id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription.paragraph1"
                            />
                        </Typography>
                        <Typography variant="paragraph">
                            <FormattedMessage
                                // tslint:disable-next-line: max-line-length
                                defaultMessage="Please acknowledge that your subscription will remain active until the next renewal date and you will not be charged from here forward."
                                id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription.paragraph2"
                            />
                        </Typography>
                        <Typography variant="paragraph">
                            <FormattedMessage
                                // tslint:disable-next-line: max-line-length
                                defaultMessage="During this period you will not be charged and you can access all of ContentChef's features."
                                id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription.paragraph3"
                            />
                        </Typography>
                        <Typography variant="label">
                            <Checkbox
                                checked={this.state.isSubscriptionDeleteConfirmChecked}
                                onChange={event =>
                                    this.setState({ isSubscriptionDeleteConfirmChecked: event.target.checked })}
                            />
                            <FormattedMessage
                                defaultMessage="I confirm that I want to delete the subscription."
                                id="scenes.Subscription.SubscriptionDetailScene.cancelSubscription.confirmCheckbox"
                            />
                        </Typography>
                    </Modal>
                </Paper>
            </div>
        );
    }
}

export default withFormInitialization(
    withForm(
        injectIntl(
            SubscriptionDetailScene
        )
    )
);
