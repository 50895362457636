import React, { Component } from 'react';
import { DefinitionListFiltersProps, DefinitionListFiltersState, InjectedProps } from './DefinitionListFiltersModel';
import { inject } from 'mobx-react';
import { Aside, AsideItem, FilterBySearch } from '../index';
import { DataAttributes, DataAttributesBuilder } from '../../services/DataAttributesBuilder';
import {
    ContentDefinitionListUrlParams
} from '../../stores/contentDefinitionListStore/contentDefinitionListStoreModel';

@inject('contentDefinitionListStore')
class DefinitionListFilters extends Component<DefinitionListFiltersProps, DefinitionListFiltersState> {
    get injected() {
        return this.props as InjectedProps;
    }

    onResetFilters = () => {
        this.injected.contentDefinitionListStore.searchParams.deleteAllUrlParams();
    }

    searchContentDefinitions = (value) => {
        this.injected.contentDefinitionListStore.searchParams.setUrlParam(
            ContentDefinitionListUrlParams.byName, value);
    }

    createSearchDataAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('content-definitions')
            .setSearchAttribute('search')
            .build();
    }

    render() {
        const { contentDefinitionListStore: { searchParams: { usableSearchParams, urlSearchParams } } } = this.injected;
        return (
            <Aside>
                <AsideItem>
                    <FilterBySearch
                        dataAttributes={this.createSearchDataAttributes}
                        defaultValue={urlSearchParams.has(ContentDefinitionListUrlParams.byName)
                            ? usableSearchParams[ContentDefinitionListUrlParams.byName]
                            : undefined
                        }
                        onSearch={this.searchContentDefinitions}
                    />
                </AsideItem>
            </Aside>
        );
    }
}

export default DefinitionListFilters;
