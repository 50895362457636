import React from 'react';
import { InputNumber, Select } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldNumber } from '../FormFields';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { ConstraintsToMatch, fieldMatchersUtils } from './FieldMatchersUtils';
import { defaultFieldWrapper } from './DefaultFieldWrapper';
const Option = Select.Option;

export class NumberTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.NUMBER)) { return null; }
        const fieldNumber = field as FieldNumber;
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);
        function elementToRender(disabled: boolean) {
            return (
                <InputNumber
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    defaultValue={fieldNumber.constraints.min}
                    value={fieldNumber.value}
                    onChange={onChange}
                    step="any"
                />
            );

        }

        return defaultFieldWrapper(elementToRender);
    }
}

export class NumberWithListOfValuesMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (
            !fieldMatchersUtils.typeAndConstraintMatcher(field, SchemaTypeIds.NUMBER, ConstraintsToMatch.listOfValues)
        ) {
            return null;
        }
        const fieldNumber = field as FieldNumber;
        const placeholder = fieldMatchersUtils.generateFieldPlaceholder(field, userLocale);

        function customOnChange(value: number | undefined) {
            return onChange(value === undefined ? '' : value);
        }

        function elementToRender(disabled: boolean) {
            return (
                <Select
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={fieldNumber.value}
                    allowClear={true}
                    onChange={customOnChange}
                >
                    {fieldNumber.constraints.listOfValues!.map((value) => {
                        return (
                            <Option key={value.toString()} value={value.code}>
                                {value.labels[userLocale || value.labels[field.locale]]}
                            </Option>
                        );
                    })}
                </Select>
            );

        }

        return defaultFieldWrapper(elementToRender);
    }
}
