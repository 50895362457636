import React, { Component } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Col, ConfigProvider, Icon, List, Row, Tag, Tooltip } from 'antd';
import { ContentItemProps, ContentItemState } from './ContentItemModel';
import { DATE_TIME_FORMAT } from '../../constants/date-constants';
import styles from './ContentItem.module.scss';
import { ContentCardActions } from '../index';
import { userPermissionsTable } from '../../constants/user-permissions-table';
import { ManageFieldProvider } from '../../providers/UserPermissionsProvider';
import { ArchiveIcon } from '../CustomIcons/CustomIcons';
import Typography from '../Typography';
import classNames from 'classnames';
import Button from '@components/Button';
import ContentStatus from '@components/ContentStatus';

const ListItem = List.Item;

@observer
class ContentItem extends Component<ContentItemProps, ContentItemState> {
    state = {
        showVersions: false
    };

    onItemClick = () => {
        if (!!this.props.onCardClick) {
            return this.props.onCardClick(this.props.content);
        }
        return null;
    }

    toggleVersionSection = () => {
        const { showVersions } = this.state;
        this.setState({ showVersions: !showVersions });
    }

    resetVersionSection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showVersions: false });
    }

    render() {
        const { content, showActions } = this.props;
        const { showVersions } = this.state;

        const wrapperClassName = classNames(styles.ContentItemListItemWrapper);

        const contentItemClassName = classNames(styles.ContentItemListItemContainer, {
            [styles.TranslateContentItem]: showVersions
        });

        const leftBodyContainer = classNames(styles.ContentItemLeftBodyContainer, {
            [styles.AddOpacity]: showVersions
        });

        const rightBodyContainer = classNames(styles.ContentItemRightBodyContainer, {
            [styles.AddOpacity]: showVersions
        });

        return (
            <ListItem
                className={wrapperClassName}
                id={content.id.toString()}
                onClick={this.onItemClick}
            >
                {showVersions && <div className={styles.ShiftedItemHelper} onClick={this.resetVersionSection}>
                    <Button
                        type="primary"
                        icon="arrow-left"
                        shape="circle"
                    />
                </div>}
                <div
                    style={{ borderLeft: `3px solid ${content.repository.color}` }}
                    className={contentItemClassName}
                >
                    <Col xs={19} sm={18} md={19} lg={20} className={styles.ContentItemBodyContainer}>
                        <Col xs={24} className={styles.ContentItemBodyHeading}>
                            <Col xs={24} md={24} lg={24} className={styles.ContentItemContentNameContainer}>
                                <Tooltip title={content.name} trigger="hover">
                                    <Typography>
                                        {content.archived &&
                                            <ArchiveIcon className={styles.ContentItemIconStyleOverwrite} />}
                                        {content.name}
                                    </Typography>
                                </Tooltip>
                            </Col>
                        </Col>
                        <Col xs={24} md={14} lg={13} className={leftBodyContainer}>
                            <div className={styles.ContentItemContentPublicIdContainer}>
                                <Icon type="link" className={styles.ContentItemIconStyleOverwrite} />
                                <span className={styles.ContentItemTextWithEllipsis}>{content.publicId}</span>
                            </div>
                            <div className={styles.ContentItemContentTagsContainer}>
                                {content.tags.map((tag, index) => (
                                    <Tag key={`${tag}_${index}`} color={'#00AEEF'}>
                                        <Icon type="tag" className={styles.ContentItemTagsIconStyleOverwrite} />
                                        {tag}
                                    </Tag>
                                ))}
                            </div>
                        </Col>
                        <Col xs={24} md={10} lg={11} className={rightBodyContainer}>
                            <Row>
                                <Col xs={24} className={styles.ContentItemDefinitionMnenonicContainer}>
                                    <Icon type="book" className={styles.ContentItemIconStyleOverwrite} />
                                    <span className={styles.ContentItemTextWithEllipsis}>
                                        {content.definition.mnemonicId}
                                    </span>
                                </Col>
                                <Col xs={24} className={styles.ContentItemRepositoryNameContainer}>
                                    <Icon type="appstore" className={styles.ContentItemIconStyleOverwrite} />
                                    <span className={styles.ContentItemTextWithEllipsis}>
                                        {content.repository.mnemonicId}
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={24}
                                    lg={12}
                                    className={styles.ContentItemContentPublicationDateContainer}
                                >
                                    {content.onlineDate
                                        ? <div className={styles.ContentItemContentDateContainer}>
                                            <Icon
                                                type="calendar"
                                                className={styles.ContentItemIconStyleOverwrite}
                                            />
                                            {moment(content.onlineDate).format(DATE_TIME_FORMAT)}
                                        </div>
                                        : null
                                    }
                                </Col>
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={24}
                                    lg={12}
                                    className={styles.ContentItemContentPublicationDateContainer}
                                >
                                    {content.offlineDate
                                        ? <div className={styles.ContentItemContentDateContainer}>
                                            <Icon
                                                type="calendar"
                                                className={styles.ContentItemIconStyleOverwrite}
                                            />
                                            {moment(content.offlineDate).format(DATE_TIME_FORMAT)}
                                        </div>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    {showActions && <Col xs={5} sm={6} md={5} lg={4} className={styles.ContentItemActionsContainer}>
                        <ManageFieldProvider permissions={userPermissionsTable.ListItemActions.ContentCardActions}>
                            <ContentCardActions
                                content={content}
                                showDuplicateAction={!content.archived}
                                showEditAction={true}
                                showStatusAction={true}
                                onClickStatusAction={this.toggleVersionSection}
                            />
                        </ManageFieldProvider>
                    </Col>}
                    <Col
                        xs={6}
                        className={styles.ContentItemStatusSection}
                    >
                        <ConfigProvider>
                            <ContentStatus
                                currentVersion={content.version}
                                currentChannels={content.channels.map(channel => channel.mnemonicId)}
                                status={content.status}
                            />
                        </ConfigProvider>
                    </Col>
                </div>
            </ListItem>
        );
    }
}

export default ContentItem;
