import { Button, Checkbox, Form, Input, Icon } from 'antd';
import { inject } from 'mobx-react';
import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import withSubscriptions from '../../hoc/withSubscription';
import { SubscriptionStoreModel } from '../../stores/subscriptionStore';
import classNames from './index.module.scss';
import { FormComponentProps } from 'antd/lib/form';
import { withForm, withFormInitialization } from '../../hoc';
import { OnboardingStoreModel } from '../../stores/onboardingStore';
import UsersStore from '../../stores/usersStore';
import withTracker from '../../hoc/withTracker/withTracker';
import { SubscriptionErrorTypeIds } from '@services/Error/SubscriptionErrorManager';

export interface SubscriptionCreationWizardProps extends FormComponentProps, RouteComponentProps, InjectedIntlProps { }

interface DecoratedSubscriptionCreationWizardProps extends SubscriptionCreationWizardProps {
    onboardingStore: OnboardingStoreModel;
    subscriptionStore: SubscriptionStoreModel;
    usersStore: UsersStore;
}

interface SubscriptionCreationWizardState {
    acceptsTermsAndConditions: boolean;
    canViewWizard: boolean;
    isCreating: boolean;
    selectedScenario: number;
    promoCode: string | undefined;
    subscriptionSpaceName: string;
}

const labels = defineMessages({
    button: {
        defaultMessage: 'Create your first space and continue',
        id: 'onboarding.wizard.button'
    },
    eula0: {
        defaultMessage: 'I accept both the',
        id: 'onboarding.wizard.checkbox.part1'
    },
    eula1: {
        defaultMessage: 'terms and conditions',
        id: 'onboarding.wizard.checkbox.part2'
    },
    eula2: {
        defaultMessage: ' and the ',
        id: 'onboarding.wizard.checkbox.part3'
    },
    eula3: {
        defaultMessage: 'privacy policies',
        id: 'onboarding.wizard.checkbox.part4'
    },
    promoCodeLabel: {
        defaultMessage: 'Have a Promo code?',
        id: 'onboarding.wizard.promoCode.label'
    },
    promoCodeInvalidError: {
        defaultMessage: 'Invalid promo code',
        id: 'onboarding.wizard.promoCode.invalidError'
    },
    spaceNameHint: {
        defaultMessage: 'For example: My wonderful website',
        id: 'onboarding.wizard.hint'
    },
    spaceNameLabel: {
        defaultMessage: 'Enter your space name',
        id: 'onboarding.wizard.label'
    },
    subTitle: {
        defaultMessage: 'You are almost there',
        id: 'onboarding.wizard.subtitle'
    },
    text: {
        defaultMessage: `It's time to create your first ContentChef space before proceeding to the dashboard`,
        id: 'onboarding.wizard.text'
    },
    title: {
        defaultMessage: 'Hi',
        id: 'onboarding.wizard.title'
    },
    spaceNamePatternValidation: {
        // tslint:disable-next-line: max-line-length
        defaultMessage: 'Space must start with a letter, accept letters, numbers, spaces, _ and must end with a letter or a number',
        id: 'subscriptions.creation.wizard.validation.SpaceName'
    },
    mandatoryPolicies: {
        // tslint:disable-next-line:max-line-length
        defaultMessage: `'terms and conditions' and 'privacy' policies are mandatory to proceed`,
        id: 'subscription.creation.wizard.validation.mandatoryPolicies'
    }
});

function getInitialState(promoCode?: string): SubscriptionCreationWizardState {
    return {
        acceptsTermsAndConditions: false,
        canViewWizard: false,
        isCreating: false,
        selectedScenario: 0,
        promoCode,
        subscriptionSpaceName: '',
    };
}

@inject('subscriptionStore', 'onboardingStore', 'usersStore')
class SubscriptionCreationWizard
    extends React.Component<SubscriptionCreationWizardProps, SubscriptionCreationWizardState> {
    formIds = {
        promoCode: 'promoCode',
        spaceName: 'spaceName',
        mandatoryPolicies: 'mandatoryPolicies'
    };
    state: SubscriptionCreationWizardState;

    public get injectedProps(): DecoratedSubscriptionCreationWizardProps {
        return this.props as DecoratedSubscriptionCreationWizardProps;
    }

    constructor(props: SubscriptionCreationWizardProps) {
        super(props);

        const promoCode = sessionStorage.getItem('promoCode');

        this.state = getInitialState(promoCode as string | undefined);
    }

    public onChangePromoCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.injectedProps.subscriptionStore.onChangePromoCode(event.target.value);
    }

    public componentDidMount() {
        // checking if the user already has some spaces
        // in that case we will redirect her/him to the space switcher

        this.injectedProps.subscriptionStore.list().then(response => {
            if (response.length > 1) {
                this.injectedProps.history.replace('/');
            } else {
                this.setState({ canViewWizard: true });
            }
        });
    }

    public handleSubmit = () => {
        this.injectedProps.form.validateFields(async (error, values) => {
            if (error) {
                return;
            }

            this.setState({
                isCreating: true
            });

            const promoCode = !!values[this.formIds.promoCode] ? values[this.formIds.promoCode].trim() : undefined;
            const spaceName = !!values[this.formIds.spaceName] ? values[this.formIds.spaceName].trim() : undefined;

            const subscriptionCreateResponse = await this.injectedProps.subscriptionStore.create({
                promoCode: promoCode,
                selectedScenario: this.state.selectedScenario,
                spaceName: spaceName,
            });

            if (!subscriptionCreateResponse) {
                this.setState({ isCreating: false });
                return;
            }

            if (!this.injectedProps.usersStore.hasAlreadyAcceptedMandatoryPolicies()) {
                await this.injectedProps.usersStore.acceptConsents(false);
            }

            const onboardingstore = this.injectedProps.onboardingStore;
            const navigate = (url: string) => location.href = url;
            const timer = 2000;
            const poll = (redirectUrl: string) => {
                onboardingstore.hasSomePermissions()
                    .then(async shouldRedirect => {
                        if (shouldRedirect && await onboardingstore.hasSomePermissions()) {
                            onboardingstore.postWizardCompletion();
                            await this.injectedProps.usersStore.trackUserGA();
                            sessionStorage.removeItem('promoCode');
                            navigate(redirectUrl);

                            return;
                        }

                        setTimeout(poll, timer, redirectUrl);
                    });
            };

            setTimeout(poll, timer, '/');
        });
    }

    public render() {
        const {
            form: {
                getFieldDecorator,
            },
            intl: {
                formatMessage,
            },
            subscriptionStore: {
                errors
            },
        } = this.injectedProps;
        const {
            isCreating,
        } = this.state;

        if (!this.state.canViewWizard) {
            return null;
        }

        return (
            <div className={classNames.WizardContainer}>
                <div className={classNames.Wizard}>
                    <h2 className={classNames.WizardTitle}>
                        {formatMessage(labels.title)}
                    </h2>
                    <h4 className={classNames.WizardSubTitle}>
                        {formatMessage(labels.subTitle)}
                    </h4>
                    <p className={classNames.WizardText}>
                        {formatMessage(labels.text)}
                    </p>
                    <Form className={classNames.WizardForm}>
                        <Form.Item
                            label={
                                formatMessage(labels.spaceNameLabel)
                            }
                        >
                            {
                                getFieldDecorator(this.formIds.spaceName, {
                                    initialValue: this.state.subscriptionSpaceName,
                                    rules: [{
                                        message:
                                            formatMessage(labels.spaceNamePatternValidation),
                                        pattern: this.injectedProps.subscriptionStore.spaceNameRegExp,
                                        required: true,
                                    }]
                                })(
                                    <Input
                                        disabled={isCreating}
                                    />
                                )
                            }
                            <span className={classNames.WizardHint}>
                                {
                                    formatMessage(labels.spaceNameHint)
                                }
                            </span>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            validateStatus={
                                (errors.createSubscription !== undefined &&
                                    errors.createSubscription.type === SubscriptionErrorTypeIds.InvalidPromoCode)
                                    ? 'error'
                                    : undefined
                            }
                            help={(errors.createSubscription !== undefined &&
                                errors.createSubscription.type === SubscriptionErrorTypeIds.InvalidPromoCode)
                                ? formatMessage(labels.promoCodeInvalidError)
                                : undefined
                            }
                            label={
                                formatMessage(labels.promoCodeLabel)
                            }
                        >
                            {
                                getFieldDecorator(this.formIds.promoCode, {
                                    initialValue: this.state.promoCode,
                                    rules: [{ message: formatMessage(labels.spaceNamePatternValidation) }]
                                })(
                                    <Input
                                        onChange={this.onChangePromoCode}
                                        disabled={isCreating}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item>
                            {
                                getFieldDecorator(this.formIds.mandatoryPolicies, {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                    rules: [{
                                        message:
                                            formatMessage(labels.mandatoryPolicies),
                                        validator: (rule, value, callback) => {
                                            if (value === false) { callback(rule.message); }
                                            callback();
                                        },
                                        type: 'boolean',
                                        required: true,
                                    }]
                                })(
                                    <Checkbox disabled={isCreating}>
                                        <span className={classNames.WizardEula}>
                                            {
                                                formatMessage(labels.eula0)
                                            }
                                            <a href="https://www.contentchef.io/terms-and-conditions" target="_blank">
                                                {formatMessage(labels.eula1)}
                                            </a>
                                            {
                                                formatMessage(labels.eula2)
                                            }
                                            <a href="https://www.iubenda.com/privacy-policy/41328736" target="_blank">
                                                {formatMessage(labels.eula3)}
                                            </a>
                                        </span>
                                    </Checkbox>
                                )
                            }
                        </Form.Item>
                    </Form>
                    <Button
                        className={classNames.WizardButton}
                        disabled={isCreating}
                        onClick={this.handleSubmit}
                        type="primary"
                    >
                        {
                            isCreating &&
                            <Icon type="loading" />
                        }
                        {
                            !isCreating &&
                            <Icon type="check-circle" />
                        }
                        {formatMessage(labels.button)}
                    </Button>
                </div>
            </div>
        );
    }
}

export default withFormInitialization(
    withForm(
        withRouter(
            injectIntl(
                withSubscriptions(
                    withTracker(
                        SubscriptionCreationWizard
                    )
                )
            )
        )
    )
);
