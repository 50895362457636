import moment from 'moment';
import { VALID_RESPONSE_STATUS } from '../constants/api-constants';
import { EDIT_URL_SUFFIX } from '../constants/routing-constants';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../constants/date-constants';
import React from 'react';
// import { PAGINATION_SIZE } from '@/constants/tables';

const DEFAULT_INITIALS = 'CC';

export const isValidName = name => name.includes(' ');

export const nameToInitials = name => {
    if (!name || name.length < 1 || typeof name !== 'string') {
        return DEFAULT_INITIALS;
    } else if (!isValidName(name)) {
        return name.length === 2
            ? name.toUpperCase()
            : `${name[0]}${name[name.length - 1]}`.toUpperCase();
    }
    const [firstName, lastName] = name.toUpperCase().split(' ');
    return firstName[0] + lastName[0];
};

// DATE PARSERS
export const parseDate = date => moment(date).format(DATE_TIME_FORMAT);
export const parseHour = date => moment(date).format(TIME_FORMAT);

// TABLE OVERFLOW CALCULATOR
export const isPercentageWidth = width =>
    typeof width === 'string' && width.includes('%');

export function isFunction<T>(arg?: T): arg is T {
    return typeof arg === 'function';
}

export const getWidth = ({ width }, baseWidth) => {
    if (isPercentageWidth(width)) {
        const [num] = width.split('%');
        return parseInt(num, 10) * (baseWidth / 100);
    }
    return width;
};
export const sumColWidths = (cols, baseWidth) =>
    cols
        .map(col => getWidth(col, baseWidth))
        .reduce((totalWidth, width) => totalWidth + width, 0);

// style calculations
export const sizeToDimensions = size => ({ width: size / 2, height: size / 2 });

// Creating repositories ID's
export const slugify = text =>
    text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text

// unsucessfull server responses
export const isValidResponse = ({ status }) => status === VALID_RESPONSE_STATUS;

// checking whether we are editing or adding new item

export const isEditMode = ({ params, url }) => url.includes(EDIT_URL_SUFFIX);

// schema json parser
export const fromJson = json => JSON.parse(json);
export const toJson = val => JSON.stringify(val, null, 2);
export const clone = obj => fromJson(toJson(obj));

// CSS UTILS
export const mergeCssClasses = arrOfClasses => arrOfClasses.join(' ').trim();
export const appendClassOnCondition = (condition, trueClass, falseClass) =>
    condition ? trueClass : falseClass;

// PAGINATION
export const paginate = (items, pageSize) => {
    if (items <= pageSize) {
        return [items];
    }
    let pages = {}; // eslint-disable-line
    let clonedItems = items.slice(); // eslint-disable-line
    let pageNumber = 0;
    while (clonedItems.length) {
        // ADD 1 TO ARRAY INDEX
        pages[pageNumber + 1] = clonedItems.splice(0, pageSize);
        pageNumber += 1;
    }
    return pages;
};

export const mergeArrOfArrays = array =>
    array.reduce((arr, innerArray) => arr.concat(innerArray), []);

export const log = (...logs) => {
    console.log('\n');
    console.log('<------------------->');
    logs.forEach(([msg, ...values]) => console.log(msg, ...values));
    console.log('<------------------->');
    console.log('\n');
};

export function alphabeticalSort(elementA: string, elementB: string) {
    const stringA = elementA.toUpperCase();
    const stringB = elementB.toUpperCase();
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }
    return 0;
}

export function defaultSort(elementA: string, elementB: string);
export function defaultSort(elementA: Date, elementB: Date);
export function defaultSort(elementA: number, elementB: number);
export function defaultSort(
    elementA: string | Date | number,
    elementB: string | Date | number
) {
    return elementA < elementB ? -1 : elementA > elementB ? 1 : 0;
}

export type Procedure = (...args: any[]) => void;

export type Options = {
    isImmediate: boolean,
};

export function debounce<F extends Procedure>(
    func: F,
    waitMilliseconds: number = 400,
    options: Options = {
        isImmediate: false
    },
): F {
    let timeoutId;

    return function(this: any, ...args: any[]) {
        const context = this;

        const doLater = function() {
            timeoutId = undefined;
            if (!options.isImmediate) {
                func.apply(context, args);
            }
        };

        const shouldCallNow = options.isImmediate && timeoutId === undefined;

        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(doLater, waitMilliseconds);
        if (shouldCallNow) {
            func.apply(context, args);
        }
    } as any;
}

export function createRange(start: number, end: number) {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export function copyToClipboard(elementId: string, valueToCopy: string) {
    var dummyInput = document.createElement('input');
    dummyInput.setAttribute('id', elementId);
    document.body.appendChild(dummyInput);
    const inputElement = document.getElementById(elementId) as HTMLInputElement;
    inputElement.value = valueToCopy;
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
}

export function getAvatarBackgroundColor(inputString: string, saturation: number, lightness: number) {
    let hash = 0;

    for (let i = 0; i < inputString.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;

    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

export function getAvatarStyle(value: unknown): React.CSSProperties {
    return {
        backgroundColor: getAvatarBackgroundColor(
            getAvatarText(value),
            75,
            75,
        ),
    };
}

export function getAvatarText(value: unknown): string {
    return typeof value === 'string' ? value.substr(0, value.length - 1) : '';
}

export function hashCode(str: string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export function intToRGB(i: number) {
    // tslint:disable-next-line: no-bitwise
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return '#' + '00000'.substring(0, 6 - c.length) + c;
}

export function downloadTxtFile(fileName: string, stringToDownload: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(stringToDownload));
    element.setAttribute('download', `${fileName}.txt`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}
