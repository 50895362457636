import React from 'react';
import classes from './index.module.scss';
import classNames from 'classnames';
import { Row, Col } from 'antd';

interface EntryProps {
    label: React.ReactNode;
    value: React.ReactNode;
    actions?: React.ReactNode[];
    alignValue?: 'center' | 'left' | 'right';
}

const Entry = (props: EntryProps) => {

    const entryValueClassName = classNames(classes.EntryValue, {
        [classes.AlignRight]: props.alignValue === 'right',
        [classes.AlignCenter]: props.alignValue === 'center',
        [classes.AlignLeft]: props.alignValue === 'left',
    });

    return (
        <Row className={classes.Entry}>
            <Col xs={12} md={4}>
                <p className={classes.EntryTitle}>
                    {props.label}
                </p>
            </Col>
            {
                props.actions && props.actions.length > 0 && (
                    <Col xs={12} md={0}>
                        {props.actions.map(action => action)}
                    </Col>
                )
            }
            <Col xs={24} md={props.actions && props.actions.length > 0 ? 10 : 20} className={entryValueClassName}>
                <p>
                    {props.value}
                </p>
            </Col>
            {
                props.actions && props.actions.length > 0 && (
                    <Col xs={0} md={10}>
                        {props.actions.map(action => action)}
                    </Col>
                )
            }
        </Row>
    );
};

export default Entry;
