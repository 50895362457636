import { FieldEditTabProps } from '../../types';
import AllowEmpty from '../Constraints/AllowEmpty';
import Required from '../Constraints/RequiredField';
import MinMax from '../Constraints/MinMax';
import StringField from '../Fields/String';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { observer } from 'mobx-react';
import { TextConstraints } from '../../FieldSerializer/fields/text';
import {
    allowEmptyMappedErrors,
    getMinMaxLengthErrorList,
    patternMappedErrors, requiredMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function SimpleTextConstraints(props: FieldEditTabProps<TextConstraints>) {
    return (
        <>
            {!props.isArrayChildren && <Required
                relatedTabKey={'validationTab'}
                mappedErrors={requiredMappedErrors}
                value={props.constraints.required}
                onChange={required => props.onChangeConstraint('required', required)}
            />}
            <AllowEmpty
                relatedTabKey={'validationTab'}
                mappedErrors={allowEmptyMappedErrors}
                value={props.constraints.allowEmpty}
                onChange={allowEmpty => props.onChangeConstraint('allowEmpty', allowEmpty)}
            />
            <MinMax
                relatedTabKey={'validationTab'}
                mappedErrors={getMinMaxLengthErrorList(props.isArrayChildren)}
                valueMax={props.constraints.maxLength}
                valueMin={props.constraints.minLength}
                onChange={(key, value) =>
                    props.onChangeConstraint(key === 'max' ? 'maxLength' : 'minLength', value)
                }
                attributeLabel={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleText.MinMax.Label"
                    defaultMessage="Text length"
                />}
                minTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleText.MinMax.min.title"
                    defaultMessage="Minimum length"
                />}
                maxTitle={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleText.MinMax.max.title"
                    defaultMessage="Maximum length"
                />}
                minHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleText.MinMax.min.helper"
                    defaultMessage="Define the minimum text length"
                />}
                maxHelper={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.SimpleText.MinMax.max.helper"
                    defaultMessage="Define the maximum text length"
                />}
            />
            <StringField
                relatedTabKey={'validationTab'}
                mappedErrors={patternMappedErrors}
                description={<FormattedMessage
                    /* tslint:disable-next-line:max-line-length */
                    defaultMessage="Define a regular expression that validates your text. Find more information "
                    /* tslint:disable-next-line:max-line-length */
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Text.pattern.description"
                >
                    {txt => <span>
                        {txt}
                        <a href="https://regexr.com/" target="_blank">
                            <FormattedMessage
                                /* tslint:disable-next-line:max-line-length */
                                id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Text.pattern.description2"
                                defaultMessage="here."
                            />
                        </a>
                    </span>}
                </FormattedMessage>}
                value={props.constraints.pattern}
                onChange={value => props.onChangeConstraint('pattern', value)}
            >
                <FormattedMessage
                    defaultMessage="Pattern"
                    id="components.ContentDefinitionSchemaEditor.ContentDefinitionModal.ConstraintTab.Text.pattern"
                />
            </StringField>
        </>
    );
}

export default observer(SimpleTextConstraints);
