import React from 'react';
import { withRouter } from 'react-router';
import { Col, List } from 'antd';
import { PublishingChannelListItemProps } from './PublishingChannelListItemModel';
import { editPublishingChannelPath } from '../../constants/routing-constants';
import { LinkableContent } from '../../hoc';
import { PublishingChannelListItemActions } from '../index';
import { ManageFieldProvider } from '../../providers/UserPermissionsProvider';
import { userPermissionsTable } from '../../constants/user-permissions-table';

const ListItem = List.Item;

import styles from './PublishingChannelListItem.module.scss';

function PublishingChannelListItem(props: PublishingChannelListItemProps) {
    const { channel, match: { params: { spaceId } } } = props;
    return (
        <ListItem id={channel.id.toString()}>
            <LinkableContent to={editPublishingChannelPath(spaceId, channel.id)}>
                <div
                    className={styles.PublishingChannelListItemContainer}
                    style={{ borderLeftColor: channel.color }}
                >
                    <Col xs={19} sm={21} md={22} className={styles.PublishingChannelListItemDataContainer}>
                        <div className={styles.PublishingChannelListItemNameContainer}>
                            {channel.name}
                        </div>
                        <div className={styles.PublishingChannelListItemMnemonicIdContainer}>
                            {channel.mnemonicId}
                        </div>
                    </Col>
                    <Col xs={5} sm={3} md={2} className={styles.PublishingChannelListItemActionsContainer}>
                        <ManageFieldProvider
                            permissions={userPermissionsTable.ListItemActions.PublishingChannelListItemActions}
                        >
                            <PublishingChannelListItemActions channelId={channel.id} />
                        </ManageFieldProvider>
                    </Col>
                </div>
            </LinkableContent>
        </ListItem>
    );
}

export default withRouter(PublishingChannelListItem);
