import React, { Component } from 'react';
import { Form, Row, Input } from 'antd';
import { injectIntl, defineMessages } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import { MediaGalleryFormProps } from './MediaGalleryFormModel';

import styles from './MediaGalleryForm.module.scss';
import { withForm } from '../../../hoc';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import { MissingPermissions } from '../..';

const FormItem = Form.Item;

const mediaGalleryFormLabel = defineMessages({
    nameLabel: {
        id: 'MediaGalleryForm.nameLabel',
        defaultMessage: 'Name'
    },
    descriptionLabel: {
        id: 'MediaGalleryForm.descriptionLabel',
        defaultMessage: 'Description'
    },
    colorLabel: {
        id: 'MediaGalleryForm.colorLabel',
        defaultMessage: 'Color'
    }
});

class MediaGalleryForm extends Component<MediaGalleryFormProps> {
    randomHexColor: string;

    constructor(props: MediaGalleryFormProps) {
        super(props);

        this.randomHexColor = '#000000'.replace(/0/g, function() {
            // tslint:disable-next-line
            return (~~(Math.random() * 16)).toString(16);
        });
    }

    trimValue = ({ target }) => {
        const { id, value } = target;
        this.props.form.setFieldsValue({ [id]: value.trim() });
    }

    render() {
        const { hasPermissions, mediaGalleryData, intl } = this.props;
        const { formatMessage } = intl;
        const { getFieldDecorator } = this.props.form;
        if (!mediaGalleryData && !hasPermissions) {
            return <MissingPermissions />;
        }
        return (
            <Form>
                <Row type="flex">
                    <FormItem
                        label={formatMessage(mediaGalleryFormLabel.nameLabel)}
                        className={styles.CreateMediaGalleryFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('name', {
                            initialValue: mediaGalleryData ? mediaGalleryData.name : undefined,
                            rules: [{ required: true }]
                        })(
                            <Input disabled={!hasPermissions} onBlur={this.trimValue} />
                        )}
                    </FormItem>

                    <FormItem
                        label={formatMessage(mediaGalleryFormLabel.descriptionLabel)}
                        className={styles.CreateMediaGalleryFormItemContainer}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('description', {
                            initialValue: mediaGalleryData ? mediaGalleryData.description : undefined,
                        })(
                            <TextArea disabled={!hasPermissions} onBlur={this.trimValue} />
                        )}
                    </FormItem>

                    <FormItem
                        label={formatMessage(mediaGalleryFormLabel.colorLabel)}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        {getFieldDecorator('color', {
                            initialValue: mediaGalleryData ? mediaGalleryData.color : this.randomHexColor
                        })(
                            <Input type="color" disabled={!hasPermissions} />
                        )}
                    </FormItem>

                </Row>
            </Form>
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(MediaGalleryForm)));
