import {
    DateSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function simpleDate(defaultLanguage: string, name: string): Field<DateSchemaType> {
    return {
        name,
        type: SchemaTypeIds.DATE,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            after: undefined,
            before: undefined,
        }
    };
}

function simpleDateInArray(): DateSchemaType {
    return {
        type: SchemaTypeIds.DATE,
        constraints: {
            before: undefined,
            after: undefined
        }
    };
}

export const dateSnippets = {
    asField: {
        simpleDate
    },
    asArray: {
        simpleDateInArray
    }
};
