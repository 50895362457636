import {
    Field,
    SchemaTypeIds,
    TextSchemaType
} from '@contentchef/contentchef-types';

function simpleText(defaultLanguage: string, name: string): Field<TextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.TEXT,
        enableFacet: undefined,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            allowEmpty: undefined,
            minLength: undefined,
            maxLength: undefined,
            pattern: undefined
        }
    };
}

function simpleTextInArray(): TextSchemaType {
    return {
        type: SchemaTypeIds.TEXT,
        enableFacet: undefined,
        constraints: {
            allowEmpty: false,
            minLength: undefined,
            maxLength: undefined,
            pattern: undefined
        },
    };
}

function listOfTextValues(defaultLanguage: string, name: string): Field<TextSchemaType> {
    return {
        name,
        type: SchemaTypeIds.TEXT,
        enableFacet: false,
        labels: {
            [defaultLanguage]: name
        },
        hint: undefined,
        placeholder: undefined,
        constraints: {
            required: false,
            listOfValues: []
        }
    };
}

function listOfTextValueInArray(): TextSchemaType {
    return {
        type: SchemaTypeIds.TEXT,
        enableFacet: undefined,
        constraints: {
            listOfValues: []
        },
    };
}

export const textSnippets = {
    asField: {
        simpleText,
        listOfTextValues,
    },
    asArray: {
        simpleTextInArray,
        listOfTextValueInArray
    }
};
