import {
    OneLinkedContentOfSchemaType,
    Field,
    SchemaTypeIds,
    OneLinkedContentOfConstraints as OneLinkedContentOfFieldConstraints
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface OneLinkedContentOfConstraints {
    lazy: boolean;
    required: boolean;
    definitionMnemonicIds?: string[];
}

export type SerializedOneFragmentOfSchema = SerializedField<OneLinkedContentOfConstraints, {}>;

export function retrieveOneLinkedContentOfEditingType(constraints: OneLinkedContentOfFieldConstraints): ListItemIds {
    return ListItemIds.ContentReference;
}

export function deserialize(serialized: SerializedOneFragmentOfSchema): Field<OneLinkedContentOfSchemaType> {
    return {
        constraints: {
            definitionMnemonicIds: serialized.constraints.definitionMnemonicIds,
            lazy: serialized.constraints.lazy!,
            required: serialized.constraints.required,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        enableFacet: serialized.enableFacet,
        type: SchemaTypeIds.ONE_LINKED_CONTENT_OF,
        extension: serialized.extension
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<OneLinkedContentOfSchemaType>, initialIndex: number): SerializedOneFragmentOfSchema {
    return {
        settings: {},
        constraints: {
            lazy: deserialized.constraints.lazy,
            required: deserialized.constraints.required,
            definitionMnemonicIds: deserialized.constraints.definitionMnemonicIds,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        enableFacet: deserialized.enableFacet,
        type: deserialized.type,
        editingType: retrieveOneLinkedContentOfEditingType(deserialized.constraints),
        initialIndex,
        extension: deserialized.extension
    };
}
