import {
    Field,
    FragmentSchemaType,
    SchemaTypeIds
} from '@contentchef/contentchef-types';

function customField(
    defaultLanguage: string, name: string, nameConstraint: string
): Field<FragmentSchemaType> {
    return {
        name,
        type: SchemaTypeIds.FRAGMENT,
        labels: {
            [defaultLanguage]: name,
        },
        placeholder: undefined,
        hint: undefined,
        constraints: {
            required: false,
            name: nameConstraint
        }
    };
}

function customFieldInArray(nameConstraint: string): FragmentSchemaType {
    return {
        type: SchemaTypeIds.FRAGMENT,
        constraints: {
            name: nameConstraint
        }
    };
}

export const fragmentSnippets = {
    asField: {
        customField
    },
    asArray: {
        customFieldInArray
    }
};
