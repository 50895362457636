import React from 'react';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import LazyField from '../Constraints/LazyField';
import { OneLinkedContentOfConstraints } from '../../FieldSerializer/fields/oneLinkedContentOf';
import DefinitionMnemonicIds from '../Constraints/DefinitionMnemonicIds';
import {
    definitionMnemonicIdsMappedErrors,
    lazyMappedErrors
} from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function LinkedContentsDetails(props: FieldEditTabProps<OneLinkedContentOfConstraints>) {
    return (
        <>
            <LazyField
                relatedTabKey={'detailsTab'}
                mappedErrors={lazyMappedErrors}
                onChange={lazy => props.onChangeConstraint('lazy', lazy)}
                value={props.constraints.lazy}
            />
            <DefinitionMnemonicIds
                relatedTabKey={'detailsTab'}
                mappedErrors={definitionMnemonicIdsMappedErrors}
                selectionType="multiple"
                value={props.constraints.definitionMnemonicIds}
                label={<FormattedMessage
                    id="LinkedContentsDetails.DefinitionMnemonicIds.Label"
                    defaultMessage="Referable content definitions"
                />}
            />
        </>
    );
}

export default observer(LinkedContentsDetails);
