import React from 'react';
import { observer } from 'mobx-react';
import FieldEditorStore from '../../FieldEditorStore';
import { ListItemIds } from '../../types';
import SimpleTextConstraints from './SimpleText';
import ListOfTextValuesConstraints from './ListOfTextValues';
import SimpleNumberConstraints from './SimpleNumber';
import ListOfNumberValuesConstraints from './ListOfNumberValues';
import HtmlPlainTextConstraints from './LongTexts';
import SimpleDateConstraints from './SimpleDate';
import SimpleBoolean from './SimpleBoolean';
import CustomFieldConstraints from './CustomField';
import MediaReferences from './MediaReferences';
import TransformableImageConstraints from './TransformableImage';
import TransformableVideoConstraints from './TransformableVideo';
import ListOfItemsConstraints from './ListOfItems';
import LegacyLinkedContentConstraints from './LegacyLinkedContent';
import LinkedContentConstraints from './LinkedContent';
import MultiCustomFieldConstraints from './MultiCustomField';

export interface ConstraintTabProps {
    id: string;
    fieldToRenderType: ListItemIds;
    fieldEditorStore: FieldEditorStore;
    defaultLang: string;
    constraints: any;
    isArrayChildren: boolean;
    onChangeConstraint(key: string, value: unknown): void;
}

function getConstraintComponent(props: ConstraintTabProps) {
    switch (props.fieldToRenderType) {
        case ListItemIds.SimpleText: return <SimpleTextConstraints {...props} />;
        case ListItemIds.ListOfTextValues: return <ListOfTextValuesConstraints {...props} />;
        case ListItemIds.SimpleNumber: return <SimpleNumberConstraints {...props} />;
        case ListItemIds.ListOfNumberValues: return <ListOfNumberValuesConstraints {...props} />;
        case ListItemIds.SimplePlainText:
        case ListItemIds.MarkdownPlainText:
        case ListItemIds.JsonPlainText:
        case ListItemIds.HtmlPlainText: return <HtmlPlainTextConstraints {...props} />;
        case ListItemIds.SimpleDate: return <SimpleDateConstraints {...props} />;
        case ListItemIds.SimpleBoolean: return <SimpleBoolean {...props} />;
        case ListItemIds.CustomField: return <CustomFieldConstraints {...props} />;
        case ListItemIds.ImageReference:
        case ListItemIds.VideoReference:
        case ListItemIds.RawReference: return <MediaReferences {...props} />;
        case ListItemIds.TransformableImage: return <TransformableImageConstraints {...props} />;
        case ListItemIds.TransformableVideo: return <TransformableVideoConstraints {...props} />;
        case ListItemIds.ListOfItems: return <ListOfItemsConstraints {...props} />;
        case ListItemIds.LegacyContentReference: return <LegacyLinkedContentConstraints {...props} />;
        case ListItemIds.ContentReference: return <LinkedContentConstraints {...props} />;
        case ListItemIds.MultiCustomField: return <MultiCustomFieldConstraints {...props} />;
        default: return null;
    }
}

function ConstraintTab(props: ConstraintTabProps) {
    return (
        <div id={props.id}>
            {getConstraintComponent(props)}
        </div>
    );
}

export default observer(ConstraintTab);
