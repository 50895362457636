import {
    AllSchemaTypes,
    ArraySchemaType,
    BooleanSchemaType,
    CloudinaryImageSchemaType,
    CloudinaryPublicIdSchemaType,
    CloudinaryRawSchemaType,
    CloudinaryVideoSchemaType,
    DateFieldConstraints,
    DateSchemaType,
    Field,
    FragmentSchemaType,
    FragmentsTable,
    I18NDictionary,
    LinkedContentSchemaType,
    LongTextSchemaType,
    NumberSchemaType,
    OneFragmentOfSchemaType,
    OneLinkedContentOfSchemaType, PublishingChannelResponse, RequiredFieldConstraints,
    SchemaTypeIds,
    SchemaTypes,
    TextFieldConstraints,
    TextSchemaType
} from '@contentchef/contentchef-types';
import { action, computed, IObservableArray, IObservableValue, observable, reaction, toJS } from 'mobx';
import { defineMessages, FormattedMessage, InjectedIntl } from 'react-intl';
import { formMetaStore } from '../../stores';
import { FieldViolationModel, ViolationModel } from '../../stores/formMetaStore/formMetaStoreModel';
import { FormFieldFactory } from './FormFieldFactory';
import { formNormalizers } from './FormFieldNormalize';
import { FormFieldDefaultValueStrategy } from './FormFieldsDefaultValue';
import { isArrayField, isFragmentField, isFragmentOfField, isSimpleField } from './FormHelpers';
import { validationErrorMessage } from './FormValidationErrorMessages';

let fieldUniqueKey: number = 0;

const incrementUniqueKey = () => {
    fieldUniqueKey++;
};

export type SimpleSchemaTypes = Exclude<AllSchemaTypes, FragmentSchemaType | OneFragmentOfSchemaType | ArraySchemaType>;

export type DynamicFieldTypes = FieldText | FieldNumber | FieldBoolean | FieldDate | FieldLongText |
    FieldCloudinaryPublicId | FieldCloudinaryRaw | FieldCloudinaryImage | FieldCloudinaryVideo | FieldLinkedContent |
    FieldOneLinkedContentOf |
    FieldArray<Field<ArraySchemaType>> | FieldFragment | FieldOneFragmentOf;

export type SimpleFieldTypes = Exclude<DynamicFieldTypes, FieldArray<Field<ArraySchemaType>> |
    FieldFragment | FieldOneFragmentOf>;

export type CommonFieldTypes =
    FieldName | FieldRepository | FieldPublicId | FieldOnlineRange | FieldTags | FieldChannels;

export class CommonAttributes<C extends Field<SchemaTypes>, V> {
    field: Field<SchemaTypes>;
    id: string;
    labels: C['labels'];
    type: C['type'];
    constraints: C['constraints'];
    hint?: C['hint'];
    placeholder?: C['placeholder'];
    locale: string;
    extension?: string;
    @observable value: V | undefined;

    constructor(field: C, locale: string, value?: V) {
        this.field = field;
        this.id = field.name;
        this.labels = field.labels;
        this.type = field.type;
        this.constraints = field.constraints;
        this.hint = field.hint;
        this.placeholder = field.placeholder;
        this.locale = locale;
        this.value = value;
        this.extension = field.extension;
    }
}

export class SimpleField<C extends Field<SimpleSchemaTypes>, V> extends CommonAttributes<C, V> {
    uniqueId: string;
    initialValue: V | undefined;
    @observable errors: IObservableArray<ViolationModel> = observable.array([]);
    @observable hasClientErrors = observable.box(false, { name: 'hasClientErrors' });

    constructor(field: C, locale: string, value?: V, parentId?: string) {
        super(field, locale, value);
        this.uniqueId = !!parentId
            ? `${parentId}__-${field.name}__-${fieldUniqueKey}`
            : `${field.name}__-${fieldUniqueKey}`;
        this.initialValue = value;
        incrementUniqueKey();
    }

    @action
    setFieldValue(value?: any) {
        this.value = value;
    }

    getSubmittableValue(): V | undefined {
        return this.value;
    }

    @action
    setValidationError(error: any): void {
        this.errors.replace(error);
    }

    @action
    setHasClientErrors(hasClientErrors: boolean): void {
        this.hasClientErrors.set(hasClientErrors);
    }

    @action
    unsetValidationError(): void {
        this.errors.clear();
        this.hasClientErrors.set(false);
    }

    @computed
    get hasError(): boolean {
        return this.errors.length !== 0 || this.hasClientErrors.get();
    }

    getFieldErrorMessages(formatMessage: InjectedIntl['formatMessage'], locale: string) {
        const violationMessages: string[] = [];
        this.errors.map((violation: ViolationModel) => {
            let errorMessage: string;
            if (!!validationErrorMessage[violation.code]) {
                errorMessage = formatMessage(validationErrorMessage[violation.code], {
                    fieldName: this.id,
                    value: violation.max || violation.min || violation.minLength || violation.maxLength || undefined
                });
            } else {
                errorMessage = formatMessage(validationErrorMessage['fieldValidationError.errorCodeNotFound'], {
                    code: violation.code
                });
            }
            violationMessages.push(errorMessage);
        });
        return violationMessages.join(', ');
    }
}

export class FieldText extends SimpleField<Field<TextSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<TextSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }

    @action
    setFieldValue(value: string) {
        if (this.constraints.required) {
            this.value = this.constraints.allowEmpty && value.length === 0 ? '' : value;
        } else {
            this.value = value.length === 0 ? undefined : value;
        }
    }
}

export class FieldNumber extends SimpleField<Field<NumberSchemaType>, number | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<NumberSchemaType>, locale: string, value?: number, parentId?: string) {
        super(field, locale, value, parentId);
    }

    @action
    setFieldValue(value: any) {
        if (typeof value === 'string' && value.trim().length === 0) {
            this.value = undefined;
        } else if (typeof value === 'number') {
            this.value = value;
        }
    }
}

export class FieldBoolean extends SimpleField<Field<BooleanSchemaType>, boolean> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<BooleanSchemaType>, locale: string, value?: boolean, parentId?: string) {
        super(field, locale, !!value, parentId);
    }
}

export class FieldDate extends SimpleField<Field<DateSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<DateSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }

    @action
    setFieldValue(value?: any) {
        this.value = !value ? undefined : formNormalizers['date'](value);
    }
}

export class FieldLongText extends SimpleField<Field<LongTextSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<LongTextSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }

    @action
    setFieldValue(value: any) {
        if (this.constraints.required) {
            this.value = !this.constraints.allowEmpty && value.length === 0 ? '' : value;
        } else {
            this.value = value.length === 0 ? undefined : value;
        }
    }
}

export class FieldCloudinaryPublicId extends SimpleField<Field<CloudinaryPublicIdSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<CloudinaryPublicIdSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }
}

export class FieldCloudinaryRaw extends SimpleField<Field<CloudinaryRawSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<CloudinaryRawSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }
}

type CloudinaryImageValue = {
    publicId: string;
    transformations: string;
};

export class FieldCloudinaryImage extends SimpleField<
    Field<CloudinaryImageSchemaType>, CloudinaryImageValue | undefined
    > {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (!!value && !!this.initialValue) {
                if (value.publicId === this.initialValue.publicId
                    && value.transformations === this.initialValue.transformations) {
                    formMetaStore.removeChangedFields(this.uniqueId);
                } else {
                    formMetaStore.addChangedFields(this.uniqueId);
                }
            } else {
                if (value === this.initialValue) {
                    formMetaStore.removeChangedFields(this.uniqueId);
                } else {
                    formMetaStore.addChangedFields(this.uniqueId);
                }
            }
        }
    );

    constructor(
        field: Field<CloudinaryImageSchemaType>, locale: string, value?: CloudinaryImageValue, parentId?: string
    ) {
        super(field, locale, value, parentId);
    }

    getSubmittableValue() {
        if (this.value !== undefined) {
            return toJS(this.value);
        } else {
            return undefined;
        }
    }
}

export type CloudinaryVideoValue = {
    publicId: string;
    transformations: string;
};

export class FieldCloudinaryVideo extends SimpleField<
    Field<CloudinaryVideoSchemaType>, CloudinaryVideoValue | undefined
    > {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (!!value && !!this.initialValue) {
                if (value.publicId === this.initialValue.publicId
                    && value.transformations === this.initialValue.transformations) {
                    formMetaStore.removeChangedFields(this.uniqueId);
                } else {
                    formMetaStore.addChangedFields(this.uniqueId);
                }
            } else {
                if (value === this.initialValue) {
                    formMetaStore.removeChangedFields(this.uniqueId);
                } else {
                    formMetaStore.addChangedFields(this.uniqueId);
                }
            }
        }
    );

    constructor(
        field: Field<CloudinaryVideoSchemaType>, locale: string, value?: CloudinaryVideoValue, parentId?: string
    ) {
        super(field, locale, value, parentId);
    }

    getSubmittableValue() {
        if (this.value !== undefined) {
            return toJS(this.value);
        } else {
            return undefined;
        }
    }
}

export class FieldLinkedContent extends SimpleField<Field<LinkedContentSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<LinkedContentSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }
}

export class FieldOneLinkedContentOf extends SimpleField<Field<OneLinkedContentOfSchemaType>, string | undefined> {

    fieldChanged = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.uniqueId);
            } else {
                formMetaStore.removeChangedFields(this.uniqueId);
            }
        }
    );

    constructor(field: Field<OneLinkedContentOfSchemaType>, locale: string, value?: string, parentId?: string) {
        super(field, locale, value, parentId);
    }
}

type ArrayValueModel<C extends ArraySchemaType> = C['constraints']['items'] extends SimpleSchemaTypes ?
    SimpleField<Field<SimpleSchemaTypes>, any> : (FieldFragment | FieldOneFragmentOf);

export class FieldArray<C extends Field<ArraySchemaType>> extends CommonAttributes<C, ArrayValueModel<C>[]> {
    uniqueId: string;
    initialLength: number;
    initialValue: any[] | undefined;
    @observable errors: IObservableArray<ViolationModel> = observable.array([]);
    @observable value: ArrayValueModel<C>[];

    arrayLengthReaction = reaction(
        () => this.value.length,
        length => {
            this.errors.clear();
            if (this.initialLength !== length) {
                formMetaStore.addChangedFields(`${this.uniqueId}_LENGTH`);
            } else {
                formMetaStore.removeChangedFields(`${this.uniqueId}_LENGTH`);
            }
        }
    );

    arrayValueReaction = reaction(
        () => this.getSubmittableValue(),
        actualValue => {
            this.errors.clear();
            if (JSON.stringify(actualValue) !== JSON.stringify(this.initialValue)) {
                formMetaStore.addChangedFields(`${this.uniqueId}_VALUE`);
            } else {
                formMetaStore.removeChangedFields(`${this.uniqueId}_VALUE`);
            }
        }
    );

    private fieldUniqueKey: number;

    constructor(field: C, locale: string, arrayValues?: any[], parentId?: string) {
        super(field, locale, []);
        this.uniqueId = !!parentId
            ? `${parentId}__-${field.name}__-${fieldUniqueKey}`
            : `${field.name}__-${fieldUniqueKey}`;
        this.fieldUniqueKey = 0;
        incrementUniqueKey();
        this.init(arrayValues);
    }

    @action
    init(arrayValues?: any[]) {
        if (arrayValues && arrayValues.length > 0) {
            this.initialValue = arrayValues;
            this.initialLength = arrayValues.length;
            arrayValues.map((value: any) => {
                this.addField(value, true);
            });
        } else {
            this.initialValue = this.constraints.required ? [] : undefined;
            this.initialLength = 0;
        }
    }

    @action
    replaceValue(arrayValues: any[]) {
        arrayValues.map((value: any) => {
            this.addField(value, false);
        });
    }

    @action
    addField(value?: any, fromInit?: boolean, position?: number) {
        const arrayItemField: any = {
            name: `${this.id}-${this.fieldUniqueKey}`,
            type: this.constraints.items.type,
            constraints: { required: true, ...this.constraints.items.constraints },
            labels: {}
        };
        this.fieldUniqueKey++;

        const arrayChildrenValue = value ? value : new FormFieldDefaultValueStrategy(arrayItemField).getValue();
        const newField = FormFieldFactory(
            formMetaStore.formMeta.fragments, arrayItemField,
            this.locale, !!fromInit, arrayChildrenValue);
        if (newField !== null) {
            if (!fromInit) { formMetaStore.addChangedFields(newField.uniqueId); }

            if (position !== undefined && Number.isInteger(position) && position >= 0) {
                this.value.splice(position, 0, newField as ArrayValueModel<C>);
            } else {
                this.value.push(newField as ArrayValueModel<C>);
            }
        }
    }

    @action
    removeField(fieldIndex: number) {
        formMetaStore.removeChangedFields(this.value[fieldIndex].uniqueId);
        this.value.splice(fieldIndex, 1);
    }

    @action
    moveChildBack(fieldIndex: number) {
        const actualField = this.value[fieldIndex];
        this.value[fieldIndex] = this.value[fieldIndex - 1];
        this.value[fieldIndex - 1] = actualField;
        this.checkChildrenOrder();
    }

    @action
    moveChildTo(fieldIndex: number, newIndex: number) {
        const actualField = this.value[fieldIndex];
        this.value.splice(fieldIndex, 1);
        this.value.splice(newIndex, 0, actualField);
        this.checkChildrenOrder();
    }

    @action
    moveChildForward(fieldIndex: number) {
        const actualField = this.value[fieldIndex];
        this.value[fieldIndex] = this.value[fieldIndex + 1];
        this.value[fieldIndex + 1] = actualField;
        this.checkChildrenOrder();
    }

    @action
    setFieldValue(values: any[]) {
        this.value = [];
        if (values.length > 0) {
            this.replaceValue(values);
        }
    }

    @action
    setFieldValueWithFieldPath(fieldPath: string, values: any) {
        if (fieldPath.length === 0 && Array.isArray(values)) {
            if (values.length > 0) {
                this.setFieldValue(values);
            } else {
                this.value = [];
            }
        }
        const splittedPath = fieldPath.split('.');
        if (splittedPath.length > 0 && !Array.isArray(values)) {
            const index = parseInt(splittedPath.shift()!, 10);
            if (splittedPath.length === 0) {
                this.value.splice(index, 1);
                this.addField(values, false, index);
            }
            if (splittedPath.length > 0) {
                const nextProperty = splittedPath.shift();
                this.value.map(field => {
                    if (field.id === nextProperty) {
                        if (isSimpleField(field)) {
                            field.setFieldValue(values);
                            return;
                        }
                        if (isFragmentField(field) || isFragmentOfField(field)) {
                            field.setFieldValueWithFieldPath(splittedPath.join('.'), values);
                            return;
                        }
                    }
                });
            }
        }
    }

    @computed
    get hasError(): boolean {
        let hasError: boolean = this.errors.length > 0;
        let i = 0;
        while (!hasError && i < this.value.length) {
            hasError = this.value[i].hasError;
            i++;
        }
        return hasError;
    }

    getSubmittableValue = () => {
        let payloadValues: any[] = [];
        this.value.map((field: ArrayValueModel<C>) => {
            const value = field.getSubmittableValue();
            if (value !== undefined) {
                payloadValues.push(value);
            }
        });

        if (payloadValues.length === 0) {
            if (this.constraints.required) {
                return payloadValues;
            } else {
                return undefined;
            }
        } else {
            return toJS(payloadValues);
        }
    }

    @action
    setValidationError(arrayErrors: any): void {
        this.errors.clear();
        arrayErrors.map((error: any) => {
            if (error.code) {
                this.errors.push(error);
            } else {
                const field = this.value[error.index];
                field.setValidationError(error.violations);
            }
        });
    }

    setHasClientErrors(arrayErrors: any[]): void {
        arrayErrors.forEach((errors, index) => {
            const arrayField = this.value[index];
            if (isSimpleField(arrayField)) {
                arrayField.setHasClientErrors(true);
                return;
            }
            if (isArrayField(arrayField) || isFragmentField(arrayField) || isFragmentField(arrayField)) {
                arrayField.setHasClientErrors(arrayErrors[index]);
                return;
            }
        });
    }

    getFieldErrorMessages(formatMessage: InjectedIntl['formatMessage'], locale: string) {
        const violationMessages: string[] = [];
        this.errors.map((violation: ViolationModel) => {
            let errorMessage: string;
            if (!!validationErrorMessage[violation.code]) {
                errorMessage = formatMessage(validationErrorMessage[violation.code], {
                    fieldName: this.id,
                    value: violation.max || violation.min || violation.minLength || violation.maxLength || undefined
                });
            } else {
                errorMessage = formatMessage(validationErrorMessage['fieldValidationError.errorCodeNotFound'], {
                    code: violation.code
                });
            }
            violationMessages.push(errorMessage);
        });
        return violationMessages.join(', ');
    }

    private checkChildrenOrder = () => {
        let stillSameChildren = true;
        let i = 0;
        if (this.initialValue) {
            while (i < this.value.length && stillSameChildren) {
                const actualIValue = this.value[i].getSubmittableValue();
                const initialIValue = this.initialValue[i];
                stillSameChildren = JSON.stringify(actualIValue) === JSON.stringify(initialIValue);
                i++;
            }
            if (stillSameChildren) {
                formMetaStore.removeChangedFields(`${this.uniqueId}_ORDER`);
            } else {
                formMetaStore.addChangedFields(`${this.uniqueId}_ORDER`);
            }
        }
    }
}

export type FragmentChildFieldType = SimpleField<Field<SimpleSchemaTypes>, any> |
    FieldArray<Field<ArraySchemaType>> |
    FieldFragment |
    FieldOneFragmentOf;

export interface FragmentValueModel {
    [key: string]: FragmentChildFieldType;
}

export class FieldFragment extends CommonAttributes<Field<FragmentSchemaType>, FragmentValueModel> {
    uniqueId: string;
    initialValue: object;
    loaded: boolean;
    fragmentNameLabels: I18NDictionary;
    @observable errors: IObservableArray<ViolationModel> = observable.array([]);
    @observable value: FragmentValueModel;

    fragmentReaction = reaction(
        () => Object.keys(this.value).length,
        length => {
            const actualValue = this.getSubmittableValue() || {};
            const actualValueKeysLength = length === 0
                ? length
                : Object.keys(actualValue).length;
            const initialValueKeysLength = Object.keys(this.initialValue).length;
            return actualValueKeysLength === initialValueKeysLength
                ? this.areSameKeys(this.initialValue, actualValue)
                    ? formMetaStore.removeChangedFields(this.uniqueId)
                    : formMetaStore.addChangedFields(this.uniqueId)
                : formMetaStore.addChangedFields(this.uniqueId);
        }
    );

    constructor(
        field: Field<FragmentSchemaType>, locale: string, loaded: boolean,
        fragmentNameLabels: I18NDictionary, value?: object, parentId?: string) {
        super(field, locale, {});
        this.loaded = loaded;
        this.fragmentNameLabels = fragmentNameLabels;
        this.initialValue = value || {};
        this.uniqueId = !!parentId
            ? `${parentId}__-${field.name}__-${fieldUniqueKey}`
            : `${field.name}__-${fieldUniqueKey}`;
        this.initializeFragmentMeta(value);
        incrementUniqueKey();
    }

    initializeFragmentMeta = (fragmentPayloadValue?: object): void => {
        if (!!fragmentPayloadValue || this.constraints.required) {
            this.addFragmentChildren(fragmentPayloadValue);
        } else {
            this.removeFragmentChildren();
        }
    }

    @action
    addFragmentChildren = (value?: any) => {
        this.loaded = false;
        const fragments = formMetaStore.formMeta.fragments;
        fragments[this.constraints.name].fields.map((fragmentField) => {
            let fragmentFieldValue: any = value
                ? value[fragmentField.name]
                : new FormFieldDefaultValueStrategy(fragmentField).getValue();

            const fragmentMeta = FormFieldFactory(
                fragments, fragmentField, this.locale,
                false, fragmentFieldValue, this.uniqueId);
            if (fragmentMeta !== null) {
                this.value[fragmentField.name] = fragmentMeta;
            }
        });
    }

    @action
    removeFragmentChildren = () => {
        this.loaded = false;
        this.removeChildrenChangedFields();
        this.value = {};
    }

    @computed
    get hasError(): boolean {
        if (this.errors.length > 0) {
            return true;
        }
        for (const key in this.value) {
            if (this.value.hasOwnProperty(key)) {
                if (this.value[key].hasError) {
                    return true;
                }
            }
        }
        return false;
    }

    getSubmittableValue = () => {
        const payloadValues: object = {};
        const fields = this.value;
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const fieldData = fields[key];
                if (fieldData) {
                    const value = fieldData.getSubmittableValue();
                    if (value !== undefined) {
                        payloadValues[key] = value;
                    }
                }

            }
        }

        if (Object.keys(payloadValues).length === 0) {
            if (this.constraints.required) {
                return payloadValues;
            } else {
                return undefined;
            }
        } else {
            return toJS(payloadValues);
        }
    }

    @action
    setValidationError(errors: any): void {
        this.errors.clear();
        errors.map((error) => {
            if (error.code) {
                this.errors.push(error);
            } else {
                const field = this.value[error.field];
                if (field) {
                    field.setValidationError(error.violations);
                }
            }
        });
    }

    @action
    setFieldValue(value: object) {
        this.initializeFragmentMeta(value);
    }

    @action
    setFieldValueWithFieldPath(fieldPath: string, value: any) {
        const splittedPath = fieldPath.split('.');
        const currentLevelPath = splittedPath.shift();
        const isEmpty = Object.keys(toJS(this.value)).length === 0;
        if (isEmpty && currentLevelPath) {
            this.initializeFragmentMeta({ [currentLevelPath]: value });
            return;
        }
        for (const key in this.value) {
            if (currentLevelPath === key && this.value.hasOwnProperty(key) && this.value[key] != null) {
                const field = this.value[key];
                if (isSimpleField(field)) {
                    field.setFieldValue(value);
                    return;
                }
                if (isArrayField(field) || isFragmentField(field) || isFragmentOfField(field)) {
                    field.setFieldValueWithFieldPath(splittedPath.join('.'), value);
                    return;
                }
            }
        }
    }

    setHasClientErrors(fragmentErrors: { [key: string]: any }): void {
        const fragmentChildKeys = Object.keys(this.value);
        for (const key in fragmentErrors) {
            if (fragmentErrors.hasOwnProperty(key) && fragmentChildKeys.includes(key)) {
                const fragmentField = this.value[key];
                if (isSimpleField(fragmentField)) {
                    fragmentField.setHasClientErrors(true);
                    return;
                }
                if (isArrayField(fragmentField) || isFragmentField(fragmentField) || isFragmentOfField(fragmentField)) {
                    fragmentField.setHasClientErrors(fragmentErrors[key]);
                    return;
                }
            }
        }
    }

    getFieldErrorMessages(formatMessage: InjectedIntl['formatMessage'], locale: string) {
        const violationMessages: string[] = [];
        this.errors.map((violation: ViolationModel) => {
            let errorMessage: string;
            if (!!validationErrorMessage[violation.code]) {
                errorMessage = formatMessage(validationErrorMessage[violation.code], {
                    fieldName: this.id,
                    value: violation.max || violation.min || violation.minLength || violation.maxLength || undefined
                });
            } else {
                errorMessage = formatMessage(validationErrorMessage['fieldValidationError.errorCodeNotFound'], {
                    code: violation.code
                });
            }
            violationMessages.push(errorMessage);
        });
        return violationMessages.join(', ');
    }

    private removeChildrenChangedFields = () => {
        formMetaStore.changedFields.map((changedField) => {
            if (changedField.fieldUniqueId.includes(this.uniqueId)) {
                formMetaStore.removeChangedFields(changedField.fieldUniqueId);
            }
        });
    }

    private areSameKeys = (a: object, b: object) => {
        const aKeys = Object.keys(a).sort();
        const bKeys = Object.keys(b).sort();
        return JSON.stringify(aKeys) === JSON.stringify(bKeys);
    }
}

export interface OneFragmentOfValueModel {
    name: string;
    fieldMeta: FieldFragment;
}

interface OneFragmentOfPayloadValueModel {
    name: string;
    value: FragmentValueModel;
}

export const createFragmentLabels = (
    fragments: FragmentsTable, fragmentName: string,
    locale: string): I18NDictionary => {
    const fragment = fragments[fragmentName];

    if (fragment && !!fragment.labels) {
        return { ...fragments[fragmentName].labels };
    } else {
        return { [locale]: fragmentName };
    }
};

export class FieldOneFragmentOf
    extends CommonAttributes<Field<OneFragmentOfSchemaType>, IObservableValue<OneFragmentOfValueModel>> {
    uniqueId: string;
    @observable errors: IObservableArray<ViolationModel> = observable.array([]);
    initialValue: OneFragmentOfPayloadValueModel = {} as OneFragmentOfPayloadValueModel;
    value: IObservableValue<OneFragmentOfValueModel> = observable.box({} as OneFragmentOfValueModel);

    fragmentValueReaction = reaction(
        () => this.value.get(),
        value => {
            if (Object.keys(value).length === Object.keys(this.initialValue).length) {
                formMetaStore.removeChangedFields(this.uniqueId);
            } else {
                formMetaStore.addChangedFields(this.uniqueId);
            }
        }
    );

    fragmentSubmittableValueReaction = reaction(
        () => this.getSubmittableValue(),
        value => {
            this.errors.clear();
        }
    );

    constructor(
        field: Field<OneFragmentOfSchemaType>, locale: string, value?: OneFragmentOfPayloadValueModel, parentId?: string
    ) {
        super(field, locale);
        this.uniqueId = !!parentId
            ? `${parentId}__-${field.name}__-${fieldUniqueKey}`
            : `${field.name}__-${fieldUniqueKey}`;
        this.initialValue = value || {} as OneFragmentOfPayloadValueModel;
        this.initializeOneFragmentOfMeta(field, value);
        incrementUniqueKey();
    }

    @action
    initializeOneFragmentOfMeta = (
        field: Field<OneFragmentOfSchemaType>,
        payloadValue?: OneFragmentOfPayloadValueModel): void => {
        if (payloadValue) {
            const fragmentName: string = payloadValue.name;
            const fragmentChildrenValue: FragmentValueModel = payloadValue.value;
            const fragmentNameLabels = createFragmentLabels(
                formMetaStore.formMeta.fragments,
                fragmentName, this.locale);
            const fragmentField: Field<FragmentSchemaType> = {
                name: fragmentName,
                constraints: { required: field.constraints.required, name: fragmentName },
                labels: field.labels,
                type: SchemaTypeIds.FRAGMENT
            };
            const fragmentChildrenMeta = new FieldFragment(
                fragmentField, this.locale,
                true, fragmentNameLabels, fragmentChildrenValue);
            if (fragmentChildrenMeta !== null) {
                this.value.set({
                    name: fragmentName,
                    fieldMeta: fragmentChildrenMeta
                });
            }
        }
    }

    @action
    createFragment = (name: string) => {
        const fragmentNameLabels = createFragmentLabels(formMetaStore.formMeta.fragments, name, this.locale);
        const fragmentField: Field<FragmentSchemaType> = {
            name: name,
            constraints: { required: true, name: name },
            labels: this.labels,
            type: SchemaTypeIds.FRAGMENT
        };
        const fragmentChildrenMeta = new FieldFragment(fragmentField, this.locale, false, fragmentNameLabels);
        if (fragmentChildrenMeta !== null) {
            this.value.set({
                name: name,
                fieldMeta: fragmentChildrenMeta
            });
        }
    }

    @action
    undoFragmentSelection = (e) => {
        e.stopPropagation();
        this.value.set({} as OneFragmentOfValueModel);
    }

    @computed
    get hasError(): boolean {
        const fragmentOfValue = this.value.get().fieldMeta;
        if (this.errors.length > 0) {
            return true;
        }
        return fragmentOfValue && fragmentOfValue.hasError;
    }

    getSubmittableValue = () => {
        const { name } = this.value.get();
        if (name !== undefined) {
            const payloadValues: any = {
                name: name,
                value: {}
            };
            const { fieldMeta } = this.value.get();
            const value = fieldMeta.getSubmittableValue();
            if (value !== undefined) {
                payloadValues.value = value;
            }

            if (Object.keys(payloadValues.value).length === 0) {
                if (this.constraints.required) {
                    return payloadValues;
                } else {
                    return undefined;
                }
            } else {
                return toJS(payloadValues);
            }
        }
    }

    @action
    setValidationError = (errors: any): void => {
        this.errors.clear();
        errors.map((error) => {
            if (error.code) {
                this.errors.push(error);
            } else {
                const { fieldMeta } = this.value.get();
                fieldMeta.setValidationError(errors.violations);
            }
        });
    }

    @action
    setFieldValue(value: OneFragmentOfPayloadValueModel) {
        this.initializeOneFragmentOfMeta(this.field as Field<OneFragmentOfSchemaType>, value);
    }

    @action
    setFieldValueWithFieldPath(fieldPath: string, fragmentValue: any) {
        const splittedPath = fieldPath.split('.');
        const currentLevelPath = splittedPath.shift();
        if (!fragmentValue.name || !fragmentValue.value) {
            throw new Error(
                'When interacting with oneFragmentOf you should pass fragment name and value as properties'
            );
        }
        if (!this.value.get().fieldMeta && currentLevelPath) {
            this.initializeOneFragmentOfMeta(
                this.field as Field<OneFragmentOfSchemaType>,
                {
                    name: fragmentValue.name,
                    value: { [currentLevelPath]: fragmentValue.value }
                }
            );
            return;
        }

        const underlyingFragment = this.value.get().fieldMeta;
        splittedPath.push(currentLevelPath!);
        underlyingFragment.setFieldValueWithFieldPath(splittedPath.join('.'), fragmentValue.value);
    }

    setHasClientErrors(fragmentOfErrors: { [key: string]: any }): void {
        const fragmentOfName = this.value.get().name;
        const errorKeys = Object.keys(fragmentOfErrors);
        errorKeys.forEach(key => {
            if (fragmentOfName === key) {
                this.value.get().fieldMeta.setHasClientErrors(fragmentOfErrors[key]);
            }
        });
    }

    getFieldErrorMessages(formatMessage: InjectedIntl['formatMessage'], locale: string) {
        const violationMessages: string[] = [];
        this.errors.map((violation: ViolationModel) => {
            let errorMessage: string;
            if (!!validationErrorMessage[violation.code]) {
                errorMessage = formatMessage(validationErrorMessage[violation.code], {
                    fieldName: this.id,
                    value: violation.max || violation.min || violation.minLength || violation.maxLength || undefined
                });
            } else {
                errorMessage = formatMessage(validationErrorMessage['fieldValidationError.errorCodeNotFound'], {
                    code: violation.code
                });
            }
            violationMessages.push(errorMessage);
        });
        return violationMessages.join(', ');
    }

}

export type FieldModelType = SimpleField<Field<SimpleSchemaTypes>, any> | FieldArray<Field<ArraySchemaType>> |
    FieldFragment | FieldOneFragmentOf;

export interface FieldsModel {
    [key: string]: FieldModelType | null;
}

export class FieldDynamic {
    fields: FieldsModel = {};
    readonly fragments: FragmentsTable;

    constructor(
        fragments: FragmentsTable, schemaFields: Field[], contentDefinitionLocale: string,
        payloadData?: object) {
        this.fragments = fragments;
        schemaFields.map((field: Field) => {
            const fieldInitialValue = payloadData
                ? payloadData[field.name]
                : new FormFieldDefaultValueStrategy(field).getValue();
            this.fields[field.name] = FormFieldFactory(
                this.fragments,
                field,
                contentDefinitionLocale,
                true,
                fieldInitialValue
            );
        });
    }

    getSubmittablePayload() {
        const payloadValues: object = {};
        for (const key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
                const field = this.fields[key];
                if (field) {
                    const value = field.getSubmittableValue();
                    if (value !== undefined) {
                        payloadValues[field.id] = value;
                    }
                }
            }
        }
        return payloadValues;
    }

    setFieldsValidationErrors(errors: FieldViolationModel[]): void {
        for (const key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
                const field = this.fields[key];
                if (field) {
                    const fieldError: FieldViolationModel | undefined = errors.find((error) => error.field === key);
                    if (fieldError) {
                        field.setValidationError(fieldError.violations);
                    }
                }
            }
        }
    }

    setClientSideErrors(errors: { [key: string]: any }): void {
        const errorKeys = Object.keys(errors);
        errorKeys.forEach((key) => {
            if (this.fields.hasOwnProperty(key) && this.fields[key] != null) {
                const error = errors[key];
                const fieldWithErrors = this.fields[key];
                if (isSimpleField(fieldWithErrors)) {
                    fieldWithErrors.setHasClientErrors(true);
                    return;
                }
                if (isArrayField(fieldWithErrors)) {
                    fieldWithErrors.setHasClientErrors(error);
                    return;
                }
                if (isFragmentField(fieldWithErrors)) {
                    fieldWithErrors.setHasClientErrors(error);
                    return;
                }
                if (isFragmentOfField(fieldWithErrors)) {
                    fieldWithErrors.setHasClientErrors(error);
                    return;
                }
            }
        });
    }

    @action
    setFieldPathValue(fieldPath: string, value: any) {
        const splittedPath = fieldPath.split('.');
        const firstFieldPath = splittedPath.shift();
        for (const key in this.fields) {
            if (firstFieldPath === key && this.fields.hasOwnProperty(key) && this.fields[key] != null) {
                const field = this.fields[key];
                if (isSimpleField(field)) {
                    field.setFieldValue(value);
                    return;
                }
                if (isArrayField(field) || isFragmentField(field) || isFragmentOfField(field)) {
                    field.setFieldValueWithFieldPath(splittedPath.join('.'), value);
                    return;
                }
            }
        }
    }
}

interface DefinedLabelModel {
    label: FormattedMessage.MessageDescriptor;
}

class SimpleStaticField<T, V, C> {
    definedLabel: DefinedLabelModel;
    name: string;
    type: T;
    constraints: RequiredFieldConstraints & C;
    initialValue: V | undefined;
    @observable value: V | undefined;

    fieldReaction = reaction(
        () => this.value,
        value => {
            if (this.initialValue !== value) {
                formMetaStore.addChangedFields(this.name);
            } else {
                formMetaStore.removeChangedFields(this.name);
            }

        }
    );

    constructor(
        fieldName: string,
        fieldConstraints: RequiredFieldConstraints & C,
        initialValue: V | undefined
    ) {
        this.name = fieldName;
        this.constraints = fieldConstraints;
        this.initialValue = initialValue;
        this.value = initialValue;
    }

    getLabelDescriptor(): FormattedMessage.MessageDescriptor {
        return this.definedLabel.label;
    }
}

export class FieldName extends SimpleStaticField<SchemaTypeIds.TEXT, string, TextFieldConstraints> {
    definedLabel = defineMessages({
        label: {
            id: 'FieldName.ContentNameLabel',
            defaultMessage: 'Content Name'
        }
    });

    constructor(initialValue?: string) {
        super('name', { required: true, maxLength: 100 }, initialValue);
    }

    @action
    setFieldValue(value?: string) {
        this.value = value;
    }

    @action
    trimValue(value?: string) {
        if (value) {
            this.value = value.trim();
        }
        return this.value;
    }
}

export class FieldRepository extends SimpleStaticField<SchemaTypeIds.NUMBER, number, {}> {
    definedLabel = defineMessages({
        label: {
            id: 'FieldRepository.ContentRepositoryLabel',
            defaultMessage: 'Content Repository'
        }
    });

    constructor(initialValue?: number) {
        super('repository', { required: true }, initialValue);
    }

    @action
    setFieldValue(value?: number) {
        this.value = value;
    }
}

export class FieldPublicId extends SimpleStaticField<SchemaTypeIds.TEXT, string, TextFieldConstraints> {
    definedLabel = defineMessages({
        label: {
            id: 'FieldPublicId.PublicIdLabel',
            defaultMessage: 'Public Id'
        }
    });

    constructor(initialValue?: string) {
        super('publicId', { required: !!initialValue }, initialValue);
    }

    @action
    setFieldValue(value?: string) {
        this.value = value;
    }

    @action
    trimValue(value?: string) {
        if (value) {
            this.value = value.trim();
        }
        return this.value;
    }
}

export class FieldTags extends SimpleStaticField<SchemaTypeIds.ARRAY, string[], {}> {
    initialValue: string[] = [];
    @observable value: IObservableArray<string>;

    definedLabel = defineMessages({
        label: {
            id: 'FieldTags.ContentTagsLabel',
            defaultMessage: 'Tags'
        }
    });

    fieldReaction = reaction(
        () => this.value.length,
        length => {
            if (this.initialValue.length !== length) {
                formMetaStore.addChangedFields(this.name);
            } else {
                if (!this.areInitialIncludedInActual()) {
                    formMetaStore.addChangedFields(this.name);
                } else {
                    formMetaStore.removeChangedFields(this.name);
                }
            }

        }
    );

    constructor(initialValue?: string[]) {
        super('tags', { required: false }, observable.array(initialValue || []));
        this.initialValue = initialValue || [];
        this.setFieldValue(this.initialValue);
    }

    areInitialIncludedInActual = () => {
        let result: boolean = true;
        this.initialValue.map((initialTag) => {
            if (!this.value.includes(initialTag)) {
                result = false;
            }
        });
        return result;
    }

    @action
    setFieldValue(values: string[]) {
        this.value.replace(values);
    }
}

export class FieldChannels extends SimpleStaticField<SchemaTypeIds.ARRAY, PublishingChannelResponse[], {}> {
    initialValue: PublishingChannelResponse[] = [];
    @observable value: IObservableArray<PublishingChannelResponse>;

    definedLabel = defineMessages({
        label: {
            id: 'FieldChannels.ContentChannelsLabel',
            defaultMessage: 'Channels'
        }
    });

    fieldReaction = reaction(
        () => this.value.length,
        length => {
            if (this.initialValue.length !== length) {
                formMetaStore.addChangedFields(this.name);
            } else {
                if (!this.areInitialIncludedInActual()) {
                    formMetaStore.addChangedFields(this.name);
                } else {
                    formMetaStore.removeChangedFields(this.name);
                }
            }

        }
    );

    constructor(initialValue?: PublishingChannelResponse[]) {
        super('channels', { required: false }, observable.array(initialValue || []));
        this.initialValue = initialValue || [];
        this.value.replace(this.initialValue);
    }

    areInitialIncludedInActual = () => {
        let result: boolean = true;
        let i = 0;
        while (result && i < this.initialValue.length) {
            const initialChannel = this.initialValue[i];
            if (!this.value.find(channel => channel.id === initialChannel.id)) {
                result = false;
            }
            i++;
        }
        return result;
    }

    @action
    setFieldValue(checked: boolean, value: PublishingChannelResponse) {
        if (checked) {
            this.value.push(value);
        } else {
            this.value.replace(this.value.filter(channel => channel.id !== value.id));
        }
    }
}

interface OnlineRangeValueModel {
    onlineDate: SimpleStaticField<'date', Date, DateFieldConstraints>;
    offlineDate: SimpleStaticField<'date', Date, DateFieldConstraints>;
}

export class FieldOnlineRange {
    definedLabel: DefinedLabelModel = defineMessages({
        label: {
            id: 'FieldOnlineRange.OnlineRangeLabel',
            defaultMessage: 'Publication range date'
        }
    });
    name: string = 'onlineRange';
    constraints: RequiredFieldConstraints = { required: false };
    @observable value: OnlineRangeValueModel;

    constructor(initialValue?: { onlineDate?: Date, offlineDate?: Date }) {
        this.value = {
            onlineDate: new SimpleStaticField<'date', Date, DateFieldConstraints>(
                'onlineDate',
                { required: false },
                initialValue && initialValue.onlineDate ? initialValue.onlineDate : undefined
            ),
            offlineDate: new SimpleStaticField<'date', Date, DateFieldConstraints>(
                'offlineDate',
                { required: false },
                initialValue && initialValue.offlineDate ? initialValue.offlineDate : undefined
            )
        };
    }

    getLabelDescriptor(): FormattedMessage.MessageDescriptor {
        return this.definedLabel.label;
    }

    getValues() {
        return {
            onlineDate: this.value.onlineDate.value,
            offlineDate: this.value.offlineDate.value
        };
    }

    @action
    setValues(values?: { onlineDate?: Date, offlineDate?: Date }) {
        this.value.onlineDate.value = (values ? values.onlineDate : undefined);
        this.value.offlineDate.value = (values ? values.offlineDate : undefined);
    }
}
