import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { observable, action, runInAction } from 'mobx';
import {
    ListContentDefinitionsRequest
} from '@contentchef/contentchef-types';
import {
    ContentDefinitionListStoreModel,
    ContentDefinitionListDataModel, DataLoaders
} from './contentDefinitionListStoreModel';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';
import SearchParamsStore from '../searchParamsStore/searchParamsStore';

class ContentDefinitionListStore implements ContentDefinitionListStoreModel {
    api: ContentChefClient;
    @observable searchParams = {} as SearchParamsStoreModel;
    @observable dataLoaders: DataLoaders = {
        contentDefinitionList: true
    };
    @observable error = {
        contentDefinitions: '',
        count: ''
    };

    @observable contentDefinitionListData: ContentDefinitionListDataModel = {
        contentDefinitionList: observable.array([]),
        total: 0
    };

    constructor(api: ContentChefClient) {
        this.api = api;
        this.searchParams = new SearchParamsStore();
    }

    @action
    async initContentDefinitionList() {
        await this.setContentDefinitions();
    }

    @action
    async setContentDefinitions(skip?: number, take?: number, name?: string) {
        this.dataLoaders.contentDefinitionList = true;
        const usableSearchParams = this.searchParams.usableSearchParams;
        const reqParams: ListContentDefinitionsRequest = {
            skip: skip || (+usableSearchParams.page - 1 || 0) * (+usableSearchParams.size || 10),
            take: take || +usableSearchParams.size || 10,
            filters: {
                name: name || usableSearchParams.search
            }
        };

        try {
            const response = await this.api.contentDefinitions.list(reqParams);
            runInAction(() => {
                this.contentDefinitionListData.contentDefinitionList.replace(response.items);
                this.contentDefinitionListData.total = response.total;
                this.dataLoaders.contentDefinitionList = false;
            });
        } catch (e) {
            runInAction(() => {
                this.error.contentDefinitions = e;
                this.dataLoaders.contentDefinitionList = false;
                console.log(e, 'Impossible to call contentDefinitions.list');
            });
        }
    }
}

export default ContentDefinitionListStore;
