import { Field, SchemaTypeIds } from '@contentchef/contentchef-types';
import { SerializedField } from './types';
import * as arr from './fields/array';
import * as bool from './fields/boolean';
import * as cloudinaryImage from './fields/cloudinary-image';
import * as cloudinaryPublicId from './fields/cloudinary-publicId';
import * as cloudinaryVideo from './fields/cloudinary-video';
import * as date from './fields/date';
import * as fragment from './fields/fragment';
import * as linkedContent from './fields/linked-content';
import * as longText from './fields/long-text';
import * as num from './fields/number';
import * as oneFragmentOf from './fields/oneFragmentOf';
import * as oneLinkedContentOf from './fields/oneLinkedContentOf';
import * as text from './fields/text';

export default function serialize(field: Field, fieldInitialIndex: number): SerializedField {
    if (!field) {
        throw new TypeError('');
    }

    switch (field.type) {
        case SchemaTypeIds.ARRAY:
            return arr.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.BOOLEAN:
            return bool.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.CLOUDINARY_IMAGE:
            return cloudinaryImage.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.CLOUDINARY_PUBLICID:
            return cloudinaryPublicId.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.CLOUDINARY_VIDEO:
            return cloudinaryVideo.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.DATE:
            return date.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.FRAGMENT:
            return fragment.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.LINKED_CONTENT:
            return linkedContent.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.LONG_TEXT:
            return longText.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.NUMBER:
            return num.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.ONE_FRAGMENT_OF:
            return oneFragmentOf.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.ONE_LINKED_CONTENT_OF:
            return oneLinkedContentOf.serialize(field, fieldInitialIndex);
        case SchemaTypeIds.TEXT:
            return text.serialize(field, fieldInitialIndex);
        default:
            throw new Error('unknown field type to serialize');
    }
}
