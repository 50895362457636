import React, { Component } from 'react';
import { Row, Col } from 'antd';

import { sceneContainerDimensions } from '../../constants/layout-sizes';
import { SceneContainerProps } from './SceneContainerModel';
import styles from './SceneContainer.module.scss';

const { columns } = sceneContainerDimensions;

class SceneContainer extends Component<SceneContainerProps> {
    render() {
        const {
            dataAttributes = {},
            children,
        } = this.props;
        return (
            <Row {...dataAttributes} className={styles.sceneContainerRowWrapper}>
                <Col {...columns} className={styles.SceneContainerBodyWrapper}>{children}</Col>
            </Row>
        );
    }
}
export default SceneContainer;
