import React, { Component } from 'react';
import { Col, Icon, Input, List, Row, Switch, Tag, Tooltip, Form } from 'antd';
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';
import classNames from './index.module.scss';
import { FieldDescriptor, ListItemFamily } from '../../types';
import Section from '../../../Section/Section';
import { FormComponentProps } from 'antd/lib/form';
import { Field } from '@contentchef/contentchef-types';
import { ChangeStepContext, ChangeStepContextData, FieldSelectionStep } from '../index';
import { createArrayItem } from '../snippets';
import { listOfItemsSnippet } from '../snippets/array';
import { inject, observer } from 'mobx-react';
import { ContentDefinitionSchemaStoreModel } from '@stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import Button from '@components/Button';
import FieldTypeIcon from '../../../atoms/FieldTypeIcon';

const FormItem = Form.Item;

const labels = defineMessages({
    namePlaceholder: {
        id: 'contentDefinitions.snippet.field.name.placeholder',
        defaultMessage: 'Only use letters, numbers, and _ '
    },
    searchPlaceholder: {
        id: 'contentDefinitions.snippet.search.field.placeholder',
        defaultMessage: 'Search a field type'
    }
});

interface DefinitionFieldSelectionProps extends InjectedIntlProps, FormComponentProps {
    fieldSnippets: FieldDescriptor[];
    onSelect(arg: Field): any;
    onCheckNameAlreadyExist(value: string): boolean;
}

interface InjectedProps extends DefinitionFieldSelectionProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
}
type DefinitionFieldSelectionState = {
    snippets: FieldDescriptor[];
    searchText: string;
    selectedFamilies: ListItemFamily[];
};

function getInitialState(initialSnippets: FieldDescriptor[]): DefinitionFieldSelectionState {
    return {
        snippets: initialSnippets,
        searchText: '',
        selectedFamilies: [
            ListItemFamily.Custom,
            ListItemFamily.Media,
            ListItemFamily.Special,
            ListItemFamily.Text,
        ],
    };
}

@inject('contentDefinitionSchemaStore')
@observer
class DefinitionFieldSelection extends Component<DefinitionFieldSelectionProps, DefinitionFieldSelectionState> {
    public state = getInitialState(this.props.fieldSnippets);

    get injected() { return this.props as InjectedProps; }

    public filterItems(): void {
        const { fieldSnippets } = this.props;
        let selectedFamilies = this.state.selectedFamilies.slice();
        let newItems = getInitialState(fieldSnippets).snippets.slice().filter(item =>
            item.families.filter(
                currentFamily => selectedFamilies.indexOf(currentFamily) >= 0
            ).length > 0
        );

        if (this.state.searchText.length > 0) {
            let regexp = new RegExp(this.state.searchText, 'i');
            newItems = newItems.filter(i => regexp.test(i.name) || (i.description && regexp.test(i.description)));
        }

        this.setState({ snippets: newItems });
    }

    public createSearchHandler(): React.ChangeEventHandler<HTMLInputElement> {
        return event => this.setState({ searchText: event.target.value }, () => this.filterItems());
    }

    public isFamilySelected(family: ListItemFamily): boolean {
        return this.state.selectedFamilies.indexOf(family) >= 0;
    }

    public getFamilyColour(tag: ListItemFamily): string {
        if (!this.isFamilySelected(tag)) {
            return `#ccc`;
        }

        switch (tag) {
            case ListItemFamily.Custom:
                return '#45a098';
            case ListItemFamily.Media:
                return '#9100ff';
            case ListItemFamily.Special:
                return '#f50';
            case ListItemFamily.Text:
            default:
                return '#108ee9';
        }
    }

    public createSelectFamilyHandler(keepIsAllSelected: boolean, family: ListItemFamily): React.MouseEventHandler {
        return () => {
            const { fieldSnippets } = this.props;
            const initialSelectedFamilies = getInitialState(fieldSnippets).selectedFamilies;

            let selectedFamilies = this.state.selectedFamilies.slice();

            const allSelected = selectedFamilies.length === initialSelectedFamilies.length;

            if (!this.isFamilySelected(family)) {
                selectedFamilies.push(family);
            } else if (allSelected && keepIsAllSelected) {
                selectedFamilies = [family];
            } else {
                selectedFamilies.splice(selectedFamilies.indexOf(family), 1);
            }

            if (selectedFamilies.length === 0) {
                selectedFamilies = initialSelectedFamilies;
            }

            this.setState({ selectedFamilies }, () => this.filterItems());
        };
    }

    public createSelectedFieldSnippet(isArray: boolean, name: string, selectedField: FieldDescriptor) {
        const { lang } = this.injected.contentDefinitionSchemaStore.schema;
        if (isArray) {
            const arrayItemConstraint = createArrayItem(selectedField);
            return this.props.onSelect(listOfItemsSnippet(lang, name, arrayItemConstraint));
        } else {
            return this.props.onSelect(selectedField.snippet(lang, name));
        }
    }

    public onClickUseField = (selectedField: FieldDescriptor, context: ChangeStepContextData) => () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.createSelectedFieldSnippet(values['isArrayField'], values['fieldName'], selectedField);
                context.changeActiveStep(FieldSelectionStep.EditField);
            }
        });
    }

    public renderUseAction(props: FieldDescriptor) {
        return (
            <ChangeStepContext.Consumer>
                {context => (
                    <Button
                        disabled={!props.usable}
                        className={classNames.ContentDefinitionsSnippetsListButton}
                        onClick={this.onClickUseField(props, context)}
                        type="primary"
                    >
                        <Icon type="tool" theme="filled" />
                        {props.usable
                            ? <FormattedMessage
                                defaultMessage="Use field"
                                id="contentDefinitions.snippet.actions.action.useField"
                            />
                            : <FormattedMessage
                                defaultMessage="Coming soon"
                                id="contentDefinitions.snippet.actions.action.useFieldSoon"
                            />}
                    </Button>
                )}
            </ChangeStepContext.Consumer>
        );
    }

    public getAvatarText(text: string): string {
        return text.substr(0, 2);
    }

    public renderTag(family: ListItemFamily, key: number) {
        let description: React.ReactNode;

        switch (family) {
            case ListItemFamily.Custom:
                description = (
                    <FormattedMessage defaultMessage="Custom" id="contentDefinitions.snippet.actions.tags.custom" />
                );
                break;
            case ListItemFamily.Media:
                description = (
                    <FormattedMessage defaultMessage="Media" id="contentDefinitions.snippet.actions.tags.media" />
                );
                break;
            case ListItemFamily.Special:
                description = (
                    <FormattedMessage defaultMessage="Special" id="contentDefinitions.snippet.actions.tags.special" />
                );
                break;
            case ListItemFamily.Text:
            default:
                description = (
                    <FormattedMessage defaultMessage="Text" id="contentDefinitions.snippet.actions.tags.text" />
                );
                break;
        }

        return (
            <Tag color={this.getFamilyColour(family)} key={key} onClick={this.createSelectFamilyHandler(false, family)}>
                {description}
                <Icon theme="filled" type="close-circle" style={{ marginLeft: 8 }} />
            </Tag>
        );
    }

    render() {
        const {
            fieldSnippets,
            form: { getFieldDecorator },
            intl: { formatMessage }
        } = this.props;
        return (
            <Section
                header={<Row>
                    <Col xs={24} md={16}>
                        <FormItem
                            required={false}
                            colon={false}
                            label={<span>
                                <span style={{ paddingRight: 5 }}>
                                    <FormattedMessage
                                        id="contentDefinitions.definitionFieldSelection.fieldName"
                                        defaultMessage="Field name"
                                    />
                                </span>
                                <Tooltip
                                    placement="left"
                                    /* tslint:disable-next-line:max-line-length */
                                    title={
                                        <FormattedMessage
                                            id="contentDefinitions.definitionFieldSelection.fieldNameHint"
                                            // tslint:disable-next-line:max-line-length
                                            defaultMessage="This is the function name of your field that you can use to access its values ​​in content delivery."
                                        />
                                    }
                                >
                                    <Icon type="info-circle" />
                                </Tooltip>
                            </span>}
                        >
                            {getFieldDecorator('fieldName', {
                                initialValue: undefined,
                                rules: [{
                                    required: true,
                                    message: <FormattedMessage
                                        id="contentDefinitions.snippet.field.name.requiredMessage"
                                        defaultMessage="Define a name before proceeding."
                                    />
                                }, {
                                    pattern: /^[a-zA-Z0-9_]+$/,
                                    message: <FormattedMessage
                                        id="contentDefinitions.snippet.field.name.patternMessage"
                                        defaultMessage="Only use letters, numbers, and _ "
                                    />
                                }, {
                                    validator: (rule, value, callback) => {
                                        if (this.props.onCheckNameAlreadyExist(value)) {
                                            callback(rule.message);
                                        }
                                        callback();
                                    },
                                    message: <FormattedMessage
                                        id="contentDefinitions.snippet.field.name.alreadyExist"
                                        /* tslint:disable-next-line:max-line-length */
                                        defaultMessage="An other field with the same name already exists"
                                    />
                                }]
                            })(
                                <Input
                                    placeholder={formatMessage(labels.namePlaceholder)}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={24} md={{ offset: 2, span: 6 }}>
                        <FormItem
                            required={false}
                            colon={false}
                            label={<span>
                                <span style={{ paddingRight: 5 }}>
                                    <FormattedMessage
                                        defaultMessage="Allow multiple values"
                                        id="contentDefinitions.allowMultipleValues"
                                    />
                                </span>
                                <Tooltip
                                    placement="left"
                                    title={
                                        <FormattedMessage
                                            // tslint:disable-next-line:max-line-length
                                            defaultMessage="If you activate this, you can assign more than one value to this field."
                                            id="contentDefinitions.allowMultipleValues.hint"
                                        />
                                    }
                                >
                                    <Icon type="info-circle" />
                                </Tooltip>
                            </span>}
                        >
                            {getFieldDecorator('isArrayField', {
                                initialValue: false,
                                valuePropName: 'checked'
                            })(
                                <Switch
                                    unCheckedChildren={'No'}
                                    checkedChildren={'Yes'}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col xs={24} className={classNames.ContentDefinitionsSnippetsSearch}>
                        <Input
                            addonBefore={<Icon type="search" />}
                            allowClear={true}
                            onChange={this.createSearchHandler()}
                            value={this.state.searchText}
                            placeholder={formatMessage(labels.searchPlaceholder)}
                        />
                    </Col>
                    <Col xs={24} className={classNames.ContentDefinitionsSnippetsTagList}>
                        <div>
                            <Tag
                                color={this.getFamilyColour(ListItemFamily.Text)}
                                onClick={this.createSelectFamilyHandler(true, ListItemFamily.Text)}
                            >
                                <FormattedMessage
                                    defaultMessage="Text"
                                    id="contentDefinitions.snippet.actions.tags.text"
                                />
                            </Tag>
                            <Tag
                                color={this.getFamilyColour(ListItemFamily.Media)}
                                onClick={this.createSelectFamilyHandler(true, ListItemFamily.Media)}
                            >
                                <FormattedMessage
                                    defaultMessage="Media"
                                    id="contentDefinitions.snippet.actions.tags.media"
                                />
                            </Tag>
                            <Tag
                                color={this.getFamilyColour(ListItemFamily.Special)}
                                onClick={this.createSelectFamilyHandler(true, ListItemFamily.Special)}
                            >
                                <FormattedMessage
                                    defaultMessage="Special"
                                    id="contentDefinitions.snippet.actions.tags.special"
                                />
                            </Tag>
                            <Tag
                                color={this.getFamilyColour(ListItemFamily.Custom)}
                                onClick={this.createSelectFamilyHandler(true, ListItemFamily.Custom)}
                            >
                                <FormattedMessage
                                    defaultMessage="Custom"
                                    id="contentDefinitions.snippet.actions.tags.custom"
                                />
                            </Tag>
                            {
                                (this.state.selectedFamilies.length !== getInitialState(fieldSnippets)
                                    .selectedFamilies.length
                                    ||
                                    !!this.state.searchText
                                ) &&
                                <a
                                    onClick={() =>
                                        this.setState({
                                            snippets: getInitialState(fieldSnippets).snippets,
                                            selectedFamilies: getInitialState(fieldSnippets).selectedFamilies,
                                            searchText: '',
                                        })
                                    }
                                >
                                    <FormattedMessage
                                        defaultMessage="Reset"
                                        id="contentDefinitions.snippet.actions.tags.reset"
                                    />
                                </a>
                            }
                        </div>
                        <FormattedMessage
                            id="contentDefinitions.snippets.total.label"
                            /* tslint:disable-next-line:max-line-length */
                            defaultMessage="{count, number} {count, plural, one {field} other {fields}} in total"
                            values={{ count: this.state.snippets.length }}
                        />
                    </Col>
                </Row>}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.snippets}
                    renderItem={(item: FieldDescriptor) => {
                        return (
                            <List.Item actions={[this.renderUseAction(item)]}>
                                <List.Item.Meta
                                    avatar={<FieldTypeIcon icon={item.icon!} />}
                                    className={classNames.ContentDefinitionsSnippetsListMeta}
                                    description={item.description}
                                    title={item.name}
                                />
                                {
                                    item.families.map(this.renderTag.bind(this))
                                }
                            </List.Item>
                        );
                    }}
                />
            </Section>
        );
    }
}

export default injectIntl(Form.create<DefinitionFieldSelectionProps>()(DefinitionFieldSelection));
