import { observable, action, runInAction, IObservableArray } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    ListContentDefinitionsRequest,
    ListContentDefinitionsItem,
} from '@contentchef/contentchef-types';
import {
    ContentListFiltersStoreModel,
    PagedListOptions
} from './contentListFiltersStoreModel';
import { DefinitionOption, RepositoryOption } from '../../models/UI';
import { ListContentTagsRequest } from '@contentchef/contentchef-types';
import {
    ListContentRepositoriesItem
} from '@contentchef/contentchef-types';

class ContentListFiltersStore implements ContentListFiltersStoreModel {
    api: ContentChefClient;
    @observable pagedRepositoryListOptions: PagedListOptions<RepositoryOption> = {
        skip: 0,
        take: 0,
        listOptions: observable.array([]),
        total: 0
    };
    @observable contentDefinitionListOptions: PagedListOptions<DefinitionOption> = {
        skip: 0,
        take: 0,
        listOptions: observable.array([]),
        total: 0
    };
    @observable suggestedTags: IObservableArray<string> = observable.array([]);

    private static mapDefinitionsToOption(definitions: ListContentDefinitionsItem[]): DefinitionOption[] {
        return definitions.map(definition => (
            {
                id: definition.id, name: definition.name, mnemonicId: definition.mnemonicId
            }
        ));
    }

    private static mapRepositoriesToOption(repositories: ListContentRepositoriesItem[]) {
        return repositories.map(repository => (
            {
                id: repository.id, name: repository.name, color: repository.color, mnemonicId: repository.mnemonicId
            }
        ));
    }

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async setContentDefinitionOptions(name?: string) {
        const requestParams: ListContentDefinitionsRequest = {
            skip: 0,
            take: 50,
            filters: {
                name
            }
        };
        try {
            const response = await this.api.contentDefinitions.list(requestParams);
            runInAction(() => {
                this.contentDefinitionListOptions.skip = response.skip;
                this.contentDefinitionListOptions.take = response.take;
                this.contentDefinitionListOptions.total = response.total;
                this.contentDefinitionListOptions.listOptions
                    .replace(ContentListFiltersStore.mapDefinitionsToOption(response.items));
            });
        } catch (e) {
            console.log('Error occured when retrieving contentDefinitions', e);
        }
    }

    @action
    async setMoreContentDefinitionOptions() {
        const requestParams: ListContentDefinitionsRequest = {
            skip: this.contentDefinitionListOptions.skip + this.contentDefinitionListOptions.take,
            take: this.contentDefinitionListOptions.take,
            filters: {}
        };
        try {
            const response = await this.api.contentDefinitions.list(requestParams);
            runInAction(() => {
                this.contentDefinitionListOptions.skip = response.skip;
                this.contentDefinitionListOptions.take = response.take;
                this.contentDefinitionListOptions.total = response.total;
                const newItems = ContentListFiltersStore.mapDefinitionsToOption(response.items);
                this.contentDefinitionListOptions.listOptions =
                    observable.array(this.contentDefinitionListOptions.listOptions.concat(newItems));
            });
        } catch (e) {
            console.log('Error occured when retrieving contentDefinitions', e);
        }
    }

    @action
    async setSuggestedTags(name?: string) {
        const requestParams: ListContentTagsRequest = {
            filters: {
                name
            }
        };
        try {
            const response = await this.api.contentTags.listSuggestedTags(requestParams);
            runInAction(() => {
                this.suggestedTags.replace(response);
            });
        } catch (e) {
            console.log('Error occurred when retrieving suggestedTags', e);
        }
    }

    @action
    async setRepositoriesOption() {
        try {
            const response = await this.api.contentRepositories.list({ skip: 0, take: 50, filters: {} });
            runInAction(() => {
                this.pagedRepositoryListOptions.skip = response.skip;
                this.pagedRepositoryListOptions.take = response.take;
                this.pagedRepositoryListOptions.total = response.total;
                this.pagedRepositoryListOptions.listOptions
                    .replace(ContentListFiltersStore.mapRepositoriesToOption(response.items));
            });
        } catch (e) {
            console.log('Error occurred when retrieving repositories', e);
        }
    }

    @action
    async setMoreRepositoriesOptions() {
        try {
            const response = await this.api.contentRepositories.list({
                skip: this.pagedRepositoryListOptions.skip + this.pagedRepositoryListOptions.take,
                take: this.pagedRepositoryListOptions.take,
                filters: {}
            });
            runInAction(() => {
                this.pagedRepositoryListOptions.skip = response.skip;
                this.pagedRepositoryListOptions.take = response.take;
                this.pagedRepositoryListOptions.total = response.total;
                const newItems = ContentListFiltersStore.mapRepositoriesToOption(response.items);
                this.pagedRepositoryListOptions.listOptions =
                    observable.array(this.pagedRepositoryListOptions.listOptions.concat(newItems));
            });
        } catch (e) {
            console.log('Error occurred when retrieving repositories', e);
        }
    }
}

export default ContentListFiltersStore;
