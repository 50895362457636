import {
    CloudinaryVideoSchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import { SerializedField } from '../types';
import { ListItemIds } from '../../types';

export interface CloudinaryVideoConstraints {
    required: boolean;
}

export type SerializedCloudinaryVideoSchema = SerializedField<CloudinaryVideoConstraints>;

export function retrieveCloudinaryVideoEditingType() {
    return ListItemIds.TransformableVideo;
}

export function deserialize(serialized: SerializedCloudinaryVideoSchema): Field<CloudinaryVideoSchemaType> {
    return {
        constraints: {
            required: serialized.constraints.required!,
        },
        labels: serialized.labels,
        placeholder: Object.keys(serialized.placeholder).length > 0 ? serialized.placeholder : undefined,
        hint: Object.keys(serialized.hint).length > 0 ? serialized.hint : undefined,
        name: serialized.name,
        type: SchemaTypeIds.CLOUDINARY_VIDEO,
        extension: serialized.extension
    };
}

// tslint:disable-next-line:max-line-length
export function serialize(deserialized: Field<CloudinaryVideoSchemaType>, initialIndex: number): SerializedCloudinaryVideoSchema {
    return {
        constraints: {
            required: deserialized.constraints.required,
        },
        labels: deserialized.labels,
        hint: deserialized.hint || {},
        placeholder: deserialized.placeholder || {},
        name: deserialized.name,
        settings: {},
        type: deserialized.type,
        editingType: retrieveCloudinaryVideoEditingType(),
        initialIndex,
        extension: deserialized.extension
    };
}
