import { DynamicFieldTypes } from '../FormFields';
import { TextTypeMatcher, TextWithListOfValuesMatcher } from './FieldTextMatchers';
import { NumberTypeMatcher, NumberWithListOfValuesMatcher } from './FieldNumberMatchers';
import { BooleanTypeMatcher } from './FieldBooleanMatchers';
import { DateTypeMatcher } from './FieldDateMatchers';
import { LongTextTypeMatcher } from './FieldLongTextMatchers';
import { CloudinaryPublicIdTypeMatcher } from './FieldCloudinaryPublicIdMatchers';
import { CloudinaryImageTypeMatcher } from './FieldCloudinaryImageMatchers';
import { LinkedContentTypeMatcher } from './FieldLinkedContentMatchers';
import { OneLinkedContentOfTypeMatcher } from './FieldOneLinkedContentOfMatchers';
import { CloudinaryVideoTypeMatcher } from './FieldCloudinaryVideoMatcher';
import { CloudinaryRawTypeMatcher } from './FieldCloudinaryRawMatchers';

export interface FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): null | JSX.Element;
}

export interface FormFieldMatchersFactory {
    maybeCreateComponent: (
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes | null
    ) => null | JSX.Element;
}

export class DefaultFormFieldMatchersFactory implements FormFieldMatchersFactory {
    private static instance: DefaultFormFieldMatchersFactory;
    private defaultMatchers: FieldMatcher[] = [
        new TextWithListOfValuesMatcher(),
        new TextTypeMatcher(),
        new NumberWithListOfValuesMatcher(),
        new NumberTypeMatcher(),
        new BooleanTypeMatcher(),
        new DateTypeMatcher(),
        new LongTextTypeMatcher(),
        new CloudinaryPublicIdTypeMatcher(),
        new CloudinaryImageTypeMatcher(),
        new CloudinaryVideoTypeMatcher(),
        new CloudinaryRawTypeMatcher(),
        new LinkedContentTypeMatcher(),
        new OneLinkedContentOfTypeMatcher(),
    ];

    public static getInstance(): FormFieldMatchersFactory {
        if (!DefaultFormFieldMatchersFactory.instance) {
            DefaultFormFieldMatchersFactory.instance = new DefaultFormFieldMatchersFactory();
        }
        return DefaultFormFieldMatchersFactory.instance;
    }

    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes | null
    ): JSX.Element | null {
        if (field === null) {
            return null;
        }
        return this.maybeFindMatch(id, userLocale, onChange, field);
    }

    private maybeFindMatch(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        let maybeComponent: JSX.Element | null = null;
        let i = 0;
        while (i < this.defaultMatchers.length && !maybeComponent) {
            maybeComponent = this.defaultMatchers[i].maybeCreateComponent(id, userLocale, onChange, field);
            i++;
        }
        return maybeComponent;
    }

    private constructor() { }
}
