import React, { Component } from 'react';
import { FieldChannelsSelectorProps, FieldChannelsSelectorState, InjectedProps } from './FieldChannelsSelectorModel';
import { inject, observer } from 'mobx-react';
import { ChannelCheckbox } from '../../index';
import { PublishingChannelResponse } from '@contentchef/contentchef-types';
import { Row } from 'antd';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import { withArchivedStatus } from '../../../providers/ArchivedContentProvider';

@inject('channelsStore')
@observer
class FieldChannelsSelector extends Component<FieldChannelsSelectorProps, FieldChannelsSelectorState> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        const { contentCreationFlow, fieldChannels } = this.props;
        const { channels } = this.injected.channelsStore;
        if (contentCreationFlow && fieldChannels.value.length === 0) {
            channels.map((channel) => {
                fieldChannels.setFieldValue(true, channel);
            });
        }
        if (!contentCreationFlow) {
            this.injected.channelsStore.filterOutNonActiveArchivedChannels(fieldChannels.value);
        }
    }

    onChangeSelectedChannels = (checked: boolean, channel: PublishingChannelResponse) => {
        this.props.fieldChannels.setFieldValue(checked, channel);
    }

    render() {
        const { fieldChannels, hasPermissions, archived } = this.props;
        const { channels } = this.injected.channelsStore;
        return (
            <Row>{
                channels.map((channel, index) => (
                    <ChannelCheckbox
                        key={index}
                        disabled={archived ? archived : !hasPermissions}
                        isChecked={!!fieldChannels.value.find(
                            selectedChannel => selectedChannel.id === channel.id
                        )}
                        channel={channel}
                        onChangeHandler={this.onChangeSelectedChannels}
                    />
                ))
            }</Row>
        );
    }
}

export default withArchivedStatus(withPermissionsToInteract(FieldChannelsSelector));
