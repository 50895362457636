import { defineMessages } from 'react-intl';

export const envRelatedLabels = defineMessages({
    notSynchronized: {
        id: 'Environment.status.notSynchronizedLabel',
        defaultMessage: 'Current version is not available in all environments'
    },
    synchronized: {
        id: 'Environment.status.synchronizedLabel',
        defaultMessage: 'Latest version is available in all environments'
    },
    versions: {
        id: 'Environment.status.versionsLabel',
        defaultMessage: 'Versions'
    },
    neverPublished: {
        id: 'Environment.status.neverPublishedLabel',
        defaultMessage: 'This content has never been published'
    },
    neverPublishedVersion: {
        id: 'Environment.status.neverPublishedVersionLabel',
        defaultMessage: 'This version has never been published'
    },
    shortNeverPublishedVersion: {
        id: 'Environment.status.shortNeverPublishedVersionLabel',
        defaultMessage: 'Not published'
    },
    environment: {
        id: 'Environment.status.environmentLabel',
        defaultMessage: 'Environment'
    },
    channels: {
        id: 'Environment.status.channelsLabel',
        defaultMessage: 'Channels'
    },
    version: {
        id: 'Environment.status.versionLabel',
        defaultMessage: 'Version'
    }
});
