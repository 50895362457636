import { observable, action, runInAction, computed } from 'mobx';
import { LoaderManagerStoreModel } from './loaderManagerStoreModel';

class LoaderManagerStore implements LoaderManagerStoreModel {
    @observable showLoader: boolean;

    @action
    setLoader() {
        runInAction(() => {
            this.showLoader = true;
        });
    }

    @action
    unsetLoader() {
        runInAction(() => {
            this.showLoader = false;
        });
    }

    @computed
    get mustShowLoader(): boolean {
        return this.showLoader === undefined || this.showLoader;
    }
}

export default LoaderManagerStore;
