import { observable, computed, action, reaction, IObservableArray } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ContentId,
    UpdateContentRequest, ContentResponse, LinkingContents, ToggleArchivedContentRequest
} from '@contentchef/contentchef-types';
import {
    EditContentStoreModel,
    EditContentDataModel,
    EditContentStoreDependencies,
} from './editContentStoreModel';
import { ChannelsStoreModel } from '../channelsStore/channelsStoreModel';
import { FormMetaStoreModel } from '../formMetaStore/formMetaStoreModel';
import { ListPublicIdStoreModel } from '../listPublicIdStore/listPublicIdStoreModel';
import { PublishingStoreModel } from '../publishingStore/publishingStoreModel';
import { ContentStoreModel } from '../contentStore/contentStoreModel';
import { RepositoryListStoreModel } from '../repositoryListStore/repositoryListStoreModel';
import {
    ContentErrorFactory,
    ContentValidationError,
    ContentErrorTypes
} from '../../services/Error/ContentErrorManager';
import { ExtensionManager } from '@stores/extensionManager';

class EditContentStore implements EditContentStoreModel {
    api: ContentChefClient;
    repositoryListStore: RepositoryListStoreModel;
    channelsStore: ChannelsStoreModel;
    formMetaStore: FormMetaStoreModel;
    contentStore: ContentStoreModel;
    listPublicIdStore: ListPublicIdStoreModel;
    publishingStore: PublishingStoreModel;
    extensionManager: ExtensionManager;

    @observable editContentData: EditContentDataModel = {
        initialContentData: undefined,
        modifiedContentData: false
    };

    constructor(api: ContentChefClient, storeDependencies: EditContentStoreDependencies) {
        this.api = api;
        Object.assign(this, storeDependencies);

        reaction(
            () => this.formMetaStore.changedFields.length,
            length => this.editContentData.modifiedContentData = length > 0
        );
    }

    @action
    async initializeEditContent(contentId: string) {
        try {
            await Promise.all([
                this.contentStore.setContentById(contentId),
                this.channelsStore.setAllChannels(),
                this.extensionManager.setSpaceExtensions()
            ]);
            const { selectedContent, linkingContents } = this.contentStore;
            if (selectedContent) {
                await Promise.all([
                    this.repositoryListStore.setRepositories(),
                    this.listPublicIdStore.initialize(selectedContent.definition.id, selectedContent.publicId),
                    this.publishingStore.startPollingPublishingRequests(selectedContent.id, selectedContent.version)
                ]);
                this.initFormMetaStoreAndButtonsState(selectedContent, linkingContents);
            }
        } catch (e) {
            console.log('Impossible to initialize edit form', e);
        }
    }

    @action
    async updateContent() {
        const updateContentData = this.createUpdateContentDate();
        if (updateContentData) {
            try {
                const response = await this.contentStore.updateContent(updateContentData);
                // TODO: REFACTOR AFTER THAT BACKEND CALL WILL RETURN THE UPDATED CONTENT FULLY RESOLVE;
                try {
                    await this.contentStore.setContentById(response.id.toString());
                    const { selectedContent, linkingContents } = this.contentStore;
                    if (selectedContent) {
                        this.publishingStore.clearPollingPublishingRequests();
                        await this.listPublicIdStore.initialize(
                            selectedContent.definition.id, selectedContent.publicId
                        );
                        await this.publishingStore.startPollingPublishingRequests(
                            selectedContent.id, selectedContent.version
                        );
                        this.initFormMetaStoreAndButtonsState(selectedContent, linkingContents);
                        return Promise.resolve();
                    }
                } catch (e) {
                    console.log('Something went wrong while trying retrieve the new content');

                    return Promise.reject(ContentErrorFactory.createContentError(e));
                }
            } catch (error) {
                error.maybeHandleViolations();
                return Promise.reject(error);
            }
        } else {
            console.log('Something went wrong while trying to update the content');
            return Promise.reject(ContentErrorFactory.createContentError(
                { type: ContentErrorTypes.UnexpectedError } as ContentValidationError
            ));
        }
    }

    async createPublishingRequest(contentId: ContentId, version: number) {
        await this.publishingStore.createPublishingRequest(contentId, version);
    }

    @computed
    get isContentModified() {
        return !this.editContentData.modifiedContentData;
    }

    @computed
    get isContentPublishable() {
        return this.formMetaStore.formMeta.channels.value.length > 0
            ? this.editContentData.modifiedContentData
            : true;
    }

    async toggleArchiveContent(archive: boolean) {
        if (this.contentStore.selectedContent) {
            const reqParams: ToggleArchivedContentRequest = {
                id: this.contentStore.selectedContent.id,
                archived: archive
            };

            try {
                await this.contentStore.toggleArchivedContent(reqParams);
                await this.initializeEditContent(this.contentStore.selectedContent.id.toString());
            } catch (e) {
                console.log(e);
            }
        }
    }

    private createUpdateContentDate(): UpdateContentRequest | undefined {
        const { initialContentData } = this.editContentData;
        if (initialContentData) {
            const { onlineDate, offlineDate, ...others } = this.formMetaStore.getCommonFieldsValue();
            const payloadValues = this.formMetaStore.getDynamicFieldValues();
            return {
                id: initialContentData.id,
                publicId: others.publicId,
                name: others.name,
                tags: others.tags,
                repositoryId: others.repository,
                channelIds: others.channels,
                payload: {
                    ...payloadValues
                },
                definitionId: initialContentData.definition.id,
                onlineDate,
                offlineDate
            } as UpdateContentRequest;
        }
        return undefined;
    }

    @action
    private initFormMetaStoreAndButtonsState(
        selectedContent: ContentResponse,
        linkedContent?: IObservableArray<LinkingContents>
    ) {
        this.formMetaStore.initializeStore(selectedContent.definition, selectedContent);
        this.editContentData.initialContentData = selectedContent;
        this.editContentData.linkedContent = linkedContent;
        this.editContentData.modifiedContentData = this.formMetaStore.changedFields.length > 0;
    }
}

export default EditContentStore;
