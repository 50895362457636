import { action, observable, runInAction } from 'mobx';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import { ContentsStatus, ListContentsRequest } from '@contentchef/contentchef-types';
import { SearchParamsStore } from '..';
import {
    ContentListDataModel,
    ContentListStoreModel,
    ContentListUrlParams,
    ContentsListLinkedModel,
    LinkableContentsFilters
} from './contentListStoreModel';
import { complexStringToArrayOfIds, stringToArrayOfString } from '../../services/urlSearchParamsHelper';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';

class ContentListStore implements ContentListStoreModel {
    api: ContentChefClient;
    searchParams: SearchParamsStoreModel = {} as SearchParamsStoreModel;

    @observable linkableContents: ContentsListLinkedModel = {
        items: observable.array([]),
        total: 0
    };
    @observable linkedContents: ContentsListLinkedModel = {
        items: observable.array([]),
        total: 0
    };

    @observable contentListData: ContentListDataModel = {
        contentsByRepo: observable.array([]),
        total: 0
    };

    @observable error = {
        contentsForRepo: '',
        linkedContentList: '',
        linkableContentsList: '',
        count: ''
    };

    constructor(api: ContentChefClient) {
        this.api = api;
        this.searchParams = new SearchParamsStore();
    }

    @action
    async initContentList() {
        await this.setContentsByRepo();
    }

    @action
    async setContentsByRepo() {
        const usableSearchParams = this.searchParams.usableSearchParams;
        const urlSearchParams = this.searchParams.urlSearchParams;
        const reqParams: ListContentsRequest = {
            skip: (+usableSearchParams.page - 1 || 0) * (+usableSearchParams.size || 10),
            take: +usableSearchParams.size || 10,
            filters: {
                name: usableSearchParams[ContentListUrlParams.byNameAndPublicId],
                repositoryIds: (
                    usableSearchParams[ContentListUrlParams.repositoryId]
                    && complexStringToArrayOfIds(usableSearchParams[ContentListUrlParams.repositoryId])
                ) || undefined,
                contentDefinitionIds: (
                    usableSearchParams[ContentListUrlParams.definitionId]
                    && complexStringToArrayOfIds(usableSearchParams[ContentListUrlParams.definitionId])
                ) || undefined,
                tags: (
                    usableSearchParams[ContentListUrlParams.tags]
                    && stringToArrayOfString(usableSearchParams[ContentListUrlParams.tags])
                ) || undefined,
                contentsStatus: urlSearchParams.has(ContentListUrlParams.contentStatus)
                    ? urlSearchParams.get(ContentListUrlParams.contentStatus)! as ContentsStatus
                    : ContentsStatus.unarchived
            }
        };
        try {
            const response = await this.api.contents.list(reqParams);
            runInAction(() => {
                this.contentListData.contentsByRepo.replace(response.items);
                this.contentListData.total = response.total;
            });
        } catch (e) {
            runInAction(() => {
                this.error.contentsForRepo = e;
                console.log(e, 'Impossible to call contents.list');
            });
        }
    }

    @action
    async setLinkableContents(filters: LinkableContentsFilters, skip?: number, take?: number) {
        const reqParams: ListContentsRequest = {
            skip: skip ? skip : 0,
            take: take ? take : 50,
            filters: {
                ...filters,
                contentsStatus: ContentsStatus.unarchived
            }
        };
        try {
            const response = await this.api.contents.list(reqParams);
            runInAction(() => {
                this.linkableContents.total = response.total;
                this.linkableContents.items.replace(response.items);
            });
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.error.linkableContentsList = e;
                console.log('impossibile to call listLinkables', e);
            });
        }
    }

    @action
    async setLinkedContents(filters: LinkableContentsFilters, skip?: number, take?: number) {
        const reqParams: ListContentsRequest = {
            skip: skip ? skip : 0,
            take: take ? take : 50,
            filters: {
                ...filters,
                contentsStatus: ContentsStatus.unarchived
            }
        };
        try {
            const response = await this.api.contents.list(reqParams);
            runInAction(() => {
                this.linkedContents.total = response.total;
                this.linkedContents.items.replace(response.items);
            });
        } catch (e) {
            console.log(e);
            runInAction(() => {
                this.error.linkedContentList = e;
                console.log('impossibile to call listLinkables', e);
            });
        }
    }
}

export default ContentListStore;
