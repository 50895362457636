import React, { Component } from 'react';
import { Row, Col, ConfigProvider, Drawer } from 'antd';
import { ContainerWithAsideProps, ContainerWithAsideState } from './ContainerWithAsideModel';
import Button from '@components/Button';

import styles from './ContainerWithAside.module.scss';

class ContainerWithAside extends Component<ContainerWithAsideProps, ContainerWithAsideState> {
    state = {
        openAside: false
    };

    showAside = () => {
        this.setState({ openAside: true });
    }

    hideAside = () => {
        this.setState({ openAside: false });
    }
    render() {
        const {
            children,
            drawerIcon = 'menu-fold',
            footer,
            renderAside,
            dataAttributes,
            disableContent
        } = this.props;
        return (
            <Row
                {...dataAttributes}
                type="flex"
                justify="center"
                className={
                    `${styles.ContainerWithAsideContainer} 
                    ${disableContent
                        ? styles.ContainerWithAsideContentDisabled
                        : ''
                    }`}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={19}
                    xl={20}
                    className={styles.ContainerWithAsideScrollableContent}
                    id="scrollable-content"
                >
                    <ConfigProvider
                        getPopupContainer={() => document.getElementById('scrollable-content') as HTMLDivElement}
                    >
                        <Row className={styles.ContainerWithAsideContentRowWrapper}>
                            <Col xs={24} style={{ height: footer ? '90%' : '100%' }}>
                                {children}
                            </Col>
                            {footer && <Col xs={24} style={{ height: '10%', display: 'flex', alignItems: 'center' }}>
                                {footer}
                            </Col>}
                        </Row>
                    </ConfigProvider>
                </Col>
                <Col id="scrollable-aside" xs={0} sm={0} md={8} lg={5} xl={4}>
                    <ConfigProvider
                        getPopupContainer={() => document.getElementById('scrollable-aside') as HTMLDivElement}
                    >
                        {renderAside()}
                    </ConfigProvider>
                </Col>
                {!this.state.openAside &&
                    <Button
                        className={styles.ContainerWithAsideDrawerButton}
                        type="primary"
                        icon={drawerIcon}
                        onClick={this.showAside}
                    />
                }
                <Drawer
                    className={styles.ContainerWithAsideDrawer}
                    closable={false}
                    visible={this.state.openAside}
                    onClose={this.hideAside}
                >
                    {renderAside()}
                </Drawer>
            </Row>
        );
    }
}

export default ContainerWithAside;
