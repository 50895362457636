import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    PublishingChannelResponse,
    CreatePublishingChannelRequest,
    PublishingChannelId,
    UpdatePublishingChannelRequest
} from '@contentchef/contentchef-types';
import {
    IObservableArray,
    action,
    observable,
    runInAction
} from 'mobx';
import { ChannelsStoreModel } from './channelsStoreModel';
import { routerStoreHelper } from '..';
import {
    editPublishingChannelPath
} from '../../constants/routing-constants';
import { SearchParamsStoreModel } from '@stores/searchParamsStore/searchParamsStoreModel';
import SearchParamsStore from '@stores/searchParamsStore/searchParamsStore';

export enum ChannelsUrlParams {
    name = 'name',
    archived = 'archived'
}

class ChannelsStore implements ChannelsStoreModel {
    api: ContentChefClient;
    searchParams: SearchParamsStoreModel = {} as SearchParamsStoreModel;
    @observable channels: IObservableArray<PublishingChannelResponse> = observable.array([]);
    @observable isLoading: boolean = false;
    @observable selectedChannels: IObservableArray<number> = observable.array([]);
    @observable channel: PublishingChannelResponse;
    archivedChannels: PublishingChannelResponse[] = [];
    availableChannels: PublishingChannelResponse[] = [];

    @observable
    error = {
        channels: ''
    };

    constructor(api: ContentChefClient) {
        this.api = api;
        this.searchParams = new SearchParamsStore();
    }

    @action
    async setChannels() {
        this.isLoading = true;
        this.channels.clear();
        try {
            const response = await this.api.channels.list({
                filters: {
                    name: this.searchParams.usableSearchParams.name,
                    archived: this.searchParams.usableSearchParams.archived === 'true'
                }
            });
            runInAction(() => {
                this.channels.replace(response);
                this.isLoading = false;
            });
        } catch (e) {
            runInAction(() => {
                this.error.channels = e;
                this.isLoading = false;
                console.log(e, 'Impossible to call getChannels');
            });
        }
    }

    @action
    async createChannel(channel: CreatePublishingChannelRequest) {
        const response = await this.api.channels.save(channel);
        if (response) {
            routerStoreHelper.routerStore.history.push(editPublishingChannelPath(await this.api.spaceId, response.id));
        }
    }

    @action
    async setChannel(id: PublishingChannelId) {
        try {
            const result = await this.api.channels.get({ id });
            runInAction(() => {
                this.channel = result;
            });
        } catch (error) {
            console.log(`Error while retriving PublishingChannel with id ${id}`, error);
        }
    }

    @action
    async updateChannel(channel: UpdatePublishingChannelRequest) {
        const requestParams = {
            ...channel,
            id: this.channel.id
        };
        const response = await this.api.channels.update(requestParams);
        if (response) {
            runInAction(() => {
                this.channel = response;
            });
        }
    }

    @action.bound
    async toggleArchived(shouldArchive: boolean) {
        try {
            const response = await this.api.channels.toggleArchived({ id: this.channel.id, archived: shouldArchive });
            runInAction(() => {
                this.channel = response;
            });
        } catch (error) {
            console.error(`Error while archiving/unarchiving publishing channel`);
        }
    }

    @action.bound
    async setAllChannels() {
        try {
            const availableChannels = await this.api.channels.list({ filters: { archived: false } });
            const archivedChannels = await this.api.channels.list({ filters: { archived: true } });
            const totalChannels = availableChannels.concat(archivedChannels).sort((channelA, channelB) => {
                if (channelA.mnemonicId > channelB.mnemonicId) {
                    return 1;
                }
                if (channelA.mnemonicId < channelB.mnemonicId) {
                    return -1;
                }
                return 0;
            });

            runInAction(() => {
                this.availableChannels = availableChannels;
                this.archivedChannels = archivedChannels;
                this.channels.replace(totalChannels);
            });
        } catch (error) {
            console.error(`Failed to set all channels`);
            console.error(error);
        }
    }

    @action.bound
    filterOutNonActiveArchivedChannels = (activeChannels: PublishingChannelResponse[]) => {
        const activeArchivedChannels = this.archivedChannels.filter(channel => {
            return channel.archived && !!activeChannels.find(selectedChannel => selectedChannel.id === channel.id);
        });

        this.channels.replace(this.availableChannels.concat(activeArchivedChannels));

        return this.channels;
    }

}

export default ChannelsStore;
