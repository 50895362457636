import React, { Component } from 'react';
import { MediaGalleryListItemActionsProps } from './MediaGalleryListItemActionsModel';
import { withRouter } from 'react-router';
import { Button } from '../';
import { FormattedMessage, defineMessages } from 'react-intl';

import styles from './MediaGalleryListItemActions.module.scss';
import { browseMediaGalleryPath, editMediaGalleryPath } from '../../constants/routing-constants';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import ActionList, { ActionDef } from '../atoms/ActionList';

const labels = defineMessages({
    browse: {
        id: 'MediaGalleryListItemActions.Browse',
        defaultMessage: 'Browse'
    },
    view: {
        id: 'MediaGalleryListItemActions.View',
        defaultMessage: 'View'
    },
    edit: {
        id: 'MediaGalleryListItemActions.Edit',
        defaultMessage: 'Edit'
    }
});

class MediaGalleryListItemActions extends Component<MediaGalleryListItemActionsProps, {}> {
    onClickPreventDefault = (e) => {
        e.preventDefault();
    }

    onEditMediaGallery = () => {
        const { match: { params: { spaceId } }, mediaGallery } = this.props;
        return editMediaGalleryPath(spaceId, mediaGallery.id);
    }

    onBrowseMediaGallery = () => {
        const { match: { params: { spaceId } }, mediaGallery } = this.props;
        return browseMediaGalleryPath(spaceId, mediaGallery.id);
    }

    overrideBrowseMediaGallery = () => {
        const { mediaGallery, overrideBrowse } = this.props;
        overrideBrowse!(mediaGallery);
    }

    createBrowseButton = () => {
        return (
            <Button
                className={styles.MediaGalleryListItemActionsButton}
                type={'primary'}
            >
                <FormattedMessage
                    id={'MediaGalleryListItemActions.Browse'}
                    defaultMessage={'Browse'}
                />
            </Button>
        );
    }

    render() {
        const { overrideBrowse, hasPermissions } = this.props;

        const actions = [
            {
                linkHandler: this.onBrowseMediaGallery,
                clickHandler: overrideBrowse ? this.overrideBrowseMediaGallery : undefined,
                title: labels.browse,
                icon: 'folder-open',
                type: 'primary',
            }
        ] as ActionDef[];

        const viewAction = {
            linkHandler: this.onEditMediaGallery,
            title: labels.view,
            icon: 'edit',
            type: 'default',
        } as ActionDef;

        const editAction = {
            linkHandler: this.onEditMediaGallery,
            title: labels.edit,
            icon: 'edit',
            type: 'default',
        } as ActionDef;

        if (!overrideBrowse) {
            actions.push(hasPermissions ? editAction : viewAction);
        }

        return (
            <ActionList actions={actions} />
        );

    }
}

export default withPermissionsToInteract(withRouter(MediaGalleryListItemActions));
