import React, { Component } from 'react';
import { List } from 'antd';
import { ReleaseItem } from '..';
import { ReleaseItemListProps, InjectedProps } from './ReleaseItemListModel';
import { observer, inject } from 'mobx-react';

@inject('publishToLiveStore')
@observer
class ReleaseItemList extends Component<ReleaseItemListProps> {
    get injected() {
        return this.props as InjectedProps;
    }
    render() {
        const { loaders: { publish, init }, releaseItems } = this.injected.publishToLiveStore;
        return (
            <List
                loading={publish || init}
                itemLayout="horizontal"
                dataSource={releaseItems}
                renderItem={item => (
                    <ReleaseItem releaseItem={item} />
                )}
            />
        );
    }
}

export default ReleaseItemList;
