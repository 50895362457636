import { Icon } from 'antd';
import React from 'react';
import { IconProps } from 'antd/lib/icon';

// tslint:disable-next-line:max-line-length
const TheIcon: React.FunctionComponent<{ title?: string, icon?: string, component?: IconProps['component'] }> = (props) => {
    return (
        <Icon
            title={props.title}
            type={props.icon}
            component={props.component}
            style={{ fontSize: '18px', color: '#08c' }}
        />
    );
};

export default TheIcon;
