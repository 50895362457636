import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    SubjectActions, ListAuditLogItem, SubjectTypes
} from '@contentchef/contentchef-types';
import { Col, List, Row } from 'antd';
import { defineMessages, injectIntl } from 'react-intl';
import { LatestActivitiesProps } from './LatestActivitiesModel';
import { ActivityPanel, Section } from '../';
import {
    editContentDefinitionPath,
    editContentPath,
    editMediaGalleryPath,
    editPublishingChannelPath, editRepositoryPath
} from '../../constants/routing-constants';

import styles from './LatestActivities.module.scss';

const latestActivitiesLabel = defineMessages({
    userLatestActivitiesLabel: {
        id: 'LatestActivities.userLatestActivitiesLabel',
        defaultMessage: 'Your recent activities'
    },
    teamLatestActivitiesLabel: {
        id: 'LatestActivities.teamLatestActivitiesLabel',
        defaultMessage: 'Your teammates\' activities'
    },
    emptyUserActivity: {
        id: 'LatestActivities.emptyUserActivity',
        defaultMessage: 'You did not perform any operation'
    },
    emptyTeamActivity: {
        id: 'LatestActivities.emptyTeamActivity',
        defaultMessage: 'Your team did not perform any recent operation'
    }
});

class LatestActivities extends Component<LatestActivitiesProps> {
    createActivityActionUrl = (item: ListAuditLogItem) => {
        const { spaceId } = this.props.match.params;
        switch (item.subjectType) {
            case (SubjectTypes.content):
                return editContentPath(spaceId, item.subjectId);
            case (SubjectTypes.contentDefinition):
                return editContentDefinitionPath(spaceId, item.subjectId);
            case (SubjectTypes.mediaGallery):
                return editMediaGalleryPath(spaceId, item.subjectId);
            case (SubjectTypes.publishingChannel):
                return editPublishingChannelPath(spaceId, item.subjectId);
            case (SubjectTypes.repository):
                return editRepositoryPath(spaceId, item.subjectId);
            default:
                return undefined;
        }
    }

    createActivityActionLink = (item) => {
        switch (item.subjectAction) {
            case (SubjectActions.create):
            case (SubjectActions.update):
                return this.createActivityActionUrl(item);
            case (SubjectActions.archive):
            default:
                return undefined;
        }
    }

    render() {
        const { userActivity, teamActivity, intl: { formatMessage } } = this.props;
        return (
            <Row justify="space-between" className={styles.LatestActivitiesContainer}>
                <Col xs={24} lg={12}>
                    <Section title={formatMessage(latestActivitiesLabel.userLatestActivitiesLabel)}>
                        <List
                            className={styles.LatestActivitiesListContainer}
                            dataSource={userActivity}
                            renderItem={item => (
                                <ActivityPanel
                                    key={item.id}
                                    activity={item}
                                    activityLink={this.createActivityActionLink(item)}
                                />
                            )}
                        />
                    </Section>
                </Col>
                <Col xs={24} lg={12}>
                    <Section title={formatMessage(latestActivitiesLabel.teamLatestActivitiesLabel)}>
                        <List
                            className={styles.LatestActivitiesListContainer}
                            dataSource={teamActivity}
                            renderItem={item => (
                                <ActivityPanel
                                    key={item.id}
                                    activity={item}
                                    activityLink={this.createActivityActionLink(item)}
                                />
                            )}
                        />
                    </Section>
                </Col>
            </Row>
        );
    }
}

export default withRouter(injectIntl(LatestActivities));
