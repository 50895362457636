import { SchemaTypeIds } from '@contentchef/contentchef-types';
import React from 'react';
import { LongTextEditor } from '../../../components';
import { DynamicFieldTypes, FieldLongText } from '../FormFields';
import { defaultFieldWrapper } from './DefaultFieldWrapper';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';

export class LongTextTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.LONG_TEXT)) { return null; }
        const fieldLongText = field as FieldLongText;

        function elementToRender(disabled: boolean) {
            return (
                <LongTextEditor
                    id={id}
                    fieldLongText={fieldLongText}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
