import React, { ChangeEvent, Component } from 'react';
import { ContentNameFieldProps, ContentNameFieldState, InjectedProps } from './ContentNameFieldModel';
import { Input } from 'antd';
import { withPermissionsToInteract } from '../../providers/UserPermissionsProvider';
import { withArchivedStatus } from '../../providers/ArchivedContentProvider';

class ContentNameField extends Component<ContentNameFieldProps, ContentNameFieldState> {
    get injected() {
        return this.props as InjectedProps;
    }

    customOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.nameField.setFieldValue(e.target.value);
        return this.injected.onChange(e.target.value);
    }

    onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = this.props.nameField.trimValue(e.target.value);
        return this.injected.onChange(trimmedValue);
    }

    render() {
        const { nameField, hasPermissions, archived, ...others } = this.props;
        return (
            <Input
                {...others}
                disabled={archived ? archived : !hasPermissions}
                type="text"
                value={nameField.value}
                onChange={this.customOnChange}
                onBlur={this.onBlur}
            />
        );
    }
}

export default withArchivedStatus(withPermissionsToInteract(ContentNameField));
