import { PublishToLiveStoreModel, ReleaseItemWithRepository } from './publishToLiveStoreModel';
import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import {
    ReleaseId,
    Release,
    ReleaseItem
} from '@contentchef/contentchef-types';
import { runInAction, action, observable, IObservableArray } from 'mobx';
import {
    ListContentRepositoriesItem
} from '@contentchef/contentchef-types';

class PublishToLiveStore implements PublishToLiveStoreModel {
    api: ContentChefClient;
    @observable releaseId: ReleaseId;
    @observable release: Release;
    @observable releaseItems: IObservableArray<ReleaseItemWithRepository> = observable.array([]);
    @observable releaseItemsToPromote: IObservableArray<ReleaseItem> = observable.array([]);
    @observable loaders = {
        init: false,
        publish: false
    };

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async init() {
        try {
            this.loaders.init = true;
            const [archivedRepositoryList, unarchivedRepositoryList, release] = await Promise.all(
                [
                    this.api.contentRepositories.list({ skip: 0, take: 100, filters: { archived: true } }),
                    this.api.contentRepositories.list({ skip: 0, take: 100, filters: { archived: false } }),
                    this.api.publishedContents.createRelease({})
                ]
            );
            const allRepositoryList = archivedRepositoryList.items.concat(unarchivedRepositoryList.items);

            runInAction(() => {
                this.clearRelease();
                this.release = release;
                this.releaseId = release.id;
                this.releaseItems.replace(this.releaseItemsWithRepository(release.releaseItems, allRepositoryList));
                this.loaders.init = false;
            });
        } catch (e) {
            console.log('Error during publishToLiveStore initialization');
            this.loaders.init = false;
        }
    }

    @action
    async addReleaseItem(releaseItem: ReleaseItem) {
        this.releaseItemsToPromote.push(releaseItem);
    }

    @action
    async removeReleaseItem(releaseItem: ReleaseItem) {
        this.releaseItemsToPromote.remove(releaseItem);
    }

    @action
    async publishToLive(): Promise<boolean> {
        try {
            this.loaders.publish = true;
            const releaseItemsIdToPromote = this.releaseItemsToPromote.map(releaseItem => releaseItem.id);
            await this.api.publishedContents.updateRelease({
                releaseId: this.releaseId,
                itemsToPromote: releaseItemsIdToPromote
            });
            const success = await this.api.publishedContents.setStageContentsToLive({
                releaseId: this.releaseId
            });
            runInAction(() => {
                this.clearRelease();
                this.loaders.publish = false;
                this.init();

            });
            return success as boolean;

        } catch (e) {
            runInAction(() => {
                this.loaders.publish = false;
            });
            console.log('Error during updateRelease', e);
            return Promise.reject(e);
        }
    }

    releaseItemsWithRepository(
        releaseItems: ReleaseItem[],
        repositoryList: ListContentRepositoriesItem[]): ReleaseItemWithRepository[] {
        return releaseItems.map(releaseItem => {
            const repository =
                repositoryList.find(repositoryItem => repositoryItem.id === releaseItem.contentRepositoryId) || {};
            return {
                ...releaseItem,
                repository
            } as ReleaseItemWithRepository;
        });
    }

    isReleaseItemSelected(releaseItem: ReleaseItem): boolean {
        return this.releaseItemsToPromote.includes(releaseItem);
    }

    @action
    selectAllItems() {
        this.releaseItemsToPromote.replace(this.releaseItems);
    }

    @action
    private clearRelease() {
        this.release = {} as Release;
        this.releaseItems.replace([]);
        this.releaseItemsToPromote.replace([]);
    }
}

export default PublishToLiveStore;
