import React, { Component, PureComponent } from 'react';
import styles from './ActionList.module.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Button from '@components/Button';
import Badge, { BadgeProps } from 'antd/lib/badge';
import classNames from 'classnames';

export interface ActionBadgeProps extends BadgeProps {
    titleToFormat?: FormattedMessage.MessageDescriptor;
}

export type ActionDef = {
    type: 'primary' | 'danger' | 'default' | 'dashed',
    linkHandler?: () => string | { pathname: string, search: string };
    clickHandler?: () => void;
    title: FormattedMessage.MessageDescriptor;
    icon?: string;
    iconComponent?: Component;
    badgeProps?: ActionBadgeProps;
};

export interface ActionListProps extends InjectedIntlProps {
    actions: ActionDef[];
}

class ActionList extends PureComponent<ActionListProps> {

    onClickPreventDefault = (e: React.MouseEvent) => {
        e.preventDefault();
    }

    render() {
        const { actions, intl: { formatMessage } } = this.props;
        return (
            <div
                onClick={this.onClickPreventDefault}
                className={styles.ActionButtonsContainer}
            >
                {
                    actions.map((item, index) => {
                        const wrapButton = !item.clickHandler;

                        const theButton = (
                            <Button
                                key={`btn-${index}`}
                                icon={item.icon}
                                title={formatMessage(item.title)}
                                type={item.type}
                                onClick={item.clickHandler}
                                className={!wrapButton ? styles.ActionItem : ''}
                            />
                        );

                        if (item.badgeProps) {
                            const actionClassName = classNames(styles.ActionItem, item.badgeProps.className);
                            return (
                                <Badge
                                    {...item.badgeProps}
                                    className={actionClassName}
                                    title={item.badgeProps.titleToFormat ?
                                        formatMessage(item.badgeProps.titleToFormat) : undefined
                                    }
                                >
                                    {theButton}
                                </Badge>
                            );
                        }

                        if (!wrapButton) {
                            return theButton;
                        }

                        return (
                            <Link
                                to={item.linkHandler!()}
                                key={`lnk-${index}`}
                                className={styles.ActionItem}
                            >
                                {theButton}
                            </Link>
                        );
                    })
                }
            </div>
        );
    }
}

export default injectIntl(ActionList);
