import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { contentDefinitionCustomFieldsListPath } from '../../../constants/routing-constants';
import ContentDefinitionSchemaEditor from '../../../components/ContentDefinitionSchemaEditor';
import {
    ContentDefinitionSchemaStoreModel
} from '../../../stores/ContentDefinitionEditorStore/ContentDefinitionSchemaStore';
import { inject, observer } from 'mobx-react';
import withForm from '../../../hoc/withForm/withForm';
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl';
import { withFormInitialization } from '../../../hoc';
import { FormComponentProps } from 'antd/lib/form';
import ContainerWithAside from '../../../components/ContainerWithAside/ContainerWithAside';
import Aside from '../../../components/Aside/Aside';
import AsideItem from '../../../components/AsideItem/AsideItem';
import { Button, Col, Form, Input, Row } from 'antd';
import CustomFieldEditorStore from '../../../components/ContentDefinitionSchemaEditor/CustomFieldEditorStore';
import withCustomFieldEditorStore from '../../../components/ContentDefinitionSchemaEditor/withCustomFieldEditorStore';
import TranslateElement
    from '../../../components/ContentDefinitionSchemaEditor/ContentDefinitionFieldEditing/TranslateElement';
import { NavStoreModel, NAVIGATION_PRIORITY } from '../../../stores/navStore/navStoreModel';
import { NavigationItem } from '../../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../../constants/navigation-internationalized-labels';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../../constants/navigation-item-identifier';
import { getErrorByErrorCode } from '../../../services/FormUtils/DefinitionFormErrorMessages';
import {
    ContentDefinitionFormStoreModel
} from '../../../stores/contentDefinitionFormStore/contentDefinitionFormStoreModel';
import { isFunction } from '../../../services/utils';
import { NotificationStoreModel } from '../../../stores/notificationStore/notificationStoreModel';
import {
    createErrorNotification,
    createInfoNotification,
    createSuccessNotification
} from '../../../services/Notification';
import { NOTIFICATION_DEFAULT_MESSAGES, NOTIFICATION_KEY_CONSTANTS } from '../../../constants/notifications-constants';
import {
    withPermissionsToInteract,
    WithPermissionsToInteractReturnType
} from '../../../providers/UserPermissionsProvider';

const FormItem = Form.Item;

export interface ContentDefinitionCustomFieldEditMatchParams {
    spaceId: string;
    contentDefId?: string;
    customFieldName?: string;
}

export interface ContentDefinitionCustomFieldEditProps extends
    RouteComponentProps<ContentDefinitionCustomFieldEditMatchParams>,
    WithPermissionsToInteractReturnType,
    FormComponentProps,
    InjectedIntlProps { }

export interface ContentDefinitionCustomFieldEditState { }

export interface DecoratedProps extends ContentDefinitionCustomFieldEditProps {
    contentDefinitionSchemaStore: ContentDefinitionSchemaStoreModel;
    contentDefinitionFormStore: ContentDefinitionFormStoreModel;
    customFieldEditorStore: CustomFieldEditorStore;
    notificationStore: NotificationStoreModel;
    navStore: NavStoreModel;
}

@inject(
    'contentDefinitionSchemaStore', 'customFieldEditorStore',
    'contentDefinitionFormStore', 'navStore', 'notificationStore'
)
@observer
class ContentDefinitionCustomFieldEdit extends
    Component<ContentDefinitionCustomFieldEditProps, ContentDefinitionCustomFieldEditState> {

    get decoratedProps() { return this.props as DecoratedProps; }

    componentDidMount(): void {
        const { customFieldName } = this.props.match.params;
        const {
            retrieveCustomFieldErrors,
            customFieldHasErrors,
            schema: { fragments }
        } = this.decoratedProps.contentDefinitionSchemaStore;
        const { setFragmentErrors } = this.decoratedProps.customFieldEditorStore;
        if (this.isEditingCustomField(customFieldName)) {
            this.pushToBreadCrumb(true, customFieldName);
            const fragment = fragments[customFieldName];
            this.decoratedProps.customFieldEditorStore.initFragment(fragment);
            if (customFieldHasErrors(customFieldName)) {
                setFragmentErrors(retrieveCustomFieldErrors(customFieldName) || []);
            }
        } else {
            this.pushToBreadCrumb(false, undefined);
            this.decoratedProps.customFieldEditorStore.initFragment(undefined);
        }
    }

    componentWillUnmount() {
        this.decoratedProps.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD);
    }

    isEditingCustomField = (arg: string | undefined): arg is string => {
        const { customFieldName } = this.props.match.params;
        const { schema: { fragments } } = this.decoratedProps.contentDefinitionSchemaStore;
        return customFieldName !== undefined && fragments.hasOwnProperty(customFieldName);
    }

    closeNotification = () => {
        this.decoratedProps.notificationStore.closeNotification(
            NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_FRAGMENT_VALIDATION
        );
    }

    pushToBreadCrumb = (isCustomFieldEditing: boolean, customFieldName: string | undefined) => {
        if (isCustomFieldEditing && customFieldName !== undefined) {
            this.decoratedProps.navStore.pushMultipleToPath([
                new NavigationItem(
                    NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD,
                    this.props.location.pathname.split('/').slice(0, -1).join('/'),
                    this.props.intl.formatMessage(NAVIGATION_LABELS.customFields),
                    NAVIGATION_PRIORITY.THIRD
                ),
                new NavigationItem(
                    NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD,
                    this.props.location.pathname,
                    this.props.intl.formatMessage(NAVIGATION_LABELS.editWithValue, { value: customFieldName }),
                    NAVIGATION_PRIORITY.FOURTH
                )
            ]);
        } else {
            this.decoratedProps.navStore.pushMultipleToPath([
                new NavigationItem(
                    NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD,
                    this.props.location.pathname.split('/').slice(0, -1).join('/'),
                    this.props.intl.formatMessage(NAVIGATION_LABELS.customFields),
                    NAVIGATION_PRIORITY.THIRD
                ),
                new NavigationItem(
                    NAVIGATION_ITEM_IDENTIFIERS.CUSTOM_FIELD,
                    this.props.location.pathname,
                    this.props.intl.formatMessage(NAVIGATION_LABELS.new),
                    NAVIGATION_PRIORITY.FOURTH
                )
            ]);
        }
    }

    onSaveClick = () => {
        if (this.props.hasPermissions) {
            this.props.form.validateFields(async (error, values) => {
                const {
                    customFieldEditorStore: { fragment, retrieveDeserializedFragment, deserializeFragmentField },
                    contentDefinitionSchemaStore: { editFragment, saveFragment, schema: { lang, fragments } }
                } = this.decoratedProps;
                if (!error) {
                    try {
                        createInfoNotification(
                            NOTIFICATION_KEY_CONSTANTS.CONTENTDEFINITION_FRAGMENT_VALIDATION,
                            this.props.intl.formatMessage,
                            NOTIFICATION_DEFAULT_MESSAGES.validationAction
                        );
                        this.decoratedProps.customFieldEditorStore.cleanFragmentErrors();
                        this.decoratedProps.customFieldEditorStore.resetFieldsInitialIndex();
                        await this.decoratedProps.contentDefinitionFormStore.validateSchemaFragment(
                            values.name, deserializeFragmentField(fragment.fields), fragment.labels,
                            lang, Object.keys(fragments)
                        );
                        this.closeNotification();
                        createSuccessNotification(
                            this.props.intl.formatMessage,
                            NOTIFICATION_DEFAULT_MESSAGES.validationAction,
                        );
                        const {
                            match: { params: { spaceId, contentDefId, customFieldName } },
                            history
                        } = this.props;
                        if (this.isEditingCustomField(customFieldName)) {
                            editFragment(customFieldName, values.name, retrieveDeserializedFragment());
                        } else {
                            saveFragment(values.name, retrieveDeserializedFragment());
                        }
                        history.push(contentDefinitionCustomFieldsListPath(spaceId, contentDefId));
                    } catch (e) {
                        this.closeNotification();
                        createErrorNotification(
                            this.props.intl.formatMessage,
                            NOTIFICATION_DEFAULT_MESSAGES.validationAction,
                        );
                        if (isFunction(e.retrieveValidationErrors)) {
                            this.decoratedProps.customFieldEditorStore.setFragmentErrors(e.retrieveValidationErrors());
                        }
                    }
                }
            });
        }
    }

    onDeleteClick = () => {
        const {
            match: { params: { spaceId, contentDefId, customFieldName } },
            history
        } = this.props;
        if (this.isEditingCustomField(customFieldName)) {
            this.decoratedProps.contentDefinitionSchemaStore.deleteFragment(customFieldName);
        }
        history.push(contentDefinitionCustomFieldsListPath(spaceId, contentDefId));
    }

    onGoBack = () => {
        const {
            match: { params: { spaceId, contentDefId } },
            history
        } = this.props;
        history.push(contentDefinitionCustomFieldsListPath(spaceId, contentDefId));
    }

    isCustomFieldNameAlreadyInUse = (rule, editingName, callback) => {
        const { match: { params: { customFieldName: initialName } } } = this.props;
        if (initialName !== editingName) {
            const fragmentNames = Object.keys(this.decoratedProps.contentDefinitionSchemaStore.schema.fragments);
            if (fragmentNames.includes(editingName)) {
                callback(rule.message);
            }
        }
        callback();
    }

    public render() {
        const {
            form: { getFieldDecorator },
            intl: { formatMessage },
            match: { params: { customFieldName } },
            hasPermissions
        } = this.props;
        const { schema } = this.decoratedProps.contentDefinitionSchemaStore;
        const {
            fragment, editingLabelsUpdate, editingLabelsCreate, labelsValidationErrors, nameValidationErrors
        } = this.decoratedProps.customFieldEditorStore;
        const { loaders } = this.decoratedProps.contentDefinitionFormStore;
        return (
            <>
                <ContainerWithAside
                    renderAside={() => (
                        <Aside>
                            <AsideItem>
                                {[
                                    hasPermissions && <Button
                                        loading={loaders.validateFragment}
                                        onClick={this.onSaveClick}
                                        style={{ width: '100%' }}
                                        key="save-custom-field"
                                        type="primary"
                                    >
                                        <FormattedMessage
                                            id="ContentDefinition.CustomField.Save"
                                            defaultMessage="SAVE CUSTOM FIELD"
                                        />
                                    </Button>,
                                    hasPermissions && <Button
                                        onClick={this.onDeleteClick}
                                        style={{ width: '100%', margin: '10px 0' }}
                                        key="delete-custom-field"
                                        type="danger"
                                    >
                                        <FormattedMessage
                                            id="ContentDefinition.CustomField.Delete"
                                            defaultMessage="DELETE CUSTOM FIELD"
                                        />
                                    </Button>,
                                    <Button
                                        style={{ width: '100%' }}
                                        onClick={this.onGoBack}
                                        key="go-to-custom-field-list"
                                        type="default"
                                    >
                                        <FormattedMessage
                                            id="ContentDefinition.CustomField.BackToList."
                                            defaultMessage="BACK TO LIST"
                                        />
                                    </Button>
                                ]}
                            </AsideItem>
                        </Aside>
                    )}
                >
                    <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Row gutter={24}>
                            <Col span={14}>
                                <FormItem
                                    help={nameValidationErrors.length > 0
                                        ? nameValidationErrors.map(
                                            error => formatMessage(getErrorByErrorCode(error.errorCode))
                                        ).join(', ')
                                        : undefined
                                    }
                                    validateStatus={nameValidationErrors.length > 0 ? 'error' : undefined}
                                    label={<FormattedMessage
                                        id="ContentDefinition.CustomField.Edit.NameItem.Label"
                                        defaultMessage="Name"
                                    />}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    {getFieldDecorator('name', {
                                        initialValue: this.props.match.params.customFieldName,
                                        rules: [
                                            { required: true },
                                            {
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                                message: <FormattedMessage
                                                    id="ContentDefinition.CustomField.Edit.Name.Pattern.Error"
                                                    /* tslint:disable-next-line:max-line-length */
                                                    defaultMessage="Only use letters, numbers, and _ "
                                                />
                                            },
                                            {
                                                validator: this.isCustomFieldNameAlreadyInUse,
                                                message: <FormattedMessage
                                                    id="ContentDefinition.CustomField.Edit.Name.AlreadyExists.Error"
                                                    /* tslint:disable-next-line:max-line-length */
                                                    defaultMessage="An other custom field with the same name already exists"
                                                />
                                            }
                                        ]
                                    })(
                                        <Input disabled={!hasPermissions || !!customFieldName} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={10}>
                                <FormItem
                                    help={labelsValidationErrors.map(error =>
                                        formatMessage(getErrorByErrorCode(error.errorCode))).join(', ')
                                    }
                                    validateStatus={labelsValidationErrors.length > 0 ? 'error' : undefined}
                                    label={<FormattedMessage
                                        id="ContentDefinition.CustomField.Edit.LabelItem.Label"
                                        defaultMessage="Label"
                                    />}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <TranslateElement
                                        defaultCulture={schema.lang}
                                        items={fragment.labels || {}}
                                        onAdd={(defaultCulture) => editingLabelsCreate(defaultCulture)}
                                        onChange={(locale, value) => editingLabelsUpdate(locale, value)}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <ContentDefinitionSchemaEditor
                            gutter={true}
                            customFieldEditorStore={this.decoratedProps.customFieldEditorStore}
                            fields={fragment.fields}
                        />
                    </Form>
                </ContainerWithAside>
            </>
        );
    }
}

export default withFormInitialization(withCustomFieldEditorStore(withPermissionsToInteract(withForm(
    injectIntl(ContentDefinitionCustomFieldEdit))
)));
