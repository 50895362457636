import React from 'react';
import classNames from 'classnames';
import './index.scss';
import Button from '@components/Button';
import Typography from '../../Typography';
import { Plan, PlanInterval } from '@services/plans';

type PlanProps = {
    plan: Plan;
    paymentInterval: PlanInterval;
    className?: string;
    highlighted?: boolean;
    onClick?(): void;
};

const getCurrentPrice = (price: Plan['price'], selectedInterval: PlanInterval) => {
    if (selectedInterval === PlanInterval.monthly) { return price.monthly; }
    if (selectedInterval === PlanInterval.yearly) { return price.yearly; }
    return '##.##';
};

const PlanCard = ({ plan, paymentInterval, className, highlighted, onClick }: PlanProps) => {
    return (
        <div
            className={classNames('PlanCardWrapper', {
                [className!]: !!className,
                ['SuggestedCard']: !!plan.suggested || !!highlighted
            })}
        >
            <div className="PlanCardHeaderWrapper">
                <Typography
                    style={{ marginTop: 0 }}
                    variant="h2"
                    className="PlanNameWrapper"
                >
                    {plan.name}
                </Typography>
                <Typography
                    className="PlanDescriptionWrapper"
                >
                    {plan.description}
                </Typography>
                <div className="PlanPriceWrapper">
                    <span className="PlanCostWrapper">
                        {plan.price.currency} {getCurrentPrice(plan.price, paymentInterval)} / month
                    </span>
                    {paymentInterval === PlanInterval.yearly && <div className="PlanBilledYearlyWrapper">
                        <span>billed ${+plan.price.yearly * 12} yearly</span>
                    </div>}
                </div>
            </div>
            <div className="PlanBenefitsWrapper">
                <div>
                    <div className="ElementsTitleContainer">Most valuated features:</div>
                    {plan.mostValuatedFeatures.map((mostValuatedFeature, index) =>
                        <div key={`MostValuatedFeature-${index}`} className="ElementTextContainer">
                            {mostValuatedFeature}
                        </div>
                    )}
                </div>
                <div className="IncludedBenefitsWrapper">
                    <div className="ElementsTitleContainer">Included benefits:</div>
                    {plan.includedBenefits.map((benefitIncluded, index) =>
                        <div key={`IncludedBenefit-${index}`} className="ElementTextContainer">{benefitIncluded}</div>
                    )}
                </div>
            </div>
            {
                onClick &&
                (
                    <div className="PlanCTAWrapper">
                        <Button type="primary" className="PlanTryItNowCTA">
                            <a onClick={onClick}>Upgrade</a>
                        </Button>
                    </div>
                )
            }
        </div>
    );
};

export default PlanCard;
