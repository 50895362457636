import React from 'react';
import { Col, Row } from 'antd';
import { SectionProps } from './SectionModel';
import classNames from 'classnames';
import styles from './Section.module.scss';

const Section = ({ header, children, footer, title, contentWrapperClass }: SectionProps) => (
    <section className={styles.SectionContainer}>
        {!!title && <Row className={styles.SectionTitle}>
            <div className={styles.TitleLabel}>{title}</div>
        </Row>}
        <Row className={classNames(styles.SectionContent, contentWrapperClass)}>
            {!!header && <Col xs={24} className={styles.ContentHeader}>{header}</Col>}
            <Col xs={24} className={styles.ContentBody}>
                {children}
            </Col>
            {!!footer && <Col xs={24} className={styles.ContentFooter}>{footer}</Col>}
        </Row>
    </section>
);

export default Section;
