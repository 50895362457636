import React, { Component, Fragment } from 'react';
import classes from './index.module.scss';
import Entry from '@components/Entry';
import { Skeleton, Tooltip } from 'antd';
import Button from '@components/Button';
import { inject, observer } from 'mobx-react';
import { UserManagementStore, notificationStore } from '../../../stores';
import withEmailVerificationStores from '@hoc/withEmailVerificationStores/withEmailVerificationStores';
import { UserInfo } from '@services/Users';
import { FormattedMessage } from 'react-intl';
import { withUserInfo } from '@hoc/withUserInfo/withUserInfo';
import SettingsContentHeader from '@components/SettingsContentHeader';
import Paper from '@components/Paper';

interface ProfilePageProps {
    userInfo?: UserInfo;
    refreshUserInfo?(): void;
}

interface ProfilePageState {
    temporaryDisabledVerification: boolean;
}

interface InjectedProps {
    userManagementStore: UserManagementStore;
}

@inject('userManagementStore')
@observer
class ProfilePage extends Component<ProfilePageProps, ProfilePageState> {
    get injected() {
        return this.props as InjectedProps;
    }

    verificationDisabledTimeout: number;

    state: ProfilePageState = {
        temporaryDisabledVerification: false
    };

    componentWillUnmount() {
        window.clearTimeout(this.verificationDisabledTimeout);
    }

    sendEmailVerification = async () => {
        await this.injected.userManagementStore.requestEmailVerification();
        if (this.injected.userManagementStore.requestVerificationSuccess) {
            await notificationStore.openNotification({
                message: (
                    <FormattedMessage
                        id="ProfilePage.emailVerificationNotification.success"
                        defaultMessage="A verification link will be sent to the provided email."
                    />
                ),
                type: 'success'
            });
            this.setState({
                temporaryDisabledVerification: true
            });
            this.verificationDisabledTimeout = window.setTimeout(
                () => {
                    this.setState({
                        temporaryDisabledVerification: false
                    });
                },
                5000
            );
        } else {
            await notificationStore.openNotification({
                message: (
                    <FormattedMessage
                        id="ProfilePage.emailVerificationNotification.error"
                        defaultMessage="We couldn't request a verification email. Try in a few minutes."
                    />
                ),
                type: 'error'
            });
        }
    }

    renderVerificationButton = (userInfo: UserInfo) => {
        return !userInfo.email_verified ? (
            <Tooltip
                key="email_not_verified"
                title={
                    <FormattedMessage
                        id="ProfilePage.emailVerificationButton.verifyEmailTooltip"
                        defaultMessage="Verify your email"
                    />
                }
            >
                <Button
                    icon="warning"
                    loading={this.injected.userManagementStore.loaders.requestingVerification}
                    disabled={this.state.temporaryDisabledVerification}
                    onClick={this.sendEmailVerification}
                    className={classes.EmailNotVerifiedButton}
                />
            </Tooltip>
        ) : null;
    }

    renderActions = (userInfo: UserInfo) => {
        return [
            this.renderVerificationButton(userInfo)
        ].filter(action => action !== null);
    }

    render() {

        const { userInfo } = this.props;

        return (
            <Fragment>
                <SettingsContentHeader
                    title={
                        <FormattedMessage
                            defaultMessage="Profile"
                            id="scenes.Profile.ProfilePage.title"
                        />
                    }
                />
                <Paper gutter={true}>
                    <Skeleton loading={!userInfo} active={true}>
                        {
                            userInfo && (
                                <React.Fragment>
                                    <Entry
                                        label={
                                            <FormattedMessage
                                                id="ProfilePage.labels.name"
                                                defaultMessage="name"
                                            />
                                        }
                                        value={userInfo.name}
                                    />
                                    <Entry
                                        label={
                                            <FormattedMessage
                                                id="ProfilePage.labels.email"
                                                defaultMessage="email"
                                            />
                                        }
                                        value={userInfo.email}
                                        actions={this.renderActions(userInfo)}
                                    />
                                </React.Fragment>
                            )
                        }
                    </Skeleton>
                </Paper>
            </Fragment>
        );
    }
}

export default withUserInfo(withEmailVerificationStores(ProfilePage));
