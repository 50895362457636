import React, { Component } from 'react';
import { injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '@components/Button';
import logo_collapsed from '../assets/images/logo_collapsed.svg';
import { dashboardPath } from '../constants/routing-constants';

interface AppErrorBoundaryProps extends RouteComponentProps<{ spaceId: string }>, InjectedIntlProps { }
interface AppErrorBoundaryState { hasError: boolean; }

import styles from './AppErrorBoundary.module.scss';
const AppErrorBoundaryLabels = defineMessages({
    descriptionMessage_1: {
        id: 'AppErrorBoundaryLabels.descriptionMessage_1',
        defaultMessage: 'Ops... Something went wrong.'
    },
    descriptionMessage_2: {
        id: 'AppErrorBoundaryLabels.descriptionMessage_2',
        defaultMessage: 'Try to reload the app with the button below,'
    },
    descriptionMessage_3: {
        id: 'AppErrorBoundaryLabels.descriptionMessage_3',
        defaultMessage: 'if the error persist please contact the support team'
    },
    ctaLabel: {
        id: 'AppErrorBoundaryLabels.buttonLabel',
        defaultMessage: 'Try to Reload'
    }
});

class AppErrorBoundary extends Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
    state = { hasError: false };

    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true });
    }

    onClick = () => {
        this.props.history.push(dashboardPath(this.props.match.params.spaceId));
        this.setState({ hasError: false });
    }

    render() {
        const { intl: { formatMessage } } = this.props;
        if (this.state.hasError) {
            return (
                <div className={styles.AppErrorBoundaryWrapper}>
                    <div className={styles.AppErrorBoundaryContainer}>
                        <div className={styles.AppErrorBoundaryImageContainer}>
                            <img src={logo_collapsed} />
                        </div>
                        <div className={styles.AppErrorBoundaryMessageContainer}>
                            <p>{formatMessage(AppErrorBoundaryLabels.descriptionMessage_1)}</p>
                            <p>{formatMessage(AppErrorBoundaryLabels.descriptionMessage_2)}</p>
                            <p>{formatMessage(AppErrorBoundaryLabels.descriptionMessage_3)}</p>
                        </div>
                        <Button
                            type="primary"
                            className={styles.AppErrorBoundaryButton}
                            onClick={this.onClick}
                        >
                            {formatMessage(AppErrorBoundaryLabels.ctaLabel)}
                        </Button>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default injectIntl(withRouter(AppErrorBoundary));
