import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { action, observable, runInAction } from 'mobx';
import UsersStore from '../usersStore';

export enum OnboardingFlow {
    Invite,
    MustAcceptConsents,
    Normal,
    NotDecided,
    Wizard,
}

export interface OnboardingStoreModel {
    onboardingFlowState: OnboardingFlow;
    loading: OnboardingStoreLoading;
    /**
     *
     * @returns {Promise<void>}
     * @memberof OnboardingStoreModel
     */
    updateConsents(termsAndCondition?: boolean, privacy?: boolean): Promise<boolean>;
    /**
     *
     * @returns {Promise<OnboardingFlow>}
     * @memberof OnboardingStoreModel
     */
    checkConsentsVersion(): Promise<OnboardingFlow>;
    /**
     * @returns {Promise<number>}
     * @memberof OnboardingStoreModel
     */
    getInvitesCount(): Promise<number>;
    /**
     * @returns {Promise<number>}
     * @memberof OnboardingStoreModel
     */
    getPermissionsCount(): Promise<number>;
    /**
     * @returns {Promise<boolean>}
     * @memberof OnboardingStoreModel
     */
    hasSomePermissions(): Promise<boolean>;
    /**
     *
     * @returns {Promise<OnboardingFlow>}
     * @memberof OnboardingStoreModel
     */
    postAcceptEula(): Promise<OnboardingFlow>;
    /**
     *
     * @returns {Promise<OnboardingFlow>}
     * @memberof OnboardingStoreModel
     */
    postAcceptInvite(): Promise<OnboardingFlow>;
    /**
     * Handles user's onboarding.
     * @returns {Promise<OnboardingFlow>}
     * @memberof OnboardingStoreModel
     */
    postSignIn(): Promise<OnboardingFlow>;
    /**
     * 
     * @returns {Promise<OnboardingFlow>}
     * @memberof OnboardingStoreModel
     */
    postWizardCompletion(): Promise<OnboardingFlow>;
}

type OnboardingStoreLoading = {
    updateConsents: boolean;
};

export class OnboardingStore implements OnboardingStoreModel {
    protected usersStore: UsersStore;

    @observable public loading: OnboardingStoreLoading = {
        updateConsents: false
    };
    @observable public onboardingFlowState = OnboardingFlow.NotDecided;

    public constructor(
        protected api: ContentChefClient,
        injectedUsersStore: UsersStore
    ) {
        this.usersStore = injectedUsersStore;
    }

    @action
    public async updateConsents(termsAndCondition?: boolean, privacy?: boolean) {
        this.loading.updateConsents = true;
        const consentsPromise: Promise<boolean>[] = [];
        if (termsAndCondition) {
            consentsPromise.push(this.usersStore.updateTermsAndConditionsConsent());
        }
        if (privacy) {
            consentsPromise.push(this.usersStore.updatePrivacyConsent());
        }
        const promisesResult = await Promise.all(consentsPromise);

        runInAction(() => {
            this.loading.updateConsents = false;
        });

        return promisesResult.every(updateResult => updateResult);
    }

    @action
    public async checkConsentsVersion() {
        const { privacy, termsAndConditions } = this.usersStore.currentUser.consents;
        const returnChange = () => runInAction(() => {
            this.onboardingFlowState = OnboardingFlow.MustAcceptConsents;
            return OnboardingFlow.MustAcceptConsents;
        });

        if (termsAndConditions.signedVersion !== termsAndConditions.latestVersion) {
            return returnChange();
        }

        if (privacy.signedVersion !== privacy.latestVersion) {
            return returnChange();
        }

        return OnboardingFlow.Normal;
    }

    public async getInvitesCount(): Promise<number> {
        try {
            const invites = (await this.api.invites.listForUser());
            return invites.filter(i => !i.accepted).length;
        } catch {
            return 0;
        }
    }

    public async getPermissionsCount(): Promise<number> {
        try {
            return (await this.api.userPermissions.list()).length;
        } catch {
            return 0;
        }
    }

    public async hasSomePermissions() {
        return (await this.getPermissionsCount()) > 0;
    }

    @action
    public async postAcceptEula() {
        this.onboardingFlowState = OnboardingFlow.Normal;
        return this.onboardingFlowState;
    }

    @action
    public async postAcceptInvite() {
        return this.checkConsentsVersion();
    }

    @action
    public async postSignIn(): Promise<OnboardingFlow> {
        const permissions: number = await this.getPermissionsCount();
        const invites: number = await this.getInvitesCount();
        const serialized = [permissions, invites].join('_');

        action(
            () => {
                switch (serialized) {
                    case '0_0':
                        this.onboardingFlowState = OnboardingFlow.Wizard;
                        break;
                    case '0_1':
                        this.onboardingFlowState = OnboardingFlow.Invite;
                        break;
                    default:
                        this.onboardingFlowState = OnboardingFlow.Normal;
                        break;
                }
            }
        )();

        if (this.onboardingFlowState === OnboardingFlow.Normal) {
            return this.checkConsentsVersion();
        }

        return this.onboardingFlowState;
    }

    @action
    public async postWizardCompletion() {
        return this.checkConsentsVersion();
    }
}

export default OnboardingStore;
