import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { SpaceStats, ListSpaceApiCredentials } from '@contentchef/contentchef-types';
import { observable, action, runInAction } from 'mobx';
import { SpaceDetailStoreModel } from './spaceDetailStoreModel';

class SpaceDetailStore implements SpaceDetailStoreModel {
    api: ContentChefClient;
    @observable spaceStats = {} as SpaceStats;
    @observable spaceApiCredentials = {} as ListSpaceApiCredentials;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async refresh() {
        try {
            const responses = await Promise.all(
                [
                    this.api.space.getSpaceStats(),
                    this.api.space.listSpaceCredentials()
                ]
            );
            runInAction(() => {
                const [spaceStats, spaceApiCredentials] = responses;
                this.spaceStats = spaceStats;
                this.spaceApiCredentials = spaceApiCredentials;
            });
        } catch (error) {
            console.log(error);
        }
    }

}

export default SpaceDetailStore;
