import { ContentChefClient } from '@contentchef/contentchef-management-js-client/dist/ContentChefClient';
import {
    ListContentRepositoriesResponse, ListContentRepositoryFilters
} from '@contentchef/contentchef-types';
import { SearchParamsStoreModel } from '../searchParamsStore/searchParamsStoreModel';

export interface RepositoryListStoreModel {
    api: ContentChefClient;
    error: ErrorModel;
    isLoadingList: boolean;
    canResetAllFilters: boolean;
    listFilters: ListContentRepositoryFilters;
    pagedRepositoryList: ListContentRepositoriesResponse;
    searchParams: SearchParamsStoreModel;
    setRepositories(): void;
    setListFilterByName(value?: string): void;
    setListFilterByArchived(value?: boolean): void;
    resetAllFilters(): void;
}

interface ErrorModel {
    repositories: string;
}

export enum RepositoryListUrlParams {
    byPage = 'page',
    bySize = 'size',
    byName = 'search',
    byArchived = 'archived'
}
