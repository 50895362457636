import { ContentChefClient } from '@contentchef/contentchef-management-js-client';
import { ListAuditLogResponse } from '@contentchef/contentchef-types';
import { ContentRepositoryId } from '@contentchef/contentchef-types';
import { observable, action, runInAction } from 'mobx';
import { ActivityStoreModel } from './activityStoreModel';

class ActivityStore implements ActivityStoreModel {
    api: ContentChefClient;
    @observable userLatestActivities = {} as ListAuditLogResponse;
    @observable teamLatestActivities = {} as ListAuditLogResponse;
    @observable userLatestActivitiesByRepo = {} as ListAuditLogResponse;
    @observable teamLatestActivitiesByRepo = {} as ListAuditLogResponse;

    constructor(api: ContentChefClient) {
        this.api = api;
    }

    @action
    async initializeHomeLatestActivities() {
        try {
            const responses = await Promise.all(
                [
                    this.api.auditLog.userLatestActivities(),
                    this.api.auditLog.userTeamLatestActivities()
                ]
            );
            runInAction(() => {
                const [userLatestActivities, teamLatestActivities] = responses;
                this.userLatestActivities = userLatestActivities;
                this.teamLatestActivities = teamLatestActivities;
            });
        } catch (error) {
            console.log(error);
        }
    }

    @action
    async initializeRepositoryLatestActivities(repositoryId: ContentRepositoryId) {
        try {
            const responses = await Promise.all(
                [
                    this.api.auditLog.userLatestActivitiesByRepository({ repositoryId }),
                    this.api.auditLog.userTeamLatestActivitiesByRepository({ repositoryId })
                ]
            );
            runInAction(() => {
                const [userLatestActivitiesByRepo, teamLatestActivitiesByRepo] = responses;
                this.userLatestActivitiesByRepo = userLatestActivitiesByRepo;
                this.teamLatestActivitiesByRepo = teamLatestActivitiesByRepo;
            });
        } catch (error) {
            console.log('Error occured while retrieving latest activities', error);
        }
    }

}

export default ActivityStore;
