import { ContentChefClient, createChefSpaceClient } from '@contentchef/contentchef-management-js-client';
import {
    observable,
    action,
} from 'mobx';
import { AWSConfiguration } from '../../constants/api-init-options';
import { routerStoreHelper } from '..';
import { dashboardPath } from '../../constants/routing-constants';
import { AuthenticationTokenResolver, SpaceIdResolver } from '@contentchef/contentchef-management-js-client/dist/api';
import { Auth } from 'aws-amplify';

export interface ApiStoreModel {
    api: ContentChefClient;
    currentSpaceId: string | undefined;
    switchToSpace(spaceId: string): void;
    changeCurrentSpace(spaceId: string): void;
    clearCurrentSpace(): void;
}

class ApiStore implements ApiStoreModel {
    @observable api: ContentChefClient;
    @observable currentSpaceId: string | undefined;

    getCurrentSpaceId = {
        getSpaceId: () => {
            return this.currentSpaceId;
        }
    };

    initializeClient() {
        const apiTokenResolver: AuthenticationTokenResolver = {
            getToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken();
                return token;
            }
        };

        const spaceIdResolver: SpaceIdResolver = {
            getSpaceId: async () => {
                if (!this.currentSpaceId) {
                    throw new Error('Current space not set');
                }
                return this.currentSpaceId;
            }
        };

        this.api = createChefSpaceClient(apiTokenResolver, spaceIdResolver, { apiRoot: AWSConfiguration.ApiBaseUrl });
    }

    @action
    async changeCurrentSpace(newSpace: string) {
        this.currentSpaceId = newSpace;
    }

    @action
    async clearCurrentSpace() {
        this.currentSpaceId = undefined;
    }

    @action
    async switchToSpace(spaceId: string) {
        await this.changeCurrentSpace(spaceId);
        routerStoreHelper.routerStore.history.push(dashboardPath(spaceId));
    }

}

const apiStore = new ApiStore();
apiStore.initializeClient();
export default apiStore;
