import React, { Component } from 'react';
import {
    injectStripe,
    ReactStripeElements,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
} from 'react-stripe-elements';
import { Form, Input, Row, Col, Icon, Popover, Modal } from 'antd';
import Button from '@components/Button';
import classes from './index.module.scss';
import withPaymentStores from '@hoc/withPaymentStore';
import { PaymentStore, notificationStore } from '../../stores';
import withFormInitialization from '@hoc/withFormInitialization/withFormInitialization';
import withForm from '@hoc/withForm/withForm';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { observer, inject } from 'mobx-react';
import {
    PaymentIntentStatus, Interval, SubscriptionType
} from '@contentchef/contentchef-types';
import paymentFlowFactory from '../../services/payments/PaymentFlowFactory';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Typography, { Align } from '../Typography';
import classNames from 'classnames';
import { formFieldNormalize } from '@services/FormUtils/FormFieldNormalize';
import { PROFILE_ROUTE } from '@constants/routing-constants';
import BillingInfo from '../BillingInfo';
import withSubscriptions from '../../hoc/withSubscription';
import Paper from '../Paper';
import { SubscriptionStore } from '@stores/subscriptionStore';
import { getSubscriptionPlanProps } from '@services/plans';
import { labels } from '@services/subscriptions/labels';
import EntryList from '../EntryList';

const { Item } = Form;

interface UpgradePlanFormProps extends
    FormComponentProps, RouteComponentProps<{ subscriptionId: string; planId: string; }>, InjectedIntlProps {
    interval: Interval;
}

interface InjectedProps extends UpgradePlanFormProps {
    stripe: ReactStripeElements.InjectedStripeProps['stripe'];
    paymentStore: PaymentStore;
    subscriptionStore: SubscriptionStore;
}

interface UpgradePlanFormState {
    showSuccess: boolean;
    showForm: boolean;
    showWaiting3dSecure: boolean;
    inProgress: boolean;
    reviewModal: boolean;
    isContinuing: boolean;
}

interface UpgradePlanFormFields {
    cardowner: string;
}

function toStripeTokenizedOptions(fields: UpgradePlanFormFields): ReactStripeElements.TokenOptions {
    return {
        name: fields.cardowner
    };
}

@inject('paymentStore', 'subscriptionStore')
@observer
class UpgradePlanForm extends Component<UpgradePlanFormProps, UpgradePlanFormState> {
    state = {
        showForm: true,
        showSuccess: false,
        showWaiting3dSecure: false,
        inProgress: false,
        reviewModal: false,
        isContinuing: false
    };

    private redirectAfterSuccessTimeoutId: number;

    get injected() {
        return this.props as InjectedProps;
    }

    tokenizePayment = () => {
        this.props.form.validateFields(async (err, fields: UpgradePlanFormFields) => {
            this.setState({
                isContinuing: true
            });
            if (!err) {
                const { match: { params: { subscriptionId } } } = this.props;
                const searchParams = new URLSearchParams(this.props.location.search);
                const status = searchParams.get('status') as PaymentIntentStatus | null;

                if (this.injected.stripe) {
                    const paymentFlow = paymentFlowFactory(
                        this.injected.stripe, this.injected.paymentStore, status
                    );

                    try {
                        await paymentFlow.tokenize(toStripeTokenizedOptions(fields));
                        await this.injected.subscriptionStore.setCustomerTaxRate({ subscriptionId });
                        this.openReviewModal();
                    } catch (error) {
                        notificationStore.openNotificationWithIcon(
                            'error',
                            {
                                message: (
                                    <FormattedMessage
                                        id="UpgradePlanForm.payment.errors.default"
                                        defaultMessage={`
                                            An error occurred. Please check your credit card information.
                                        `}
                                    />
                                )
                            }
                        );
                        this.setState({
                            showForm: true,
                            showWaiting3dSecure: false,
                            showSuccess: false
                        });
                    }
                }
            }
            this.setState({
                isContinuing: false
            });
        });

    }

    onSubmit = () => {
        this.props.form.validateFields(async (err, fields: UpgradePlanFormFields) => {
            if (!err) {
                if (this.injected.stripe) {
                    const { interval } = this.props;
                    const { subscriptionId, planId } = this.props.match.params;
                    const searchParams = new URLSearchParams(this.props.location.search);
                    const status = searchParams.get('status') as PaymentIntentStatus | null;

                    const paymentFlow = paymentFlowFactory(
                        this.injected.stripe, this.injected.paymentStore, status
                    );

                    try {
                        this.setState({
                            inProgress: true
                        });
                        await paymentFlow.tokenize(toStripeTokenizedOptions(fields));
                        await paymentFlow.pay(subscriptionId, planId, interval);
                        this.setState({
                            showSuccess: true,
                            showWaiting3dSecure: false,
                            showForm: false,
                            inProgress: false,
                            reviewModal: false
                        });
                        this.redirectAfterSuccessTimeoutId = window.setTimeout(
                            () => this.props.history.replace(PROFILE_ROUTE),
                            5000
                        );
                    } catch (error) {
                        this.setState({
                            inProgress: false,
                            reviewModal: false
                        });
                        await paymentFlow.handlePaymentError(
                            error,
                            {
                                onStart: () => {
                                    this.setState({
                                        showForm: false,
                                        showWaiting3dSecure: true,
                                        showSuccess: false
                                    });
                                },
                                onEnd: () => {
                                    this.setState({
                                        showSuccess: true,
                                        showWaiting3dSecure: false,
                                        showForm: false
                                    });

                                    this.redirectAfterSuccessTimeoutId = window.setTimeout(
                                        () => this.props.history.replace(PROFILE_ROUTE),
                                        5000
                                    );
                                },
                                onActionError: () => {
                                    notificationStore.openNotificationWithIcon(
                                        'error',
                                        {
                                            message: (
                                                <FormattedMessage
                                                    id="UpgradePlanForm.payment.errors.action"
                                                    defaultMessage={`
                                                        Failed to complete and verify the payment. Please try again.
                                                    `}
                                                />
                                            )
                                        }
                                    );
                                    this.setState({
                                        showSuccess: false,
                                        showWaiting3dSecure: false,
                                        showForm: true
                                    });
                                }
                            },
                            {
                                onEnd: () => {
                                    const urlSearchParams = new URLSearchParams();
                                    urlSearchParams.set('status', PaymentIntentStatus.REQUIRES_PAYMENT_METHOD);
                                    this.props.history.push({
                                        pathname: window.location.pathname,
                                        search: urlSearchParams.toString()
                                    });
                                    this.setState({
                                        showSuccess: false,
                                        showWaiting3dSecure: false,
                                        showForm: true
                                    });
                                    notificationStore.openNotificationWithIcon(
                                        'error',
                                        {
                                            message: (
                                                <FormattedMessage
                                                    id="UpgradePlanForm.payment.errors.failedPayment"
                                                    defaultMessage={`
                                                        Credit card was invalid and payment did not go through. 
                                                        Please try again.
                                                    `}
                                                />
                                            )
                                        }
                                    );
                                }
                            },
                            () => {
                                notificationStore.openNotificationWithIcon(
                                    'error',
                                    {
                                        message: (
                                            <FormattedMessage
                                                id="UpgradePlanForm.payment.errors.default"
                                                defaultMessage={`
                                                    An error occurred. Please check your credit card information.
                                                `}
                                            />
                                        )
                                    }
                                );
                                this.setState({
                                    showForm: true,
                                    showWaiting3dSecure: false,
                                    showSuccess: false
                                });
                            }
                        );
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        if (this.redirectAfterSuccessTimeoutId) {
            window.clearTimeout(this.redirectAfterSuccessTimeoutId);
        }
    }

    renderWithTooltipWhenEmptyBilling(component: React.ReactNode) {
        return (
            <Popover
                placement="bottom"
                content={
                    <FormattedMessage
                        id="UpgradePlanForm.labels.emptyBilling"
                        defaultMessage="You should fill your billing information before proceeding."
                    />
                }
            >
                {component}
            </Popover>
        );
    }

    renderCompleteOrderButton() {
        const { isSubscriptionBillingInfoEmpty } = this.injected.subscriptionStore;
        const { isContinuing } = this.state;
        return (
            <Button
                type="primary"
                onClick={this.tokenizePayment}
                loading={isContinuing}
                disabled={isSubscriptionBillingInfoEmpty}
            >
                <FormattedMessage
                    id="UpgradePlanForm.form.continue"
                    defaultMessage="Continue"
                />
            </Button>
        );
    }

    completeOrderRender() {
        const { isSubscriptionBillingInfoEmpty } = this.injected.subscriptionStore;
        return (
            <div className={classes.FormActionsContainer}>
                {
                    isSubscriptionBillingInfoEmpty ?
                        this.renderWithTooltipWhenEmptyBilling(this.renderCompleteOrderButton()) :
                        this.renderCompleteOrderButton()
                }
            </div>
        );
    }

    renderForm() {
        const { form: { getFieldDecorator } } = this.props;
        return (
            <React.Fragment>
                <Row gutter={16}>
                    <BillingInfo />
                    <Paper gutter={true}>
                        <Form
                            id="upgrade-plan-form"
                            className={classes.Form}
                        >
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Item
                                        label={
                                            <FormattedMessage
                                                id="UpgradePlanForm.form.labels.card_owner"
                                                defaultMessage="Card owner"
                                            />
                                        }
                                    >
                                        {getFieldDecorator('cardowner', {
                                            normalize: formFieldNormalize('emptyStringToUndefined'),
                                            rules: [{
                                                required: true
                                            }]
                                        })(<Input type="text" />)}
                                    </Item>
                                </Col>
                                <Col xs={24}>
                                    <Item
                                        label={
                                            <FormattedMessage
                                                id="UpgradePlanForm.form.labels.cardNumber"
                                                defaultMessage="Card Number"
                                            />
                                        }
                                        required={true}
                                    >
                                        <CardNumberElement
                                            className="ant-input"
                                            style={{
                                                base: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                        />
                                    </Item>
                                </Col>
                                <Col xs={12}>
                                    <Item
                                        label={
                                            <FormattedMessage
                                                id="UpgradePlanForm.form.labels.expireOn"
                                                defaultMessage="Expires on"
                                            />
                                        }
                                        required={true}
                                    >
                                        <CardExpiryElement
                                            className="ant-input"
                                            style={{
                                                base: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                        />
                                    </Item>
                                </Col>
                                <Col xs={12}>
                                    <Item
                                        label={
                                            <FormattedMessage
                                                id="UpgradePlanForm.form.labels.cvc"
                                                defaultMessage="CVC"
                                            />
                                        }
                                        required={true}
                                    >
                                        <CardCVCElement
                                            className="ant-input"
                                            style={{
                                                base: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                        />
                                    </Item>
                                </Col>
                            </Row>
                            {this.completeOrderRender()}
                        </Form>
                    </Paper>
                </Row>
            </React.Fragment>
        );
    }

    renderSuccess() {
        const iconClassname = classNames(classes.ActionIcon, classes.SuccessIcon);
        return (
            <div
                className={classes.ActionContainer}
            >
                <Icon
                    className={iconClassname}
                    type="check-circle"
                />
                <div>
                    <Typography variant="h4" align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.success.title"
                            defaultMessage="Congratulations!!!"
                        />
                    </Typography>
                    <Typography align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.success.paragraph1"
                            defaultMessage={
                                `Thank you for subscribing to ContentChef.`
                            }
                        />
                    </Typography>
                    <Typography variant="h6" align="center" className={classes.Footer}>
                        <FormattedMessage
                            id="UpgradePlanForm.payment.success.footer"
                            defaultMessage="Enjoy!!!"
                        />
                    </Typography>
                    <Typography align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.success.redirect"
                            defaultMessage={
                                `Shortly you will be redirected to your settings page.`
                            }
                        />
                    </Typography>
                </div>
            </div>
        );
    }

    render3dSecure() {
        const iconClassname = classNames(classes.ActionIcon, classes.WarningIcon);
        return (
            <div
                className={classes.ActionContainer}
            >
                <Icon
                    className={iconClassname}
                    type="warning"
                />
                <div>
                    <Typography variant="h4" align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.3dsecure.title"
                            defaultMessage="Action required"
                        />
                    </Typography>
                    <Typography align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.3dsecure.paragraph1"
                            defaultMessage="A popup will appear shortly to verify the payment."
                        />
                    </Typography>
                    <Typography align="center">
                        <FormattedMessage
                            id="UpgradePlanForm.payment.3dsecure.paragraph2"
                            defaultMessage="Please avoid refreshing and/or interacting with the website."
                        />
                    </Typography>
                </div>
            </div>
        );
    }

    openReviewModal = () => {
        this.setState({
            reviewModal: true
        });
    }

    closeReviewModal = () => {
        this.setState({
            reviewModal: false
        });
    }

    notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
        return value !== null && value !== undefined;
    }

    renderReviewOrderSection = () => {
        const { interval, match: { params: { planId } }, intl } = this.props;

        if (parseInt(planId, 10) === SubscriptionType.Enterprise) {
            return null;
        }

        const {
            name,
            price:
            { currency, monthly, yearly }
        } = getSubscriptionPlanProps(parseInt(planId, 10) as SubscriptionType, intl);
        const cost = interval === Interval.month ? monthly : yearly * 12;
        const { customerTaxRate } = this.injected.subscriptionStore;

        const planCostLabel =
            interval === Interval.month ?
                intl.formatMessage(labels.planCostPerMonth, { currency }) :
                intl.formatMessage(labels.planCostPerYear, { currency });

        const taxesOnPrice =
            customerTaxRate && !customerTaxRate.isTaxExempt ? cost as number * customerTaxRate.percentage / 100 : 0;
        const total =
            customerTaxRate && !customerTaxRate.isTaxExempt ?
                (cost as number + (taxesOnPrice)).toFixed(2) : cost;
        const entries = [
            {
                alignValue: 'right' as Align,
                label: name,
                value: `${cost} ${planCostLabel}`
            },
            taxesOnPrice && customerTaxRate && !customerTaxRate.isTaxExempt ? {
                alignValue: 'right' as Align,
                label: customerTaxRate.displayName,
                value: `+ ${taxesOnPrice.toFixed(2)} ${currency}`
            } : null,
            {
                alignValue: 'right' as Align,
                label: (
                    <span className={classes.TotalEntryDefault}>
                        <FormattedMessage
                            id="UpdatePlanForm.reviewOrder.total"
                            defaultMessage="Total"
                        />
                    </span>),
                value: (
                    <span
                        className={classNames(classes.TotalEntryDefault, classes.TotalEntryValue)}
                    >
                        {total} {planCostLabel}
                    </span>
                )
            }
        ].filter(this.notEmpty);
        return (
            <EntryList
                entries={entries}
            />
        );
    }

    render() {
        const { showForm, showSuccess, showWaiting3dSecure, inProgress } = this.state;
        const containerClassName = classNames(classes.FormContainer, {
            [classes.PaymentFeedback]: showSuccess || showWaiting3dSecure
        });
        return (
            <div className={containerClassName}>
                {showForm && this.renderForm()}
                {showSuccess && this.renderSuccess()}
                {showWaiting3dSecure && this.render3dSecure()}
                <Modal
                    title={
                        <Typography
                            align="center"
                            variant="h5"
                            className={classes.ReviewModalHeading}
                        >
                            <FormattedMessage id="ReviewOrderModal.title" defaultMessage="Review your order" />
                        </Typography>}
                    centered={true}
                    className={classes.ReviewModal}
                    visible={this.state.reviewModal}
                    onCancel={this.closeReviewModal}
                    footer={
                        <div className={classes.ReviewModalFooter}>
                            <Button
                                type="primary"
                                loading={inProgress}
                                onClick={this.onSubmit}
                            >
                                <FormattedMessage
                                    id="ReviewOrderModal.buttons.confirm"
                                    defaultMessage="Confirm"
                                />
                            </Button>
                        </div>}
                >
                    <BillingInfo allowEdit={false} />
                    {this.renderReviewOrderSection()}
                </Modal>
            </div>
        );
    }
}

export default injectStripe(
    withSubscriptions(withFormInitialization(withForm(withRouter(withPaymentStores(injectIntl(UpgradePlanForm))))))
);
