import { Component } from 'react';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { DynamicFieldProps, DynamicFieldState, InjectedProps } from './DynamicFieldModel';
import {
    DefaultFormFieldMatchersFactory
} from '../../../services/FormUtils/FormFieldMatchers/FormFieldMatchersFactory';
import { debounce } from '@services/utils';

@observer
class DynamicField extends Component<DynamicFieldProps, DynamicFieldState> {

    debouncedOnChange: (value: any) => any;

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: DynamicFieldProps) {
        super(props);

        this.debouncedOnChange = debounce(this.injected.onChange, 400);
    }

    onCustomChange = (e: any) => {
        const { verifyFieldHasClientError: hasClientErrors } = this.props;
        let value = e;
        if (e && e.target) {
            value = e.target.value;
        }
        this.props.fieldData.setFieldValue(value);
        this.props.fieldData.unsetValidationError();

        const onChangeResult = this.debouncedOnChange(this.props.fieldData.getSubmittableValue());
        this.props.fieldData.setHasClientErrors(hasClientErrors());

        return onChangeResult;
    }

    maybeCreateField = () => {
        const { fieldData } = this.props;
        const { locale } = this.props.intl;
        const { id } = this.injected;
        return DefaultFormFieldMatchersFactory
            .getInstance()
            .maybeCreateComponent(id, locale, this.onCustomChange, fieldData);
    }

    render() {
        return this.maybeCreateField();
    }
}

export default injectIntl(DynamicField);
