import { ReactStripeElements } from 'react-stripe-elements';
import { PaymentStore } from '../../stores';
import { PaymentFlow } from './PaymentFlow';
import { Interval } from '@contentchef/contentchef-types';

export class RequiresPaymentMethodFlow extends PaymentFlow {
    constructor(
        stripe: ReactStripeElements.StripeProps,
        paymentStore: PaymentStore
    ) {
        super(stripe, paymentStore);
    }

    async pay(subscriptionId: string, planId: string, interval: Interval, token?: stripe.Token) {
        const tokenToUse = token ? token.id : this.token.id;
        try {
            return await this.paymentStore.attemptPayment(
                subscriptionId, parseInt(planId, 10), tokenToUse
            );
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
