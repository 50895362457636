import React from 'react';
import classes from './index.module.scss';
import { FormattedMessage } from 'react-intl';

export default function OnboardingPrivacy() {
    return (
        <div className={classes.OnboardingPrivacy}>
            <FormattedMessage
                defaultMessage="By signing up, you agree to our"
                id="components.onboarding.OnboardingPrivacy.text.first"
            />
            <a href="https://www.iubenda.com/privacy-policy/41328736" target="_blank">
                <FormattedMessage
                    defaultMessage="Data Policy"
                    id="components.onboarding.OnboardingPrivacy.dataPolicy"
                />
            </a>
            <FormattedMessage
                defaultMessage="and our"
                id="components.onboarding.OnboardingPrivacy.text.second"
            />
            <a href="https://www.iubenda.com/privacy-policy/41328736/cookie-policy?an=no&s_ck=false" target="_blank">
                <FormattedMessage
                    defaultMessage="Cookies Policy"
                    id="components.onboarding.OnboardingPrivacy.cookiePolicy"
                />
            </a>
        </div>
    );
}
