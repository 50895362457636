import { InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

export interface SideBarProps extends InjectedIntlProps, RouteComponentProps<{ spaceId: string }> { }

export interface SideBarState {
    activeKey: SidebarItems;
}

export enum SidebarItems {
    DASHBOARD = 'dashboard',
    CONTENTS = 'contents',
    CONTENT_DEFINITIONS = 'content-definitions',
    MEDIA_GALLERIES = 'media-galleries',
    PUBLISH_TO_LIVE = 'publishtolive',
    SETTINGS = 'settings',
    PUBLISHING_CHANNELS = 'publishing-channels',
    REPOSITORIES = 'repositories',
    USERS_AND_PERMISSIONS = 'users-and-permissions',
    WEBHOOKS = 'webhooks',
    SpaceKeys = 'api-keys',
    EXTENSIONS = 'extensions'
}
