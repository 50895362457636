import React from 'react';
import moment from 'moment';
import {
    Row,
    Col,
    Tooltip,
    List, Icon
} from 'antd';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { RoundAvatar } from '..';
import { ActivityPanelProps } from './ActivityPanelModel';
import { activityTypeLabels, activityLabels } from '../../constants/activity-constants';

import styles from './ActivityPanel.module.scss';

const ActivityIcon = {
    content: (
        <Icon
            className={styles.ActivityIcon}
            type="database"
        />
    ),
    content_definition: (
        <Icon
            className={styles.ActivityIcon}
            type="book"
        />
    ),
    repository: (
        <Icon
            className={styles.ActivityIcon}
            type="appstore"
        />
    ),
    media_gallery: (
        <Icon
            className={styles.ActivityIcon}
            type="picture"
        />
    ),
    publishing_channel: (
        <Icon
            className={styles.ActivityIcon}
            type="cluster"
        />
    ),
};

const ActivityListItem = (activity, formatMessage) => (
    <Row type="flex" align="middle" justify="center" className={styles.ActivityPanelRowContainer}>
        <Col xs={4} className={styles.ActivityStatusCol}>
            {ActivityIcon[activity.subjectType]}
        </Col>
        <Col xs={20}>
            <Row type="flex" align="middle" justify="center">
                <Col xs={24} md={12} className={styles.ActivityStatusCol}>
                    <div className={styles.ActivityStatusWrapper}>
                        <Tooltip placement="top" title={activity.actionAuthor.fullName}>
                            <div>
                                <RoundAvatar name={activity.actionAuthor.fullName} size={28} />
                            </div>
                        </Tooltip>
                        <div className={styles.DateRow}>
                            <div id="activity_date" className={styles.DateContent}>
                                {moment(activity.actionDate).format('DD/MM/YYYY')}
                            </div>
                            <div id="activity_time" className={styles.DateContent}>
                                {moment(activity.actionDate).format('hh:mm a')}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12} className={styles.ActivityDetailsCol}>
                    <div className={styles.ActivityDetailsWrapper}>
                        <div className={styles.DetailsRepository}>
                            {formatMessage(activityTypeLabels[activity.subjectType])}
                        </div>
                        <div className={styles.DetailsTitle}>
                            <Tooltip placement="top" title={activity.subjectTitle}>
                                <p>{activity.subjectTitle}</p>
                            </Tooltip>
                        </div>
                        <div className={styles.DetailsLastAction}>
                            {
                                formatMessage(
                                    activityLabels[activity.subjectAction],
                                    {
                                        subjectType: formatMessage(activityTypeLabels[activity.subjectType])
                                    }
                                )
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
);

const ActivityPanel = ({ activity, activityLink, intl: { formatMessage } }: ActivityPanelProps) => (
    <List.Item className={styles.ActivityPanel}>
        {!!activityLink ? (
            <Link to={activityLink} className={styles.ActivityPanelLink}>
                {ActivityListItem(activity, formatMessage)}
            </Link>
        ) : (
                ActivityListItem(activity, formatMessage)
            )}
    </List.Item>
);

export default injectIntl(ActivityPanel);
