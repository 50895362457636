import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LOGIN_ROUTE } from '../../constants/routing-constants';

export interface LogoutButtonProps extends RouteComponentProps {
    children?: React.ReactNode;
    onClick?(event: React.MouseEvent<HTMLSpanElement>): any;
}

class LogoutButton extends React.Component<LogoutButtonProps> {
    public render() {
        const {
            children,
            onClick,
        } = this.props as LogoutButtonProps;

        return (
            <span
                onClick={
                    event => {
                        if (typeof onClick === 'function') {
                            onClick(event);
                        }

                        Auth.signOut().then(() => {
                            location.href = LOGIN_ROUTE;
                        });
                    }
                }
            >
                {
                    children
                }
            </span>
        );
    }
}

export default withRouter(LogoutButton);
