import React from 'react';
import { Input, Form, Divider } from 'antd';
import { injectIntl, defineMessages, InjectedIntlProps } from 'react-intl';
import SignInWithGoogle from './SignInWithGoogle';
import classes from './index.module.scss';
import OnboardingContainer from './OnboardingContainer';
import OnboardingPrivacy from './OnboardingPrivacy';
import { Auth } from 'aws-amplify';
import { RouteComponentProps, Route } from 'react-router-dom';
import { ROOT_ROUTE, REGISTRATION_ROUTE } from '@constants/routing-constants';
import Button from '@components/Button';
import SignInImage from '../../assets/images/signInFormImage.svg';
import { Helmet } from 'react-helmet';
import withTracker from '../../hoc/withTracker/withTracker';
import { ISignInProps } from 'aws-amplify-react/lib/Auth/SignIn';

const {
    Item,
} = Form;

const signInLabels = defineMessages({
    title: {
        defaultMessage: 'Sign in and start delivering your content',
        id: 'components.onboarding.signIn.title'
    },
    or: {
        defaultMessage: 'or',
        id: 'components.onboarding.signIn.or'
    },
    email: {
        defaultMessage: 'Email',
        id: 'components.onboarding.SignIn.email'
    },
    password: {
        defaultMessage: 'Password',
        id: 'components.onboarding.SignIn.Password'
    },
    primaryAction: {
        defaultMessage: 'Sign in',
        id: 'components.onboarding.signIn.signIn'
    },
    orSignUp: {
        defaultMessage: `or if you don't have an account you can`,
        id: 'components.onboarding.signIn.orSignUp'
    },
    secondaryAction: {
        defaultMessage: 'Sign up',
        id: 'components.onboarding.signIn.signUp'
    },
    forgotPassword: {
        id: 'components.onboarding.SignIn.ForgotPassword',
        defaultMessage: 'Forgot your password?'
    }
});

interface SignInProps extends ISignInProps, RouteComponentProps, InjectedIntlProps { }

interface SignInState {
    email: string;
    loading: boolean;
    password: string;
}

function getInitialState(): SignInState {
    return {
        email: '',
        loading: false,
        password: '',
    };
}

class SignIn extends React.Component<SignInProps, SignInState> {
    public state = getInitialState();

    public constructor(props: any) {
        super(props);
    }

    public onError(error: unknown): any {
        if (error === null) {
            return;
        }

        if (typeof error === 'string') {
            return this.props.onAuthEvent!('signIn', {
                data: error,
                type: 'error',
            });
        }

        if (typeof error === 'object' && error !== null) {
            const message = (error as any).message;

            return this.props.onAuthEvent!('signIn', {
                type: 'error',
                data: message ? message : JSON.stringify(error)
            });
        }
    }

    public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [event.target.name]: event.target.value,
        } as any);
    }

    public signIn = async () => {
        const {
            password,
            email: username,
        } = this.state;

        this.setState({ loading: true });

        try {
            await Auth.signIn({ password, username });

            const session = await Auth.currentSession();

            if (session.isValid()) {
                this.props.onStateChange!('signedIn', {});
            }
        } catch (error) {
            this.onError(error);
        }

        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps: SignInProps & RouteComponentProps) {
        if ((prevProps.authState !== this.props.authState) && this.props.authState === 'signedIn') {
            this.props.history.push(ROOT_ROUTE);
        }
    }

    public render() {
        const {
            email,
            loading,
            password,
        } = this.state;

        if (this.props.authState !== 'signIn') {
            return null;
        }

        return (
            <React.Fragment>
                <OnboardingContainer
                    topContent={this.props.intl.formatMessage(signInLabels.title)}
                    leftContent={<img style={{ width: '100%' }} src={SignInImage} alt={'Sign up image'} />}
                >
                    <Form className={classes.OnboardingForm}>
                        {process.env.REACT_APP_ENABLE_GOOGLE_AUTH === 'true' && (<>
                            <Item>
                                <SignInWithGoogle disabled={loading} />
                            </Item>
                            <Divider className={classes.OnboardingOr}>
                                {this.props.intl.formatMessage(signInLabels.or)}
                            </Divider>
                        </>)}
                        <Item>
                            <Input
                                placeholder={this.props.intl.formatMessage(signInLabels.email)}
                                disabled={loading}
                                onChange={this.handleInputChange}
                                name="email"
                                value={email}
                            />
                        </Item>
                        <Item>
                            <Input
                                placeholder={this.props.intl.formatMessage(signInLabels.password)}
                                disabled={loading}
                                onChange={this.handleInputChange}
                                name="password"
                                type="password"
                                value={password}
                            />
                            <a onClick={() => this.props.onStateChange!('forgotPassword', {})}>
                                {this.props.intl.formatMessage(signInLabels.forgotPassword)}
                            </a>
                        </Item>
                        <Item>
                            <Button
                                className={classes.OnboardingButton}
                                htmlType="submit"
                                disabled={loading}
                                loading={loading}
                                onClick={this.signIn}
                                type="primary"
                            >
                                {this.props.intl.formatMessage(signInLabels.primaryAction)}
                            </Button>
                        </Item>
                        <Item className={classes.OnboardingOrSignup}>
                            {this.props.intl.formatMessage(signInLabels.orSignUp)}
                            <a
                                style={{ marginLeft: 5 }}
                                onClick={() => {
                                    this.props.onStateChange!('signUp', {});
                                    this.props.history!.push(REGISTRATION_ROUTE);
                                }}
                            >
                                {this.props.intl.formatMessage(signInLabels.secondaryAction)}
                            </a>
                        </Item>
                        <Item>
                            <OnboardingPrivacy />
                        </Item>
                    </Form>
                </OnboardingContainer>
            </React.Fragment>
        );
    }
}

export const SignInComponent = injectIntl(SignIn);

export function SignInWithRoute(props: any) {
    return (
        <Route
            path="/login"
            component={withTracker((rProps: any) => (
                <React.Fragment>
                    <Helmet>
                        <title>
                            Login - ContentChef
                        </title>
                        <link rel="canonical" href="https://app.contentchef.io/login" />
                        <meta name="description" content="Login for ContentChef Dashboard" />
                    </Helmet>
                    <SignInComponent {...{ ...props, ...rProps }} />
                </React.Fragment>
            ))}
        />
    );
}
