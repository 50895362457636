import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import withExtensionAPI from '@hoc/withExtensionAPI';
import { useExtensionAPIStore } from '@hooks/mobx';
import withDefinitionErrors, { WithDefinitionErrorsReturnProps } from '@hoc/withDefinitionErrors/withDefinitionErrors';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import Select from '../Fields/Select';
import Segment from '@components/Segment/Segment';
import { Form } from 'antd';
import { SchemaTypeIds } from '@contentchef/contentchef-types';

interface ExtensionProps extends WithDefinitionErrorsReturnProps, InjectedIntlProps {
    value?: string;
    type: SchemaTypeIds;
    onChange(value?: string): any;
}

function Extension(props: ExtensionProps) {

    const extensionAPIStore = useExtensionAPIStore();

    useEffect(
        () => {
            async function list() {
                await extensionAPIStore.setExtensions();
            }
            list();
        },
        []
    );

    function buildOptions() {
        return extensionAPIStore.extensions
            .filter(extension => extension.supportedTypes.includes(props.type))
            .map(extension => {
                return {
                    value: extension.mnemonicId,
                    label: extension.name
                };
            });
    }

    return (
        <Segment
            divider={true}
            title={
                <FormattedMessage
                    id="omponents.ContentDefinitionSchemaEditor.ContentDefinitionFieldEditing.Extension"
                    defaultMessage="Extension"
                />
            }
            titleVariant="label"
        >
            <Form.Item
                help={props.hasErrors ? props.errors.map(e => props.intl.formatMessage(e)).join(', ') : undefined}
                validateStatus={props.hasErrors ? 'error' : undefined}
                style={{ width: '100%' }}
            >
                <Select
                    onChange={props.onChange}
                    value={props.value}
                    options={buildOptions()}
                />
            </Form.Item>
        </Segment>
    );
}

export default injectIntl(withDefinitionErrors(withExtensionAPI(observer(Extension))));
