import React from 'react';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldCloudinaryRaw } from '../FormFields';
import { CloudinaryRawField } from '../../../components';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class CloudinaryRawTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.CLOUDINARY_RAW)) { return null; }
        const fieldCloudinaryRaw = field as FieldCloudinaryRaw;

        function elementToRender(disabled: boolean) {
            return (
                <CloudinaryRawField
                    id={id}
                    disableActions={disabled}
                    fieldData={fieldCloudinaryRaw}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
