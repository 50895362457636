import React, { Component } from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Button, Col, Form, Input, Row } from 'antd';
import {
    UpdateContentDefinitionFormProps,
    UpdateContentDefinitionFormState,
    DecoratedProps
} from './UpdateContentDefinitionFormModel';
import styles from './UpdateContentDefinitionForm.module.scss';
import { withForm } from '../../../hoc';
import { withPermissionsToInteract } from '../../../providers/UserPermissionsProvider';
import ContentDefinitionSchemaEditor from '../../ContentDefinitionSchemaEditor';
import ContentDefinitionEditCustomFields from '../../ContentDefinitionSchemaEditor/ContentDefinitionEditCustomFields';
import ContentDefinitionDefaultLang from '../ContentDefinitionDefaultLang/ContentDefinitionDefaultLang';
import Section from '../../Section/Section';
import { debounce } from '../../../services/utils';
import {
    isAnEmptyJsonObject,
    isAValidSchemaObject,
    isValidJsonObject
} from '../../../constants/json-editor-constants';
import MonacoEditorJSON from '../../MonacoEditorJSON';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import NotificationPopover from '../../NotificationPopover/NotificationPopover';

const FormItem = Form.Item;
const UpdateContentDefinitionFormLabels = defineMessages({
    nameField: {
        id: 'UpdateContentDefinitionFormLabels.nameField',
        defaultMessage: 'Name'
    },
    mnemonicIdField: {
        id: 'UpdateContentDefinitionFormLabels.mnemonicIdField',
        defaultMessage: 'Mnemonic Id'
    },
    defaultLanguage: {
        id: 'UpdateContentDefinitionFormLabels.defaultLanguage',
        defaultMessage: 'Labels\' default language'
    },
    schemaField: {
        id: 'UpdateContentDefinitionFormLabels.schemaField',
        defaultMessage: 'Content Definition Schema'
    },
    notValidJsonObjectError: {
        id: 'UpdateContentDefinitionFormLabels.notValidJsonObjectError',
        defaultMessage: 'schema is not a valid JSON object'
    },
    invalidEmptyObject: {
        id: 'UpdateContentDefinitionFormLabels.invalidEmptyObject',
        defaultMessage: 'schema cannot be an empty JSON object'
    },
    invalidSchemaObject: {
        id: 'UpdateContentDefinitionFormLabels.invalidSchemaObject',
        defaultMessage: 'missing mandatory key lang, fields and fragments'
    },
    corruptSchemaTooltipTitle: {
        id: 'UpdateContentDefinitionFormLabels.tooltip.title',
        defaultMessage: 'Corrupt json schema'
    },
    corruptSchemaTooltipMessage: {
        id: 'UpdateContentDefinitionFormLabels.corruptSchemaTooltipMessage',
        defaultMessage: 'Fix all the problems before to switch back to custom editor.'
    }
});

@inject('contentDefinitionSchemaStore', 'contentDefinitionFormStore')
@observer
class UpdateContentDefinitionForm extends
    Component<UpdateContentDefinitionFormProps, UpdateContentDefinitionFormState> {
    debouncedOnChangeMonacoEditorValue: (value: string) => void;
    state: UpdateContentDefinitionFormState = {
        customEditor: true,
        isSchemaValid: true,
        isSchemaCorrupt: false,
        jsonSchemaError: ''
    };

    get decoratedProps() { return this.props as DecoratedProps; }

    constructor(props: UpdateContentDefinitionFormProps) {
        super(props);
        this.debouncedOnChangeMonacoEditorValue = debounce(this.onChangeMonacoEditorValue, 700);
    }

    retrieveLocalStorageValue = () => {
        const value = localStorage.getItem('developerMode');
        return !(value === null || value === 'false');
    }

    onClickChangeEditor = () => {
        if (!this.state.customEditor) {
            if (this.decoratedProps.contentDefinitionSchemaStore.isJsonSchemaValidForCustomEditorSchema()) {
                this.decoratedProps.contentDefinitionSchemaStore.setCustomEditorSchema();
                this.decoratedProps.contentDefinitionSchemaStore.cleanJsonEditorSchema();
                this.setState({ customEditor: !this.state.customEditor });
            } else {
                this.setState({ isSchemaCorrupt: true });
            }
        } else {
            this.decoratedProps.contentDefinitionSchemaStore.setJsonEditorSchema(
                this.decoratedProps.contentDefinitionSchemaStore.retrieveDeserializedDefinitionSchema()
            );
            this.setState({ customEditor: !this.state.customEditor });
        }
    }

    onChangeMonacoEditorValue = (value: string) => {
        if (!isValidJsonObject(value)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    UpdateContentDefinitionFormLabels.notValidJsonObjectError
                )
            });
        }
        const result = JSON.parse(value);
        if (isAnEmptyJsonObject(result)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    UpdateContentDefinitionFormLabels.invalidEmptyObject
                )
            });
        }
        if (!isAValidSchemaObject(result)) {
            return this.setState({
                isSchemaValid: false,
                jsonSchemaError: this.props.intl.formatMessage(
                    UpdateContentDefinitionFormLabels.invalidSchemaObject
                )
            });
        }
        this.decoratedProps.contentDefinitionSchemaStore.setJsonEditorSchema(result);
        return this.setState({
            isSchemaValid: true,
            jsonSchemaError: ''
        });
    }

    onNotificationChangeState = (visible: boolean) => {
        if (!visible) {
            this.setState({ isSchemaCorrupt: false });
        }
    }

    printSwitchEditorButton = () => {
        return this.state.customEditor
            ? (
                <Button icon={'swap'} type="dashed" onClick={this.onClickChangeEditor}>
                    <FormattedMessage
                        id="UpdateContentDefinitionFormLabels.Button.JSONEditor"
                        defaultMessage="JSON Editor"
                    />
                </Button>
            ) : (
                <NotificationPopover
                    visible={this.state.isSchemaCorrupt}
                    title={this.props.intl.formatMessage(UpdateContentDefinitionFormLabels.corruptSchemaTooltipTitle)}
                    content={
                        this.props.intl.formatMessage(UpdateContentDefinitionFormLabels.corruptSchemaTooltipMessage)
                    }
                    onVisibleChangeCallback={this.onNotificationChangeState}
                    placement={'bottomLeft'}
                    iconType="close-circle"
                >
                    <Button icon={'swap'} type="dashed" onClick={this.onClickChangeEditor}>
                        <FormattedMessage
                            id="UpdateContentDefinitionFormLabels.Button.CustomEditor"
                            defaultMessage="Custom Editor"
                        />
                    </Button>
                </NotificationPopover>
            );
    }

    trimValue = ({ target }) => {
        const { id, value } = target;
        this.props.form.setFieldsValue({ [id]: value.trim() });
    }

    render() {
        const {
            hasPermissions,
            form: { getFieldDecorator },
            formData,
            intl: { formatMessage },
            jsonSchema
        } = this.props;
        const { schema } = this.decoratedProps.contentDefinitionSchemaStore;
        return (
            <Section
                header={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {this.retrieveLocalStorageValue() && this.printSwitchEditorButton()}
                    {this.state.customEditor && <ContentDefinitionEditCustomFields />}
                </div>}
            >
                <Form className={styles.FormContainer}>
                    <Row gutter={12}>
                        <Col xs={24} lg={10}>
                            <FormItem
                                label={formatMessage(UpdateContentDefinitionFormLabels.nameField)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {getFieldDecorator('name', {
                                    initialValue: formData.name,
                                    rules: [{ required: true }]
                                })(
                                    <Input disabled={!hasPermissions} onBlur={this.trimValue} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={24} lg={10}>
                            <FormItem
                                label={formatMessage(UpdateContentDefinitionFormLabels.mnemonicIdField)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {getFieldDecorator('mnemonicId', {
                                    initialValue: formData.mnemonicId
                                })(
                                    <Input disabled={true} />
                                )}
                            </FormItem>
                        </Col>
                        {this.state.customEditor && <Col xs={24} md={8} xl={6}>
                            <FormItem
                                label={formatMessage(UpdateContentDefinitionFormLabels.defaultLanguage)}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {
                                    getFieldDecorator('defaultLanguage', {
                                        initialValue: formData.schema.lang,
                                        rules: [{ required: true }]
                                    })(
                                        <ContentDefinitionDefaultLang hasPermissions={hasPermissions} />
                                    )
                                }
                            </FormItem>
                        </Col>}
                    </Row>

                    {this.state.customEditor && <Row gutter={12} style={{ flex: 1, overflowY: 'auto' }}>
                        <Col xs={{ span: 24 }} style={{ height: '100%' }}>
                            <ContentDefinitionSchemaEditor
                                gutter={true}
                                fields={schema.fields}
                            />
                        </Col>
                    </Row>}

                    {!this.state.customEditor && <Row gutter={12} className={styles.JsonEditorRowContainer}>
                        <Col xs={24} className={'ant-form-item-label'}>
                            <label htmlFor={'schema'} className={'ant-form-item-required'}>
                                {formatMessage(UpdateContentDefinitionFormLabels.schemaField)}
                            </label>
                        </Col>
                        <Col
                            xs={24}
                            className={classNames(styles.SchemaEditorColContainer, 'ant-form-item-control-wrapper')}
                        >
                            <MonacoEditorJSON
                                onChange={this.onChangeMonacoEditorValue}
                                id="schema"
                                hasPermissions={hasPermissions}
                                schema={jsonSchema}
                            />
                        </Col>
                        <Col
                            xs={24}
                            className={classNames(styles.SchemaEditorWithoutErrorContainer, {
                                [styles.SchemaEditorWithErrorContainer]: !this.state.isSchemaValid
                            })}
                        >
                            {!this.state.isSchemaValid && <label htmlFor={'schema'}>
                                {this.state.jsonSchemaError}
                            </label>}
                        </Col>
                    </Row>}
                </Form>
            </Section>
        );
    }
}

export default withPermissionsToInteract(withForm(injectIntl(UpdateContentDefinitionForm)));
