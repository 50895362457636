import React, { Component } from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { Form, Row, Icon, Button, Drawer, List } from 'antd';
import { DynamicContentField, ContentItem } from '../..';
import { EditContentFormProps, EditContentFormState, InjectedProps } from './EditContentFormModel';
import StaticContentField from '../StaticContentField/StaticContentField';
import styles from './EditContentForm.module.scss';
import { withForm } from '../../../hoc';
import { ColumnProps } from 'antd/lib/table';
import { LinkingContents, ContentResponse } from '@contentchef/contentchef-types';
import { IObservableArray } from 'mobx';
import { withRouter, NavLink } from 'react-router-dom';
import { editContentPath } from '../../../constants/routing-constants';
import ContentInfo from '../../ContentInfo/ContentInfo';
import ContentStatusSection from '../../ContentStatusSection';

const editContentFormLabels = defineMessages({
    usedByButtonTitle: {
        id: 'EditContentForm.usedBy.title',
        defaultMessage: 'This content gets used by:'
    }
});

const getInitialState = (): EditContentFormState => ({
    modalVisible: false,
});

@inject('editContentStore', 'contentStatusStore')
@observer
class EditContentForm extends Component<EditContentFormProps, EditContentFormState>  {
    get injected() {
        return this.props as InjectedProps;
    }

    public state = getInitialState();

    public closeDialog = () =>
        this.setState(state =>
            ({
                ...state, modalVisible: false
            })
        )

    createDynamicFields = () => {
        const { fields } = this.props.dynamicFields;
        const fieldsToRender: JSX.Element[] = [];
        for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
                const fieldData = fields[key];
                if (fieldData) {
                    fieldsToRender.push(
                        <DynamicContentField
                            key={fieldData.id}
                            genericFieldMeta={fieldData}
                        />
                    );
                }
            }
        }
        return fieldsToRender;
    }

    public getDataSource(linkedContent: IObservableArray<LinkingContents> | undefined): any[] {
        if (!linkedContent) {
            return [];
        }
        return linkedContent.map(({ name, id }, key) => (
            {
                key,
                name: typeof name === 'string' && name.length > 100 ? name.substr(0, 97) + '...' : name,
                id,
            }
        ));
    }

    public getTableColumns(): Array<ColumnProps<LinkingContents>> {
        return [
            {
                dataIndex: 'id',
                key: 'id',
                title: 'ID',
            },
            {
                align: 'left',
                dataIndex: 'name',
                key: 'name',
                title: 'Name',
            },
            {
                render: (text, content) => {
                    const EditButton = withRouter(props => (
                        <NavLink
                            to={editContentPath(props.match.params.spaceId, content.id)}
                        >
                            <Icon type="edit" />
                        </NavLink>
                    ));

                    return <EditButton />;
                }
            }
        ];
    }

    render() {
        const {
            fieldName,
            fieldRepository,
            fieldPublicId,
            fieldOnlineRange,
            fieldTags,
            fieldChannels,
            match: { params: { spaceId } },
            intl: { formatMessage }
        } = this.props;
        const { initialContentData, linkedContent } = this.injected.editContentStore.editContentData;
        const { status } = this.injected.contentStatusStore;

        return (
            !!initialContentData && (
                <div
                    id={initialContentData.definition.id.toString()}
                    className={styles.EditFormContainer}
                >
                    <Row
                        className={styles.EditFormContainerInfoSectionContainer}
                    >
                        <div className={styles.EditFormContainerInfoItem}>
                            <ContentStatusSection
                                currentChannels={initialContentData.channels.map(channel => channel.mnemonicId)}
                                status={status}
                                currentVersion={initialContentData.version}
                            />
                        </div>
                        <div className={styles.EditFormContainerInfoItem}>
                            <Button
                                type="ghost"
                                icon="link"
                                onClick={() => this.setState(state => ({ ...state, modalVisible: true }))}
                                title={formatMessage(editContentFormLabels.usedByButtonTitle)}
                            >
                                <FormattedMessage
                                    id="EditContentForm.usedBy"
                                    defaultMessage={`Used by {numberOfContents, number} {numberOfContents, plural,
                                                    one {content} other {contents}}`}
                                    values={{ numberOfContents: linkedContent ? linkedContent.length : 0 }}
                                />
                            </Button>
                            <Drawer
                                closable={false}
                                width={'50%'}
                                visible={this.state.modalVisible}
                                onClose={this.closeDialog}
                            >
                                <List
                                    dataSource={linkedContent}
                                    renderItem={(item: ContentResponse) => (
                                        <NavLink
                                            style={{ color: 'inherit' }}
                                            to={editContentPath(spaceId, item.id)}
                                        >
                                            <ContentItem
                                                content={item}
                                                showActions={false}
                                            />
                                        </NavLink>
                                    )}
                                    pagination={linkedContent && linkedContent.length > 0 ? {
                                        defaultCurrent: 1,
                                        hideOnSinglePage: true,
                                        pageSize: 5,
                                        position: 'both',
                                        total: linkedContent.length,
                                    } : false}
                                />
                            </Drawer>
                        </div>
                        <div className={styles.EditFormContainerInfoItem}>
                            <ContentInfo />
                        </div>

                    </Row>
                    <Form data-id="edit_form">
                        <Row type="flex" justify="start" >
                            <StaticContentField
                                contentCreationFlow={false}
                                definition={initialContentData.definition}
                                fieldName={fieldName}
                                fieldRepository={fieldRepository}
                                fieldPublicId={fieldPublicId}
                                fieldOnlineRange={fieldOnlineRange}
                                fieldTags={fieldTags}
                                fieldChannels={fieldChannels}
                            />
                            {this.createDynamicFields()}
                        </Row>
                    </Form>
                </div>
            )
        );
    }
}

export default withRouter(withForm(injectIntl(EditContentForm)));
