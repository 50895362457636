import React, { Component } from 'react';
import { MediaDetails } from '../index';
import { MediaPreviewProps, MediaPreviewState } from './MediaPreviewModel';

import styles from './MediaPreview.module.scss';
import { CLOUDINARY_IMAGE, CLOUDINARY_VIDEO, CLOUDINARY_RAW } from '../../services/cloudinaryUtils';
import { Icon } from 'antd';

class MediaPreview extends Component<MediaPreviewProps, MediaPreviewState> {

    renderMedia = () => {
        const { mediaData } = this.props;
        switch (mediaData.data.resourceType) {
            case CLOUDINARY_VIDEO:
            case CLOUDINARY_IMAGE:
                return (
                    <img
                        src={mediaData.data.thumbnailUrl}
                        className={styles.MediaPreviewImage}
                    />
                );
            case CLOUDINARY_RAW:
                return (
                    <Icon
                        className={styles.MediaPreviewRawFile}
                        type="file"
                        twoToneColor="#00AEEF"
                        theme="twoTone"
                    />
                );
            default:
                return (
                    <Icon
                        className={styles.MediaPreviewRawFile}
                        type="file"
                        twoToneColor="#00AEEF"
                        theme="twoTone"
                    />
                );
        }
    }

    render() {
        const { mediaData, updateMediaCallback } = this.props;
        return (
            <React.Fragment>
                <div
                    id="mediaImageContainer"
                    className={styles.mediaImageContainer}
                    data-media-detail={mediaData.retrieveImageName()}
                >
                    {this.renderMedia()}
                </div>
                <div id="mediaDataContainer" className={styles.mediaDataContainer}>
                    <MediaDetails media={mediaData} onUpdateMedia={updateMediaCallback} />
                </div>
            </React.Fragment>
        );
    }
}

export default MediaPreview;
