import { DynamicFieldTypes } from '../FormFields';
import { SchemaTypeIds } from '@contentchef/contentchef-types';

export enum ConstraintsToMatch {
    listOfValues = 'listOfValues'
}

const generateFieldPlaceholder = (field: DynamicFieldTypes, userLocale: string): string | undefined => {
    return !!field.placeholder
        ? field.placeholder[userLocale] || field.placeholder[field.locale]
        : undefined;
};

function typeMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds): boolean {
    return field.type === typeToMatch;
}

function typeAndConstraintMatcher(field: DynamicFieldTypes, typeToMatch: SchemaTypeIds, constraint: string): boolean {
    return field.type === typeToMatch && !!field.constraints[constraint];
}

export const fieldMatchersUtils = {
    generateFieldPlaceholder,
    typeMatcher,
    typeAndConstraintMatcher
};
