import React, { Component, DragEvent, ChangeEvent, MouseEvent } from 'react';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {
    CloudinaryImageUploadProps,
    CloudinaryImageUploadState,
    InjectedProps
} from './CloudinaryImageUploadModel';
import Button from '@components/Button';

import styles from './CloudinaryImageUpload.module.scss';
import { NOTIFICATION_KEY_CONSTANTS } from '../../constants/notifications-constants';
import { Media } from '../../stores/Media';
import MediaToUpload from '../MediaToUpload';
import classNames from 'classnames';

const cloudinaryImageUploadLabels = defineMessages({
    uploadMessage_inProgress: {
        id: 'CloudinaryImageUpload.uploadMessage_inProgress',
        defaultMessage: 'Uploading'
    },
    uploadDescription_inProgress: {
        id: 'CloudinaryImageUpload.uploadDescription_inProgress',
        defaultMessage: 'Waiting for cloudinary...'
    },
    uploadMessage_success: {
        id: 'CloudinaryImageUpload.uploadMessage_success',
        defaultMessage: 'Successfully uploaded media {name}'
    },
    uploadMessage_failure: {
        id: 'CloudinaryImageUpload.uploadMessage_failure',
        defaultMessage: 'Failed to upload media {name}'
    },
    uploadMessage_failure_limit: {
        id: 'CloudinaryImageUpload.uploadMessage_failure_limit',
        defaultMessage: 'File {name} is too large.'
    },
    uploadMessage_error: {
        id: 'CloudinaryImageUpload.uploadMessage_error',
        defaultMessage: 'Upload Error'
    },
    uploadDescription_error: {
        id: 'CloudinaryImageUpload.uploadDescription_error',
        defaultMessage: 'Failed to upload resource {fileName}'
    },
    fileNotSupported: {
        id: 'CloudinaryImageUpload.fileNotSupported',
        defaultMessage: 'File selected is not supported'
    },
    backToMediaGallery: {
        id: 'CloudinaryImageUpload.backToGallery',
        defaultMessage: 'Back to media gallery'
    }
});

@inject('mediaGalleryFlowStore', 'notificationStore', 'mediaTagsStore')
@observer
class CloudinaryImageUpload extends Component<CloudinaryImageUploadProps, CloudinaryImageUploadState> {

    browseFile: HTMLInputElement | null;
    debounceValidMedia: Function;

    get injected() {
        return this.props as InjectedProps;
    }

    constructor(props: CloudinaryImageUploadProps) {
        super(props);

        this.state = {
            isFileOver: false,
        };

        this.onDrop = this.onDrop.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.openFinder = this.openFinder.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentWillUnmount() {
        this.injected.mediaGalleryFlowStore.removeAllMediaToUpload();
    }

    initializeMediaToUpload = (files: FileList) => {
        const filesToUpload = files;
        this.injected.mediaGalleryFlowStore.initMediaToUpload(filesToUpload, this.props.limitFiles);
    }

    validateAllFiles = (files: FileList) => {
        const { validateFileType } = this.props;
        for (let index = 0; index < files.length; index++) {
            const file = files.item(index);

            if (!validateFileType(file!)) {
                return false;
            }
        }

        return true;
    }

    async onDrop(ev: DragEvent<HTMLDivElement>) {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        ev.preventDefault();
        if (
            ev.dataTransfer.files
            && this.validateAllFiles(ev.dataTransfer.files)
        ) {
            const files = ev.dataTransfer.files;
            this.setState({
                isFileOver: false
            });
            this.initializeMediaToUpload(files);
        } else {
            this.setState({
                isFileOver: false
            });
            notificationStore.openNotificationWithIcon('error', {
                placement: 'topRight',
                message: formatMessage(cloudinaryImageUploadLabels.fileNotSupported)
            });
        }

    }

    onDragOver(ev: DragEvent<HTMLDivElement>) {
        ev.preventDefault();
        this.setState({
            isFileOver: true
        });
    }

    onDragLeave(ev: DragEvent<HTMLDivElement>) {
        ev.preventDefault();
        this.setState({
            isFileOver: false
        });
    }

    onRemove = (mediaToRemove: Media) => (e: MouseEvent<any>) => {
        e.preventDefault();
        this.injected.mediaGalleryFlowStore.removeMediaToUpload(mediaToRemove);
    }

    async onUpload() {
        const { mediaGalleryFlowStore, notificationStore, intl: { formatMessage } } = this.injected;
        notificationStore.openNotificationWithIcon('info', {
            key: NOTIFICATION_KEY_CONSTANTS.MEDIA_UPLOAD_REQUEST,
            placement: 'topRight',
            duration: 0,
            message: formatMessage(cloudinaryImageUploadLabels.uploadMessage_inProgress),
            description: formatMessage(cloudinaryImageUploadLabels.uploadDescription_inProgress)
        });
        try {
            const results = await mediaGalleryFlowStore.uploadAllFiles();
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIA_UPLOAD_REQUEST);
            results.map(result => {
                if (result.status === 'fulfilled') {
                    const { value } = result;
                    notificationStore.openNotificationWithIcon('success', {
                        placement: 'topRight',
                        message: formatMessage(
                            cloudinaryImageUploadLabels.uploadMessage_success, { name: value.fileName })
                    });

                } else {
                    notificationStore.openNotificationWithIcon('error', {
                        placement: 'topRight',
                        message: result.reason.error && result.reason.error.type === 'CloudinaryMediaLimitError' ?
                            formatMessage(
                                cloudinaryImageUploadLabels.uploadMessage_failure_limit,
                                { name: result.reason.fileName }
                            )
                            : formatMessage(
                                cloudinaryImageUploadLabels.uploadMessage_failure, { name: result.reason.fileName })
                    });
                }
            });

            const successResults = results.filter(result => result.status === 'fulfilled') as PromiseFulfilledResult<
                { url: string; mediaPublicId: string; fileName: string; }>[];

            setTimeout(
                () => {
                    notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIA_UPLOAD_REQUEST);
                    if (this.injected.mediaGalleryFlowStore.buildParentFolder().length > 0) {
                        this.injected.mediaGalleryFlowStore
                            .setFolderPath(this.injected.mediaGalleryFlowStore.buildParentFolder());
                    } else {
                        this.injected.mediaGalleryFlowStore.clearFolderPath();
                    }
                    if (results.filter(result => result.status === 'rejected').length === 0) {
                        this.props.onUpload(successResults);
                    }
                    this.injected.mediaGalleryFlowStore.removeSuccessfullyUploadedMedia();
                },
                2000);

        } catch (e) {
            notificationStore.closeNotification(NOTIFICATION_KEY_CONSTANTS.MEDIA_UPLOAD_REQUEST);
            notificationStore.openNotificationWithIcon('error', {
                placement: 'topRight',
                message: formatMessage(cloudinaryImageUploadLabels.uploadMessage_error),
                description: formatMessage(cloudinaryImageUploadLabels.uploadDescription_error)
            });
        }
    }

    openFinder() {
        if (this.browseFile) {
            this.browseFile.click();
        }
    }

    onChangeFile(ev: ChangeEvent<HTMLInputElement>) {
        const { notificationStore } = this.injected;
        const { formatMessage } = this.props.intl;
        const target = ev.target;
        if (
            target.files
            && this.validateAllFiles(target.files)
        ) {
            const files = target.files;
            this.initializeMediaToUpload(files);
        } else {
            notificationStore.openNotificationWithIcon('error', {
                placement: 'topRight',
                message: formatMessage(cloudinaryImageUploadLabels.fileNotSupported)
            });
        }
    }

    onRemoveMedia = (media: Media) => {
        this.injected.mediaGalleryFlowStore.removeMediaToUpload(media);
    }

    render() {
        const { isFileOver } = this.state;
        const { formatMessage } = this.props.intl;

        const mediaListClassName = classNames(styles.CloudinaryImageUploadMediaList, {
            [styles.MoreThanTwoMedia]: this.injected.mediaGalleryFlowStore.mediaToUpload.length > 2
        });

        return (
            <div
                className={styles.CloudinaryImageUploadDropZoneContainer}
                onDrop={this.onDrop}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                data-id="media-upload-section"
            >
                <div
                    className={`${styles.CloudinaryImageUploadDropZoneInfo} ${
                        isFileOver
                            ? styles.CloudinaryImageUploadDropZoneInfoOnDrag
                            : ''
                        }`}
                >
                    {
                        this.injected.mediaGalleryFlowStore.mediaToUpload.length > 0 ?
                            // Cycle over mediaToUpload and render a component for each of them
                            <div className={styles.CloudinaryImageUploadDropZoneUploadStep}>
                                <div className={mediaListClassName}>
                                    {
                                        this.injected.mediaGalleryFlowStore.mediaToUpload.map(
                                            media => (
                                                <MediaToUpload onRemoveMedia={this.onRemoveMedia} media={media} />
                                            )
                                        )
                                    }
                                </div>
                                <Button
                                    type="primary"
                                    id="upload-media-button"
                                    className={styles.CloudinaryImageUploadButton}
                                    onClick={this.onUpload}
                                    loading={this.injected.mediaGalleryFlowStore.mediaAreUploading}
                                    disabled={!this.injected.mediaGalleryFlowStore.areMediaReadyToBeUploaded}
                                    size="large"
                                >
                                    <FormattedMessage
                                        id="CloudinaryImageUpload.uploadLabel"
                                        defaultMessage="Upload"
                                    />
                                </Button>
                            </div>
                            :
                            <div onClick={this.openFinder} className={styles.CloudinaryImageUploadEmptyContainer}>
                                <div>
                                    <input
                                        type="file"
                                        id="media-upload-file"
                                        ref={(ref) => this.browseFile = ref}
                                        style={{ display: 'none' }}
                                        onChange={this.onChangeFile}
                                    />
                                    <Icon type="upload" />
                                    <p
                                        className={styles.CloudinaryImageUploadInfoLabel}
                                    >
                                        <FormattedMessage
                                            id="CloudinaryImageUpload.infoLabel"
                                            defaultMessage="Click in this area or drag an image to upload it"
                                        />
                                    </p>
                                </div>
                            </div>
                    }
                    <Button
                        icon="double-left"
                        type="primary"
                        onClick={this.props.previousStep}
                        className={styles.CloudinaryImageUploadPreviousStep}
                        title={formatMessage(cloudinaryImageUploadLabels.backToMediaGallery)}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(CloudinaryImageUpload);
