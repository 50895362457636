import React from 'react';
import { SchemaTypeIds } from '@contentchef/contentchef-types';
import { DynamicFieldTypes, FieldCloudinaryImage } from '../FormFields';
import { CloudinaryImageField } from '../../../components';
import { fieldMatchersUtils } from './FieldMatchersUtils';
import { FieldMatcher } from './FormFieldMatchersFactory';
import { defaultFieldWrapper } from './DefaultFieldWrapper';

export class CloudinaryImageTypeMatcher implements FieldMatcher {
    maybeCreateComponent(
        id: string, userLocale: string, onChange: (e: any) => void, field: DynamicFieldTypes
    ): JSX.Element | null {
        if (!fieldMatchersUtils.typeMatcher(field, SchemaTypeIds.CLOUDINARY_IMAGE)) { return null; }
        const fieldCloudinaryImage = field as FieldCloudinaryImage;

        function elementToRender(disabled: boolean) {
            return (
                <CloudinaryImageField
                    id={id}
                    disableActions={disabled}
                    fieldData={fieldCloudinaryImage}
                    onChange={onChange}
                />
            );
        }

        return defaultFieldWrapper(elementToRender);
    }
}
