import { ContentDefinitionResponse, ContentResponse, ContentInfo } from '@contentchef/contentchef-types';
import { action, observable, runInAction } from 'mobx';
import {
    FormMetaStoreModel,
    FormMetaModel,
    CommonFieldsValue,
    ChangedFieldsModel,
    GenericErrorModel,
    FieldViolationModel,
    InitialCommonValuesModel
} from './formMetaStoreModel';
import {
    Field
} from '@contentchef/contentchef-types';
import {
    FieldChannels,
    FieldDynamic,
    FieldName,
    FieldOnlineRange,
    FieldPublicId, FieldRepository,
    FieldTags
} from '../../services/FormUtils/FormFields';
import { validationErrorMessage } from '../../services/FormUtils/FormValidationErrorMessages';

class FormMetaStore implements FormMetaStoreModel {
    @observable formMeta: FormMetaModel = {} as FormMetaModel;
    @observable genericErrors: GenericErrorModel = {
        contentDefinitionNotFound: undefined
    };
    @observable changedFields: ChangedFieldsModel[] = [];
    contentInfo: ContentInfo;

    static isUnknownContentDefinitionError(errors: FieldViolationModel[]) {
        return errors[0].field === 'ContentDefinitionId';
    }

    @action
    initializeStore(
        contentDef: ContentDefinitionResponse,
        contentData?: ContentResponse
    ) {
        this.formMeta = {} as FormMetaModel;
        this.genericErrors = { contentDefinitionNotFound: undefined };

        this.changedFields = [];
        const contentDefSchema = contentDef.schema;
        this.formMeta.language = contentDefSchema.lang;
        this.formMeta.fragments = contentDefSchema.fragments;
        this.contentInfo = {
            contentDefinitionId: contentDef.id,
            contentDefinitionMnemonicId: contentDef.mnemonicId,
        };

        if (contentData) {
            const initialCommonValue = {
                name: contentData.name,
                repository: contentData.repository.id,
                publicId: contentData.publicId,
                onlineRange: { onlineDate: contentData.onlineDate, offlineDate: contentData.offlineDate },
                tags: contentData.tags,
                channels: contentData.channels
            };
            this.contentInfo.repositoryId = contentData.repository.id;
            this.contentInfo.contentId = contentData.id;
            this.contentInfo.contentPublicId = contentData.publicId;
            this.createCommonFieldsModel(initialCommonValue);
            this.createDynamicFieldsModel(
                contentDefSchema.fields, contentDefSchema.lang, contentData.payload);
        } else {
            this.createCommonFieldsModel({} as InitialCommonValuesModel);
            this.createDynamicFieldsModel(contentDefSchema.fields, contentDefSchema.lang);
        }
    }

    @action
    addChangedFields(fieldToAdd: string) {
        if (!this.changedFields.find((field) => field.fieldUniqueId === fieldToAdd)) {
            this.changedFields.push({ fieldUniqueId: fieldToAdd });
        }
    }

    @action
    removeChangedFields(fieldToRemove: string) {
        this.changedFields = this.changedFields.filter((field) => field.fieldUniqueId !== fieldToRemove);
    }

    getCommonFieldsValue(): CommonFieldsValue {
        const { name, repository, publicId, onlineRange, tags, channels } = this.formMeta;
        return {
            name: <string> name.value,
            repository: <number> repository.value,
            publicId: publicId.value,
            ...onlineRange.getValues(),
            tags: <string[]> tags.value.toJS(),
            channels: channels.value.map(channel => channel.id)
        };
    }

    getDynamicFieldValues(): object {
        const { dynamicFields } = this.formMeta;
        return dynamicFields.getSubmittablePayload();
    }

    getFormValue(): object {
        return {
            ...this.getCommonFieldsValue(),
            payload: this.getDynamicFieldValues()
        };
    }

    @action
    setValidationErrors(errors: FieldViolationModel[]) {
        runInAction(() => {
            if (FormMetaStore.isUnknownContentDefinitionError(errors)) {
                this.genericErrors.contentDefinitionNotFound =
                    validationErrorMessage['generic.contentDefinitionNotFound'];
            } else {
                this.formMeta.dynamicFields.setFieldsValidationErrors(errors);
                this.genericErrors.contentDefinitionNotFound = undefined;
            }
        });
    }

    @action
    unsetGenericErrors() {
        this.genericErrors.contentDefinitionNotFound = undefined;
    }

    @action
    setClientSideErrors(errors: any) {
        this.formMeta.dynamicFields.setClientSideErrors(errors);
    }

    @action
    setFieldPathValue(fieldPath: string, value: any) {
        this.formMeta.dynamicFields.setFieldPathValue(fieldPath, value);
    }

    @action
    private createCommonFieldsModel(initialCommonValues: InitialCommonValuesModel) {
        this.formMeta.name = new FieldName(initialCommonValues.name);
        this.formMeta.repository = new FieldRepository(initialCommonValues.repository);
        this.formMeta.publicId = new FieldPublicId(initialCommonValues.publicId);
        this.formMeta.onlineRange = new FieldOnlineRange(initialCommonValues.onlineRange);
        this.formMeta.tags = new FieldTags(initialCommonValues.tags);
        this.formMeta.channels = new FieldChannels(initialCommonValues.channels);
    }

    @action
    private createDynamicFieldsModel(
        schemaFields: Field[], definitionLocale: string, payloadData?: object
    ) {
        this.formMeta.dynamicFields = new FieldDynamic(
            this.formMeta.fragments, schemaFields,
            definitionLocale, payloadData);
    }
}

const formMetaStore = new FormMetaStore();
export default formMetaStore;
