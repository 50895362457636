import React, { Component } from 'react';
import { Pagination } from 'antd';
import { inject, observer } from 'mobx-react';
import { ContentDefinitionsFooterProps, InjectedProps } from './ContentDefinitionsFooterModel';
import {
    ContentDefinitionListUrlParams
} from '../../stores/contentDefinitionListStore/contentDefinitionListStoreModel';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/tables';

import styles from './ContentDefinitionsFooter.module.scss';

@inject('contentDefinitionListStore')
@observer
class ContentDefinitionsFooter extends Component<ContentDefinitionsFooterProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    onChangePage = pageNumber => {
        if (pageNumber !== DEFAULT_PAGE) {
            this.injected.contentDefinitionListStore.searchParams.setUrlParam(
                ContentDefinitionListUrlParams.byPage, pageNumber.toString()
            );
        } else {
            this.injected.contentDefinitionListStore.searchParams.deleteUrlParam(
                ContentDefinitionListUrlParams.byPage
            );
        }
    }

    onPaginationChangeSize = (current, pageSize) => {
        if (pageSize !== DEFAULT_PAGE_SIZE) {
            this.injected.contentDefinitionListStore.searchParams.setUrlParam(
                ContentDefinitionListUrlParams.bySize, pageSize.toString()
            );
        } else {
            this.injected.contentDefinitionListStore.searchParams.setUrlParam(
                ContentDefinitionListUrlParams.bySize, pageSize.toString()
            );
        }
    }

    render() {
        const { currentPage, currentSize } = this.props;
        const { total: totalCount } = this.injected.contentDefinitionListStore.contentDefinitionListData;
        return (
            <Pagination
                className={styles.ContentDefinitionsFooterPagination}
                showSizeChanger={true}
                pageSize={currentSize}
                total={totalCount}
                current={currentPage}
                onChange={this.onChangePage}
                onShowSizeChange={this.onPaginationChangeSize}
            />
        );
    }
}

export default ContentDefinitionsFooter;
