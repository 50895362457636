import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { injectIntl, defineMessages } from 'react-intl';
import { HomeProps, InjectedProps } from './HomeModel';
import { SceneContainer, LatestActivities } from '../../components';
import { DataAttributes, DataAttributesBuilder } from '../../services/DataAttributesBuilder';
import { WithLoadingData, withDashboardStores } from '../../hoc';
import { NavigationItem } from '../../stores/navStore/navStore';
import { NAVIGATION_LABELS } from '../../constants/navigation-internationalized-labels';
import { dashboardPath } from '../../constants/routing-constants';
import { NAVIGATION_ITEM_IDENTIFIERS } from '../../constants/navigation-item-identifier';
import { NAVIGATION_PRIORITY } from '../../stores/navStore/navStoreModel';

import SpaceStats from './components/SpaceStats';
import Header from './components/Header';

const homeLabels = defineMessages({
    title: {
        id: 'Home.title',
        defaultMessage: 'Your dashboard'
    }
});

@inject('activityStore', 'navStore', 'spaceDetailStore')
@observer
class Home extends Component<HomeProps> {
    get injected() {
        return this.props as InjectedProps;
    }

    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const { match: { params: { spaceId } } } = this.props;
        this.injected.navStore.setNavigationPath(
            new NavigationItem(
                NAVIGATION_ITEM_IDENTIFIERS.DASHBOARD,
                dashboardPath(spaceId),
                formatMessage(NAVIGATION_LABELS.dashboard),
                NAVIGATION_PRIORITY.FIRST
            )
        );
    }

    componentWillUnmount() {
        this.injected.navStore.removeItem(NAVIGATION_ITEM_IDENTIFIERS.DASHBOARD);
    }

    initializeData = () => {
        return Promise.all([
            this.injected.activityStore.initializeHomeLatestActivities(),
            this.injected.spaceDetailStore.refresh()
        ]);
    }

    createSceneContainerAttributes = (): DataAttributes => {
        return new DataAttributesBuilder('dashboard')
            .setNameAttribute('dashboard')
            .build();
    }

    render() {
        const { intl: { formatMessage } } = this.props;
        const { activityStore: { userLatestActivities, teamLatestActivities },
            spaceDetailStore: { spaceApiCredentials, spaceStats } } = this.injected;

        return (
            <SceneContainer
                title={formatMessage(homeLabels.title)}
                dataAttributes={this.createSceneContainerAttributes().Attributes}
            >
                <WithLoadingData promise={this.initializeData}>
                    <Header spaceApiCredentials={spaceApiCredentials} />
                    <SpaceStats spaceStats={spaceStats} />
                    <LatestActivities
                        userActivity={userLatestActivities.items}
                        teamActivity={teamLatestActivities.items}
                    />
                </WithLoadingData>
            </SceneContainer>
        );
    }
}

export default withDashboardStores(injectIntl(Home));
