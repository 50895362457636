import React from 'react';
import { AsideItemProps } from './AsideItemModel';
import styles from './AsideItem.module.scss';

const AsideItem = (props: AsideItemProps) => {
    const { children, header } = props;
    return (
        <div
            className={styles.AsideItemDefault}
        >
            {header && <p className={styles.AsideItemHeader}>{header}</p>}
            {children}
        </div>
    );
};

export default AsideItem;
