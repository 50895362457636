import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { DynamicContentFieldProps, DynamicContentFieldState } from './DynamicContentFieldModel';
import { ArrayField, DynamicField, FragmentField, LabelWithHint } from '../../';
import { injectIntl } from 'react-intl';
import { createDynamicFieldRules } from '../../../services/FormUtils/FormFieldRules';
import {
    FieldArray,
    FieldFragment,
    FieldOneFragmentOf,
    SimpleFieldTypes,
} from '../../../services/FormUtils/FormFields';
import {
    ArraySchemaType,
    Field,
    SchemaTypeIds
} from '@contentchef/contentchef-types';
import OneFragmentOfField from '../OneFragmentOfField/OneFragmentOfField';
import styles from './DynamicContentField.module.scss';
import { withForm } from '../../../hoc';
import ExtensionField from '../ExtensionField';

const FormItem = Form.Item;

@observer
class DynamicContentField extends Component<DynamicContentFieldProps, DynamicContentFieldState> {

    createArrayField = (fieldMetaData: FieldArray<Field<ArraySchemaType>>) => {
        const { parentFieldId } = this.props;
        return (
            <ArrayField
                arrayFieldMeta={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createFragmentField = (fieldMetaData: FieldFragment) => {
        const { parentFieldId } = this.props;
        return (
            <FragmentField
                fragmentMetaData={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createOneFragmentOfField = (fieldMetaData: FieldOneFragmentOf) => {
        const { parentFieldId } = this.props;
        return (
            <OneFragmentOfField
                fieldMetaData={fieldMetaData}
                parentFieldId={parentFieldId}
            />
        );
    }

    createDynamicField = (fieldData: SimpleFieldTypes) => {
        const { form: { getFieldDecorator, getFieldError, },
            parentFieldId, intl: { locale, formatMessage } } = this.props;

        const formItemId = parentFieldId ? `${parentFieldId}.${fieldData.id}` : fieldData.id;

        const verifyFieldHasClientError = () => {
            const errors = getFieldError(formItemId);
            return errors !== undefined && errors.length > 0;
        };

        return (
            <FormItem
                htmlFor={formItemId}
                label={
                    !!fieldData.hint
                        ? <LabelWithHint
                            label={fieldData.labels[locale] || fieldData.labels[fieldData.locale]}
                            hint={fieldData.hint[locale] || fieldData.hint[fieldData.locale]}
                        />
                        : fieldData.labels[locale] || fieldData.labels[fieldData.locale]
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                validateStatus={fieldData.errors.length > 0 ? 'error' : undefined}
                help={
                    fieldData.errors.length > 0 ?
                        fieldData.getFieldErrorMessages(
                            formatMessage,
                            locale
                        ) :
                        undefined
                }
            >
                {fieldData.extension ?
                    getFieldDecorator(formItemId, {
                        initialValue: fieldData.value,
                        rules: createDynamicFieldRules(fieldData, formatMessage, locale)

                    })(<ExtensionField
                        fieldData={fieldData}
                        verifyFieldHasClientError={verifyFieldHasClientError}
                    />) :
                    getFieldDecorator(formItemId, {
                        initialValue: fieldData.value,
                        rules: createDynamicFieldRules(fieldData, formatMessage, locale)

                    })(<DynamicField
                        fieldData={fieldData}
                        verifyFieldHasClientError={verifyFieldHasClientError}
                    />)
                }
            </FormItem>
        );
    }

    handleFields = () => {
        const { genericFieldMeta } = this.props;
        if (genericFieldMeta.extension) {
            return this.createDynamicField(genericFieldMeta as SimpleFieldTypes);
        }
        switch (genericFieldMeta.type) {
            case SchemaTypeIds.ARRAY: {
                return this.createArrayField(genericFieldMeta as FieldArray<Field<ArraySchemaType>>);
            }
            case SchemaTypeIds.FRAGMENT: {
                return this.createFragmentField(genericFieldMeta as FieldFragment);
            }
            case SchemaTypeIds.ONE_FRAGMENT_OF: {
                return this.createOneFragmentOfField(genericFieldMeta as FieldOneFragmentOf);
            }
            default:
                return this.createDynamicField(genericFieldMeta as SimpleFieldTypes);
        }
    }

    render() {
        return (
            <div className={styles.DynamicFieldContainer}>
                {this.handleFields()}
            </div>
        );
    }
}

export default withForm(injectIntl(DynamicContentField));
