export enum NAVIGATION_ITEM_IDENTIFIERS {
    REPOSITORIES = 'repositories',
    CUSTOM_FIELD_LIST = 'customFieldsList',
    DEFINITIONS = 'definitions',
    NEW_DEFINITION = 'new_definition',
    EDIT_DEFINITION = 'edit_definition',
    CONTENTS = 'contents',
    DASHBOARD = 'dashboard',
    MEDIAGALLERY = 'mediagallery',
    PUBLISH_TO_LIVE = 'publishToLive',
    PUBLISHING_CHANNEL = 'publishingChannel',
    CUSTOM_FIELD = 'customField',
    USERS = 'users',
    WEBHOOKS = 'webhooks',
    APIKEYS = 'apikeys',
    EXTENSIONS = 'extensions'
}
