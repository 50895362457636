import React from 'react';
import { FieldEditTabProps } from '../../types';
import { observer } from 'mobx-react';
import { ArrayConstraints } from '../../FieldSerializer/fields/array';
import IndexingAttribute from '../Constraints/Indexing';
import ArrayAdditionalDetails from './index';
import { arrayItemIndexingMappedErrors } from '../../../../services/FormUtils/DefinitionFieldMappedErrors';

function ListOfItemsDetails(props: FieldEditTabProps<ArrayConstraints>) {
    return (
        <>
            <IndexingAttribute
                relatedTabKey={'detailsTab'}
                mappedErrors={arrayItemIndexingMappedErrors}
                type={props.fieldEditorStore.serializedField.constraints.items.type}
                value={props.fieldEditorStore.serializedField.constraints.items['enableFacet']}
                onChange={props.fieldEditorStore.updateEnableFacet}
            />
            <ArrayAdditionalDetails
                id={'ArrayAdditionalDetails'}
                isArrayChildren={true}
                fieldToRenderType={props.fieldEditorStore.serializedField.editingSubType!}
                fieldEditorStore={props.fieldEditorStore}
                defaultLang={props.defaultLang}
                constraints={props.constraints.items.constraints}
                onChangeConstraint={props.fieldEditorStore.updateArrayItemsConstraint}
            />
        </>
    );
}

export default observer(ListOfItemsDetails);
